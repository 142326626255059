/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import { AssetSetupData } from '../models/assets';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import {
  AssetConnection,
  AssetCreateInput,
  AssetMetadataInput,
  AssetOutput,
  AssetResponse,
  AssetType,
  AssetTypeIdInput,
  AssetUpdateInput,
  CreateAssetMutationVariables,
  FileUpload,
  FileUploadInput,
  FilterInput,
  GetAllAssetsQuery,
  GetAllAssetsQueryVariables,
  GetAssetByIdQuery,
  GetAssetByIdQueryVariables,
  GetAssetTypeTemplateByAssetTypeIdQuery,
  GetAssetTypeTemplateByAssetTypeIdQueryVariables,
  InitializeFileUploadMutationVariables,
  UpdateAssetMutationVariables,
} from '../../../awsAppSync/API';
import {
  CREATE_ASSET_ERROR,
  FETCHING_ASSET_SETUP_ERROR,
  GENERIC_FAILED_ERROR,
  UPDATE_ASSET_ERROR,
} from '../../shared/constants';
import {
  createAsset,
  initializeFileUpload,
  updateAsset,
} from '../../../awsAppSync/graphql/mutations';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import {
  getAllAssets,
  getAssetById,
  getAssetTypeTemplateByAssetTypeId,
} from '../../../awsAppSync/graphql/queries';

@Injectable({
  providedIn: 'root',
})
export class AssetSetupService {
  public assetSetup: AssetSetupData[] = [];
  private currentDataItem: any;

  constructor(
    private awsService: BaseAWSRequestService,
    private popupDialogService: PopupDialogService,
  ) {}

  //Get all Asset setup Grid Data
  // async getAssetSetupGridData(
  //   filter: FilterInput,
  // ): Promise<AssetConnection | null> {
  //   try {
  //     const variables: GetAssetsQueryVariables = { filter };
  //     const response = (await this.awsService.client.graphql({
  //       query: getAssets,
  //       variables: variables,
  //     })) as { data: GetAssetsQuery };
  //     return response.data.getAssets!;
  //   } catch (error) {
  //     console.log(FETCHING_ASSET_SETUP_ERROR, error);
  //     return null;
  //   }
  // }

  // Get all Asset setup Grid Data using the generic executeGetQuery method
  // async getAssetSetupGridData(
  //   filter: FilterInput,
  // ): Promise<AssetConnection | null> {
  //   try {
  //     const variables: GetAssetQueryVariables = { filter };

  //     // Call the generic query method
  //     const assetConnection =
  //       await this.awsService.executeGetQuery<GetAssetQuery>(
  //         getAsset,
  //         variables,
  //       );

  //     // Return the required data or null if not available
  //     return assetConnection.getAssets || null;
  //   } catch (error) {
  //     console.log(FETCHING_ASSET_SETUP_ERROR, error);
  //     return null;
  //   }
  // }

  removeTimeStamp(date: string) {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  }

  public getAssets(
    filter: FilterInput,
  ): Promise<{ assets: AssetSetupData[]; totalCount: number }> {
    return new Promise((resolve, reject) => {
      this.getAssetSetupGridData(filter)
        .then((assetConnection) => {
          if (assetConnection?.items) {
            const mappedAssets = assetConnection.items.map((asset) => ({
              id: asset!.id ?? '',
              pk: asset!.pk ?? '',
              sk: asset!.sk ?? '',
              level1Value: asset!.level1Value ?? '',
              level1ValueId: asset!.level1ValueId ?? '',
              level2Value: asset!.level2Value ?? '',
              level2ValueId: asset!.level2ValueId ?? '',
              level3Value: asset!.level3Value ?? '',
              level3ValueId: asset!.level3ValueId ?? '',
              level4Value: asset!.level4Value ?? '',
              level4ValueId: asset!.level4ValueId ?? '',
              route: asset!.route ?? '',
              segmentRelation: asset!.segmentRelation ?? '',
              assetName: asset!.assetName ?? '',
              assetType: asset!.assetType ?? '',
              latitude: asset!.latitude ?? '',
              longitude: asset!.longitude ?? '',
              gpsCoordinate: asset!.gpsCoordinate ?? '',
              measurementFrequency: asset!.measurementFrequency ?? '',
              status: asset!.status ?? '',
              assetDelinquencyDate: asset!.assetDelinquencyDate
                ? this.removeTimeStamp(asset!.assetDelinquencyDate)
                : null,
              measurements:
                asset!.measurementCriteria?.map((measurement) => ({
                  id: measurement?.id ?? '',
                  measurementSource: measurement?.measurementSource ?? '',
                  measurementType: measurement?.measurementType ?? '',
                  measurementOperator: measurement?.measurementOperator ?? '',
                  criteria: measurement?.criteria
                    ? measurement.criteria + measurement.unit
                    : '',
                  level4Value: measurement?.level4Value ?? '',
                  level4ValueId: measurement?.level4ValueId ?? '',
                  level4Relation: measurement?.level4Relation ?? '',
                  isCompliance: measurement?.compliance ?? false,
                  isDeleted: false,
                })) ?? [],
            }));
            this.assetSetup = mappedAssets;
          }
          resolve({
            assets: this.assetSetup,
            totalCount: assetConnection?.totalCount || 0,
          });
        })
        .catch((error) => {
          console.log(FETCHING_ASSET_SETUP_ERROR, error);
          reject(error);
        });
    });
  }
  //createAsset method using the generic executeMutation method
  async createAsset(input: AssetCreateInput): Promise<AssetResponse | null> {
    try {
      const variables: CreateAssetMutationVariables = { input };
      // Call the generic mutation method
      const assetResponse =
        await this.awsService.executeMutation<AssetResponse>(
          createAsset,
          variables,
        );

      // Check if the result contains the asset item
      if (assetResponse && assetResponse.createAsset.status) {
        return assetResponse.createAsset;
      }
      return null;
    } catch (error) {
      console.error(CREATE_ASSET_ERROR, error);
      return null;
    }
  }
  async updateAsset(input: AssetUpdateInput) {
    try {
      const variables: UpdateAssetMutationVariables = { input };
      // Call the generic mutation method
      const assetResponse =
        await this.awsService.executeMutation<AssetResponse>(
          updateAsset,
          variables,
        );
      // Check if the result contains the asset item
      if (assetResponse && assetResponse.updateAsset.status) {
        return assetResponse.updateAsset;
      }
    } catch (error) {
      console.error(UPDATE_ASSET_ERROR, error);
      return null;
    }
  }

  // Upload asset image using the generic executeMutation method
  async uploadAssetImage(input: FileUploadInput): Promise<FileUpload | null> {
    try {
      const variables: InitializeFileUploadMutationVariables = { input };

      // Call the generic mutation method
      let uploadedImgResult = await this.awsService.executeMutation<FileUpload>(
        initializeFileUpload,
        variables,
      );
      uploadedImgResult = uploadedImgResult.initializeFileUpload;
      // Check if the uploaded image result has a presignedURL
      if (uploadedImgResult && uploadedImgResult.presignedURL) {
        return uploadedImgResult;
      }

      return null;
    } catch (error) {
      console.error(CREATE_ASSET_ERROR, error);
      return null;
    }
  }

  // async deleteAsset(input: DeleteAssetInput): Promise<AssetResult | null> {
  //   const variables: DeleteAssetMutationVariables = { input };
  //   try {
  //     const response = await this.awsService.client.graphql({
  //       query: deleteAsset,
  //       variables: variables,
  //     });
  //     const assetDeleteResult: AssetResult = response.data.deleteAsset;
  //     if (assetDeleteResult && assetDeleteResult.item) {
  //       return assetDeleteResult;
  //     }
  //     return null;
  //   } catch (error: any) {
  //     console.error(DELETE_ASSET_ERROR, error);
  //     return null;
  //   }
  // }

  // Delete asset using the generic executeMutation method
  // async deleteAsset(input: DeleteAssetInput): Promise<AssetResult | null> {
  //   try {
  //     const variables: DeleteAssetMutationVariables = { input };

  //     // Call the generic mutation method
  //     let assetDeleteResult =
  //       await this.awsService.executeMutation<AssetResult>(
  //         deleteAsset,
  //         variables,
  //       );

  //     assetDeleteResult = assetDeleteResult.deleteAsset;

  //     // Check if the assetDeleteResult contains the necessary item
  //     if (assetDeleteResult && assetDeleteResult.item) {
  //       return assetDeleteResult;
  //     }

  //     return null;
  //   } catch (error: any) {
  //     console.error(DELETE_ASSET_ERROR, error);
  //     return null;
  //   }
  // }
  // async deleteAssetOnConfirmClick() {
  //   //Need to remove this static values once API Schema is UPdated
  //   //we will take the values from API responce
  //   const input: DeleteAssetInput = {
  //     primaryCompanyId: '01J30BV3FPHM5MJVKCKN9GWXT0',
  //     companyId: '01J30DHX3DJG4ZFN1XJYFDPV6J',
  //     regionId: '01J30F3CMP9B772V6NS5NDGMX4',
  //     id: '01J449S90880M92K405ZSFSN7Z',
  //   };
  //   const _deleteAseet = await this.deleteAsset(input);
  //   if (_deleteAseet?.status?.toLocaleLowerCase() === SUCCESS) {
  //     //Success
  //     this.popupDialogService.openDialog(
  //       DELETE_SUCCESSFULL,
  //       DELETE_ASSET_SUCCESSFULL_MSG,
  //       SUCCESS,
  //       DONE,
  //     );
  //   } else {
  //     //Failed
  //     this.popupDialogService.openDialog(
  //       DELETE_FAILED,
  //       DELETE_ASSET_FAILED__MSG,
  //       FAILED,
  //       RETRY,
  //       undefined,
  //       true,
  //       CANCEL_BTN_TEXT,
  //       true,
  //       DELETE_ASSEST_METHOD,
  //     );
  //   }
  // }
  setCurrentDataItem(data: any): void {
    this.currentDataItem = data;
  }
  getCurrentDataItem(): any {
    return this.currentDataItem;
  }

  async getAssetSetupGridData(
    filter: FilterInput,
  ): Promise<AssetConnection | null> {
    try {
      const variables: GetAllAssetsQueryVariables = { filter };
      const result = await this.awsService.executeGetQuery<GetAllAssetsQuery>(
        getAllAssets,
        variables,
      );
      return result.getAllAssets ?? null; // Using nullish coalescing operator
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }
  // Commenting asset Type Template schema change code
  async getAssetTypeTemplateById(
    input: AssetTypeIdInput,
  ): Promise<AssetType | null> {
    try {
      const variables: GetAssetTypeTemplateByAssetTypeIdQueryVariables = {
        input,
      };
      const result =
        await this.awsService.executeGetQuery<GetAssetTypeTemplateByAssetTypeIdQuery>(
          getAssetTypeTemplateByAssetTypeId,
          variables,
        );
      return result.getAssetTypeTemplateByAssetTypeId ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  async getAssetById(input: AssetMetadataInput): Promise<AssetOutput | null> {
    try {
      const variables: GetAssetByIdQueryVariables = { input };
      const response = await this.awsService.executeGetQuery<GetAssetByIdQuery>(
        getAssetById,
        variables,
      );
      return response.getAssetById ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }
}
