import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { API_ERROR_MSG, CANCEL_TEMPLATE_BTN_CONFIRM_TXT, CLOSE, EMAIL_REGEX, ERROR, FAILED, MOBILTEX_MEMBER_OVERVIEW, NO, SUCCESS, SURE_CANCEL_HEADER, SURE_CANCEL_MSG } from '../../shared/constants';
import { UserService } from '../../core/user.service';
import { CompanyOption, TimeZoneOption, UserCreateInput, UserOption, UserPrimaryCompanyInput, UserUpdateInput } from '../../../awsAppSync/API';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { CompanyService } from '../../core/services/company.service';
import { Store } from '@ngxs/store';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { SetSelectedUser } from '../../core/store/selectedUser.state';
import { City, Country, State } from 'country-state-city';
import { passwordValidator } from '../../shared/helpers/passwordValidators';
import { RemoveMTX_Member, SelectedMTX_MemberState } from '../../core/store/MTX_Member.state';
import { UserRoleModel } from '../../core/models/user.module';

@Component({
  selector: 'app-add-mtx-member',
  templateUrl: './add-mtx-member.component.html',
  styleUrl: './add-mtx-member.component.css'
})

export class AddMTXMemberComponent implements OnInit, OnDestroy {
  @ViewChild('activeCheckbox', { static: true }) activeCheckbox!: ElementRef;
  form: FormGroup;
  isActive = false;
  isActiveText = 'OFF';
  existingUserDropdown: UserOption[] = [];
  countries: any[] = [];
  states: any[] = [];
  cities: any[] = [];
  allTimeZone: (TimeZoneOption | null)[] | null | undefined;
  companyOptions: (CompanyOption | null)[] | null | undefined;
  selectedTimeZone = '';
  selectedCountry = '';
  selectedCountryCode = '';
  selectedCountryName = '';
  selectedState = '';
  selectedStateName = '';
  selectedCityName = '';
  public isLoading = false;
  primaryCompanyName$: Observable<string | null | undefined> | undefined;
  selectedExistingUser: UserOption | undefined;
  loggedUserName$: Observable<string | null | undefined> | undefined;
  loggedUserName = '';
  loggedInCorViewUserId$: Observable<string | null | undefined> | undefined;
  loggedInCorViewUserId = '';
  loggedInCorViewUserName$: Observable<string | null | undefined> | undefined;
  loggedInCorViewUserName = '';
  primaryCompanyID$: Observable<string | null | undefined> | undefined;
  primaryCompanyID = '';
  selectedMemberId$: Observable<string | null | undefined> | undefined;
  selectedMemberId = '';
  selectedMemberIsEditable$: Observable<boolean | null | undefined> | undefined;
  selectedMemberIsEditable = false;
  userName = '';
  selectedExistingUserID = "";
  filteredUsers: UserOption[] = [];
  userRoleList: UserRoleModel[] =[];
  showPassword = false;
  showConfirmPassword = false;

  constructor(
    private router: Router,
    private popupDialogService: PopupDialogService,
    private fb: FormBuilder,
    private companyService: CompanyService,
    private _userService: UserService,
    private store: Store,
    private toastr: CustomToastrService,
  ) {
    this.loggedUserName$ = this.store.select(
      AuthenticateUserState.getLoggedInName,
    );
    this.loggedInCorViewUserId$ = this.store.select(
      AuthenticateUserState.getCoreViewPrimaryCompanyId,
    );
    this.loggedInCorViewUserName$ = this.store.select(
      AuthenticateUserState.getCoreViewPrimaryCompanyName,
    );
    this.primaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
    this.primaryCompanyName$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyName,
    );
    this.selectedMemberId$ = this.store.select(
      SelectedMTX_MemberState.getSelectedMTX_MemberId,
    );
    this.selectedMemberIsEditable$ = this.store.select(
      SelectedMTX_MemberState.getSelectedMTX_MemberIsEdited,
    );
    this.form = this.fb.group(
      {
        userType: ['newUser'],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        userRole: ['',Validators.required],
        existingUserDropdown: [''],
        userName: ['', Validators.required],
        emailId: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
        phoneNumber: ['', Validators.pattern(/^[\d+-]+$/)],
        corporateOfficeNumber: [
          '',
          [Validators.required, Validators.pattern(/^[\d+-]+$/)],
        ],
        address: [''],
        city: [''],
        stateProvince: [''],
        postalZipCode: [''],
        country: [''],
        timeZone: ['', Validators.required],
        active: [true],
        password: ['', [Validators.required, passwordValidator()]],
        confirmPassword: ['', Validators.required],
      },
      { validators: this.passwordsMatchValidator },
    );
  }

  ngOnInit(): void {
    this.activeCheckbox.nativeElement.click();
    this.countries = Country.getAllCountries();
    this.loadCompanyOptions();
    this.loadTimeZone();
    this.primaryCompanyID$?.subscribe((id) => {
      this.primaryCompanyID = id!;
    });
    this.loggedUserName$?.subscribe((name) => {
      this.loggedUserName = name!;
    });
    this.loggedInCorViewUserId$?.subscribe((name) => {
      this.loggedInCorViewUserId = name!;
    });
    this.loggedInCorViewUserName$?.subscribe((name) => {
      this.loggedInCorViewUserName = name!;
    });
    this.selectedMemberId$?.subscribe((id) => {
      this.selectedMemberId = id!;
    });
    this.selectedMemberIsEditable$?.subscribe((value) => {
      this.selectedMemberIsEditable = value!;
    });
    this.loadUserRoles();
    this.loadData();
  }

  async loadUserRoles() {
    const userRoles = await this._userService.getAllRoles(true);
    if (userRoles && userRoles.items != null) {
      userRoles.items.forEach(element => {
        const role:UserRoleModel = {
          id: element?.id ?? '',
          name: element?.name ?? '',
          selectedRole: false
        }
        this.userRoleList.push(role);
      });
      // this.userRoleList = userRoles.items
      //   ?.filter((role): role is Role => role != null)
      //   .map(role => ({ ...role, selectedRole: false })) || [];
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetSelectedUser(false, ''));
  }

  async loadData() {
    if (this.selectedMemberIsEditable) {
      this.isLoading = true;
      const selectedUser = await this._userService.getUserByCoreViewUserId(
        this.selectedMemberId,
      );
      this.loggedInCorViewUserId = selectedUser?.coreViewUserId ?? '';
      this.selectedMemberId = selectedUser?.id ?? '';
      this.form = this.fb.group({
        userType: ['existingUser'],
        userName: [
          {
            value: selectedUser?.username != '' ? selectedUser?.username : '',
            disabled: true,
          },
        ],
        emailId: [
          {
            value: selectedUser?.email != '' ? selectedUser?.email : '',
            disabled: true,
          },
        ],
        phoneNumber: [
          {
            value: selectedUser?.cellPhone != '' ? selectedUser?.cellPhone : '',
            disabled: true,
          },
        ],
        corporateOfficeNumber: [
          {
            value:
              selectedUser?.officePhone != '' ? selectedUser?.officePhone : '',
            disabled: true,
          },
        ],
        address: [
          {
            value: selectedUser?.addr1 != '' ? selectedUser?.addr1 : '',
            disabled: true,
          },
        ],
        city: [
          {
            value: selectedUser?.addrCity != '' ? selectedUser?.addrCity : '',
            disabled: true,
          },
        ],
        stateProvince: [
          {
            value:
              selectedUser?.stateProvince != ''
                ? selectedUser?.stateProvince
                : '',
            disabled: true,
          },
        ],
        postalZipCode: [
          {
            value: selectedUser?.postalZip != '' ? selectedUser?.postalZip : '',
            disabled: true,
          },
        ],
        country: [
          {
            value: selectedUser?.country != '' ? selectedUser?.country : '',
            disabled: true,
          },
        ],
        timeZone: [
          {
            value: selectedUser?.timeZone != '' ? selectedUser?.timeZone : '',
            disabled: true,
          },
        ],
        active: [{ value: selectedUser?.active, disabled: true }],
      });
      const selectedRoleIds = selectedUser?.userRoles?.flatMap(x => x?.roleId) || [];
      this.userRoleList.forEach(element => {
        if (selectedRoleIds.some(roleId => roleId === element.id)) {
          element.selectedRole = true;
        }
      });
      this.userName = selectedUser?.firstName + ' ' + selectedUser?.lastName;
      this.isActive = selectedUser?.active ?? false;
      this.form.get('active')?.setValue(this.isActive);

      if (selectedUser?.country) {
        const selectedCountry = this.countries.find(
          (country) => country.isoCode === selectedUser.country?.toUpperCase(),
        );

        if (selectedCountry) {
          this.selectedCountry = selectedCountry.name;
          this.selectedCountryCode = selectedCountry.isoCode;
          this.form.get('country')?.setValue(selectedCountry.name);
          this.states = State.getStatesOfCountry(selectedCountry.isoCode);
        }
      }
      if (selectedUser?.stateProvince) {
        if (selectedUser.stateProvince && this.selectedCountry) {
          const selectedState = this.states.find(
            (state) =>
              state.isoCode === selectedUser.stateProvince?.toUpperCase(),
          );

          if (selectedState) {
            this.selectedState = selectedState.isoCode;
            this.form.get('stateProvince')?.setValue(selectedState.name);
            this.cities = City.getCitiesOfState(
              this.selectedCountryCode,
              selectedState.isoCode,
            );
          }
        }
      }
      if (selectedUser?.addrCity) {
        if (selectedUser.addrCity) {
          this.selectedCityName = selectedUser.addrCity;
          this.form.get('city')?.setValue(selectedUser.addrCity);
        }
      }
      if (selectedUser?.timeZone) {
        if (selectedUser.timeZoneId) {
          this.selectedTimeZone = selectedUser.timeZoneId;
          this.form.get('timeZone')?.setValue(selectedUser.timeZoneId);
        }
      }
      this.isLoading = false;
    }
  }

  async loadExistingUserData() {
    this.isLoading = true;
    const exsistingUserData = await this._userService.getAllUserOption();
    if (exsistingUserData && exsistingUserData.items) {
      this.existingUserDropdown = exsistingUserData.items.filter(
        (item): item is UserOption => item !== null && item !== undefined,
      );
      this.isLoading = false;
      this.filteredUsers = [...this.existingUserDropdown];
    }
  }

  async loadCompanyOptions() {
    const response = await this.companyService.getAllCompanyOption();
    this.companyOptions = response?.items;
  }

  async loadTimeZone() {
    const response = await this.companyService.getAllTimeZoneOptions();
    this.allTimeZone = response?.items;
  }

  onUserTypeChange(value: string) {
    this.form.get('userType')?.setValue(value);
    if (this.form.get('userType')?.value === 'existingUser') {
      this.loadExistingUserData();
      this.resetForm();
      this.form.get('userType')?.setValue(value);
    } else {
      this.resetForm();
    }
  }

  resetForm() {
    this.form = this.fb.group(
      {
        userType: ['newUser'],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        userRole: [],
        existingUserDropdown: [''],
        userName: ['', Validators.required],
        emailId: ['', [Validators.required, Validators.pattern(EMAIL_REGEX)]],
        phoneNumber: ['', Validators.pattern(/^[\d+-]+$/)],
        corporateOfficeNumber: [
          '',
          [Validators.required, Validators.pattern(/^[\d+-]+$/)],
        ],
        address: [''],
        city: [''],
        stateProvince: [''],
        postalZipCode: [''],
        country: [''],
        timeZone: ['', Validators.required],
        active: [true]
      },
    );
  }

  onUserRoleChange(value: string) {
    this.form.get('userRole')?.setValue(value);
    this.userRoleList.forEach(element => {
      element.selectedRole = element.id === value;
    });
  }
  

  toggleActive() {
    if (this.isActive) {
      this.isActiveText = 'OFF';
      this.isActive = false;
    } else if (!this.isActive) {
      this.isActiveText = 'ON';
      this.isActive = true;
    }
  }

  onCountryChange(event: Event): void {
    const selectedCountryName = (event.target as HTMLSelectElement).value;
    const selectedCountry = this.countries.find(
      (country) => country.name === selectedCountryName,
    );

    if (selectedCountry) {
      this.selectedCountryName = selectedCountry.name;
      this.selectedCountry = selectedCountry.isoCode;
      this.states = State.getStatesOfCountry(selectedCountry.isoCode);
      this.cities = [];
    }
  }

  onCityChange(event: Event): void {
    this.selectedCityName = (event.target as HTMLSelectElement).value;

  }

  onStateChange(event: Event): void {
    const selectedStateName = (event.target as HTMLSelectElement).value;
    const selectedState = this.states.find(
      (state) => state.name === selectedStateName,
    );

    if (selectedState) {
      this.selectedStateName = selectedState.name;
      this.cities = City.getCitiesOfState(
        this.selectedCountry,
        selectedState.isoCode,
      );
    }
  }

  onTimeZoneChange(event: Event): void {
    this.selectedTimeZone = (event.target as HTMLSelectElement).value;
  }

  passwordsMatchValidator(group: AbstractControl) {
    const password = group.get('password')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    return password === confirmPassword ? null : { passwordMismatch: true };
  }

  togglePasswordVisibility(field: string): void {
    if (field === 'password') {
      this.showPassword = !this.showPassword;
    } else if (field === 'confirmPassword') {
      this.showConfirmPassword = !this.showConfirmPassword;
    }
  }

  onCancel() {
    this.store.dispatch(new SetSelectedUser(false, ''));
    this.popupDialogService.openDialog(
      SURE_CANCEL_HEADER,
      SURE_CANCEL_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => this.router.navigate([MOBILTEX_MEMBER_OVERVIEW]),
      true,
      NO,
    );
  }

  async saveAndExit() {
    this.form.markAllAsTouched();
    const isUserRoleSelected = this.userRoleList.some(x => x.selectedRole == true);
    if (isUserRoleSelected && this.form.valid) {
      try {
        this.isLoading = true;
        let response;
        if (this.selectedMemberIsEditable) {
          const updateInput = this.updateUser();
          response = await this._userService.updateUser(updateInput);
        } else {
          const addInput = this.createUser();
          response = await this._userService.createUser(addInput);
        }
        if (response?.status === 'Success') {
          const dialogRef = this.popupDialogService.openDialog(
            this.selectedMemberIsEditable == true
              ? "Member Updated Successfully!"
              : "Member Created Successfully!",
            "You'll be redirected to Mobiltex Member Overview list page",
            SUCCESS,
            CLOSE,
            () => this.router.navigate([MOBILTEX_MEMBER_OVERVIEW]),
          );
          dialogRef.afterClosed().subscribe(() => {
            this.store.dispatch(new RemoveMTX_Member());
            this.isLoading = false;
          });
        } else {
          this.popupDialogService.openDialog(
            this.selectedMemberIsEditable == true
              ? "Updating Member Failed!"
              : "Creating Member Failed!",
            "An unexpected error occurred. Please try adding your Member again.",
            FAILED,
            CLOSE,
          );
          this.toastr.showError(
            this.selectedMemberIsEditable == true
              ? "Failed to update Member"
              : response?.error?.message ?? API_ERROR_MSG,
            ERROR,
          );
          console.log(response?.error?.message);
          this.isLoading = false;
        }
      } catch (error) {
        console.log(error);
        this.isLoading = false;
      }
    }
  }

  updateUser() {
    const updatedUser: UserUpdateInput = {
      coreViewUserId: this.loggedInCorViewUserId,
      id: this.selectedMemberId,
      updatedBy: this.loggedUserName,
      isMTXUser: true,
      isUserPCAdmin: false,
      //roleId: this.form.get('userRole')?.value ?? '',
      roleId: this.userRoleList.find(x => x.selectedRole == true)?.id,
      userRolesInput: []
    };
    console.log(updatedUser);
    return updatedUser;
  }

  createUser() {
    const input: UserCreateInput = {
      firstName: this.form.get('firstName')?.value ?? '',
      lastName: this.form.get('lastName')?.value ?? '',
      active: this.isActive,
      username: (this.form.get('userName')?.value ?? '')
        .trim()
        .replace(/\s+/g, ''),
      email: this.form.get('emailId')?.value ?? '',
      postalZip: this.form.get('postalZipCode')?.value ?? '',
      addr1: this.form.get('address')?.value ?? '',
      addrCity: this.selectedCityName ?? '',
      cellPhone: this.form.get('phoneNumber')?.value ?? '',
      officePhone: this.form.get('corporateOfficeNumber')?.value ?? '',
      country: this.selectedCountryName ?? '',
      stateProvince: this.selectedStateName ?? '',
      timeZone: this.selectedTimeZone ?? '',
      timeZoneId: this.selectedTimeZone ?? '',
      password: this.form.get('password')?.value ?? '',
      createdBy: this.loggedUserName,
      confirmPassword: this.form.get('confirmPassword')?.value ?? '',
      coreViewUserId:
        this.form.get('userType')?.value === 'existingUser'
          ? this.selectedExistingUser?.corViewUserId
          : null,
      coreViewPrimaryCompany:
        this.form.get('userType')?.value === 'existingUser'
          ? ({
            id: this.selectedExistingUser?.corViewUserId,
            displayName:
              this.selectedExistingUser?.primaryCompany?.displayName,
          } as UserPrimaryCompanyInput)
          : ({
            id: this.loggedInCorViewUserId,
            displayName: this.loggedInCorViewUserName,
          } as UserPrimaryCompanyInput),
      syrcPrimaryCompany: {
        id: '',
        displayName: "",
      } as UserPrimaryCompanyInput,
      isMTXUser: true,
      isUserPCAdmin: false,
      //roleId: this.form.get('userRole')?.value ?? '',
      roleId: this.userRoleList.find(x => x.selectedRole == true)?.id,
      userRolesInput: []
    };
    console.log(input);
    return input;
  }

  existingUserChange(event: any) {
    const selectedUser = event as UserOption;
    this.selectedExistingUser = this.existingUserDropdown.find(
      (user) => user.corViewUserId === selectedUser.corViewUserId,
    );
    this.selectedExistingUserID = selectedUser.corViewUserId ?? "";
    this.form = this.fb.group({
      userType: ['existingUser'],
      userName: [
        {
          value:
            this.selectedExistingUser?.username != ''
              ? this.selectedExistingUser?.username
              : '',
          disabled: true,
        },
      ],
      emailId: [
        {
          value:
            this.selectedExistingUser?.email != ''
              ? this.selectedExistingUser?.email
              : '',
          disabled: true,
        },
      ],
      phoneNumber: [
        {
          value:
            this.selectedExistingUser?.cellPhone != ''
              ? this.selectedExistingUser?.cellPhone
              : '',
          disabled: true,
        },
      ],
      corporateOfficeNumber: [
        {
          value:
            this.selectedExistingUser?.officePhone != ''
              ? this.selectedExistingUser?.officePhone
              : '',
          disabled: true,
        },
      ],
      address: [
        {
          value:
            this.selectedExistingUser?.addr1 != ''
              ? this.selectedExistingUser?.addr1
              : '',
          disabled: true,
        },
      ],
      city: [
        {
          value:
            this.selectedExistingUser?.addrCity != ''
              ? this.selectedExistingUser?.addrCity
              : '',
          disabled: true,
        },
      ],
      stateProvince: [
        {
          value:
            this.selectedExistingUser?.stateProvince != ''
              ? this.selectedExistingUser?.stateProvince
              : '',
          disabled: true,
        },
      ],
      postalZipCode: [
        {
          value:
            this.selectedExistingUser?.postalZip != ''
              ? this.selectedExistingUser?.postalZip
              : '',
          disabled: true,
        },
      ],
      country: [
        {
          value:
            this.selectedExistingUser?.country != ''
              ? this.selectedExistingUser?.country
              : '',
          disabled: true,
        },
      ],
      timeZone: [
        {
          value:
            this.selectedExistingUser?.timeZone != ''
              ? this.selectedExistingUser?.timeZone
              : '',
          disabled: true,
        },
      ],
      active: [{ value: this.selectedExistingUser?.active, disabled: true }],
    });
    if (this.selectedExistingUser?.country) {
      const selectedCountry = this.countries.find(
        (country) =>
          country.isoCode === this.selectedExistingUser?.country?.toUpperCase(),
      );

      if (selectedCountry) {
        this.selectedCountry = selectedCountry.name;
        this.selectedCountryCode = selectedCountry.isoCode;
        this.form.get('country')?.setValue(selectedCountry.name);
        this.states = State.getStatesOfCountry(selectedCountry.isoCode);
      }
    }
    if (this.selectedExistingUser?.stateProvince) {
      if (this.selectedExistingUser.stateProvince && this.selectedCountry) {
        const selectedState = this.states.find(
          (state) =>
            state.isoCode ===
            this.selectedExistingUser?.stateProvince?.toUpperCase(),
        );

        if (selectedState) {
          this.selectedState = selectedState.isoCode;
          this.form.get('stateProvince')?.setValue(selectedState.name);
          this.cities = City.getCitiesOfState(
            this.selectedCountryCode,
            selectedState.isoCode,
          );
        }
      }
    }
    if (this.selectedExistingUser?.addrCity) {
      if (this.selectedExistingUser.addrCity && this.selectedState) {
        this.selectedCityName = this.selectedExistingUser.addrCity;
        this.form.get('city')?.setValue(this.selectedExistingUser.addrCity);
      }
    }
    if (this.selectedExistingUser?.timeZone) {
      if (this.selectedExistingUser.timeZoneId) {
        this.selectedTimeZone = this.selectedExistingUser.timeZoneId;
        this.form
          .get('timeZone')
          ?.setValue(this.selectedExistingUser.timeZoneId);
      }
    }
  }

  isCheckboxDisabled(): boolean {
    const userType = this.form.get('userType')?.value;
    //return true;
    return userType === 'existingUser' || this.selectedMemberIsEditable;
  }

  onDropdownFilterChange(filterValue: string): void {
    // Filter the users based on the input search value
    this.filteredUsers = this.existingUserDropdown.filter(user =>
      user?.username?.toLowerCase().includes(filterValue.toLowerCase())
    );

    // If the selected user is still in the filtered list, keep it, otherwise reset the selection
    const selectedUserExists = this.filteredUsers.some(user => user.corViewUserId === this.selectedExistingUserID);

    if (!selectedUserExists && this.filteredUsers.length > 0) {
      // If the selected user is not in the filtered list, select the first user
      this.selectedExistingUserID = this.filteredUsers[0].corViewUserId ?? "";
      this.existingUserChange(this.filteredUsers[0]);
    } else if (this.filteredUsers.length === 0) {
      // If no users match the filter, reset the selection
      this.selectedExistingUserID = "";
    }
  }
}