<kendo-listview *ngIf="visible" [data]="menuOptions" [style.top.px]="positionY" [style.left.px]="positionX"
  class="dropdown-menu dropdown-menu-height" kendoListViewSelectable [ngStyle]="{ width: elementWidth }">
  <ng-template kendoListViewItemTemplate let-dataItem>
    <div class="dropdown-menu-item" [ngClass]="{ disabled: !dataItem.isEnable }">
      <div [ngClass]="{
      customPinImage: dataItem.imgType === 'pin',
      customUnPinImage: dataItem.imgType === 'unpin',
      customRedirectImage: dataItem.imgType === 'redirect',
      customDeleteIconImage: dataItem.imgType === 'deleteIicon',
      customEditImage: dataItem.imgType === 'edit',
      customDetailsImage: dataItem.imgType === 'details',
      customCheckImage: dataItem.imgType === 'check',
      customPendingImage: dataItem.imgType === 'pending',
      customSurveyBacklogImage: dataItem.imgType === 'surveyBacklog',
      customViewImage: dataItem.imgType === 'view',
      customFavImage: dataItem.imgType === 'fav',
      customTimerImage: dataItem.imgType === 'timer',
      customEditNoteImage: dataItem.imgType === 'edit_note',
      customfavPinImage: dataItem.imgType === 'fav',
      customUserHistoryImage: dataItem.imgType === 'userHistory',
      customLoginAsImage: dataItem.imgType === 'loginAs',
      customUploadImage: dataItem.imgType === 'upload',
      customSYNCImage: dataItem.imgType === 'sync',
      customDownloadImage: dataItem.imgType === 'download',
      customNotificationBellImage: dataItem.imgType === 'notifications_bell',
      customSearchImage:dataItem.imgType==='search_icon',
      'not-allowed-cursor': !dataItem.isEnable  
    }" (click)="dataItem.isEnable && selectOption(dataItem, dataItem.id)">
        <div *ngIf="dataItem.id !== 1; else elseBlock">
          <a class="row-action-list" [ngClass]="{ 'disabled-link': !dataItem.isEnable }"
            (click)="dataItem.isEnable && navigate(dataItem.refLink)">
            {{ dataItem.name }}
          </a>
        </div>
        <ng-template #elseBlock>
          <span class="row-action-list">{{ dataItem.name }}</span>
        </ng-template>
      </div>
    </div>

  </ng-template>
</kendo-listview>