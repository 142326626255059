<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container-fluid py-2" style="margin-top: -28px;">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <h5 class="mtx-text-primary" style="margin-left: -12px; margin-top: 22px;">{{ title | translate }}</h5>
      </div>
      <div class="col-auto ml-auto">
        <div class="d-flex flex-wrap align-items-center gap-2 justify-content-end">
          <div style="width: 330px;">
            <app-search [placeholder]="searchPlaceholder" (searchInput)="handleSearchInput($event)"></app-search>
          </div>
          <div class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <app-template [templateTypeInput]="templateTypeInput"></app-template>
          </div>
          <!-- <div
      class="col-xs-6 col-sm-auto col-md-auto col-lg-auto"
      *ngIf="showFilterBtn"
    >
      <button
        (click)="toggleFilterTray()"
        class="btn mtx-btn-secondary mtx-font-weight-500"
      >
        <span style="vertical-align: bottom" class="material-icons"
          >filter_alt</span
        >
        {{ 'GENERIC_Buttons.FILTERS' | translate }}
        Filters
        <span style="vertical-align: bottom" class="material-icons"
          >arrow_drop_down</span
        >
      </button>
    </div> -->
          <div *ngIf="permission" class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <button class="btn mtx-btn-secondary mtx-font-weight-500" style="height: 38px; font-family: Roboto"
              (click)="createSurveyTask()">
              <span class="plus-icon">+</span>
              <!-- {{ 'SURVEY_OVERVIEW.SURVEY_TASK_BTN' | translate }} -->
              Survey Task
            </button>
          </div>
          <div class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <button class="btn mtx-btn-primary" style="height: 38px;" (click)="openDialog()">
              <span style="vertical-align: middle" class="material-icons">download</span>
              <!-- {{ 'GENERIC_Buttons.EXPORT' | translate }} -->
              Export
            </button>
          </div>
          <div class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <app-columnadjustment (columnPosition)="reorderColumnPosition($event)"
              [templateTypeInput]="templateTypeInput"
              (selectedColumnsChange)="OnSelectedTemplateColumnsChange($event)"></app-columnadjustment>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3 justify-content-end mb-3" style="float: right" *ngIf="showHierarchicalFilter">
      <app-heirarchy-filter-buttons (isTrayCollapsed)="collapseFilterTray()" [isSurveyRouteFilterRequired]="true">
      </app-heirarchy-filter-buttons>
      <app-heirarchy-filter-popup></app-heirarchy-filter-popup>
    </div>
    <div class="mt-3">
      <kendo-grid [data]="gridData" [filterable]="'menu'" [pageable]="PAGINATION_ARRAY" [pageSize]="pageSize"
        class="grid-shadow" [rowSticky]="rowSticky" [resizable]="true" [skip]="state.skip" [pageable]="true"
        [pageSize]="state.take" [filter]="state.filter" (dataStateChange)="dataStateChange($event)">
        <kendo-grid-column [resizable]="false" [width]="80" [filterable]="false" field="id" title=""
          [resizable]="false">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div class="flex-container" style="display: flex; align-items: center">
              <span class="k-icon k-font-icon my-custom-icon-class" style="position: relative"
                (click)="openDropdownMenu($event, dataItem)"></span>
              <span *ngIf="isSticky(dataItem.id)" class="k-icon k-font-icon k-i-star k-i-pin pin-icon-css"
                (click)="rowActionStickyRow($event, dataItem)">
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="surveyName" title="Survey Name" [width]="150" *ngIf="IsColumnSelected('surveyName')"
          [minResizableWidth]="80"></kendo-grid-column>
        <kendo-grid-column field="surveyType" title="Survey Type" [width]="200" *ngIf="IsColumnSelected('surveyType')"
          [minResizableWidth]="100">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <div #customFilterContainer>
              <app-custom-grid-filter fieldName="surveyType" [inputElements]="surveyTypeFilterValues"
                [parentDiv]="customFilter" [state]="customstate"
                (selectedValues)="applyFilter($event, filterService, 'surveyType')">
              </app-custom-grid-filter>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="surveyPriority" title="Survey Priority" [width]="140"
          *ngIf="IsColumnSelected('surveyPriority')" [minResizableWidth]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span [ngClass]="getPriorityClass(dataItem.surveyPriority)" style="font-weight: 700">
              <span class="dot"></span> {{ dataItem.surveyPriority }}
            </span>
          </ng-template>
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <div #customFilterContainer>
              <app-custom-grid-filter fieldName="surveyPriority" [inputElements]="surveyPriorityFilterValues"
                [parentDiv]="customFilter" [state]="customstate"
                (selectedValues)="applyFilter($event, filterService, 'surveyPriority')">
              </app-custom-grid-filter>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="surveyStatus" title="Survey Status" [width]="200"
          *ngIf="IsColumnSelected('surveyStatus')" [width]="220" [minResizableWidth]="120">
          <!-- <ng-template kendoGridCellTemplate let-dataItem>
            Survey Created
          </ng-template> -->
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <div #customFilterContainer>
              <app-custom-grid-filter fieldName="surveyStatus" [inputElements]="surveyStatusFilterValues"
                [parentDiv]="customFilter" [state]="customstate"
                (selectedValues)="applyFilter($event, filterService, 'surveyStatus')">
              </app-custom-grid-filter>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="dueDate" title="Due Date" [width]="150" [filter]="'date'"
          *ngIf="IsColumnSelected('dueDate')" [minResizableWidth]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.dueDate | customDate }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="dataCollectionType" title="Data Collection Type" [width]="160"
          *ngIf="IsColumnSelected('dataCollectionType')" [minResizableWidth]="90">
          <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
            <div #customFilterContainer>
              <app-custom-grid-filter fieldName="dataCollectionType" [inputElements]="surveyDataCollectionFilterValues"
                [parentDiv]="customFilter" [state]="customstate"
                (selectedValues)="applyFilter($event, filterService, 'dataCollectionType')">
              </app-custom-grid-filter>
            </div>
          </ng-template>
        </kendo-grid-column>
        <!-- <kendo-grid-column field="assignedTo.name" title="Assignee" *ngIf="IsColumnSelected('assignedTo')" [width]="150"
          [minResizableWidth]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.assignedTo?.name || 'N/A' }}
          </ng-template>
        </kendo-grid-column> -->
        <kendo-grid-column field="supervisor" title="Supervisor" [width]="150" *ngIf="IsColumnSelected('supervisor')"
          [minResizableWidth]="70"></kendo-grid-column>
        <kendo-grid-column field="dateOfCompletion" title="Date of Completion" [width]="150" [filter]="'date'"
          *ngIf="IsColumnSelected('dateOfCompletion')" [minResizableWidth]="80">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.dateOfCompletion | customDate }}
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="surveyDescription" [width]="350" title="Survey Description"
          *ngIf="IsColumnSelected('surveyDescription')" [minResizableWidth]="150">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span *ngIf="isExpanded(dataItem.id)">
              {{ dataItem.surveyDescription }}
            </span>
            <span *ngIf="!isExpanded(dataItem.id)">
              {{ dataItem.surveyDescription | slice: 0 : 70 }}
              <button *ngIf="dataItem.surveyDescription?.length > 70" class="see-more"
                (click)="toggleDescription(dataItem.id)">
                {{ 'GRIDS.SEE_MORE' | translate }}
              </button>
            </span>
          </ng-template>
        </kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
          <div>{{ 'GRID.NO_RECORDS' | translate }}</div>
        </ng-template>
      </kendo-grid>
    </div>
    <app-row-actions class="row-action" [visible]="dropdownVisible" [positionX]="dropdownX" [width]="ellipsisWidth"
      [positionY]="dropdownY" [inputScreen]="inputScreen" (visibleChange)="handleDropdownVisibilityChange($event)"
      [rowId]="rowId" [isStickyRow]="isStickyRow" [permission]="permission"
      (optionSelected)="handleDropdownOption($event)"></app-row-actions>
  </div>
</div>

<kendo-dialog *ngIf="isDialogOpen" (close)="closeDialog()" title="Assign Survey" id="assignPopup" class="custom-dialog">
  <p class="survey-header">{{this.surveyName}} <span class="survey-header-span">Direct Survey</span> </p>
  <div>
    <label for="assignTo" class="form-label">Assign to *</label>
    <kendo-dropdownlist [data]="userList" [(ngModel)]="selectedValue" (selectionChange)="assignToUserChange($event)" textField="value"
      valueField="id" style="height: 45%;" name="assignTo"></kendo-dropdownlist>
  </div>
  <kendo-dialog-actions>
    <button (click)="onMoveToFieldData(this.rowId)" [disabled]="IsAssignToButtonDisabled"
      [ngClass]="{'upload-btn-css': !IsAssignToButtonDisabled, 'disabled-upload-btn-css ': IsAssignToButtonDisabled}">
      Move to Field Data
    </button>
    <button (click)="onCancel()" class="cancel-btn-css">
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>