import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  columnNames,
  ReportColumnNames,
  reportColumnsForDynamicColumns,
} from '../../shared/enums';
import { Router } from '@angular/router';
import {
  API_ERROR_MSG,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  CANCEL_TEMPLATE_HEADING,
  CANCEL_TEMPLATE_MSG,
  CLOSE,
  CREATE_NEW_REPORT_SUBHEADING,
  CREATE_REPORT_FAILED_HEADER,
  CREATE_REPORT_FROM_TEMPLATE,
  EDIT_MODE_REPORT,
  EMAIL_ALREADY_EXISTS,
  EMAIL_REGEX,
  EMPTY_STRING,
  ERROR,
  FAILED,
  FETCHING_TEMPLATES_ERROR,
  FROM_TEMPLATE_LIB,
  NO,
  REDIRECT_REPORT_MANAGEMENT,
  REDIRECT_REPORT_TEMPLATE_LIBRARY,
  SUCCESS,
  VALID_EMAIL,
  VIEW_MODE_REPORT,
} from '../../shared/constants';
import { CreateReportServiceService } from './service/create-report-service.service';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { RemoveTemplate, TemplateState } from '../../core/store/template.state';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
} from '@progress/kendo-data-query';
import { TranslateService } from '@ngx-translate/core';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { AssetTypeState } from '../../core/store/filter-assettype';
import { SegmentTypeState } from '../../core/store/filter-segmenttype.state';
import { SegmentState } from '../../core/store/filter-segments';
import {
  ConfigureReportInput,
  HierarchyFilterInput,
  ReportInput,
  ReportCreateInput,
  ReportTemplate,
  Report,
  RunReportInput,
  ReportUpdateInput,
} from '../../../awsAppSync/API';
import { Level3State } from '../../core/store/filter-level3.state';
import { ReportTemplatesLibraryService } from '../../core/services/report-templates-library.service';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { ReportTemplateLibrary } from '../../core/models/report-templates-library.module';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { MatDialog } from '@angular/material/dialog';
import { RunReportComponent } from '../../shared/run-report/run-report.component';
import { Level3LevelNameState } from '../../core/store/filter-level3Name.state';
import { Level2LevelNameState } from '../../core/store/filter-level2Name.state';
import { Level1LevelNameState } from '../../core/store/filter-level1Name.state';

@Component({
  selector: 'app-create-report',
  templateUrl: './create-report.component.html',
  styleUrl: './create-report.component.css',
})
export class CreateReportComponent implements OnInit, OnDestroy {
  reportForm: FormGroup;
  dateRangeForm: FormGroup;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  emails: string[] = [];
  currentStep = 1;
  selectedFormat = '';
  templatesFromAPI: ReportTemplateLibrary[] = [];
  defaultTemplate: ReportTemplateLibrary = {
    id: '',
    templateName: 'Select Report Template',
    description: '',
    reportType: '',
    lastUsedOn: null,
    templateColumns: [],
  };
  templatesForUI: ReportTemplateLibrary[] = [];

  displayedColumns: { field: string; type: string }[] = [];
  selectedColumnsKeyValuePair: { field: string; title: string }[] = [];
  repeatEveryValue: number | undefined;
  tableData: any[] = [];
  selectedColumns: any[] = [];
  selectedColumnsFromStep1: string[] = [];
  isScheduleOn: any;
  mappedColumns: (string | null)[] = [];
  public isPopupOpen = false;
  selectedItems: Record<string, boolean> = {};
  columnOptions: Record<string, string[]> = {};
  public gridData: any[] = [];
  selectedTemplate:
    | { name: string | null | undefined; columns: string[] }
    | undefined;
  templateName: string | undefined | null;
  selectedFormats: string[] = [];
  columnNames: string[] = [];
  reportSubheading = CREATE_NEW_REPORT_SUBHEADING;
  isFrom$: Observable<string | null>;
  templateName$: Observable<string | null>;
  reportType$: Observable<string | null>;
  isFromWhere: string | null = EMPTY_STRING;
  state: { filter: CompositeFilterDescriptor } = {
    filter: { logic: 'and', filters: [] },
  };
  isTemplateSelected = false;
  startDate: Date | null = new Date();
  storeSegmentTypeSet$: Observable<string[]>;
  selectedSegmentType: string[] = [];
  storeSelectedSegments$: Observable<string[]>;
  selectedSegments: string[] = [];
  endDate: Date | null = new Date();
  isLoading = false;
  storePrimaryCompanyID$: Observable<string | null>;
  // selectedprimaryCompanyId: string | null = '';
  scheduleTime: Date = new Date(2000, 11, 31, 11, 0);
  loggedInCorViewUserName$: Observable<string | null | undefined> | undefined;
  loggedInCorViewUserName = '';
  storeSelectedAssetType$: Observable<string[]>;
  selectedAssetType: string[] = [];
  storeSelectedLevel3$: Observable<string[]>;
  selectedLevel3: string[] = [];
  selectedprimaryComapnyId: string | null = '';
  filterModel: any = {};
  hierarchyFilterInput: HierarchyFilterInput[] = [];
  configureReportInput: ConfigureReportInput = {};
  currentSelectedColumns: string[] = [];
  editReportId$: Observable<string | null | undefined>;
  editReportId?: string | null;
  currentReportId: string | undefined = EMPTY_STRING;
  runReportInput: RunReportInput = {};
  step1response: any;
  reportError = false;
  saveRunReportClicked = false;
  emailError: string | null = null;
  selectedColumnInput: Record<string, string> = {};
  showGrid = true;
  storeLevel1Name$: Observable<string | null>;
  level1Name: string | null = EMPTY_STRING;
  storeLevel2Name$: Observable<string | null>;
  level2Name: string | null = EMPTY_STRING;
  storeLevel3Name$: Observable<string | null>;
  level3Name: string | null = EMPTY_STRING;

  constructor(
    private fb: FormBuilder,
    private popupDialogService: PopupDialogService,
    private store: Store,
    public service: CreateReportServiceService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private reportTemplatesService: ReportTemplatesLibraryService,
    private toastr: CustomToastrService,
    private hierarchyFilter: HeirarchyFilterServiceService,
    public dialog: MatDialog,
    private _reportTemplateService: ReportTemplatesLibraryService,
  ) {
    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
    this.storeSelectedLevel3$ = this.store.select(
      Level3State.getSelectedLevel3Name,
    );
    this.storeSegmentTypeSet$ = this.store.select(
      SegmentTypeState.getSelectedSegmentType,
    );
    this.storeSelectedSegments$ = this.store.select(
      SegmentState.getSelectedSegmentIds,
    );
    this.storeSelectedAssetType$ = this.store.select(
      AssetTypeState.getSelectedAssetTypeIds,
    );
    this.storeLevel1Name$ = this.store.select(
      Level1LevelNameState.getLevel1LevelNameId,
    );
    this.storeLevel2Name$ = this.store.select(
      Level2LevelNameState.getLevel2LevelNameId,
    );
    this.storeLevel3Name$ = this.store.select(
      Level3LevelNameState.getLevel3LevelNameId,
    );
    this.reportForm = this.fb.group({
      selectedTemplate: [''],
      reportName: ['', Validators.required],
      reportType: ['', Validators.required],
      reportCreatorName: ['', Validators.required],
      reportDescription: [''],
      recurringSchedule: [false],
      emailList: this.fb.array([this.fb.control('', Validators.email)]),
      repeatEvery: ['0'],
      repeatFrequency: ['Daily'],
      repeatTime: [this.scheduleTime],
      reportFormat: ['PDF'],
      summary: [''],
      pk: [''],
      sk: [''],
      id: [''],
    });
    const currentYear = new Date().getFullYear();
    this.startDate = new Date(currentYear, 0, 1); // January 1st
    this.endDate = new Date(currentYear, 11, 31); // December 31st
    this.dateRangeForm = this.fb.group(
      {
        startDate: [this.startDate],
        endDate: [this.endDate],
      },
      { validator: this.dateRangeValidator },
    );
    this.isFrom$ = this.store.select(TemplateState.getIsFrom);
    this.editReportId$ = this.store.select(TemplateState.getEditReportId);
    this.templateName$ = this.store.select(TemplateState.getTemplateName);
    this.reportType$ = this.store.select(TemplateState.getReportType);

    this.loggedInCorViewUserName$ = this.store.select(
      AuthenticateUserState.getLoggedInName,
    );
  }

  ngOnInit(): void {
    this.storePrimaryCompanyID$.subscribe((id) => {
      this.selectedprimaryComapnyId = '';
      this.selectedprimaryComapnyId = id;
    });

    this.storeSelectedLevel3$.subscribe((level3name) => {
      this.selectedLevel3 = level3name;
      this.hierarchyFilterInput = [];
      if (this.selectedprimaryComapnyId && this.selectedLevel3.length > 0) {
        this.fetchLevel3Values(this.selectedprimaryComapnyId);
      }
    });

    this.storeSegmentTypeSet$.subscribe((segmentType) => {
      this.selectedSegmentType = [];
      this.selectedSegmentType = segmentType;
    });

    this.storeSelectedSegments$.subscribe((segment) => {
      this.selectedSegments = [];
      this.selectedSegments = segment;
    });

    this.storeSelectedAssetType$.subscribe((assettype) => {
      this.selectedAssetType = [];
      this.selectedAssetType = assettype;
    });

    this.storeLevel1Name$.subscribe((level1name) => {
      this.level1Name = level1name;
    });

    this.storeLevel2Name$.subscribe((level2name) => {
      this.level2Name = level2name;
    });

    this.storeLevel3Name$.subscribe((level3name) => {
      this.level3Name = level3name;
    });

    this.dateRangeForm.get('startDate')?.valueChanges.subscribe((value) => {
      if (!this.dateRangeForm.errors?.['dateRangeInvalid']) {
        this.startDate = value;
        this.filterSearchButtonClicked();
      }
    });

    this.dateRangeForm.get('endDate')?.valueChanges.subscribe((value) => {
      if (!this.dateRangeForm.errors?.['dateRangeInvalid']) {
        this.endDate = value;
        this.filterSearchButtonClicked();
      }
    });
    this.loggedInCorViewUserName$?.subscribe((name) => {
      this.loggedInCorViewUserName = name!;
    });
    this.initializeForm();
    this.loadInitialData();
    this.updateTemplateName();
    this.isTemplateSelected = !!this.reportForm.get('selectedTemplate')?.value;
    this.handleTemplateChanges();
    this.handleReportTypeChanges();
    this.getALLColumnsForReport();
    this.updateTiles();
  }

  filterSearchButtonClicked(): void {
    this.isLoading = true;
    this.updateFilterModel();
  }

  updateFilterModel() {
    if (this.currentSelectedColumns) {
      this.filterModel = {
        level4: this.selectedSegments,
        assetTypes: this.selectedAssetType,
        segmentTypes: this.selectedSegmentType,
        hierarchyFilterInputs: this.hierarchyFilterInput,
      };
      this.configureReportInput = {
        reportId: this.currentReportId,
        primaryCompanyId: this.selectedprimaryComapnyId,
        selectedColumns: null,
        reportTemplateId: '',
        pageSize: 50,
        filters: JSON.stringify(this.filterModel),
        startDate: this.formatToISO(this.startDate),
        endDate: this.formatToISO(this.endDate),
      };
      this.configureReport();
    }
  }

  async configureReport() {
    const response = await this.service.configureReport(
      this.configureReportInput,
    );
    this.gridData = response?.items ? response.items : [];
    if (this.gridData) {
      this.isLoading = false;
    }
  }

  async fetchLevel3Values(primaryCompanyId: string) {
    const allLevel3Values: HierarchyFilterInput[] = [];
    const level3Values =
      await this.hierarchyFilter.getAllLevel3ValueByPrimaryCompany(
        primaryCompanyId,
      );
    if (level3Values?.items) {
      level3Values.items.forEach((item) => {
        if (item) {
          if (this.selectedLevel3.includes(item.id))
            allLevel3Values.push({
              level1: item.level1ValueId || '',
              level2: item.level2ValueId || '',
              level3: item.id || '',
            });
        }
      });
      this.hierarchyFilterInput = allLevel3Values;
    }
  }

  async getALLColumnsForReport() {
    const response = await this.service.getALLColumnsForReport();
    const columnNames = response!.items!.map((item) => item!.columnName);
    this.columnNames = columnNames.map((column) => {
      if (column === 'Level1 Value') {
        return this.level1Name ?? '';
      }
      if (column === 'Level2 Value') {
        return this.level2Name ?? '';
      }
      if (column === 'Level3 Value') {
        return this.level3Name ?? '';
      }
      return column;
    });
  }

  dateRangeValidator(
    formGroup: AbstractControl,
  ): Record<string, boolean> | null {
    const startDate = formGroup.get('startDate')?.value;
    const endDate = formGroup.get('endDate')?.value;

    if (startDate && endDate && startDate > endDate) {
      return { dateRangeInvalid: true };
    }
    return null;
  }

  formatToISO(date: Date | null): string | null {
    return date ? new Date(date).toISOString() : null;
  }

  initializeForm(): void {
    this.reportForm = this.fb.group({
      selectedTemplate: [''],
      reportName: ['', Validators.required],
      reportType: ['', Validators.required],
      reportCreatorName: ['', Validators.required],
      reportDescription: [''],
      recurringSchedule: [false],
      emailList: this.fb.array([this.fb.control('', Validators.email)]),
      repeatEvery: [''],
      repeatFrequency: ['Daily'],
      repeatTime: [this.scheduleTime],
      reportFormat: ['PDF'],
      summary: [''],
      pk: [''],
      sk: [''],
      id: [''],
    });
  }

  async loadInitialData() {
    this.isLoading = true;
    await this.loadTemplates();
    this.getTemplateData();
    if (this.isFromWhere === EDIT_MODE_REPORT) {
      this.getReportById();
    }
    if (this.isFromWhere === CREATE_REPORT_FROM_TEMPLATE) {
      this.getReportByIdFromTemplate();
    }
    this.updateSelectState();

    this.columnOptions = this.convertEnumToOptions();

    this.reportForm
      .get('selectedTemplate')
      ?.valueChanges.subscribe((selectedTemplateid) => {
        this.updateReportTypeBasedOnTemplate(selectedTemplateid);
      });

    this.isLoading = false;
  }
  async getReportById() {
    const reportinput: ReportInput = {
      primaryCompanyId: this.selectedprimaryComapnyId
        ? this.selectedprimaryComapnyId
        : '',
      id: this.editReportId ? this.editReportId : '',
    };
    const reportData = await this.service.getReportById(reportinput);
    await this.addReportDatatoUI(reportData);
  }
  async getReportByIdFromTemplate() {
    const reportinput: ReportInput = {
      primaryCompanyId: this.selectedprimaryComapnyId
        ? this.selectedprimaryComapnyId
        : '',
      id: this.editReportId ? this.editReportId : '',
    };
    const reportData = await this._reportTemplateService.getReportTemplateById(reportinput);
    await this.addReportDatatoUIFromTemplate(reportData);
  }
  async addReportDatatoUI(existingReport: Report | null) {
    this.isLoading = true;
    if (existingReport) {
      this.reportForm.controls['selectedTemplate'].setValue(
        existingReport.reportTemplateId,
      );
      this.reportForm.controls['reportName'].setValue(
        existingReport.reportName,
      );
      this.reportForm.controls['reportType'].setValue(
        existingReport.reportType,
      );
      this.reportForm.get('reportType')?.disable();
      this.reportForm.controls['reportCreatorName'].setValue(
        existingReport.reportCreatorName,
      );
      this.reportForm.controls['reportDescription'].setValue(
        existingReport.reportDescription,
      );

      const editedcolumns: string[] = existingReport.selectedColumns
        ? Object.values(JSON.parse(existingReport.selectedColumns))
        : [];

      const selectedColumns = this.templatesForUI.find(
        (x) => x.id === existingReport.reportTemplateId,
      );
      this.selectedColumns = selectedColumns?.templateColumns
        ? selectedColumns.templateColumns.map((column: string) =>
            this.extractSecondPart(column),
          )
        : [];

      editedcolumns.forEach((column) => {
        this.selectedItems[column] = true;
      });

      this.reportForm.controls['recurringSchedule'].setValue(
        existingReport.isRecurringSchedule,
      );

      this.isScheduleOn = existingReport.isRecurringSchedule;
      this.emails = existingReport.emailList
        ? existingReport.emailList.filter(
            (item): item is string => item !== null,
          )
        : [];
      this.repeatEveryValue = existingReport.scheduleRepeatNumber
        ? existingReport.scheduleRepeatNumber
        : undefined;

      this.reportForm.controls['repeatFrequency'].setValue(
        existingReport.interval,
      );
      this.selectedFormats = existingReport.reportFormat
        ? existingReport.reportFormat.filter(
            (item): item is string => item !== null,
          )
        : [];
      this.reportForm.controls['summary'].setValue(existingReport.summary);
      this.reportForm.controls['pk'].setValue(existingReport.pk);
      this.reportForm.controls['sk'].setValue(existingReport.sk);
      this.reportForm.controls['id'].setValue(existingReport.id);
      this.bindTimeFromBackend(existingReport.intervalTime);
      this.reportSubheading =
        this.isFromWhere == EDIT_MODE_REPORT
          ? 'to Edit the Report ' +
            this.reportForm.get('reportName')?.value?.trim()
          : 'to Create a Report';
      if (existingReport.reportTemplateId) {
        this.isTemplateSelected = true;
        this.onTemplateChange();
      }
    }
    this.isLoading = false;
  }

  async addReportDatatoUIFromTemplate(existingReport: ReportTemplate | null) {
    this.isLoading = true;
    if (existingReport) {
      this.reportForm.controls['selectedTemplate'].setValue(
        existingReport.id,
      );
      this.reportForm.controls['reportType'].setValue(
        existingReport.reportType,
      );
      this.reportForm.get('reportType')?.disable();

      this.reportForm.controls['reportDescription'].setValue(
        existingReport.description,
      );

      this.selectedColumns = existingReport.selectedColumns
        ? Object.values(JSON.parse(existingReport.selectedColumns))
        : [];

      this.selectedColumns.forEach((column) => {
        this.selectedItems[column] = true;
      });
      if(existingReport.id){
        this.isTemplateSelected = true;
        this.onTemplateChange();
      }
    }
    this.isLoading = false;
  }

  extractSecondPart(input: string, delimiter = ':'): string {
    return input.split(delimiter)[1]?.trim() || '';
  }

  handleReportTypeChanges(): void {
    const selectedReportType = this.reportForm.get('reportType')?.value;
    if (!selectedReportType) {
      this.reportForm.patchValue({ reportType: '' });
    }
  }

  handleTemplateChanges(): void {
    const selectedTemplate = this.reportForm.get('selectedTemplate')?.value;
    if (!selectedTemplate) {
      this.reportForm.patchValue({ selectedTemplate: '' });
    }
    this.reportSubheading =
      this.isFromWhere == EDIT_MODE_REPORT
        ? 'to Edit the Report'
        : 'to Create a Report';
  }

  ngOnDestroy() {
    this.store.dispatch(new RemoveTemplate());
    this.reportForm.reset();
  }

  getTemplateData() {
    this.isFrom$.subscribe((isFrom) => {
      this.isFromWhere = isFrom;
    });
    this.editReportId$.subscribe((reportId) => {
      this.editReportId = reportId;
    });

    this.templateName$.subscribe((templateName) => {
      this.reportForm.patchValue({
        selectedTemplate: templateName,
      });
      this.onTemplateChange();
    });
    if (!this.setTemplateSelect()) {
      this.reportForm.controls['selectedTemplate'].setValue('');
    }

    this.reportType$.subscribe((reportType) => {
      this.reportForm.patchValue({
        reportType: reportType,
      });
    });
    if (!this.setReportTypeSelect()) {
      this.reportForm.controls['reportType'].setValue('');
    }
  }

  updateReportTypeBasedOnTemplate(templateId: string): void {
    const selectedTemplate = this.templatesFromAPI.find(
      (template) => template.id === templateId,
    );

    if (selectedTemplate) {
      this.reportForm
        .get('reportType')
        ?.setValue(selectedTemplate.reportType, { emitEvent: false });
    }
  }

  updateSelectState(): void {
    if (this.isFromWhere === VIEW_MODE_REPORT) {
      this.reportForm.disable();
    } else if (this.isFromWhere === FROM_TEMPLATE_LIB) {
      this.reportForm.get('reportType')?.disable();
    }
  }

  convertEnumToOptions(): Record<string, string[]> {
    const options: Record<string, string[]> = {};
    for (const key in reportColumnsForDynamicColumns) {
      if (
        Object.prototype.hasOwnProperty.call(
          reportColumnsForDynamicColumns,
          key,
        )
      ) {
        const types = reportColumnsForDynamicColumns[
          key as keyof typeof reportColumnsForDynamicColumns
        ]
          .split(',')
          .filter((type) => type);
        options[key] = types;
      }
    }
    return options;
  }

  togglePopup(): void {
    this.isPopupOpen = !this.isPopupOpen;
  }

  getFilteredColumns(): string[] {
    return this.columnNames!;
  }

  getSelectedColumns(): string[] {
    return Object.keys(this.selectedItems).filter(
      (item) => this.selectedItems[item],
    );
  }

  onCheckboxChange(item: string, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    this.selectedItems[item] = checkbox.checked;
  }

  getColumnType(item: string): string {
    return item.toLowerCase().includes('date') ||
      item.toLowerCase().includes('read')
      ? 'date'
      : 'text';
  }

  updateTiles(): void {
    this.displayedColumns = this.getSelectedColumns().map((column) => ({
      field: column,
      type: this.getColumnType(column),
    }));
  }

  allColumnsSelected(): boolean {
    const filteredColumns = this.getFilteredColumns();
    return (
      filteredColumns?.length === this.displayedColumns.length &&
      filteredColumns.length > 0
    );
  }

  removeColumn(field: string): void {
    this.selectedItems[field] = false;
    this.displayedColumns = this.displayedColumns.filter(
      (column) => column.field !== field,
    );
    this.gridData = this.gridData.map((row) => {
      const { [field]: _, ...rest } = row;
      return rest;
    });
    this.updateGridWidth();
    this.reportForm.get('selectedColumns')?.setValue(this.displayedColumns);
  }

  updateGridWidth(): void {
    const columnWidth = 250; // Set each column's width to 100px
    const totalWidth = this.displayedColumns.length * columnWidth;
    const gridElement = document.querySelector('.kendo-grid-container');

    if (gridElement) {
      (gridElement as HTMLElement).style.width = `${totalWidth}px`;
    }
  }

  getColumnDisplayName(key: string): string {
    return columnNames[key as keyof typeof columnNames] || key;
  }

  onToggleChange(event: any) {
    const _isOn = this.reportForm.get('recurringSchedule')?.value;
    this.isScheduleOn = event.target.checked;
    this.cd.detectChanges();
  }

  cancel() {
    if (this.isFromWhere === FROM_TEMPLATE_LIB) {
      this.popupDialogService.openDialog(
        CANCEL_TEMPLATE_HEADING,
        CANCEL_TEMPLATE_MSG,
        FAILED,
        CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
        () => this.router.navigate([REDIRECT_REPORT_TEMPLATE_LIBRARY]),
        true,
        NO,
        true,
      );
    } else {
      this.popupDialogService.openDialog(
        CANCEL_TEMPLATE_HEADING,
        CANCEL_TEMPLATE_MSG,
        FAILED,
        CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
        () => this.router.navigate([REDIRECT_REPORT_MANAGEMENT]),
        true,
        NO,
        true,
      );
    }
  }

  cancelOfStep2() {
    if (this.isFromWhere === FROM_TEMPLATE_LIB) {
      this.popupDialogService.openDialog(
        CANCEL_TEMPLATE_HEADING,
        CANCEL_TEMPLATE_MSG,
        FAILED,
        CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
        () => this.router.navigate([REDIRECT_REPORT_TEMPLATE_LIBRARY]),
        true,
        NO,
        true,
      );
    } else {
      this.popupDialogService.openDialog(
        CANCEL_TEMPLATE_HEADING,
        CANCEL_TEMPLATE_MSG,
        FAILED,
        CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
        () => this.router.navigate([REDIRECT_REPORT_MANAGEMENT]),
        true,
        NO,
        true,
      );
    }
  }

  back() {
    this.currentStep = 1;
  }

  goToStep(step: number) {
    // if (step === 2 && this.reportForm.valid) {
    //   this.currentStep = step;
    // }
    if (step === 2) {
      this.currentStep = step;
    }
  }

  onRepeatEveryChange(event: any): void {
    const value = event.target.valueAsNumber;

    this.repeatEveryValue = value < 0 ? 0 : value;
  }

  selection(value: string): void {
    const index = this.selectedFormats.indexOf(value);
    if (index > -1) {
      this.selectedFormats.splice(index, 1);
    } else {
      this.selectedFormats.push(value);
    }
  }
  setTemplateSelect(): boolean {
    const selectedTemplateid = this.reportForm
      .get('selectedTemplate')
      ?.value?.trim();

    this.isTemplateSelected = !!selectedTemplateid;
    return this.isTemplateSelected;
  }
  setReportTypeSelect(): boolean {
    const selectedReportType = this.reportForm.get('reportType')?.value?.trim();

    return !!selectedReportType;
  }

  onTemplateChange(): void {
    const selectedTemplateid = this.reportForm
      .get('selectedTemplate')
      ?.value?.trim();

    this.isTemplateSelected = !!selectedTemplateid;

    if (selectedTemplateid) {
      const selectemp = this.templatesFromAPI.find(
        (template) => template.id === selectedTemplateid,
      );
      if (selectemp)
        this.selectedTemplate = {
          name: selectemp.templateName,
          columns: selectemp.templateColumns,
        };

      if (this.selectedTemplate) {
        this.templateName = this.selectedTemplate.name;
        this.selectedColumnsKeyValuePair = this.selectedTemplate.columns.map(
          (column) => {
            const [field, title] = column
              .split(' : ')
              .map((part) => part.trim());
            return { field, title };
          },
        );
        this.selectedColumns = this.selectedTemplate.columns.map((column) => {
          const [, value] = column.match(/:\s*([^:]+)$/) || [];
          return value?.trim() || column;
        });

        this.displayedColumns = this.selectedTemplate.columns.map((column) => {
          const [, value] = column.match(/:\s*([^:]+)$/) || [];
          return {
            field: value?.trim() || column,
            type: this.getColumnType(value?.trim() || column),
          };
        });
        //this.updateGridData(this.selectedTemplate);
        this.reportForm.get('reportType')?.disable();
        if (this.router.url.includes('report-template-library')) {
          this.reportForm.get('reportType')?.disable();
          this.reportForm.get('reportType')?.reset();
        }
        this.reportForm.controls['reportDescription'].setValue(
          selectemp?.description,
        );
      } else {
        this.resetTemplateSelection();
      }
    } else {
      this.resetTemplateSelection();
      this.isTemplateSelected = false; // Reset the flag

      const reportTypeControl = this.reportForm.get('reportType');
      reportTypeControl?.enable();
      reportTypeControl?.setValue('');
    }
  }

  updateGridData(input: any) {
    this.gridData = this.gridData.map((row) => {
      const filteredRow: any = {};
      input?.columns.forEach((column: any) => {
        const [key] = column.match(/'([^']+)'/) || [];
        const columnKey = key?.trim() || column;
        filteredRow[columnKey.replace(/'/g, '')] =
          row[columnKey.replace(/'/g, '')] || null;
      });
      return filteredRow;
    });
  }

  updateTemplateName(): void {
    const selectedTemplateName = this.reportForm.get('selectedTemplate')?.value;
    this.templateName = selectedTemplateName ? selectedTemplateName : null;
  }

  onFilterChange(filter: CompositeFilterDescriptor): void {
    const hasFieldFilter = this.hasFilterForField(filter, 'ComplianceStatus');
    if (!hasFieldFilter) {
      // this.complaiceStatusFilterValues.forEach(
      //   (item) => (item.selected = false),
      // );
      // alert('Filter cleared for yourField');
    }
    this.state = { ...this.state, filter };
  }

  private hasFilterForField(
    filter: CompositeFilterDescriptor,
    field: string,
  ): boolean {
    return filter.filters.some(
      (f: FilterDescriptor | CompositeFilterDescriptor) => {
        if ((f as CompositeFilterDescriptor).filters) {
          return this.hasFilterForField(f as CompositeFilterDescriptor, field);
        } else {
          return (f as FilterDescriptor).field === field;
        }
      },
    );
  }

  resetTemplateSelection(): void {
    this.selectedColumns = [];
    this.displayedColumns = [];
    this.gridData = [];
  }

  addChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && !this.emails.includes(value)) {
      if (this.isValidEmail(value)) {
        this.emails.push(value);
        this.emailError = null;
      } else {
        this.emailError = VALID_EMAIL;
      }
    } else if (this.emails.includes(value)) {
      this.emailError = EMAIL_ALREADY_EXISTS;
    }
    event.chipInput!.clear();
  }

  isValidEmail(email: string): boolean {
    const emailPattern = EMAIL_REGEX;
    return emailPattern.test(email);
  }

  removeChip(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  onValueChange(selectedItems: any[]) {
    this.selectedColumns = [...selectedItems];
  }

  onReset() {
    this.selectedItems = {};
    this.currentSelectedColumns = [];
  }

  onSave() {
    const selectedColumns = Object.keys(this.selectedItems).filter(
      (key) => this.selectedItems[key],
    );
    this.currentSelectedColumns = selectedColumns;

    if (this.currentSelectedColumns.length == 0) {
      this.showGrid = false;
    } else {
      this.showGrid = true;
    }

    Object.entries(this.selectedItems)
      .filter(([_, isSelected]) => isSelected)
      .forEach(([title]) => {
        if (title === this.level1Name) {
          title = 'Level1 Value';
        } else if (title === this.level2Name) {
          title = 'Level2 Value';
        } else if (title === this.level3Name) {
          title = 'Level3 Value';
        }
        const field = Object.keys(ReportColumnNames).find(
          (key) => ReportColumnNames[key] === title,
        );
        if (field) {
          if (title === 'Level1 Value') {
            title = this.level1Name ?? '';
          }
          if (title === 'Level2 Value') {
            title = this.level2Name ?? '';
          }
          if (title === 'Level3 Value') {
            title = this.level3Name ?? '';
          }
          this.selectedColumnInput[field] = title;
        }
      });

    this.displayedColumns = selectedColumns.map((item) => ({
      field: item,
      type: this.getColumnType(item),
    }));

    const reversedReportColumnNames = Object.entries(ReportColumnNames).reduce(
      (acc, [key, value]) => {
        acc[value] = key;
        return acc;
      },
      {} as Record<string, string>,
    );

    if ('Level1 Value' in reversedReportColumnNames) {
      reversedReportColumnNames[this.level1Name ?? ''] =
        reversedReportColumnNames['Level1 Value'];
      delete reversedReportColumnNames['Level1 Value'];
    }
    if ('Level2 Value' in reversedReportColumnNames) {
      reversedReportColumnNames[this.level2Name ?? ''] =
        reversedReportColumnNames['Level2 Value'];
      delete reversedReportColumnNames['Level2 Value'];
    }
    if ('Level3 Value' in reversedReportColumnNames) {
      reversedReportColumnNames[this.level3Name ?? ''] =
        reversedReportColumnNames['Level3 Value'];
      delete reversedReportColumnNames['Level3 Value'];
    }

    this.selectedColumnsKeyValuePair = this.currentSelectedColumns
      .filter((title) => title in reversedReportColumnNames)
      .map((title) => ({
        field: reversedReportColumnNames[title],
        title: title,
      }));
    this.updateGridWidth();
  }

  async loadTemplates(): Promise<void> {
    try {
      const response = await this.reportTemplatesService.getReportGridData({
        searchText: '',
        pageSize: 1000,
        pageNumber: 1,
        primaryCompanyId: this.selectedprimaryComapnyId ?? '',
        lastEvaluatedKey: null,
      });
      if (response?.items) {
        this.templatesFromAPI = response.items
          ?.filter((item): item is ReportTemplate => item != null)
          .map((item: ReportTemplate) => ({
            id: item?.id,
            templateName: item?.templateName,
            description: item?.description,
            reportType: item?.reportType,
            lastUsedOn: item?.lastUsedOn,
            templateColumns: this.convertJsonStringToArray(
              item.selectedColumns,
            ),
          }));
        this.templatesForUI = [this.defaultTemplate, ...this.templatesFromAPI];
        this.isLoading = false;
      }
    } catch (error) {
      console.error(FETCHING_TEMPLATES_ERROR, error);
    }
  }

  convertJsonStringToArray(jsonString: string | undefined | null): string[] {
    if (!jsonString) {
      return [];
    }
    try {
      const parsed = JSON.parse(jsonString) as Record<string, string>;
      return Object.entries(parsed).map(([key, value]) => `${key} : ${value}`);
    } catch (error) {
      console.error('Error parsing JSON string:', error);
      return [];
    }
  }

  async onSaveAndContinue() {
    const selectedTemplateControl = this.reportForm.get('selectedTemplate');
    if (selectedTemplateControl) {
      selectedTemplateControl.clearValidators();
      selectedTemplateControl.updateValueAndValidity();
    }
    this.reportForm.markAllAsTouched();
    if (this.reportForm.valid) {
      this.isLoading = true;
      try {
        if (this.isFromWhere === EDIT_MODE_REPORT) {
          await this.updateReport();
        } else {
          await this.createReport();
        }
      } catch (ex) {
        console.log(ex);
      }
    }
    if (selectedTemplateControl?.value == EMPTY_STRING) {
      this.onSave();
    }
  }

  async updateReport() {
    try {
      const selectedTemplateId = this.reportForm.get('selectedTemplate')?.value;
      const templateselected = this.templatesFromAPI.find(
        (f) => f.id == selectedTemplateId,
      );
      const input: ReportUpdateInput = {
        pk: this.reportForm.get('pk')?.value,
        sk: this.reportForm.get('sk')?.value,
        id: this.reportForm.get('id')?.value,
        reportTemplateId: this.reportForm
          .get('selectedTemplate')
          ?.value?.trim(),
        reportName: this.reportForm.get('reportName')?.value?.trim(),
        reportType: this.reportForm.get('reportType')?.value?.trim(),
        reportCreatorName: this.reportForm
          .get('reportCreatorName')
          ?.value?.trim(),
        reportDescription: this.reportForm
          .get('reportDescription')
          ?.value?.trim(),
        selectedColumns: templateselected?.selectedColumn,
        isRecurringSchedule: this.reportForm.get('recurringSchedule')?.value,
        isFavourite: false,
        emailList: this.emails,
        scheduleRepeatNumber: this.repeatEveryValue,
        interval: this.reportForm.get('repeatFrequency')?.value,
        reportFormat: this.selectedFormats,
        summary: this.reportForm.get('summary')?.value?.trim(),
        intervalTime: this.formatTimeToAMPM(
          this.reportForm.get('repeatTime')?.value,
        ),
        updatedBy: this.loggedInCorViewUserName,
        filter: '',
        startDate: '',
        endDate: '',
      };
      const response = await this.service.updateReport(input);
      this.currentReportId = response?.report?.id;
      this.step1response = response;
      this.isLoading = false;
      if (response?.status?.toLowerCase() === 'success') {
        this.goToStep(2);
      } else {
        this.popupDialogService.openDialog(
          CREATE_REPORT_FAILED_HEADER,
          'An unexpected error occurred. Please try again.',
          FAILED,
          CLOSE,
        );
        this.toastr.showError(response?.error?.message ?? API_ERROR_MSG, ERROR);
        this.isLoading = false;
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  async createReport() {
    const selectedTemplateId = this.reportForm.get('selectedTemplate')?.value;
    const templateselected = this.templatesFromAPI.find(
      (f) => f.id == selectedTemplateId,
    );

    try {
      const input: ReportCreateInput = {
        reportTemplateId: this.reportForm
          .get('selectedTemplate')
          ?.value?.trim(),
        primaryCompanyId: this.selectedprimaryComapnyId
          ? this.selectedprimaryComapnyId
          : '',
        reportName: this.reportForm.get('reportName')?.value?.trim(),
        reportType: this.reportForm.get('reportType')?.value?.trim(),
        reportCreatorName: this.reportForm
          .get('reportCreatorName')
          ?.value?.trim(),
        reportDescription: this.reportForm
          .get('reportDescription')
          ?.value?.trim(),
        selectedColumns: templateselected?.selectedColumn,
        isRecurringSchedule: this.reportForm.get('recurringSchedule')?.value,
        isFavourite: false,
        emailList: this.emails,
        scheduleRepeatNumber: this.repeatEveryValue,
        interval: this.reportForm.get('repeatFrequency')?.value,
        reportFormat: this.selectedFormats,
        summary: this.reportForm.get('summary')?.value?.trim(),
        intervalTime: this.formatTimeToAMPM(
          this.reportForm.get('repeatTime')?.value,
        ),
        createdBy: this.loggedInCorViewUserName,
        filter: '',
        startDate: '',
        endDate: '',
      };
      const response = await this.service.createReport(input);
      this.currentReportId = response?.report?.id;
      this.step1response = response;
      this.isLoading = false;
      if (response?.status?.toLowerCase() === 'success') {
        this.goToStep(2);
      } else {
        this.popupDialogService.openDialog(
          CREATE_REPORT_FAILED_HEADER,
          'An unexpected error occurred. Please try again.',
          FAILED,
          CLOSE,
        );
        this.toastr.showError(response?.error?.message ?? API_ERROR_MSG, ERROR);
        this.isLoading = false;
      }
    } catch (ex) {
      console.log(ex);
    }
  }

  formatTimeToAMPM(date: Date): string {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const formattedMinutes = minutes.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  bindTimeFromBackend(timeString: string): void {
    this.scheduleTime = this.parseTimeFromString(timeString);
    this.reportForm.controls['repeatTime'].setValue(this.scheduleTime);
  }

  parseTimeFromString(timeString: string): Date {
    const [time, modifier] = timeString.split(' ');
    // eslint-disable-next-line prefer-const
    let [hours, minutes] = time.split(':').map(Number);

    if (modifier === 'PM' && hours !== 12) {
      hours += 12;
    }
    if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }
    const date = new Date();
    date.setHours(hours, minutes, 0, 0);
    return date;
  }

  async saveReport() {
    this.isLoading = true;
    try {
      const input: ReportUpdateInput = {
        pk: this.step1response.report.pk,
        sk: this.step1response.report.sk,
        id: this.step1response.report.id,
        reportTemplateId: this.step1response.report.reportTemplateId ?? '',
        reportName: this.step1response.report.reportName,
        reportType: this.step1response.report.reportType,
        reportCreatorName: this.step1response.report.reportCreatorName,
        reportDescription: this.step1response.report.reportDescription,
        selectedColumns: this.selectedColumnInput
          ? JSON.stringify(this.selectedColumnInput)
          : this.step1response.report.selectedColumns,
        isRecurringSchedule: this.step1response.report.isRecurringSchedule,
        isFavourite: this.step1response.report.isFavourite,
        emailList: this.step1response.report.emailList,
        scheduleRepeatNumber: this.step1response.report.scheduleRepeatNumber,
        interval: this.step1response.report.interval,
        reportFormat: this.step1response.report.reportFormat,
        summary: this.step1response.report.summary,
        intervalTime: this.step1response.report.intervalTime,
        updatedBy: this.step1response.report.updatedBy,
        filter: JSON.stringify(this.filterModel),
        startDate: this.formatToISO(this.startDate),
        endDate: this.formatToISO(this.endDate),
      };
      const response = await this.service.updateReport(input);
      const mesg =
        this.isFromWhere === EDIT_MODE_REPORT
          ? 'Report  Updated Successfully!'
          : 'Report Created Successfully!';
      if (
        response?.status?.toLowerCase() === 'success' &&
        !this.saveRunReportClicked
      ) {
        this.popupDialogService.openDialog(
          mesg,
          "You'll be redirected to Report Management list page.",
          SUCCESS,
          CLOSE,
          () => this.router.navigate([REDIRECT_REPORT_MANAGEMENT]),
        );
      } else if (
        this.saveRunReportClicked &&
        response?.status?.toLowerCase() === 'success'
      ) {
        const mesg =
          this.isFromWhere === EDIT_MODE_REPORT
            ? 'Report Updated Successfully!'
            : 'Report Created Successfully!';
        this.toastr.showSuccess(mesg);
      } else {
        this.reportError = true;
        this.popupDialogService.openDialog(
          CREATE_REPORT_FAILED_HEADER,
          'An unexpected error occurred. Please try again.',
          FAILED,
          CLOSE,
        );
        this.toastr.showError(response?.error?.message ?? API_ERROR_MSG, ERROR);
        this.isLoading = false;
      }
      this.isLoading = false;
    } catch (ex) {
      console.log(ex);
    }
  }

  async saveAndRun() {
    this.saveRunReportClicked = true;
    await this.saveReport();
    this.runReportInput = {
      primaryCompanyId: this.selectedprimaryComapnyId,
      reportId: this.currentReportId,
      isRunReport: true,
      emails: EMPTY_STRING,
    };
    this.service.runReportInput = this.runReportInput;
    if (!this.reportError) {
      this.dialog.open(RunReportComponent);
    }
  }
}
