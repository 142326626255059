<div *ngIf="isLoader">
  <app-loader></app-loader>
</div>
<div class="col-auto">
  <h5 class="mtx-text-primary" style="margin-left: 10px; margin-top: 6px">{{ 'CREATE_ASSET_SETUP.TITLE' | translate }}
  </h5>
</div>

<div class="card card-shadow mt-4 card-css">
  <form #createAssetForm="ngForm">
    <div class="card-body">
      <div class="row" style="margin-top: -20px;">
        <div class="col-md-2">
          <div class="progress-container mb-4 bs-stepper">
            <div class="step-container">
              <span style="color: #737373;">Step {{currentStep}}/5</span>
              <div class="progress-bars">
                <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 1, 'inactive-bar': currentStep < 1}"
                  role="progressbar">
                </div>
                <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 2, 'inactive-bar': currentStep < 2}"
                  role="progressbar">
                </div>
                <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 3, 'inactive-bar': currentStep < 3}"
                  role="progressbar">
                </div>
                <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 4, 'inactive-bar': currentStep < 4}"
                  role="progressbar">
                </div>
                <div class="progress-bar" [ngClass]="{'active-bar': currentStep >= 5, 'inactive-bar': currentStep < 5}"
                  role="progressbar">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mtx-card-bg-primary mtx-card-info">
        <div class="mtx-padding-bottom-0" style="padding-left: 10px">
          <div class="row mt-2">
            <div class="col-sm-12 col-md-8 d-flex">
              <span class="mtx-font-20 mtx-font-weight-500" style="margin-right: 5px">{{
                'CREATE_ASSET_SETUP.FORM_HEADER_HEADING' | translate }}</span>
            </div>
            <div
              class="col-sm-12 col-md-4 pull-right mtx-text-end mtx-padding-right-0 mtx-font-15 d-flex justify-content-end">
              <a class="mtx-theme-primary-color" style="margin-right: 10px">{{ 'CREATE_ASSET_SETUP.VIEW_DOCUMENTATION' |
                translate }}
                <span class="material-icons mtx-theme-primary-color icon-css">open_in_new</span></a>
            </div>
            <div *ngIf="!isSaveClicked" class="col-12">
              <span class="mtx-sub-text-primary mtx-font-14">{{ 'CREATE_ASSET_SETUP.FORM_HEADER_SUBHEADING' | translate
                }}
              </span>
            </div>
          </div>
          <div *ngIf="isSaveClicked" class="row">
            <div class="col">
              <ul>
                <li class="mtx-sub-text-primary mtx-font-14">
                  {{
                  'CREATE_ASSET_SETUP.FORM_HEADER_SUBHEADING_Click1' | translate
                  }}
                </li>
                <li [ngClass]="{ 'mtx-font-weight-500': !isSaveClicked }" class="mtx-sub-text-primary mtx-font-14"
                  style="font-weight: 600">
                  {{
                  'CREATE_ASSET_SETUP.FORM_HEADER_SUBHEADING_Click2' | translate
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3 asset-headline mt-4 mb-0 ">
          <h1 class="asset-headline">Create New Asset Type</h1>
          <p class="asset-type-desc"> <strong> Asset Types
              {{this.currentIndex+1}}/{{this.assets.assetTypeInputs.length}}</strong></p>
        </div>
        <div *ngIf="isEdit" class="col-md-9 mt-4 mb-0 d-flex justify-content-end align-items-center">
          <button class="btn mtx-btn-secondary asset-btn-css" (click)="createAssetTypeBtnClick()">
            <i class="k-icon k-font-icon k-i-plus"></i> Create Asset Type
          </button>
        </div>
      </div>

      <div *ngIf="assets.assetTypeInputs?.[currentIndex]as currentAsset;">
        <div class="row">
          <div class="col-md-3 mt-2 text-asset-type-category">Asset Type Category :</div>
          <div class="col-md-4">
            <kendo-dropdownlist [data]="assetTypeCategoryList" [valuePrimitive]="true"
              name="assetTypeCategory--{{currentIndex}}" [defaultItem]="categoryDefaultItem" textField="assetType"
              valueField="id" required #assetTypeCategoryListVal="ngModel" [(ngModel)]="currentAsset.assetTypeId"
              (ngModelChange)="onAssetTypeChange($event)">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="{ 'label-css': dataItem.id==='' }">{{dataItem.assetType}}</span>
              </ng-template>
            </kendo-dropdownlist>
            <span *ngIf="assetTypeCategoryListVal.invalid && assetTypeCategoryListVal.touched" class="text-danger mb-2">
              Select Asset Type Category.
            </span>
          </div>
          <!-- Button-Section -->
          <div class="col-md-5 d-flex justify-content-end align-items-center">
            <kendo-button id="btn-save" class="btn-save me-3" (click)="saveAssetDetails()"
              [disabled]="!createAssetForm.valid || !isFormChanged">Save</kendo-button>
            <div class="d-flex align-items-center button-container">
              <button [disabled]="currentIndex === 0" class="circle-button" (click)="showPreviousData()">
                <i class="k-icon k-font-icon k-i-arrow-chevron-left"></i>
              </button>
              <button
                [disabled]="this.nextBtnFlag || this.assets.assetTypeInputs?.length - 1 <= this.currentIndex"
                class="circle-button" (click)="showNextData()">
                <i class="k-icon k-font-icon k-i-arrow-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div *ngFor="let asset of currentAsset.assetTypeTemplateInputs; let i = index" class="">
          <div *ngIf="!asset.isDeleted" class="row mt-4 my-4 asset-template">
            <!-- Asset Type and Measurement Frequency -->
            <div class="row">
              <div class="col-md-3 mt-2 txt-css">
                <label class>Asset Type Template :</label>
              </div>
              <div class="col-md-4">
                <input type="text" class="form-control" placeholder="Add New Asset Type Template"
                  name="assetTypeTemplateName--{{i}}" #assetTypeTemplateName="ngModel" required
                  [(ngModel)]="asset.assetTypeTemplateName" (ngModelChange)="validateAssetTypeName($event, i)">
                <span *ngIf="assetTypeTemplateName.invalid && assetTypeTemplateName.touched" class="text-danger">
                  Asset Type is Required.
                </span>
                <span *ngIf="!isUniqueAssetName(asset.assetTypeTemplateName, i) && asset.assetTypeTemplateName"
                  class="text-danger">
                  Asset Type must be unique.
                </span>
              </div>
              <!-- Conditional Delete Button -->
              <div class="col-md-3">
                <button *ngIf="currentAsset.activeAssetTypeInputs > 1"
                  class="k-icon k-font-icon k-i-close-outline k-i-x-outline k-i-error delete-btn"
                  (click)="deleteAssetType(i)"></button>
              </div>
            </div>

            <div class="row d-flex align-items-center my-2">
              <div class="col-md-3 txt-css">
                <label>Annual Compliance</label>
              </div>
              <div class="col-md-4 d-flex align-items-center" style="gap: 10px;">
                <label class="switch">
                  <input type="checkbox" [(ngModel)]="asset.isAnnualCompliance" (ngModelChange)="checkFormChanges()"
                    name="isAnnualCompliance--{{i}}" />
                  <span class="slider round"></span>
                </label>
                <div class="export-modal-textandsupportingtext09">
                  <span class="export-modal-text21"> {{asset.isAnnualCompliance?'ON':'OFF' }}</span>
                </div>
              </div>
            </div>
            <div class="row d-flex align-items-center mb-3">
              <div class="col-md-3 txt-css">
                <label>Periodic Compliance</label>
              </div>
              <div class="col-md-2 d-flex align-items-center" style="gap: 10px;">
                <label class="switch">
                  <input type="checkbox" [(ngModel)]="asset.isPeriodicCompliance" (ngModelChange)="checkFormChanges()"
                    name="isPeriodicCompliance--{{i}}" />
                  <span class="slider round"></span>
                </label>
                <div class="export-modal-textandsupportingtext09">
                  <span class="export-modal-text21"><span>{{ asset.isPeriodicCompliance?'ON':'OFF'
                      }}</span></span>
                </div>
              </div>
              <div class="col-md-5 d-flex align-items-center">
                <div class="measurement-container">
                  <div class="input-container">
                    <input type="text" class="form-control measurement-input" placeholder="Measurement Frequency"
                      [required]="asset.isPeriodicCompliance" #measurementFrequencyVal="ngModel" readonly
                      name="measurementFrequency--{{i}}" [(ngModel)]="asset.customFrequencyString" />
                    <span class="edit-icon" (click)="openMeasurementFrequencyPopup(i)">
                      <i class="material-icons">edit</i>
                    </span>
                  </div>
                  <span
                    *ngIf="asset.isPeriodicCompliance && measurementFrequencyVal.invalid && measurementFrequencyVal.touched"
                    class="text-danger mb-2">
                    Select Measurement Frequency.
                  </span>
                </div>
              </div>
            </div>

            <div class="row">
              <!-- Labels for Measurement Fields (only rendered once) -->
              <div class="row mx-2" style="color: #737373">
                <div class="col-md-3">
                  <label>Measurement Source</label>
                </div>
                <div class="col-md-4">
                  <label>Measurement Type</label>
                </div>
                <div class="col-md-3">
                  <label>Compliance Measurement?</label>
                </div>
                <div class="col-md-2 no-wrap">
                  <label>View in Main Grid</label>
                </div>
              </div>
            </div>
            <!-- Dynamic Measurement Rows -->
            <div *ngFor="
                let measurement of asset.measurementCriteriaInputs;
                let j = index
              " class="row mx-2">
              <div *ngIf="!measurement.isDeleted" class="col-md-3">
                <!-- Container for Dropdown or Input -->
                <ng-container *ngIf="currentAsset.assetType === 'Test Station'; else inputTemplate">
                  <!-- Dropdown for 'test station' -->
                  <div *ngIf="!measurement.isCustomTestStationOptionSelected">
                    <kendo-dropdownlist [data]="testStationMeasurementSource"
                      [(ngModel)]="measurement.measurementSource" (ngModelChange)="checkFormChanges()"
                      name="measurementSource--{{i}}-{{j}}" [valuePrimitive]="true"
                      [defaultItem]="measurementSourcedefaultItem" textField="text" valueField="value" required
                      #measurementSourceVal="ngModel">
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span [ngClass]="{ 'label-css': dataItem.value === '' }">{{ dataItem.text }}</span>
                      </ng-template>
                      <ng-template kendoDropDownListItemTemplate let-dataItem>
                        <a *ngIf="dataItem.value === 'addCustom'" (click)="enableCustomInput(i,j)"
                          style="color: blue; text-decoration: underline; cursor: pointer;">
                          + Add Custom
                        </a>
                        <span *ngIf="dataItem.value !== 'addCustom'">{{ dataItem.text }}</span>
                      </ng-template>
                    </kendo-dropdownlist>
                    <span *ngIf="measurementSourceVal.invalid && measurementSourceVal.touched" class="text-danger">
                      Measurement Source is required.
                    </span>
                  </div>
                  <!-- Custom Input Field -->
                  <div *ngIf="measurement.isCustomTestStationOptionSelected">
                    <input id="source--{{i}}-{{j}}" type="text" class="form-control"
                      placeholder="Enter Measurement Source" name="measurementSource--{{i}}-{{j}}"
                      [(ngModel)]="measurement.measurementSource" required (ngModelChange)="checkFormChanges()"
                      #measurementSourceVal="ngModel" />
                    <span *ngIf="measurementSourceVal.invalid && measurementSourceVal.touched" class="text-danger">
                      Measurement Source is required.
                    </span>
                  </div>
                </ng-container>

                <!-- Input Template -->
                <ng-template #inputTemplate>
                  <input id="source--{{i}}-{{j}}" type="text" class="form-control"
                    placeholder="Enter Measurement Source" name="measurementSource--{{i}}-{{j}}"
                    [(ngModel)]="measurement.measurementSource" required (ngModelChange)="checkFormChanges()"
                    #measurementSourceVal="ngModel" />
                  <span *ngIf="measurementSourceVal.invalid && measurementSourceVal.touched" class="text-danger">
                    Measurement Source is required.
                  </span>
                </ng-template>
                <!-- Duplicate combination check -->
                <div class="col-md-12">
                  <span
                    *ngIf="!isUniqueMeasurementCombination(measurement.measurementSource, measurement.measurementType, i, j)"
                    class="text-danger no-wrap">
                    Duplicate combination of Measurement Source and Measurement Type is not allowed.
                  </span>
                </div>
              </div>
              <div *ngIf="!measurement.isDeleted" class="col-md-4">
                <kendo-dropdownlist [data]="currentAsset.measurementTypesList" [(ngModel)]="measurement.measurementType"
                  (ngModelChange)="checkFormChanges()" name="measurementType--{{i}}-{{j}}" [valuePrimitive]="true"
                  [defaultItem]="measurementTypesdefaultItem" textField="measurementType" valueField="measurementType"
                  required #measurementTypesVal="ngModel">
                  <ng-template kendoDropDownListValueTemplate let-dataItem>
                    <span [ngClass]="{ 'label-css': dataItem.id === '' }">{{
                      dataItem.measurementType
                      }}</span>
                  </ng-template>
                </kendo-dropdownlist>
                <span *ngIf="measurementTypesVal.invalid && measurementTypesVal.touched" class="text-danger">
                  Measurement Type is required.
                </span>
              </div>
              <div *ngIf="!measurement.isDeleted" class="col-md-3 d-flex justify-content-center align-items-center">
                <input id="compliance--{{i}}-{{j}}" type="checkbox" class="form-check-input"
                  name="complianceCheckbox--{{i}}-{{j}}" (change)="complianceCheckBoxChange(i,j)"
                  (ngModelChange)="checkFormChanges()" [(ngModel)]="measurement.compliance" />
              </div>

              <div *ngIf="!measurement.isDeleted" class="col-md-2 d-flex justify-content-center align-items-center">
                <input id="viewInMainGrid--{{i}}-{{j}}" type="checkbox" class="form-check-input"
                  name="viewInMainGridCheckbox--{{i}}-{{j}}" [(ngModel)]="measurement.viewInMainGrid"
                  (ngModelChange)="checkFormChanges()" />
                <div *ngIf="!measurement.isDeleted" class="mx-3">
                  <button *ngIf="asset.activeMeasurementCriteriasCount> 1"
                    class="k-icon k-font-icon k-i-close-outline k-i-x-outline k-i-error delete-btn"
                    (click)="deleteMeasurement(i, j)"></button>
                </div>
              </div>
            </div>
            <!-- Add New Measurement Button -->
            <div *ngIf="!asset.isDeleted" class="col-md-3 mx-4 " style="padding: 0.375rem 0.75rem">
              <button class="btn mtx-btn-secondary addBtn-css" (click)="addMeasurement(i)">
                + Add New Measurement
              </button>
            </div>

          </div>
        </div>
      </div>
      <button class="btn mtx-btn-secondary mt-4 addBtn-css" (click)="addAssetType()">
        Create New Asset Type Template
      </button>
    </div>
  </form>
  <!-- Divider line before buttons -->
  <hr class="button-divider mt-3" />
  <!-- Cancel, Save & Exit, and Save & Next Buttons -->
  <div class="mt-2 mb-3 d-flex justify-content-end">
    <button class="btn mtx-btn-secondary me-4 fontWeight" style="width: 170px;height: 40px;" (click)="cancel()">
      Cancel
    </button>
    <button class="btn mtx-btn-secondary me-4 fontWeight disabledBtnCss" style="width: 170px;height: 40px;"
      (click)="saveAndExit(createAssetForm)">
      Exit
    </button>
    <button *ngIf="!isEdit" class="btn mtx-btn-primary me-4 fontWeight disabledBtnCss"
      style="width: 170px;height: 40px;" [disabled]="isCreateAPIFlag" (click)="saveAndNext(createAssetForm)">
      Next
    </button>
  </div>
</div>
<app-custom-frequency [OpenDialog]="isDialogOpen" [defaultFrequency]="custFrequency"
  (isDialogOpenFlag)="closeMeasurementFrequencyPopup()"
  (customFrequency)="setCustomFrequency($event)"></app-custom-frequency>