import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Output,
  ViewChild,
  ViewChildren,
  QueryList,
  OnInit,
  AfterViewInit, OnDestroy,

} from '@angular/core';
import {
  ColumnBase,
  ColumnReorderEvent,
  GridComponent,
  ColumnComponent,
  RowArgs,
  RowStickyFn,
  GridDataResult,
  DataStateChangeEvent,
} from '@progress/kendo-angular-grid';
import { InspectionService } from '../../core/services/inspection.service';
import { ExportModalComponent } from '../../shared/export-modal/export-modal.component';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { MatDialog } from '@angular/material/dialog';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
} from '@progress/kendo-data-query';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { FilterService } from '@progress/kendo-angular-grid';
import { LocalStorageService } from '../../shared/service/local-storage/local-storage.service';
import {
  DEFAULT,
  DEFAULTPAGESIZE,
  INSPECTION,
  LONG,
  ND,
  PAGINATION_ARRAY,
  RD,
  REDIRECT_ASSET_HISTORY,
  REDIRECT_ASSET_SETUP,
  REDIRECT_EXCEPTION,
  SEARCHINSPECTION,
  ST,
  TH,
  ZERO,
  INSPECTION_SCREEN
} from '../../shared/constants';
import { FilterInput, HierarchyFilterInput, Inspection, KendoGridFilters, SurveyRouteFilterInput, templateType } from '../../../awsAppSync/API';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { Observable } from 'rxjs';
import { Level3State } from '../../core/store/filter-level3.state';
import { SegmentTypeState } from '../../core/store/filter-segmenttype.state';
import { SegmentState } from '../../core/store/filter-segments';
import { SurveyRouteState } from '../../core/store/filter-serveyrotes';
import { AssetTypeState } from '../../core/store/filter-assettype';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { Column, InspectionModel, Measurement, ProcessedMeasurement, ProcessedSurveyData } from '../../core/models/inspection.module';
import { Router } from '@angular/router';
import { PermissionService } from '../../core/services/permission.service';
import { ExceptionService } from '../exception/service/exception.service';

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrl: './inspection.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InspectionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('pdfExport', { static: false }) pdfExport!: PDFExportComponent;
  @ViewChild('grid') grid!: GridComponent;
  @ViewChild('customFilterContainer', { static: false })
  customFilter!: ElementRef;
  @ViewChildren(ColumnComponent) columns!: QueryList<ColumnComponent>;
  @Output() ClearButtonCLick = new EventEmitter<boolean>();
  @Output() IsFilterAdded = new EventEmitter<boolean>();
  public _selectedColumns: string[] = [
    'assetType',
    'assetName',
    'complianceStatus',
    'inspectionTargetDate',
    'lastInspectionDate',
    'assetDelinquencyDate',
    'technician',
    'daysUntilDelinquent'
  ];
  public templateTypeInput = templateType.inspection;
  public inputScreen = INSPECTION;
  public searchPlaceholder = SEARCHINSPECTION;
  //public gridData: (InspectionModel | null)[] = [];
  originalData: (InspectionModel | null)[] = [];
  
  public gridData: GridDataResult = { data: [], total: 0 };
  public searchText = "";
  kendoGridFilters : KendoGridFilters =  {};
  public PAGINATION_ARRAY = PAGINATION_ARRAY;
  public pageSize = DEFAULTPAGESIZE;

  public currentPage = 1;
  public totalItems = ZERO;
  public complaiceStatusFilterValues: any[] = [];
  public assetTypeFilterValues: any[] = [];
  public technicianFilterValues: any[] = [];
  public myStickyRows: string[] = []; // Change type to string
  public isStickyRow = false;
  public dropdownVisible = false;
  public dropdownX = ZERO;
  public dropdownY = ZERO;
  public currentDataItem: any;
  public rowId = ZERO;
  public isLoading=false;
  gridDataToExport: any[] = [];
  filteredData: any[] = [];
  pageChanged = false;
  getFilterDropdownValue = false;
  selectedRowData: any;

  storePrimaryCompanyID$: Observable<string | null>;
  selectedprimaryComapnyId : string | null = "";
  storeSelectedLevel3$: Observable<string[]>;
  selectedLevel3:string[] = [];
  storeSegmentTypeSet$: Observable<string[]>;
  selectedSegmentType:string[] = [];
  storeSelectedSegments$: Observable<string[]>;
  selectedSegments:string[] = [];
  storeSelectedSurveyRoute$: Observable<string[]>;
  selectedSurveyRoute:string[] = [];
  storeSelectedAssetType$: Observable<string[]>;
  selectedAssetType:string[] = [];

  filterModel: FilterInput = {};
  hierarchyFilterInput : HierarchyFilterInput[] = [];
  surveyRouteFilterInput: SurveyRouteFilterInput[] = [];
  filterDataPresent = false;
  dynamicColumns: Column[] = [];

  public expandedRowId: any = null;  
  globalClickListener: ((event: MouseEvent) => void) | null = null;
  permission = false;

  
  constructor(
    public inspectionService: InspectionService,
    private toastr: CustomToastrService,
    public dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
    private store : Store,
    private hierarchyFilter: HeirarchyFilterServiceService,
    private router: Router,
    private elementRef: ElementRef,
    public permissionService: PermissionService,
    public exceptionService: ExceptionService,
  ) {
    this.storePrimaryCompanyID$= this.store.select(AuthenticateUserState.getSyrcPrimaryCompanyId);
    this.storeSelectedLevel3$ = this.store.select(Level3State.getSelectedLevel3Name);
    this.storeSegmentTypeSet$ = this.store.select(SegmentTypeState.getSelectedSegmentType);
    this.storeSelectedSegments$ = this.store.select(SegmentState.getSelectedSegmentIds);
    this.storeSelectedSurveyRoute$ = this.store.select(SurveyRouteState.getSelectedSurveyRouteIds);
    this.storeSelectedAssetType$ = this.store.select(AssetTypeState.getSelectedAssetTypeIds);
  }

  ngAfterViewInit(): void {
   
    this.globalClickListener = this.handleClickOutside.bind(this);
    document.addEventListener('click', this.globalClickListener as EventListener, true);
  }

  handleClickOutside(event: MouseEvent): void {
    const gridElement = this.elementRef.nativeElement.querySelector('kendo-grid');
    
    if (gridElement && !gridElement.contains(event.target as Node)) {
      this.collapseAllRows();
    }
  }

  collapseAllRows(): void {
    const totalRows = this.gridData.data.length; 
  
    for (let i = 0; i < totalRows; i++) {
      this.grid.collapseRow(i); 
    }
  }

  ngOnDestroy(): void {
    if (this.globalClickListener) {
      document.removeEventListener('click', this.globalClickListener as EventListener, true);
    }
  }

  async ngOnInit() {
    this.permission = this.permissionService.hasPermission(INSPECTION_SCREEN, 'fullAccess');
    this.storePrimaryCompanyID$.subscribe(id => {
      this.selectedprimaryComapnyId = "";
      this.selectedprimaryComapnyId = id;
    });

    this.storeSelectedLevel3$.subscribe(level3name => {
        this.selectedLevel3 = level3name;
        this.hierarchyFilterInput = [];
        if(this.selectedprimaryComapnyId && this.selectedLevel3.length > 0){
          this.fetchLevel3Values(this.selectedprimaryComapnyId);
        }
    });

    this.storeSegmentTypeSet$.subscribe(segmentType => {
      this.selectedSegmentType = [];
      this.selectedSegmentType = segmentType;
    });
    
    this.storeSelectedSegments$.subscribe(segment => {
      this.selectedSegments = [];
      this.selectedSegments = segment;
    });

    this.storeSelectedSurveyRoute$.subscribe(Surveyroute => {
      this.surveyRouteFilterInput = [];
      this.selectedSurveyRoute = Surveyroute;
      if(this.selectedSurveyRoute.length > 0 && this.selectedprimaryComapnyId){
        this.fetchSurveyRouteValues(this.selectedprimaryComapnyId);
      }
    });

    this.storeSelectedAssetType$.subscribe(assettype => {
      this.selectedAssetType =[];
      this.selectedAssetType = assettype;
    });

    this.hierarchyFilter.isClearFilterClick.subscribe((value) => {
      this.onClearFilterPressed(value);
    });

    this.hierarchyFilter.isSurveyRouteClearFilterClick.subscribe((value) => {
      this.onClearFilterPressed(value);
    });

    this.hierarchyFilter.isSurveyRouteFilterAdded.subscribe(() => {
      this.updateGridOnSurveyRouteAdded();
    });

    await this.loadInspectionData();
  }

  onClearFilterPressed(value: boolean) {
    if(value){
      this.filterDataPresent = false;
    }
    this.updateFilterModel(value);
  }

  updateGridOnSurveyRouteAdded() {
    this.updateFilterModel(true);
  }

  async bindGridData() {
    this.loadInspectionData();
  }

  private updateFilterModel(value: boolean) {
    if(value){
      this.gridData = {
        data: [],
        total: 0
      };
    }
    else{
      this.filterModel = {
        primaryCompany: this.selectedprimaryComapnyId,
        level4: this.selectedSegments,
        assetTypes: this.selectedAssetType,
        segmentTypes: this.selectedSegmentType,
        surveyRouteFilterInputs: this.surveyRouteFilterInput,
        hierarchyFilterInputs: this.hierarchyFilterInput,
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
        searchText: this.searchText,
        gridFilters: this.kendoGridFilters
      };
    }
  }

async fetchLevel3Values(primaryCompanyId: string) {
  const allLevel3Values: HierarchyFilterInput[] = [];
  const level3Values = await this.hierarchyFilter.getAllLevel3ValueByPrimaryCompany(primaryCompanyId);
  if (level3Values?.items) {
    level3Values.items.forEach(item => {
      if (item) {
        if(this.selectedLevel3.includes(item.id))
        allLevel3Values.push({
          level1: item.level1ValueId || '',
          level2: item.level2ValueId || '',
          level3: item.id || '',
        });
      }
    });
    this.hierarchyFilterInput = allLevel3Values;
  }
}

async fetchSurveyRouteValues(primaryCompanyId: string) {
  const allSurveyRouteFilterValues: SurveyRouteFilterInput[] = [];
  const surveyRouteValues = await this.hierarchyFilter.getAllSurveyRouteBySubFilterInput({primaryCompanyId: primaryCompanyId});
  if (surveyRouteValues?.items) {
    surveyRouteValues.items.forEach(item => {
      if (item) {
        if(this.selectedSurveyRoute.includes(item.id)){
          item.RouteAssociateAssetList?.forEach(level=>{
            level?.level3ValueIdList?.forEach(l3 => {
              allSurveyRouteFilterValues.push({
                level1: level?.level1ValueId,
                level2: level?.level2ValueId,
                level3: l3,
                assetId: level.assetId
              });
            });
          })
        }
      }
    });
  }
  this.surveyRouteFilterInput = allSurveyRouteFilterValues;
}

filterSearchButtonClicked():void {
  this.loadInspectionData();
  this.myStickyRows = [];
}

 async loadInspectionData ():Promise<void>{
    try {
      this.isLoading = true;
      await this.fetchLevel3Values(this.selectedprimaryComapnyId!);
      await this.fetchSurveyRouteValues(this.selectedprimaryComapnyId!);
      this.updateFilterModel(false);
      const response = await this.inspectionService.getAllInspectionData(this.filterModel);
      if (response?.totalCount === 0 && this.selectedLevel3.length > 0) {
        this.filterDataPresent = true;
      }
      if (response?.items) {
        const items = response.items.filter((item): item is Inspection => item !== null);
        const inspectionModels: InspectionModel[] = this.mapInspectionsToModels(items);
        this.dynamicColumns = this.generateDynamicColumns(inspectionModels);
        const inspectionModules = this.mapInspectionsAndMeasurments(inspectionModels);
        this.gridData ={
          data: inspectionModules,
          total : response?.totalCount != null ? response?.totalCount : 0
        }
        this.originalData = inspectionModules;
        this.cdr.markForCheck(); 
        this.getDistinctValues('assetType');
        this.getDistinctValues('complianceStatus');
        this.getDistinctValues('technician');
        this.processTechnicianFilterValues();
        this.isLoading = false;
        if (items.length > 0) {
          this.filterDataPresent = false;
        }
      }
    } 
    catch (error) {
      console.error(error);
    }
 }

 processTechnicianFilterValues(): void {
  const processedValues = new Set<string>();
  this.technicianFilterValues.forEach(item => {
    const techs = item.text.split(','); // Split by comma
    techs.forEach((tech: string) => {
      const trimmedTech = tech.trim();
      if (trimmedTech) {
        processedValues.add(trimmedTech);
      }
    });
  });
  this.technicianFilterValues = Array.from(processedValues).map(item => ({ text: item, selected: false }));
}

 mapInspectionsToModels(inspections: Inspection[]): InspectionModel[] {
  return inspections.map(inspection => ({
      __typename: "Inspection",
      id: inspection.id ?? null,
      pk: inspection.pk ?? null,
      assetName: inspection.assetName ?? null,
      assetType: inspection.assetType ?? null,
      station: inspection.station ?? null,
      daysUntilDelinquent: inspection.daysUntilDelinquent != null && inspection.daysUntilDelinquent !== '' 
      ? `${inspection.daysUntilDelinquent} Days` 
      : null,
      complianceStatus: inspection.complianceStatus ?? null,
      lastInspectionDate: inspection.lastInspectionDate ?? null,
      inspectionTargetDate: inspection.inspectionTargetDate ?? null,
      assetDelinquencyDate: inspection.assetDelinquencyDate ?? null,
      technician: inspection.technician ?? null,
      measurementPoint: inspection.measurementPoint ?? null,
      measurements: inspection.measurements ?? null,
      measurementsProcesses: null, // Initialize to null or set if available
      dynamicValues: {} // Initialize as empty object
  }));
}

 private mapInspectionsAndMeasurments(inspections: InspectionModel[]): InspectionModel[] {
  return inspections.map(ins => {
      // Process measurements
      const measurements = ins.measurements ?? []; // Default to an empty array if null or undefined
      const processedMeasurements = this.processSurveyData(
          measurements.filter((measurement): measurement is Measurement => measurement !== null)
      );

      return {
          __typename: "Inspection",
          id: ins.id ?? null,
          pk: ins.pk ?? null,
          assetName: ins.assetName ?? null,
          assetType: ins.assetType ?? null,
          station: ins.station ?? null,
          complianceStatus: ins.complianceStatus ?? null,
          lastInspectionDate: ins.lastInspectionDate ?? null,
          inspectionTargetDate: ins.inspectionTargetDate ?? null,
          assetDelinquencyDate: ins.assetDelinquencyDate ?? null,
          technician: ins.technician ?? null,
          daysUntilDelinquent:ins.daysUntilDelinquent ?? null,
          measurementsProcesses: processedMeasurements, // This will now contain the array
          measurements: ins.measurements,
          dynamicValues: ins.dynamicValues
      };
  });
}

processSurveyData = (measurements: Measurement[] | null | undefined): ProcessedSurveyData => {
  const dates = new Set<string>();
  const table: Record<string, ProcessedMeasurement> = {};
  let measurementPointCounter = 1; // Initialize the counter
  if (measurements) {
      measurements.forEach(measurement => {
          const surveyDate = measurement.surveyDate?.split('T')[0];
          if (surveyDate) {
              dates.add(surveyDate);
          }

          const measurementType = measurement.measurementType ?? '';
          const source = measurement.source ?? '';
          const status =  measurement.isCompliant ? "Compliant":"Non Compliant" 
          const unit = measurement.unit ?? '';
          const criteria = measurement.criteria + ' '+ unit;
          const level4Value = measurement.level4Value ?? '';
          const key = `${source}-${measurementType}`;

          if (!table[key]) {
              table[key] = {
                  measurementPoint: measurementPointCounter++, // Assign and increment the counter
                  source,
                  measurementType,
                  status,
                  criteria,
                  level4Value,
              };
          }
           else {
            const existingValues = table[key].level4Value?.split(', ').map(value => value.trim());
            if (!existingValues?.includes(level4Value)) {
                table[key].level4Value += `, ${level4Value}`;
            }
          }

          if (surveyDate) {
              let value =measurement.measurementValue ?? '';
              value = value + " "+ unit;
              table[key][`Measurement On ${surveyDate}`] = value;
          }
      });
  }

  // Convert table record to an array
  const formattedTable = Object.values(table);

  return {
      table: formattedTable,
      dates: Array.from(dates),
  };
};


onWindowScroll(_event: any): void {
    this.dropdownVisible = false;
  }

  public OnSelectedTemplateColumnsChange(selectedColumns: string[]) {
    this._selectedColumns = selectedColumns;
    this._selectedColumns.forEach((columnName) => {
      this.IsColumnSelected(columnName);
    });
  }

  public IsColumnSelected(columnName: string): boolean {
    return this._selectedColumns.includes(columnName);
  }

  openDialog(): void {
    const currentPageData = this.gridData.data.slice(
      this.state.skip,
      this.state ? this.state.skip as number + this.pageSize :0
    );
    const dataToExport = this.pageChanged
      ? this.gridDataToExport
      : currentPageData;

    this.filteredData = dataToExport.map((item) => {

      const filteredItem: Record<string, any> = {};
      this._selectedColumns.forEach((column) => {
        filteredItem[column] = item[column];
      });
      filteredItem["dynamicValues"] = item.dynamicValues;
      return filteredItem;
    });

    this.dialog.open(ExportModalComponent, {
      data: {
        selectedColumnData: this.filteredData,
        selectedColumn: this._selectedColumns,
        pdfExport: this.pdfExport,
        pageSize: this.pageSize,
        gridData: dataToExport,
        allData: this.originalData,
        flag: INSPECTION,
        filters : this.filterModel
      },
    });

    // Reset pageChanged flag if needed
    if (this.pageChanged) {
      this.pageChanged = false;
    }
  }

  equalsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === 'string') {
      return field.toLowerCase() === searchTerm.toLowerCase();
    }
    return false;
  }

  // Helper function to check if a field contains the search term
  containsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === 'string') {
      return field.toLowerCase().includes(searchTerm);
    }
    return false;
  }


  formatDate(date: Date): string {
    if (!date) return '';

    const day = date.getDate();
    const month = date.toLocaleString(DEFAULT, { month: LONG });
    const year = date.getFullYear();

    const daySuffix = this.getDaySuffix(day);

    return `${day}${daySuffix} ${month}, ${year}`;
  }

  getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return TH;
    switch (day % 10) {
      case 1:
        return ST;
      case 2:
        return ND;
      case 3:
        return RD;
      default:
        return TH;
    }
  }

  public rowSticky: RowStickyFn = (args: RowArgs) =>
    this.isSticky(args.dataItem.id);

  public isSticky(id: string): boolean {
    return this.myStickyRows.indexOf(id) >= 0;
  }

  public toggleStickyRow(id: string): void {
    if (this.isSticky(id)) {
      this.myStickyRows = this.myStickyRows.filter(
        (stickyId) => stickyId !== id,
      );
    } else {
      this.myStickyRows.push(id);
    }
    this.sortGridData();
  }
  private sortGridData(): void {
    //Create a copy of gridData to avoid modifying the original array in place
    const sorteddt = [...this.gridData.data];

    //Sort the copied array
    sorteddt.sort((a, b) => {
      //Check if a or b is null or undefined
      if (a === null || a === undefined) return 1;
      if (b === null || b === undefined) return -1;

      const isASticky = this.isSticky(a.id);
      const isBSticky = this.isSticky(b.id);

      if (isASticky && !isBSticky) {
        return -1;
      } else if (!isASticky && isBSticky) {
        return 1;
      } else {
        // Maintain the original order if both are non-sticky
        return a.id.localeCompare(b.id); // Use localeCompare for string comparison
      }
    });
    this.gridData.data=sorteddt
  }
  //this code is w.r.t row-actions drop down
  openDropdownMenu(event: MouseEvent, dataItem: any) {
    event.stopPropagation(); // Prevent the click event from propagating to the document
    this.dropdownVisible = true;
    this.currentDataItem = dataItem;
    this.rowId = this.currentDataItem.id;
    // Adding the data to local storage
    this.localStorageService.setItem('rowData', this.currentDataItem);

    const gridElement = document.querySelector('.k-grid') as HTMLElement; // Replace '.k-grid' with the appropriate selector for your Kendo grid
    const dropdownHeight = 150; // Set this to the height of your dropdown menu
    const dropdownWidth = 200; // Set this to the width of your dropdown menu

    if (gridElement) {
      const gridRect = gridElement.getBoundingClientRect();
      const clickX = event.clientX - gridRect.left + gridElement.scrollLeft;
      const clickY = event.clientY - gridRect.top + gridElement.scrollTop;

      // Calculate space available below and above the click position
      const spaceBelow = window.innerHeight - (event.clientY - gridRect.top + gridElement.scrollTop);

      // Adjust dropdownY based on available space
      if (spaceBelow < dropdownHeight) {
        // Not enough space below, position dropdown above
        this.dropdownY = clickY - dropdownHeight;
      } else {
        // Enough space below, position dropdown below
        this.dropdownY = clickY;
      }

      // Ensure the dropdown doesn't overflow horizontally
      this.dropdownX = Math.max(0, Math.min(clickX, gridElement.clientWidth - dropdownWidth));

      // Adjust dropdownX to account for the grid's left boundary
      this.dropdownX += gridRect.left;

      // Ensure dropdownY stays within the viewport
      this.dropdownY = Math.max(0, this.dropdownY + 150);

      // If dropdownY exceeds the viewport height, position it at the bottom
      if (this.dropdownY + dropdownHeight > window.innerHeight) {
        this.dropdownY = window.innerHeight - dropdownHeight;
      }
    }

    const rowId = dataItem.id;
    this.selectedRowData = dataItem;
    this.isStickyRow = this.isSticky(rowId);
}

  handleDropdownOption(option: number) {
    // Handle the option selection (e.g., call a method or service)
    // for option 1 we have to make the rows sticky and non-sticky
    if (option === 1) {
      this.toggleStickyRow(this.currentDataItem.id);
    }
    if (option === 2) {
      this.exceptionService.selectedRowData = this.selectedRowData;
      this.router.navigate([REDIRECT_EXCEPTION], {});
    }
    if (option === 3) {
      this.router.navigate([REDIRECT_ASSET_HISTORY], {});
    }
    if (option === 4) {
      this.router.navigate([REDIRECT_ASSET_SETUP], {});
    }
  }

  handleDropdownVisibilityChange(visible: boolean) {
    this.dropdownVisible = visible;
  }

  onScroll(_event: any): void {
    this.dropdownVisible = false;
  }

  rowActionStickyRow(event: MouseEvent, dataItem: any) {
    event.stopPropagation(); // Prevent the click event from propagating to the document
    //we want to change the text from Upin to Pin of drop-down that we are making this call
    const rowId = dataItem.id;
    //we need to call the isSticky function to check the cooresponding row is sticky or not
    //here we have used the order by to check either row is sticky or not
    if (this.isSticky(rowId)) {
      this.isStickyRow = true;
    } else {
      this.isStickyRow = false;
    }
    //this is used to make the row unsticky
    this.toggleStickyRow(rowId);
  }

  // Column reorder functionality Code
  public onColumnReorder(event: ColumnReorderEvent): void {
    const lockedColumns = ['assetType', 'assetName'];

    const field = this.getColumnField(event.column);

    if (
      (field && lockedColumns.includes(field)) ||
      this.isNonReorderableColumn(event.column) ||
      this.isNewIndexInFirstThree(event.newIndex)
    ) {
      this.toastr.showInfo('Mandatory Columns cannot be re-ordered', 'Info');
      event.preventDefault();
    }
  }

  getColumnField(column: ColumnBase): string | null {
    if ('field' in column) {
      return (column as any).field;
    }
    return null;
  }

  private isNewIndexInFirstThree(newIndex: number): boolean {
    return newIndex < 3;
  }

  showHeirarchyFilterPopup = false;

  private isNonReorderableColumn(column: ColumnBase): boolean {
    const headerClass = column.headerClass;

    if (typeof headerClass === 'string') {
      return headerClass.includes('non-reorderable');
    }
    return false;
  }

  //col-reorder code
  //col-reorder code i.e this method will be invoked when there is change in drag/drop  template
  public reorderColumnPosition(columnPosition: {
    previousIndexOfCol: number;
    newIndexOfCol: number;
    columnName: string;
  }): void {

    const fieldName = columnPosition.columnName;
    const index = columnPosition.newIndexOfCol + 1;
    const column = this.columns.find((col) => col.field === fieldName);
    if (column) {
      if (columnPosition.newIndexOfCol < columnPosition.previousIndexOfCol) {
        this.grid.reorderColumn(column as ColumnBase, index, { before: true });
      } else {
        this.grid.reorderColumn(column as ColumnBase, index);
      }
      this.columns.reset([...this.columns.toArray()]);
    }
  }

  public applyFilter(
    selectedValues: any[],
    filterService: FilterService,
    field : string,
  ): void {
    const filters: FilterDescriptor[] = [];
    // Loop through selectedValues to log text and check if selected
    for (const value of selectedValues) {
      // Add filter based on whether the value is selected
      if (value.selected) {
        filters.push({
          field: field,
          operator: field === 'technician' ? 'contains' : 'eq',
          value: value.text,
        });
      }
    }
    const rootFilter: CompositeFilterDescriptor = {
      logic: 'or',
      filters: filters,
    };
    filterService.filter(rootFilter);
  }

  getDistinctValues(columnName: keyof InspectionModel): void {
    // Create a Set to store distinct non-null values for the specified column
    const distinctValues = new Set<string>(
      this.gridData.data
        .map((item) => item![columnName])
        .filter((value): value is string => value !== null && value !='' && value !== undefined)
    );

    // Create a new filter array for the column if it doesn't exist
    const filterValuesMap: Record<string, { text: string; selected: boolean }[]> = {
      assetType: this.assetTypeFilterValues,
      complianceStatus: this.complaiceStatusFilterValues,
      technician: this.technicianFilterValues,
    };

    // Add distinct values to the respective filter array based on the column name
    distinctValues.forEach((item) => {
      const filterItem = { text: item, selected: false };
      const filterArray = filterValuesMap[columnName];
      if (filterArray) {
        // Check if the item already exists to prevent duplicates
        if (!filterArray.some(f => f.text === item)) {
          filterArray.push(filterItem);
        }
      }
    });
}


  
  //tray code to show and hide the filters
  height = 780; // initial height
  showHierarchicalFilter = false;
  showFilterBtn = true;
  toggleFilterTray() {
    this.showHierarchicalFilter = !this.showHierarchicalFilter;
    this.showFilterBtn = !this.showFilterBtn;
    if (this.showHierarchicalFilter) {
      this.height = 830;
    } else {
      this.height = 780;
    }
  }
  collapseFilterTray() {
    this.toggleFilterTray();
  }

  getStatusClass(priority: string): string {
    switch (priority?.toLowerCase()) {
      case 'compliant':
        return 'compliant';
      case 'exception':
        return 'exception';
      case 'delinquent':
        return 'delinquent';
      default:
        return '';
    }
  }

  getMeasurementStatusClass(status: string): string {
    switch (status?.toLowerCase()) {
      case 'compliant':
        return 'compliant';
      case 'non compliant':
        return 'non-compliant';
      default:
        return '';
    }
  }
  generateDynamicColumns(data: InspectionModel[] |null): Column[] {
    const columns: Column[] = [];
  
    // Loop through all inspection items, including those without measurements
    data?.forEach(item => {
      // Initialize dynamicValues for all items
      item.dynamicValues = item.dynamicValues || {};
  
      // Check if measurements exist
      if (Array.isArray(item.measurements) && item.measurements.length > 0) {
        item.measurements.forEach(measurement => {

          if (measurement?.compliance === true) {
            const columnName = `${measurement.measurementType} - ${measurement.level4Value}`;
            const value = `${measurement.measurementValue} ${measurement.unit}`;
  
            // Create column if it doesn't already exist
            if (!columns.find(col => col.field === columnName)) {
              columns.push({
                field: columnName,
                title: columnName,
                width: 200 // Adjust as needed
              });
            }
  
            // Set the value for this dynamic column
            item.dynamicValues[columnName] = value;
          }
        });
      }
  
      // Ensure all columns are initialized in dynamicValues, even if there are no measurements
      columns.forEach(col => {
        if (!item.dynamicValues[col.field]) {
          item.dynamicValues[col.field] = ''; // Set to empty string if no value
        }
      });
    });
  
    return columns;
  }

  handleSearchInput(changeWord: string) {
    if (changeWord.length < 3 && this.searchText.length > 2) {
        this.searchText = "";
        this.loadInspectionData();
    } else if (changeWord.length > 2) {
        this.searchText = changeWord;
        this.loadInspectionData();
    }
  }

  customstate: { filter: CompositeFilterDescriptor } = {
    filter: { logic: 'and', filters: [] },
  };

  public state: State = {
    skip: 0,
    take: this.pageSize,
    group: [],
    filter: { filters: [], logic: "and" },
    sort: [],
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.myStickyRows = [];
    this.isLoading = true;
    this.state = state;
    this.currentPage = state.skip / this.pageSize + 1; 
    this.kendoGridFilters = this.state.filter as KendoGridFilters;
    const filter = state.filter as CompositeFilterDescriptor;
    this.customstate = { ...this.customstate, filter };
    this.loadInspectionData();
  }
  
}


