<kendo-grid *ngIf="flag === 'inspection'" [kendoGridBinding]="filteredGridData">
  <kendo-grid-column
    field="assetType"
    [width]="100"
    *ngIf="IsColumnSelected('AssetType')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Asset Type">Asset Type</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [headerClass]="'ContentGridHeader'"
    field="assetName"
    title="Asset Name"
    [width]="100"
    *ngIf="IsColumnSelected('AssetName')"
  >
  </kendo-grid-column>
  <kendo-grid-column
    [headerClass]="'ContentGridHeader'"
    field="station"
    title="Station"
    [width]="120"
    *ngIf="IsColumnSelected('Station')"
  >
  </kendo-grid-column>
  <kendo-grid-column
    field="complianceCriteria"
    [width]="140"
    *ngIf="IsColumnSelected('Completion')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Compliance Criteria">Compliance Criteria</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="status"
    [width]="135"
    *ngIf="IsColumnSelected('ComplianceStatus')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Compliance Status">Compliance Status</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="nextInspectionDate"
    [width]="145"
    *ngIf="IsColumnSelected('NextInspectionDate')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Next Inspection Date">Next Inspection Date</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="lastComplianceRead"
    [width]="145"
    *ngIf="IsColumnSelected('LastComplianceRead')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Last Compliance Read">Last Compliance Read</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="technician"
    [width]="145"
    *ngIf="IsColumnSelected('Technician')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Technician">Technician</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    [headerClass]="'ContentGridHeader'"
    field="lastMeasurement"
    title="Last Measurement"
    [width]="130"
    *ngIf="IsColumnSelected('LastMeasurement')"
  >
  </kendo-grid-column>
  <!-- <ng-template kendoGridDetailTemplate let-dataItem>
    <div class="expanded-content">
      <kendo-grid [kendoGridBinding]="dataItem.detailData">
        <kendo-grid-column field="ProductID" title="Measurement Point"></kendo-grid-column>
        <kendo-grid-column field="ProductName" title="Measurement Name"></kendo-grid-column>
        <kendo-grid-column field="Quantity" title="Compliance"></kendo-grid-column>
        <kendo-grid-column field="UnitPrice" title="Criteria"></kendo-grid-column>
        <kendo-grid-column field="UnitPrice" title="Measurement On 23/05/2023" [headerClass]="'bordered-column-lt'"
          [class]="'bordered-column-l'"></kendo-grid-column>
        <kendo-grid-column field="UnitPrice" title="Measurement On 23/05/2022" [headerClass]="'bordered-column-t'"
          [class]="'bordered-column-b'"></kendo-grid-column>
        <kendo-grid-column field="UnitPrice" title="Measurement On 23/05/2021" [headerClass]="'bordered-column-rt'"
          [class]="'bordered-column-r'"></kendo-grid-column>
      </kendo-grid>
    </div>
  </ng-template> -->
</kendo-grid>

<kendo-grid *ngIf="flag === 'exception'" [kendoGridBinding]="filteredGridData">
  <kendo-grid-column
        field="assetType"
        [title]="'INSPECTION_GRID_COLUMNS.ASSET_TYPE' | translate"
        [minResizableWidth]="80"
        [width]="120"
      >
      </kendo-grid-column>
      <kendo-grid-column
        field="assetName"
        [title]="'INSPECTION_GRID_COLUMNS.ASSET_NAME' | translate"
        [minResizableWidth]="80"
        [width]="120"
      ></kendo-grid-column>

  <kendo-grid-column
        field="targetRemediationDate"
        title="Target Remedation Date"
        [minResizableWidth]="80"
        format="{0:dd/MM/yyyy}"
        filter="date"
        [width]="160">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="right-alignment">{{
            dataItem.targetRemediationDate | customDate
          }}</span>
        </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
        field="assetDelinquencyDate"
        [title]="'INSPECTION_GRID_COLUMNS.ASSET_DELINQUENCY_DATE' | translate"
        [minResizableWidth]="80"
        [width]="160"
        format="{0:dd/MM/yyyy}"
        filter="date"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="right-alignment">{{
            dataItem.assetDelinquencyDate | customDate
          }}</span>
        </ng-template>
  </kendo-grid-column>

  <kendo-grid-column
        field="workFlowStatus"
        [title]="'EXCEPTION_GRID_COLUMNS.WORKFLOW_STATUS' | translate"
        [minResizableWidth]="80"
        [width]="160"
      >
  </kendo-grid-column>

  <kendo-grid-column
        field="comments"
        [title]="'EXCEPTION_GRID_COLUMNS.COMMENTS' | translate"
        [minResizableWidth]="150"
        [width]="300"
      >
      </kendo-grid-column>

      <kendo-grid-column
      #technician
      field="technician"
      [title]="'INSPECTION_GRID_COLUMNS.TECHNICIAN' | translate"
      [minResizableWidth]="80"
      [width]="135"
    >
    </kendo-grid-column>

    <kendo-grid-column
      #technician
      field="specialist"
      title="Specialist"
      [minResizableWidth]="80"
      [width]="135"
    >
    </kendo-grid-column>

    <kendo-grid-column
    *ngFor="let column of dynamicColumns"
    [field]="column.title"
    [width]="120"
    [minResizableWidth]="80"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-index="rowIndex">
      <span [title]="column.title">{{
        getNestedValue(dataItem, column.field)
      }}</span>
    </ng-template>
  </kendo-grid-column>

</kendo-grid>

<kendo-grid *ngIf="flag === 'asset-setup'" [kendoGridBinding]="filteredGridData">
  <kendo-grid-column
    field="level1Value"
    [width]="120"
    *ngIf="IsColumnSelected('level1Value')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Business Unit">Business Unit</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="level2Value"
    [width]="120"
    *ngIf="IsColumnSelected('level2Value')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Asset Owner">Asset Owner</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="level3Value"
    [width]="120"
    *ngIf="IsColumnSelected('level3Value')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Region">Region</span>
    </ng-template>
  </kendo-grid-column>
  <!-- <kendo-grid-column
    *ngFor="let column of dynamicColumns"
    [field]="column.title"
    [width]="120"
    [minResizableWidth]="80"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-index="rowIndex">
      <span [title]="column.title">{{
        getNestedValue(dataItem, column.field)
      }}</span>
    </ng-template>
  </kendo-grid-column> -->
  <kendo-grid-column
    field="Segment"
    [width]="120"
    *ngIf="IsColumnSelected('Segment')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Segment">Segment</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="route"
    [width]="120"
    *ngIf="IsColumnSelected('route')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Route">Route</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
        field="segmentRelation"
        title="Segment Relation"
        [minResizableWidth]="80"
        [width]="150"
        *ngIf="IsColumnSelected('segmentRelation')"
  ></kendo-grid-column>
      <kendo-grid-column
        field="assetName"
        title="Asset Name"
        [minResizableWidth]="80"
        [width]="160"
        *ngIf="IsColumnSelected('assetName')"
      ></kendo-grid-column>
      <kendo-grid-column
        field="assetType"
        [title]="'ASSET_SETUP.ASSET_TYPE' | translate"
        [minResizableWidth]="80"
        [width]="160"
        *ngIf="IsColumnSelected('assetType')"
      ></kendo-grid-column>
      <kendo-grid-column
        field="measurementFrequency"
        [title]="'ASSET_SETUP.MEASUREMENT_FREQUENCY' | translate"
        [minResizableWidth]="80"
        [width]="160"
      ></kendo-grid-column>
      <kendo-grid-column
        field="gpsCoordinate"
        [title]="'ASSET_SETUP.GPS_COORDINATE' | translate"
        [minResizableWidth]="80"
        [width]="160"
        *ngIf="IsColumnSelected('gpsCoordinate')"
      ></kendo-grid-column>
      <kendo-grid-column
        field="assetDelinquencyDate"
        title="Asset Delinquency Date"
        [minResizableWidth]="80"
        [width]="160"
        *ngIf="IsColumnSelected('nextDelinquencyDate')"
        format="{0:dd/MM/yyyy}"
        filter="date"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <span class="right-alignment">{{
            dataItem.assetDelinquencyDate | customDate
          }}</span>
        </ng-template>
      </kendo-grid-column>
</kendo-grid>


<kendo-grid *ngIf="flag === 'asset-history'" [kendoGridBinding]="filteredGridData">
  <kendo-grid-column field="Date" [width]="120" >
    <ng-template kendoGridHeaderTemplate>
      <span title="Date">Date</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    *ngFor="let column of dynamicColumns"
    [field]="column.title"
    [width]="120"
    [minResizableWidth]="80"
  >
    <ng-template kendoGridCellTemplate let-dataItem let-index="rowIndex">
      <span [title]="column.title">{{
        getNestedValue(dataItem, column.field)
      }}</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="Comments" [width]="120">
    <ng-template kendoGridHeaderTemplate>
      <span title="Comments">Comments</span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<kendo-grid
  *ngIf="flag === 'survey-overview'"
  [kendoGridBinding]="filteredGridData"
>
  <kendo-grid-column
    field="surveyName"
    [width]="120"
    *ngIf="IsColumnSelected('surveyName')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Name">Survey Name</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="surveyType"
    [width]="120"
    *ngIf="IsColumnSelected('surveyType')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Type">Survey Type</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="surveyPriority"
    [width]="120"
    *ngIf="IsColumnSelected('surveyPriority')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Priority">Survey Priority</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="surveyStatus"
    [width]="120"
    *ngIf="IsColumnSelected('surveyStatus')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Status">Survey Status</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="dueDate"
    [width]="120"
    *ngIf="IsColumnSelected('dueDate')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Due Date">Due Date</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="dataCollectionType"
    [width]="120"
    *ngIf="IsColumnSelected('dataCollectionType')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Date Collection">Data Collection Type</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="assignedTo"
    [width]="120"
    *ngIf="IsColumnSelected('assignedTo')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Assignee">Assignee</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="measurementFrequency"
    [width]="120"
    *ngIf="IsColumnSelected('measurementFrequency')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Measurement Frequency">Measurement Frequency</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="supervisor"
    [width]="130"
    *ngIf="IsColumnSelected('supervisor')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Supervisor">Supervisor</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="dateOfCompletion"
    [width]="150"
    *ngIf="IsColumnSelected('dateOfCompletion')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Date Of Completion">Date Of Completion</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="surveyDescription"
    [width]="120"
    *ngIf="IsColumnSelected('surveyDescription')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Description">Survey Description</span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<kendo-grid
  *ngIf="flag === 'survey-library'"
  [kendoGridBinding]="filteredGridData"
>
  <kendo-grid-column
    field="surveyName"
    [width]="120"
    *ngIf="IsColumnSelected('surveyName')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Name">Survey Name</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="surveyType"
    [width]="120"
    *ngIf="IsColumnSelected('surveyType')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Type">Survey Type</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="version"
    [width]="120"
    *ngIf="IsColumnSelected('version')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Version">Version</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="versionDate"
    [width]="120"
    *ngIf="IsColumnSelected('versionDate')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Version Date">Version Date</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="surveyPriority"
    [width]="120"
    *ngIf="IsColumnSelected('surveyPriority')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Priority">Survey Priority</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="surveyStatus"
    [width]="120"
    *ngIf="IsColumnSelected('surveyStatus')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Status">Survey Status</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="dataCollectionType"
    [width]="120"
    *ngIf="IsColumnSelected('dataCollectionType')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Date Collection">Data Collection Type</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="dateCompleted"
    [width]="120"
    *ngIf="IsColumnSelected('dateCompleted')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Date Completed">Date Completed</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="dateOfUpload"
    [width]="120"
    *ngIf="IsColumnSelected('dateOfUpload')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Date Of Upload">Date Of Upload</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="lastSynch"
    [width]="120"
    *ngIf="IsColumnSelected('lastSynch')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Last Synch">Last Synch</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="assignee"
    [width]="120"
    *ngIf="IsColumnSelected('assignee')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Assignee">Assignee</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="technician"
    [width]="130"
    *ngIf="IsColumnSelected('technician')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Technician">Technician</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="surveyDescription"
    [width]="120"
    *ngIf="IsColumnSelected('surveyDescription')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Description">Survey Description</span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<kendo-grid
  *ngIf="flag === 'report-details'"
  [kendoGridBinding]="filteredGridData"
>
  <kendo-grid-column
    field="surveyName"
    [width]="120"
    *ngIf="IsColumnSelected('surveyName')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Name">Survey Name</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="surveyType"
    [width]="120"
    *ngIf="IsColumnSelected('surveyType')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Type">Survey Type</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="surveyPriority"
    [width]="120"
    *ngIf="IsColumnSelected('surveyPriority')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Priority">Survey Priority</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="surveyStatus"
    [width]="120"
    *ngIf="IsColumnSelected('surveyStatus')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Status">Survey Status</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="dueDate"
    [width]="120"
    *ngIf="IsColumnSelected('dueDate')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Due Date">Due Date</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="dataCollectionType"
    [width]="120"
    *ngIf="IsColumnSelected('dataCollectionType')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Date Collection">Data Collection Type</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="technician"
    [width]="120"
    *ngIf="IsColumnSelected('technician')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Technician">Technician</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="measurementFrequency"
    [width]="120"
    *ngIf="IsColumnSelected('measurementFrequency')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Measurement Frequency">Measurement Frequency</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="supervisor"
    [width]="130"
    *ngIf="IsColumnSelected('supervisor')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Supervisor">Supervisor</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="dateOfCompletion"
    [width]="150"
    *ngIf="IsColumnSelected('dateOfCompletion')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Date Of Completion">Date Of Completion</span>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column
    field="surveyDescription"
    [width]="120"
    *ngIf="IsColumnSelected('surveyDescription')"
  >
    <ng-template kendoGridHeaderTemplate>
      <span title="Survey Description">Survey Description</span>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
