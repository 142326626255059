import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InspectionComponent } from './feature/inspection/inspection.component';
import { DashboardComponent } from './feature/dashboard/dashboard.component';
import { AuthReturnComponent } from './feature/auth-return/auth-return.component';
import { ExceptionComponent } from './feature/exception/exception.component';
import { AssetHistoryComponent } from './feature/asset-history/asset-history.component';
import { AssetSetupComponent } from './feature/asset-setup/asset-setup.component';
import { DataProcessingComponent } from './feature/data-processing/data-processing.component';
import { PendingSurveyComponent } from './feature/pending-survey/pending-survey.component';
import { FieldDataComponent } from './feature/field-data/field-data.component';
import { MapsComponent } from './feature/maps/maps.component';
import { ResolutionComponent } from './feature/resolution/resolution.component';
import { SurveyBacklogComponent } from './feature/survey-backlog/survey-backlog.component';
import { CreateSingleAssetComponent } from './feature/create-single-asset/create-single-asset.component';
import { UnauthorizedComponent } from './feature/unauthorized/unauthorized.component';
import { ImportBulkAssetComponent } from './feature/import-bulk-asset/import-bulk-asset.component';
import { AuthGuard } from './core/guards/auth.guard';
import { CreateNewTemplateComponent } from './feature/create-new-template/create-new-template.component';
import { ReportManagementComponent } from './feature/report-management/report-management.component';
import { ReportTemplatesLibraryComponent } from './feature/report-templates-library/report-templates-library.component';
import { CreateReportComponent } from './feature/create-report/create-report.component';
import { ReportDetailComponent } from './feature/report-detail/report-detail.component';
import { SurveyOverviewComponent } from './feature/survey-overview/survey-overview.component';
import { SurveyRouteManagementComponent } from './feature/survey-route-management/survey-route-management.component';
import { CreateSurveyComponent } from './feature/create-survey/create-survey.component';
import { CreateRouteComponent } from './feature/create-route/create-route.component';
import { AssetSelectionComponent } from './feature/asset-selection/asset-selection.component';
import { SetupHierarchyLevelsComponent } from './feature/setup-hierarchy-levels/setup-hierarchy-levels.component';
import { CompanyOverviewComponent } from './feature/company-overview/company-overview.component';
import { CreatePrimaryCompanyComponent } from './feature/create-primary-company/create-primary-company.component';
import { CreateAssetTypeComponent } from './feature/create-asset-type/create-asset-type.component';
import { SetupSegmentsAndAssetsComponent } from './feature/setup-segments-and-assets/setup-segments-and-assets.component';
import { AddNewUserComponent } from './feature/add-new-user/add-new-user.component';
import { UserOverviewComponent } from './feature/user-overview/user-overview.component';
import { NoAccessComponent } from './feature/no-access/no-access.component';
import {
  ASSET_OVERVIEW_SCREEN,
  SURVEY_OVERVIEW_SCREEN,
  SURVEY_ROUTE_SCREEN,
  COMPANY_OVERVIEW_SCREEN,
  USER_OVERVIEW_SCREEN,
  INSPECTION_SCREEN,
  EXCEPTION_SCREEN,
  ASSET_HISTORY_SCREEN,
  REPORT_MANAGEMENT_SCREEN,
  REPORT_TEMPLATE_SCREEN,
  MOBILTEX_MEMBER_SCREEN,
} from './shared/constants';
import { ImportAssetInspectionDataComponent } from './feature/import-asset-inspection-data/import-asset-inspection-data.component';
import { ImportBulkAssetInspectionDataComponent } from './feature/import-bulk-asset-inspection-data/import-bulk-asset-inspection-data.component';
import { MTXMemberOverviewComponent } from './feature/mtx-member-overview/mtx-member-overview.component';
import { AddMTXMemberComponent } from './feature/add-mtx-member/add-mtx-member.component';
import { SurveyFieldDataComponent } from './feature/survey-field-data/survey-field-data.component';
import { SurveyLibraryComponent } from './feature/survey-library/survey-library.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
    data: { breadcrumb: 'Dashboard', title: 'Dashboard' },
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { breadcrumb: 'Dashboard', title: 'Dashboard' },
    canActivate: [AuthGuard],
  },
  {
    path: 'maps',
    component: MapsComponent,
    data: { breadcrumb: 'Map', title: 'Map' },
    canActivate: [AuthGuard],
  },
  { path: 'forbidden', component: UnauthorizedComponent },
  { path: 'unauthorized', component: UnauthorizedComponent },
  { path: 'auth-return', component: AuthReturnComponent },
  { path: 'login', redirectTo: 'dashboard' },
  { path: 'no-access', component: NoAccessComponent },
  {
    path: 'compliance',
    data: { breadcrumb: 'Compliance' },
    children: [
      {
        path: 'inspection',
        data: {
          breadcrumb: 'Inspections',
          title: 'Inspections',
          screen: INSPECTION_SCREEN,
          requiredPermission: ['readAccess', 'fullAccess'],
        },
        component: InspectionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'inspection/:assetName',
        data: {
          breadcrumb: 'Inspections',
          title: 'Inspections',
          screen: INSPECTION_SCREEN,
          requiredPermission: ['readAccess', 'fullAccess'],
        },
        component: InspectionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'exception',
        data: {
          breadcrumb: 'Exceptions',
          title: 'Exceptions',
          screen: EXCEPTION_SCREEN,
          requiredPermission: ['readAccess', 'fullAccess'],
        },
        component: ExceptionComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'exception/:assetName',
        data: {
          breadcrumb: 'Exception',
          title: 'Exceptions',
          screen: EXCEPTION_SCREEN,
          requiredPermission: ['readAccess', 'fullAccess'],
        },
        component: ExceptionComponent,
        canActivate: [AuthGuard],
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'reports',
    data: { breadcrumb: 'Reports' },
    children: [
      {
        path: 'report-management',
        data: {
          breadcrumb: 'Report Management',
          title: 'Reports',
          screen: REPORT_MANAGEMENT_SCREEN,
          requiredPermission: ['readAccess', 'fullAccess'],
        },
        component: ReportManagementComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'report-management',
        data: { breadcrumb: 'Report Management', title: 'Reports' },
        children: [
          {
            path: 'create',
            data: {
              breadcrumb: 'Create Report',
              title: 'Create Report',
              screen: REPORT_MANAGEMENT_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: CreateReportComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit',
            data: {
              breadcrumb: 'Edit Report',
              title: 'Edit Report',
              screen: REPORT_MANAGEMENT_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: CreateReportComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'details',
            data: {
              breadcrumb: 'Report Details',
              title: 'Report Details',
              screen: REPORT_MANAGEMENT_SCREEN,
              requiredPermission: ['readAccess', 'fullAccess'],
            },
            component: ReportDetailComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'report-templates-library',
        data: {
          breadcrumb: 'Report Templates Library',
          title: 'Report Templates Library',
          screen: REPORT_TEMPLATE_SCREEN,
          requiredPermission: ['readAccess', 'fullAccess'],
        },
        component: ReportTemplatesLibraryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'report-templates-library',
        data: { breadcrumb: 'Report Templates Library' },
        children: [
          {
            path: 'create',
            data: {
              breadcrumb: 'Create Template',
              title: 'Create Template',
              screen: REPORT_TEMPLATE_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: CreateNewTemplateComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit',
            data: {
              breadcrumb: 'Edit Template',
              title: 'Edit Template',
              screen: REPORT_TEMPLATE_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: CreateNewTemplateComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'create-report',
            data: {
              breadcrumb: 'Create Report',
              title: 'Create Report',
              screen: REPORT_MANAGEMENT_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: CreateReportComponent,
            canActivate: [AuthGuard],
          },
        ],
        canActivate: [AuthGuard],
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'assets',
    data: { breadcrumb: 'Assets' },
    children: [
      {
        path: 'asset-history',
        data: {
          breadcrumb: 'Asset History',
          title: 'Asset History',
          screen: ASSET_HISTORY_SCREEN,
          requiredPermission: ['fullAccess', 'readAccess'],
        },
        component: AssetHistoryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'asset-setup',
        data: {
          breadcrumb: 'Asset Overview',
          title: 'Asset Overview',
          screen: ASSET_OVERVIEW_SCREEN,
          requiredPermission: ['fullAccess', 'readAccess'],
        },
        component: AssetSetupComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'asset-setup',
        data: { breadcrumb: 'Asset Setup' },
        children: [
          {
            path: 'create',
            data: {
              breadcrumb: 'Create',
              title: 'Create Single Asset',
              screen: ASSET_OVERVIEW_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: CreateSingleAssetComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit',
            data: {
              breadcrumb: 'Edit',
              title: 'Edit Asset',
              screen: ASSET_OVERVIEW_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: CreateSingleAssetComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'details',
            data: { breadcrumb: 'Details', title: 'Asset Details' },
            component: CreateSingleAssetComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'import',
            data: {
              breadcrumb: 'Import Assets',
              title: 'Import Assets',
              screen: ASSET_OVERVIEW_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: ImportBulkAssetComponent,
            canActivate: [AuthGuard],
          },
        ],
        canActivate: [AuthGuard],
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'survey',
    data: { breadcrumb: 'Surveys' },
    children: [
      {
        path: 'data-processing',
        data: { breadcrumb: 'Data Processing', title: 'Data Processing' },
        component: DataProcessingComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'survey-library',
        data: {
          breadcrumb: 'Finalised Surveys',
          title: 'Survey Library',
          //screen: SURVEY_FIELD_DATA_SCREEN,
          //requiredPermission: ['fullAccess', 'readAccess'],
        },
        component: SurveyLibraryComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'survey-overview',
        data: {
          breadcrumb: 'Survey Overview',
          title: 'Survey Overview',
          screen: SURVEY_OVERVIEW_SCREEN,
          requiredPermission: ['fullAccess', 'readAccess'],
        },
        component: SurveyOverviewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'survey-overview',
        data: { breadcrumb: 'Survey Overview', title: 'Survey Overview' },
        children: [
          {
            path: 'create',
            data: {
              breadcrumb: 'Create Survey',
              title: 'Create Survey',
              screen: SURVEY_OVERVIEW_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: CreateSurveyComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'survey-overview',
        data: { breadcrumb: 'Survey Overview', title: 'Survey Overview' },
        children: [
          {
            path: 'asset-selection',
            data: {
              breadcrumb: 'Asset Selection',
              title: 'Asset Selection',
              screen: SURVEY_OVERVIEW_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: AssetSelectionComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'pending-survey',
        data: { breadcrumb: 'Pending Survey', title: 'Pending Survey' },
        component: PendingSurveyComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'route-management',
        data: {
          breadcrumb: 'Route Management',
          title: 'Route Management',
          screen: SURVEY_ROUTE_SCREEN,
          requiredPermission: ['fullAccess', 'readAccess'],
        },
        component: SurveyRouteManagementComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'route-management',
        data: { breadcrumb: 'Route Management', title: 'Route Management' },
        children: [
          {
            path: 'create',
            data: {
              breadcrumb: 'Create Route',
              title: 'Create Route',
              screen: SURVEY_ROUTE_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: CreateRouteComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit',
            data: {
              breadcrumb: 'Edit Survey Route',
              title: 'Edit Survey Route',
              screen: SURVEY_ROUTE_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: CreateRouteComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'field-data-review',
        data: {
          breadcrumb: 'Field Data',
          title: 'Field Data',
          //screen: SURVEY_FIELD_DATA_SCREEN,
          //requiredPermission: ['fullAccess', 'readAccess'],
        },
        component: SurveyFieldDataComponent,
        canActivate: [AuthGuard],
      }
    ],
    canActivate: [AuthGuard],
  },
  {
    path: 'field-data',
    component: FieldDataComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'survey-backlog',
    component: SurveyBacklogComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resolution',
    component: ResolutionComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'setup',
    data: { breadcrumb: 'Setup' },
    children: [
      {
        path: 'company-overview',
        data: {
          breadcrumb: 'Company Overview',
          title: 'Company Overview',
          screen: COMPANY_OVERVIEW_SCREEN,
          requiredPermission: ['readAccess', 'fullAccess'],
        },
        component: CompanyOverviewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'company-overview',
        data: { breadcrumb: 'Company Overview', title: 'Company Overview' },
        children: [
          {
            path: 'create',
            data: {
              breadcrumb: 'Create Primary Company',
              title: 'Create New Primary Company',
              screen: COMPANY_OVERVIEW_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: CreatePrimaryCompanyComponent,
            canActivate: [AuthGuard],
          },
          {
            path: 'edit',
            data: {
              breadcrumb: 'Edit Primary Company',
              title: 'Edit Primary Company',
              screen: COMPANY_OVERVIEW_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: CreatePrimaryCompanyComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
      {
        path: 'create-asset-type',
        data: {
          breadcrumb: 'Company',
          title: 'Company',
          screen: COMPANY_OVERVIEW_SCREEN,
          requiredPermission: ['fullAccess'],
        },
        component: CreateAssetTypeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'setup-hierarchy-levels',
        data: {
          breadcrumb: 'Company',
          title: 'Setup Hierarchy & levels',
          screen: COMPANY_OVERVIEW_SCREEN,
          requiredPermission: ['fullAccess'],
        },
        component: SetupHierarchyLevelsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'setup-segments-assets',
        data: {
          breadcrumb: 'Company',
          title: 'Setup Segments & Assets',
          screen: COMPANY_OVERVIEW_SCREEN,
          requiredPermission: ['fullAccess'],
        },
        component: SetupSegmentsAndAssetsComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'import',
        data: {
          breadcrumb: 'Company',
          title: 'Import Segements & Assets',
          screen: COMPANY_OVERVIEW_SCREEN,
          requiredPermission: ['fullAccess'],
        },
        component: ImportBulkAssetComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'import-inspection-data',
        data: {
          breadcrumb: 'Company',
          title: 'Import Asset Inspecction Data',
          screen: COMPANY_OVERVIEW_SCREEN,
          requiredPermission: ['fullAccess'],
        },
        component: ImportAssetInspectionDataComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'import-bulk-inspection-data',
        data: {
          breadcrumb: 'Company',
          title: 'Import Asset Inspecction Data',
          screen: COMPANY_OVERVIEW_SCREEN,
          requiredPermission: ['fullAccess'],
        },
        component: ImportBulkAssetInspectionDataComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        data: {
          breadcrumb: 'Users',
          title: 'User Overview',
          screen: USER_OVERVIEW_SCREEN,
          requiredPermission: ['readAccess', 'fullAccess'],
        },
        component: UserOverviewComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'asset-overview',
        data: {
          breadcrumb: 'Asset Overview',
          title: 'Asset Overview',
          screen: ASSET_OVERVIEW_SCREEN,
          requiredPermission: ['readAccess', 'fullAccess'],
        },
        component: AssetSetupComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        data: { breadcrumb: 'User', title: 'User Overview' },
        children: [
          {
            path: 'add',
            data: {
              breadcrumb: '',
              title: 'Add New User',
              screen: USER_OVERVIEW_SCREEN,
              requiredPermission: ['fullAccess'],
            },
            component: AddNewUserComponent,
            canActivate: [AuthGuard],
          },
        ],
      },
    ],
  },
  { path: 'no-access', component: NoAccessComponent },
  {
    path: 'mobiltex_members',
    data: {
      breadcrumb: 'Mobiltex Member',
      title: 'Mobiltex Member Overview',
      screen: MOBILTEX_MEMBER_SCREEN,
      requiredPermission: ['fullAccess', 'readAccess'],
    },
    component: MTXMemberOverviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'mobiltex_members',
    data: { breadcrumb: 'Setup' },
    children: [
      {
        path: 'add',
        data: {
          breadcrumb: 'Mobiltex Member',
          title: 'Add Mobiltex Member',
          screen: MOBILTEX_MEMBER_SCREEN,
          requiredPermission: ['fullAccess'],
        },
        component: AddMTXMemberComponent,
        canActivate: [AuthGuard],
      },
    ],
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
