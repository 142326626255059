import { Component, EventEmitter, inject, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { PrimaryCompanyModel } from '../../../awsAppSync/API';
import {
  RemoveUserProfileData,
  Roles,
  UpdateSelectedLevel3Value,
  UpdateSelectedPrimaryCompany,
  UserProfileDataState,
  UpdateSelectedLevel3Id,
} from '../../core/store/user-profile.state';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import {
  RemoveAuthenticateUser,
  UpdateSyrcPrimaryCompany,
  UpdateAuthenticationState,
} from '../../core/store/authenticate-user.state';
import { Router } from '@angular/router';
import { MOBILTEX_MEMBER_OVERVIEW } from '../../shared/constants';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import {
  RemoveLevel1LevelName,
  SetLevel1LevelName,
} from '../../core/store/filter-level1Name.state';
import {
  RemoveLevel2LevelName,
  SetLevel2LevelName,
} from '../../core/store/filter-level2Name.state';
import {
  Level3LevelNameState,
  RemoveLevel3LevelName,
  SetLevel3LevelName,
} from '../../core/store/filter-level3Name.state';
import {
  RemoveSelectedAssetTypeIds,
  SetAssetTypeModel,
} from '../../core/store/filter-assettype';
import {
  RemoveLevel1State,
  SetLevel1State,
} from '../../core/store/filter-level1.state';
import {
  RemoveLevel2State,
  SetLevel2State,
} from '../../core/store/filter-level2.state';
import {
  RemoveLevel3State,
  SetLevel3State,
} from '../../core/store/filter-level3.state';
import {
  RemoveSelectedSegmentIds,
  SetSegmentModel,
} from '../../core/store/filter-segments';
import {
  RemoveSegmentTypeState,
  SetSegmentTypeState,
} from '../../core/store/filter-segmenttype.state';
import {
  RemoveSelectedSurveyRouteIds,
  SetSurveyRouteModel,
} from '../../core/store/filter-serveyrotes';
import { PermissionService } from '../../core/services/permission.service';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrl: './profile-menu.component.css',
})
export class ProfileMenuComponent {
  @Output() closePopup = new EventEmitter<void>();

  primaryCompany$: Observable<PrimaryCompanyModel | null | undefined>;
  userId$: Observable<string | null>;
  userName$: Observable<string | null>;
  userEmail$: Observable<string | null>;
  isMTXUser$: Observable<boolean | null>;
  roles$: Observable<(Roles | null)[] | null | undefined>;
  syrcPrimaryCompanies$: Observable<
    (PrimaryCompanyModel | null)[] | null | undefined
  >;
  selectedPrimaryCompany$: Observable<string | null>;
  selectedLevel3Value$: Observable<string | null>;

  primaryCompany: PrimaryCompanyModel | null | undefined;
  userId: string | null = null;
  userName: string | null = null;
  userEmail: string | null = null;
  isMTXUser: boolean | null = null;
  roles: (Roles | null)[] | null | undefined = null;

  level3Values: string[] = [];
  selectedLevel3Value: string | null = null;
  syrcPrimaryCompanies: (PrimaryCompanyModel | null)[] | null | undefined;
  selectedSyrcPrimaryCompany: PrimaryCompanyModel | null = null;
  primaryCompanyName = '';
  userInitials = '';
  level3Name = '';
  storeLevel3Name$: Observable<string | null>;
  isLoading = false;

  private readonly oidcSecurityService = inject(OidcSecurityService);

  constructor(
    private store: Store,
    private router: Router,
    private _heirarchyFilterService: HeirarchyFilterServiceService,
    private permissionService: PermissionService,
  ) {
    this.primaryCompany$ = this.store.select(
      UserProfileDataState.getPrimaryCompany,
    );
    this.userId$ = this.store.select(UserProfileDataState.getUserId);
    this.userName$ = this.store.select(UserProfileDataState.getUserName);
    this.userEmail$ = this.store.select(UserProfileDataState.getUserEmail);
    this.isMTXUser$ = this.store.select(UserProfileDataState.getIsMTXUser);
    this.roles$ = this.store.select(UserProfileDataState.getRoles);
    this.syrcPrimaryCompanies$ = this.store.select(
      UserProfileDataState.getSyrcPrimaryCompanies,
    );
    this.selectedPrimaryCompany$ = this.store.select(
      UserProfileDataState.getSelectedPrimaryCompany,
    );
    this.selectedLevel3Value$ = this.store.select(
      UserProfileDataState.getSelectedLevel3Value,
    );
    this.storeLevel3Name$ = this.store.select(
      Level3LevelNameState.getLevel3LevelNameId,
    );
  }

  ngOnInit(): void {
    this.storeLevel3Name$.subscribe((level3Name) => {
      this.level3Name = level3Name!;
    });

    this.isMTXUser$.subscribe((isMTXUser) => {
      this.isMTXUser = isMTXUser ?? false;
    });

    this.primaryCompany$.subscribe((primaryCompany) => {
      this.primaryCompany = primaryCompany ?? null;
      this.primaryCompanyName = this.primaryCompany?.name ?? '';
    });

    this.userId$.subscribe((userId) => {
      this.userId = userId ?? '';
    });

    this.userName$.subscribe((userName) => {
      this.userName = userName ?? '';
      this.setUserInitials();
    });

    this.userEmail$.subscribe((userEmail) => {
      this.userEmail = userEmail ?? '';
    });

    if (this.isMTXUser) {
      this.syrcPrimaryCompanies$.subscribe((syrcPrimaryCompanies) => {
        this.syrcPrimaryCompanies = syrcPrimaryCompanies;
      });
      this.selectedPrimaryCompany$.subscribe((selectedPrimaryCompany) => {
        this.selectedSyrcPrimaryCompany =
          this.syrcPrimaryCompanies?.find(
            (company) => company?.name === selectedPrimaryCompany,
          ) ?? null;
        this.primaryCompanyName = this.selectedSyrcPrimaryCompany?.name ?? '';
      });
    } else {
      this.roles$.subscribe((roles) => {
        this.roles = roles ?? [];
        this.level3Values = this.roles
          .filter((role) => role?.level3Value)
          .map((role) => role!.level3Value!);
      });
      this.selectedLevel3Value$.subscribe((selectedLevel3Value) => {
        this.selectedLevel3Value = selectedLevel3Value ?? '';
      });
      if (this.level3Values) {
        this.selectedLevel3Value = this.level3Values[0]
      }
    }
    this.permissionService.transformData();
  }

  onLevel3ValueChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedLevel3Value = selectElement.value;
    this.store.dispatch(new UpdateSelectedLevel3Value(selectElement.value));
    const matchingRole = this.roles?.find(
      (role) => role?.level3Value === selectElement.value,
    );
    const selectedLevel3Id = matchingRole?.level3Id ?? '';
    this.store.dispatch(new UpdateSelectedLevel3Id(selectedLevel3Id));
    this.router.navigate(['/dashboard']).then(() => {
      window.location.reload();
    });
  }

  onSyrcPrimaryCompanyValueChange(): void {
    if (this.selectedSyrcPrimaryCompany) {
      this.primaryCompanyName = this.selectedSyrcPrimaryCompany.name ?? '';
      this.store.dispatch(
        new UpdateSelectedPrimaryCompany(
          this.selectedSyrcPrimaryCompany.name ?? '',
        ),
      );
      this.store.dispatch(
        new UpdateSyrcPrimaryCompany(
          this.selectedSyrcPrimaryCompany.id ?? '',
          this.selectedSyrcPrimaryCompany.name ?? '',
        ),
      );
      this.generateLevelNames();
    }
  }

  async generateLevelNames() {
    this.isLoading = true;
    this.store.dispatch(new RemoveLevel1LevelName());
    this.store.dispatch(new RemoveLevel2LevelName());
    this.store.dispatch(new RemoveLevel3LevelName());
    const levelNameList =
      await this._heirarchyFilterService.getLevelNamesByPrimaryComapany();
    if (levelNameList && levelNameList.items) {
      levelNameList.items?.forEach((item) => {
        if (item?.level1Name != null) {
          this.store.dispatch(new SetLevel1LevelName(item.level1Name));
        }
        if (item?.level2Name != null) {
          this.store.dispatch(new SetLevel2LevelName(item.level2Name));
        }
        if (item?.level3Name != null) {
          this.store.dispatch(new SetLevel3LevelName(item.level3Name));
        }
      });
    }
    this._heirarchyFilterService.setIsPrimaryCompanyChange(true);
    this.removeselectedFilter();
    this.router.navigate(['/dashboard']).then(() => {
      window.location.reload();
    });
    //await this._heirarchyFilterService.initialize();
    this.isLoading = false;
    this.closePopup.emit();
  }
  removeselectedFilter() {
    this.store.dispatch(new SetLevel1State([]));
    this.store.dispatch(new SetLevel2State([]));
    this.store.dispatch(new SetLevel3State([]));
    this.store.dispatch(new SetSegmentTypeState([]));
    this.store.dispatch(new SetSegmentModel([]));
    this.store.dispatch(new SetAssetTypeModel([]));
    this.store.dispatch(new SetSurveyRouteModel([]));
  }

  setUserInitials() {
    if (this.userName) {
      const nameParts = this.userName.split(' ');
      const initials = nameParts.map((part) => part.charAt(0)).join('');
      this.userInitials = initials.toUpperCase();
    }
  }

  logout(): void {
    this.store.dispatch(new UpdateAuthenticationState(false));
    this.store.dispatch(new RemoveLevel1State());
    this.store.dispatch(new RemoveLevel2State());
    this.store.dispatch(new RemoveLevel3State());
    this.store.dispatch(new RemoveSegmentTypeState());
    this.store.dispatch(new RemoveSelectedSegmentIds());
    this.store.dispatch(new RemoveSelectedAssetTypeIds());
    this.store.dispatch(new RemoveSelectedSurveyRouteIds());
    this.oidcSecurityService
      .logoffAndRevokeTokens()
      .pipe(
        tap(() => this.store.dispatch(new RemoveAuthenticateUser())),
        tap(() => this.store.dispatch(new RemoveUserProfileData())),
        //tap(() => window.location.href = "https://account.corview.cloud/connect/endsession"),
      )
      .subscribe({
        error: (e) => console.error('Logoff error:', e),
        complete: () => console.log('Logged off successfully'),
      });
  }

  ManageMobiltexMemberClicked() {
    this.closePopup.emit();
    this.router.navigate([MOBILTEX_MEMBER_OVERVIEW]);
  }
}
