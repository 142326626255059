import { Component, OnDestroy, OnInit } from '@angular/core';
import { columnNames, reportColumns } from '../../shared/enums';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import {
  API_ERROR_MSG,
  AUDIT,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  CANCEL_TEMPLATE_HEADING,
  CANCEL_TEMPLATE_MSG,
  CLOSE,
  CONTINUE,
  CREATE_REPORT_TEMPLATE_FAILED_HEADER,
  EDIT_MODE_REPORT,
  EMPTY_STRING,
  ERROR,
  FAILED,
  NO,
  PERFORMANCE,
  REDIRECT_REPORT_TEMPLATE_LIBRARY,
  SUCCESS,
  SUCCESS_TEMPLATE_HEADING,
  SUCCESS_TEMPLATE_MSG,
} from '../../shared/constants';
import { Router } from '@angular/router';
import {
  ReportColumn,
  ReportInput,
  ReportTemplate,
  ReportTemplateCreateInput,
  ReportTemplateUpdateInput,
} from '../../../awsAppSync/API';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { Observable } from 'rxjs';
import { ReportTemplatesLibraryService } from '../../core/services/report-templates-library.service';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { ReportColumnResponce } from '../../core/models/report-templates-library.module';
import { RemoveTemplate, TemplateState } from '../../core/store/template.state';
import { Level3LevelNameState } from '../../core/store/filter-level3Name.state';
import { Level2LevelNameState } from '../../core/store/filter-level2Name.state';
import { Level1LevelNameState } from '../../core/store/filter-level1Name.state';

@Component({
  selector: 'app-create-new-template',
  templateUrl: './create-new-template.component.html',
  styleUrl: './create-new-template.component.css',
})
export class CreateNewTemplateComponent implements OnInit,OnDestroy {
  form!: FormGroup;
  //columnOptions: Record<string, string[]> = {};
  ReportColumns: ReportColumnResponce[] | undefined;
  selectedItems: Record<string, boolean> = {};
  allReportColumns: ReportColumnResponce[] | undefined;
  tiles: string[] = [];
  reportTypes = [AUDIT, PERFORMANCE];
  storePrimaryCompanyID$: Observable<string | null>;
  primaryCompanyId = '';
  isLoading = false;
  loggedInCorViewUserId$: Observable<string | null | undefined> | undefined;
  loggedInCorViewUserId = '';
  isFromWhere: string | null = EMPTY_STRING;
  isFrom$: Observable<string | null>;
  editReportId$: Observable<string | null | undefined>;
  editReportId?: string | null;
  storeLevel1Name$: Observable<string | null>;
  level1Name: string | null = EMPTY_STRING;
  storeLevel2Name$: Observable<string | null>;
  level2Name: string | null = EMPTY_STRING;
  storeLevel3Name$: Observable<string | null>;
  level3Name: string | null = EMPTY_STRING;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private popupDialogService: PopupDialogService,
    private store: Store,
    private _reportTemplateService: ReportTemplatesLibraryService,
    private toastr: CustomToastrService,
  ) {
    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
    this.loggedInCorViewUserId$ = this.store.select(
      AuthenticateUserState.getCoreViewPrimaryCompanyId,
    );
    this.isFrom$ = this.store.select(TemplateState.getIsFrom);
    this.editReportId$ = this.store.select(TemplateState.getEditReportId);
    this.storeLevel1Name$ = this.store.select(Level1LevelNameState.getLevel1LevelNameId);
    this.storeLevel2Name$ = this.store.select(Level2LevelNameState.getLevel2LevelNameId);
    this.storeLevel3Name$ = this.store.select(Level3LevelNameState.getLevel3LevelNameId);
  }

  ngOnInit(): void {
    this.storePrimaryCompanyID$.subscribe((primaryCompanyId) => {
      this.primaryCompanyId = primaryCompanyId ?? '';
    });
    this.loggedInCorViewUserId$?.subscribe((name) => {
      this.loggedInCorViewUserId = name!;
    });
    this.storeLevel1Name$.subscribe((level1name) => {
      this.level1Name = level1name;
    });

    this.storeLevel2Name$.subscribe((level2name) => {
      this.level2Name = level2name;
    });

    this.storeLevel3Name$.subscribe((level3name) => {
      this.level3Name = level3name;
    });
    this.initializeForm();
    this.getTemplateData();
    this.loadInitialData();
    this.getAllColumnsForReport();
    this.form.get('reportType')?.valueChanges.subscribe(() => {
      this.handleReportTypeChange();
    });
    this.updateTiles();
  }

  ngOnDestroy() {
    this.store.dispatch(new RemoveTemplate());
    this.form.reset();
  }

  initializeForm(): void {
    this.form = this.fb.group({
      templateName: ['', Validators.required],
      reportType: ['', Validators.required],
      reportDescription: [''],
      selectedColumns: [[]],
      pk: [''],
      sk: [''],
    });
  }

  getTemplateData(){
    this.isFrom$.subscribe((isFrom) => {
      this.isFromWhere = isFrom;
    });
    this.editReportId$.subscribe((reportId) => {
      this.editReportId = reportId;
    });
  }

  async loadInitialData(){
    this.isLoading = true;
    if (this.isFromWhere === EDIT_MODE_REPORT) {
      this.getReportById();
    }
    this.isLoading = false;
  }

  async getReportById() {
    const reportinput: ReportInput = {
      primaryCompanyId: this.primaryCompanyId
        ? this.primaryCompanyId
        : '',
      id: this.editReportId ? this.editReportId : '',
    };
    const reportData = await this._reportTemplateService.getReportTemplateById(reportinput);
    await this.addReportDatatoUI(reportData);
  }

  async addReportDatatoUI(existingReport: ReportTemplate | null) {
      this.isLoading = true;
      if (existingReport) {
        console.log(existingReport);
        this.form.controls['templateName'].setValue(
          existingReport.templateName,
        );
        this.form.controls['reportType'].setValue(
          existingReport.reportType,
        );
        //this.form.get('reportType')?.disable();
        this.form.controls['reportDescription'].setValue(
          existingReport.description,
        );

        const selectedColumns: string[]= existingReport.selectedColumns
          ? Object.values(JSON.parse(existingReport.selectedColumns))
          : [];
  
        selectedColumns.forEach((column) => {
          this.selectedItems[column] = true;
        });

        this.form.controls['pk'].setValue(
          existingReport.pk,
        );
        this.form.controls['sk'].setValue(
          existingReport.sk,
        );
      }
      this.isLoading = false;
    }

  convertEnumToOptions(): Record<string, string[]> {
    const options: Record<string, string[]> = {};
    for (const key in reportColumns) {
      if (Object.prototype.hasOwnProperty.call(reportColumns, key)) {
        const types = reportColumns[key as keyof typeof reportColumns]
          .split(',')
          .filter((type) => type); // Remove empty types
        options[key] = types;
      }
    }
    return options;
  }

  handleReportTypeChange(): void {
    this.selectedItems = {};
    this.tiles = [];
    this.form.get('selectedColumns')?.setValue([]);
  }

  getFilteredColumns(): string[] {
    //const reportType = this.form.get('reportType')?.value || AUDIT;
    // return Object.keys(this.columnOptions).filter((column) =>
    //   this.columnOptions[column].includes(reportType),
    // );
    return this.ReportColumns
      ? this.ReportColumns.map((f) => f.columnName)
      : [];
  }

  getSelectedColumns(): string[] {
    return Object.keys(this.selectedItems).filter(
      (item) => this.selectedItems[item],
    );
  }

  onCheckboxChange(item: string, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const isChecked = checkbox.checked;
    this.selectedItems[item] = isChecked;
    if (isChecked) {
      this.tiles.push(item);
    } else {
      this.tiles = this.tiles.filter((selectedItem) => selectedItem !== item);
    }
    this.form.get('selectedColumns')?.setValue(this.tiles);
  }

  removeColumn(item: string): void {
    this.selectedItems[item] = false;
    this.tiles = this.tiles.filter((selectedItem) => selectedItem !== item);
    this.form.get('selectedColumns')?.setValue(this.tiles);
  }

  updateTiles(): void {
    this.tiles = this.getSelectedColumns();
  }

  allColumnsSelected(): boolean {
    const filteredColumns = this.getFilteredColumns();
    return (
      filteredColumns.length === this.tiles.length && filteredColumns.length > 0
    );
  }

  getColumnDisplayName(key: string): string {
    return columnNames[key as keyof typeof columnNames] || key;
  }
  getSelectedColumnJson() {
    const selectedColumns = this.getSelectedColumns();
    const selectedValues = this.allReportColumns?.reduce(
      (acc, column) => {
        if (selectedColumns.includes(column.columnName)) {
          acc[column.dbname] = column.columnName;
        }
        return acc;
      },
      {} as Record<string, string>,
    );

    return selectedValues;
  }

  cancel() {
    this.popupDialogService.openDialog(
      CANCEL_TEMPLATE_HEADING,
      CANCEL_TEMPLATE_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => this.router.navigate([REDIRECT_REPORT_TEMPLATE_LIBRARY]),
      true,
      NO,
    );
  }
  
  async getAllColumnsForReport() {
    const response = await this._reportTemplateService.getAllColumnsForReport();
    this.allReportColumns = response?.items
      ?.filter((item): item is ReportColumn => item != null)
      .map((f) => ({
        id: f?.id,
        columnName: f?.columnName,
        dbname: f?.dbname,
        key: f?.key,
      }));
    this.allReportColumns?.map((column) => {
      if (column.dbname === "level1Value") {
        column.columnName = this.level1Name ?? '';
      }
      if (column.dbname === "level2Value") {
        column.columnName =  this.level2Name ?? '';
      }
      if (column.dbname === "level3Value") {
        column.columnName =  this.level3Name ?? '';
      }
      return column;
    });
    this.ReportColumns = this.allReportColumns;
    this.ReportColumns?.map((f) => f.columnName);
    this.updateTiles();
  }

  async save() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      try {
        if (this.isFromWhere === EDIT_MODE_REPORT) {
          await this.updateTemplate();
        } else {
          await this.createTemplate();
        }
      } catch (ex) {
        console.log(ex);
      }
    }
  }

  async updateTemplate() {
    this.isLoading = true;
      //convert template to edit
    const templateSave: ReportTemplateUpdateInput = {
      pk: this.form.get('pk')?.value,
      sk: this.form.get('sk')?.value,
      id: this.editReportId!,
      description: this.form.get('reportDescription')?.value,
      reportType: this.form.get('reportType')?.value,
      selectedColumns: JSON.stringify(this.getSelectedColumnJson()),
      templateName: this.form.get('templateName')?.value,
      updatedBy: this.loggedInCorViewUserId,
    };
    const response = await this._reportTemplateService.updateReportTemplate(templateSave);
    this.isLoading = false;
    if (response!.status === 'Success') {
      this.popupDialogService.openDialog(
        'Template Updated Successfully!',
        SUCCESS_TEMPLATE_MSG,
        SUCCESS,
        CONTINUE,
        () => this.router.navigate([REDIRECT_REPORT_TEMPLATE_LIBRARY]),
      );
    } else
    {
      this.popupDialogService.openDialog(
        CREATE_REPORT_TEMPLATE_FAILED_HEADER,
        'An unexpected error occurred. Please try again.',
        FAILED,
        CLOSE,
      );
      this.toastr.showError(response?.error?.message ?? API_ERROR_MSG, ERROR);
    }
  }

  async createTemplate() {
    this.isLoading = true;
      //convert template to save
      const templateSave: ReportTemplateCreateInput = {
        primaryCompanyId: this.primaryCompanyId,
        description: this.form.get('reportDescription')?.value,
        reportType: this.form.get('reportType')?.value,
        selectedColumns: JSON.stringify(this.getSelectedColumnJson()),
        templateName: this.form.get('templateName')?.value,
        createdBy: this.loggedInCorViewUserId,
      };
      const response = await this._reportTemplateService.createReportTemplate(templateSave);
      this.isLoading = false;
      if (response!.status === 'Success') {
        this.popupDialogService.openDialog(
          SUCCESS_TEMPLATE_HEADING,
          SUCCESS_TEMPLATE_MSG,
          SUCCESS,
          CONTINUE,
          () => this.router.navigate([REDIRECT_REPORT_TEMPLATE_LIBRARY]),
        );
      } else {
        this.popupDialogService.openDialog(
          CREATE_REPORT_TEMPLATE_FAILED_HEADER,
          'An unexpected error occurred. Please try again.',
          FAILED,
          CLOSE,
        );
        this.toastr.showError(response?.error?.message ?? API_ERROR_MSG, ERROR);
      }
  }
}
