<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container-fluid py-2">
  <div class="row g-2 justify-content-end">
    <div class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
      <button class="btn mtx-btn-primary" style="height: 38px;" (click)="openDialog()">
        <span style="vertical-align: middle" class="material-icons">download</span>
        <!-- {{ 'GENERIC_Buttons.EXPORT' | translate }} -->
        Export
      </button>
    </div>
  </div>
  <div class="mt-3">
    <kendo-grid
      [kendoGridBinding]="gridData"
      [pageable]="PAGINATION_ARRAY"
      [pageSize]="pageSize"
      class="grid-shadow"
      [resizable]="true"
    >
      <!-- Dynamically generated columns -->
      <kendo-grid-column *ngFor="let column of dynamicColumns"
        [field]="column.field"
        [title]="column.title"
        [width]="150"
        [minResizableWidth]="80"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          {{ dataItem[column.field] }}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
  
  
</div>
