import {
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  AfterViewChecked,
} from '@angular/core';
import { Assets } from '../../core/models/assets';
import { AssetService } from '../../core/services/asset.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  API_ERROR_MSG,
  ASSET_LOAD_COMPLETE,
  CANCEL_BTN_TEXT,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  CANCEL_TEMPLATE_HEADING,
  CANCEL_TEMPLATE_MSG,
  CLOSE,
  CONTINUE,
  DEFAULTPAGESIZE,
  EMPTY_STRING,
  ERROR,
  FAILED,
  FAILED_ASSET_FETCH,
  FAILED_ASSET_LOAD,
  FAILED_ASSET_UPDATE,
  FETCHED_ASSEST_UNDEFINED,
  REDIRECT_ROUTE_MANAGEMENT,
  SAVE_TEMPLATE_HEADING,
  SAVE_TEMPLATE_MSG,
  SUCCESS,
  ZERO,
} from '../../shared/constants';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { Router } from '@angular/router';
import { SurveyRouteService } from '../../core/services/survey-route.service';
import { MapService } from '../../core/services/map.service';
import { GoogleMaps } from '../../core/models/google-maps';
import { SurveyRoute, SurveyRouteAssociatedAsset } from '../../core/models/survey-routes';
import { Observable, switchMap } from 'rxjs';
import {
  AssetMetadataInput,
  FilterInput,
  HierarchyFilterInput,
  KendoGridFilters,
  SurveyRouteCreateInput,
  SurveyRouteFilterInput,
  SurveyRouteUpdateInput,
} from '../../../awsAppSync/API';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { AssetTypeState } from '../../core/store/filter-assettype';
import { Level3State } from '../../core/store/filter-level3.state';
import { SegmentState } from '../../core/store/filter-segments';
import { SegmentTypeState } from '../../core/store/filter-segmenttype.state';
import { SurveyRouteState } from '../../core/store/filter-serveyrotes';
import { Store } from '@ngxs/store';
import { State } from '@progress/kendo-data-query';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { AddSurveyRouteModalComponent } from '../add-survey-route-modal/add-survey-route-modal.component';
import { MatDialog } from '@angular/material/dialog';
import {
  RemoveSurveyRouteData,
  SurveyRouteDataState,
} from '../../core/store/survey-route.state';
import { SurveyRouteEditModalComponent } from '../../shared/survey-route-edit-modal/survey-route-edit-modal.component';
import { TranslateService } from '@ngx-translate/core';

declare let google: GoogleMaps;

@Component({
  selector: 'app-create-route',
  templateUrl: './create-route.component.html',
  styleUrl: './create-route.component.css',
})
export class CreateRouteComponent
  implements OnInit, OnDestroy, AfterViewChecked
{
  @ViewChild('map', { static: true }) mapElementRef!: ElementRef;
  @ViewChild('infoCard') infoCardElement!: ElementRef;
  center = { lat: 48.88478197, lng: -107.41672385 };
  map: any;
  selectedAsset: Assets | null = null;
  selectedEditRouteAsset: SurveyRouteAssociatedAsset[] | null = null;
  private renderer = inject(Renderer2);
  drawingManager: any;
  private selectedAssets = new Set<string>();
  assets: Assets[] = [];
  tempAssets: Assets[] = [];
  private markerMap = new Map<string, any>();
  private currentRectangle: any;
  currentStep = 1;
  displayOrderSelection = false;
  isOrderButtonEnabled = false;
  markerLabel = EMPTY_STRING;
  markerIcon: { url: string; scaledSize: any } | undefined;
  routeName = EMPTY_STRING;
  routeDescription = EMPTY_STRING;
  isEditMode = false;
  selectedRoute: SurveyRoute | null = null;
  shouldCheckHeaderCheckbox: any;
  title = 'SURVEY_ROUTE.CREATE_ROUTE';
  filterModel: FilterInput = {};
  storePrimaryCompanyID$: Observable<string | null>;
  selectedprimaryComapnyId: string | null = '';
  storeSelectedLevel3$: Observable<string[]>;
  selectedLevel3: string[] = [];
  storeSegmentTypeSet$: Observable<string[]>;
  selectedSegmentType: string[] = [];
  storeSelectedSegments$: Observable<string[]>;
  selectedSegments: string[] = [];
  storeSelectedSurveyRoute$: Observable<string[]>;
  selectedSurveyRoute: string[] = [];
  storeSelectedAssetType$: Observable<string[]>;
  selectedAssetType: string[] = [];
  hierarchyFilterInput: HierarchyFilterInput[] = [];
  surveyRouteFilterInput: SurveyRouteFilterInput[] = [];
  public searchText = '';
  kendoGridFilters: KendoGridFilters = {};
  public pageSize = DEFAULTPAGESIZE;
  currentPage = ZERO;
  public state: State = {
    skip: 0,
    take: this.pageSize,
    group: [],
    filter: { filters: [], logic: 'and' },
    sort: [],
  };
  isLoading = false;
  selectedRoutePk: string = EMPTY_STRING;
  selectedRouteSk: string = EMPTY_STRING;
  AssociatedAssetId: Assets[] = [];
  allSelected = false;
  tempselectedAssets: Assets[] = [];
  storeRouteName$: Observable<string | null>;
  storeRouteName: string | null = EMPTY_STRING;
  storeRouteDescription$: Observable<string | null>;
  storeRouteDescription: string | null = EMPTY_STRING;
  storeSelectedRoute$: Observable<any | null>;
  storeSelectedRoute: any;
  filterDataPresent = false;

  constructor(
    private assetService: AssetService,
    private surveyRouteService: SurveyRouteService,
    private router: Router,
    private popupDialogService: PopupDialogService,
    private mapService: MapService,
    private store: Store,
    private toastr: CustomToastrService,
    private hierarchyFilter: HeirarchyFilterServiceService,
    public dialog: MatDialog,
    private translate: TranslateService,
  ) {
    this.storeRouteName$ = this.store.select(SurveyRouteDataState.getrouteName);
    this.storeRouteDescription$ = this.store.select(
      SurveyRouteDataState.getrouteDescription,
    );
    this.storeSelectedRoute$ = this.store.select(
      SurveyRouteDataState.getselectedRoute,
    );

    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
    this.storeSelectedLevel3$ = this.store.select(
      Level3State.getSelectedLevel3Name,
    );
    this.storeSegmentTypeSet$ = this.store.select(
      SegmentTypeState.getSelectedSegmentType,
    );
    this.storeSelectedSegments$ = this.store.select(
      SegmentState.getSelectedSegmentIds,
    );
    this.storeSelectedSurveyRoute$ = this.store.select(
      SurveyRouteState.getSelectedSurveyRouteIds,
    );
    this.storeSelectedAssetType$ = this.store.select(
      AssetTypeState.getSelectedAssetTypeIds,
    );
  }

  ngOnInit(): void {
    this.storeRouteName$.subscribe((routeName) => {
      this.storeRouteName = routeName;
    });
    this.storeRouteDescription$.subscribe((routeDescription) => {
      this.storeRouteDescription = routeDescription;
    });
    this.storeSelectedRoute$.subscribe((selectedRoute) => {
      this.storeSelectedRoute = selectedRoute;
    });

    this.storePrimaryCompanyID$?.subscribe((id) => {
      this.selectedprimaryComapnyId = id!;
    });
    this.storeSelectedLevel3$.subscribe((level3name) => {
      this.selectedLevel3 = level3name;
      this.hierarchyFilterInput = [];
      if (this.selectedprimaryComapnyId && this.selectedLevel3.length > 0) {
        //this.fetchLevel3Values(this.selectedprimaryComapnyId);
      }
    });
    this.clearAssetOrder();
    const routeData = this.surveyRouteService.getRouteData();
    if (routeData) {
      this.routeName = routeData?.routeName;
      this.routeDescription = routeData.routeDescription;
      if (routeData.selectedRow) {
        this.selectedRoutePk = routeData.selectedRow.pk;
        this.selectedRouteSk = routeData.selectedRow.sk;
        this.selectedRoute = routeData.selectedRow;
        this.selectedEditRouteAsset = routeData.selectedRow
          .surveyRouteAssociatedAsset
          ? routeData.selectedRow.surveyRouteAssociatedAsset.filter(
              (asset): asset is SurveyRouteAssociatedAsset => asset !== null,
            )
          : null;
        this.isEditMode = true;

        this.AssociatedAssetId =
          this.selectedEditRouteAsset?.map((asset) => {
            return this.mapToAssets(asset);
          }) ?? [];

        if (this.isEditMode) {
          this.title = 'ADD_SURVEY_ROUTE_MODAL.EDIT_SURVEY_ROUTE';
        }

        this.updateGridAndMap(this.AssociatedAssetId ?? []);
      }
    } else if (this.storeSelectedRoute) {
      this.routeName = this.storeRouteName!;
      this.routeDescription = this.storeRouteDescription!;
      this.selectedRoutePk = this.storeSelectedRoute.pk;
      this.selectedRouteSk = this.storeSelectedRoute.sk;
      this.selectedRoute = this.storeSelectedRoute;
      this.selectedEditRouteAsset = this.storeSelectedRoute
        .surveyRouteAssociatedAsset
        ? this.storeSelectedRoute.surveyRouteAssociatedAsset.filter(
            (
              asset: SurveyRouteAssociatedAsset | null,
            ): asset is SurveyRouteAssociatedAsset => asset !== null,
          )
        : null;
      this.isEditMode = true;

      this.AssociatedAssetId =
        this.selectedEditRouteAsset?.map((asset) => {
          return this.mapToAssets(asset);
        }) ?? [];

      if (this.isEditMode) {
        this.title = 'ADD_SURVEY_ROUTE_MODAL.EDIT_SURVEY_ROUTE';
      }
      this.updateGridAndMap(this.AssociatedAssetId ?? []);
    }
    this.isLoading = true;
    this.loadAssets();
    this.hierarchyFilter.isClearFilterClick.subscribe((value) => {
      this.onClearFilterPressed(value);
    });

    this.assetService.AssociatedAssetId = this.AssociatedAssetId;
  }

  mapToAssets(sraa: SurveyRouteAssociatedAsset): Assets {
    return {
      id: sraa.assetId ?? null,
      pk: this.selectedRoutePk, // Set default values or handle appropriately
      sk: this.selectedRouteSk,
      level1Id: sraa.level1ValueId ?? null,
      level2Id: sraa.level2ValueId ?? null,
      level3Id: sraa.level3ValueId ?? null,
      name: sraa.assetName ?? null,
      latitude: sraa.latitude ? parseFloat(sraa.latitude) : null,
      longitude: sraa.longitude ? parseFloat(sraa.longitude) : null,
      type: sraa.assetType ?? null,
      selected: true, // Default value
      lastInspectionDate: sraa.lastInspectionDate ?? null,
      assetDelinquencyDate: sraa.assetDelinquencyDate ?? null,
      order: sraa.order ?? '',
      status: sraa.status ?? null,
    };
  }

  onClearFilterPressed(value: boolean) {
    if (value) {
      this.filterDataPresent = false;
      this.assets = [];
      this.loadMap();
    }
  }

  filterSearchButtonClicked(): void {
    this.isLoading = true;
    this.loadAssets();
  }

  async fetchLevel3Values(primaryCompanyId: string): Promise<void> {
    const allLevel3Values: HierarchyFilterInput[] = [];
    const level3Values =
      await this.hierarchyFilter.getAllLevel3ValueByPrimaryCompany(
        primaryCompanyId,
      );
    if (level3Values?.items) {
      level3Values.items.forEach((item) => {
        if (item) {
          if (this.selectedLevel3.includes(item.id))
            allLevel3Values.push({
              level1: item.level1ValueId || '',
              level2: item.level2ValueId || '',
              level3: item.id || '',
            });
        }
      });
    }
    this.hierarchyFilterInput = allLevel3Values;
  }

  private updateFilterModel() {
    this.filterModel = {
      primaryCompany: this.selectedprimaryComapnyId,
      level4: this.selectedSegments,
      assetTypes: this.selectedAssetType,
      segmentTypes: this.selectedSegmentType,
      surveyRouteFilterInputs: this.surveyRouteFilterInput,
      hierarchyFilterInputs: this.hierarchyFilterInput,
      searchText: this.searchText,
      pageNumber: this.currentPage == 0 ? 1 : this.currentPage,
      pageSize: 100000,
      gridFilters: this.kendoGridFilters,
    };
  }

  toggleOrderSelection(): void {
    this.displayOrderSelection = !this.displayOrderSelection;
    this.updateAllMarkerLabels();
    this.tempAssets = this.assets;
    // if (this.selectedRoute) {
    //   this.tempAssets = this.assets;
    // }
    const selectedAsset = this.assets.filter((asset) => asset.selected);
    this.updateGridAndMap(selectedAsset);
    this.currentStep = 2;
  }

  updateGridAndMap(selectedAsset: Assets[]) {
    if (this.isEditMode) {
      const selectedAssetMap = new Map(
        selectedAsset.map((asset) => [asset.id, asset]),
      );

      this.assets = this.assets.map((asset) => {
        const newAsset = selectedAssetMap.get(asset.id);
        return newAsset !== undefined ? newAsset : asset;
      });

      this.isEditMode = false;
      this.updateButtonState();
    } else {
      this.assets = selectedAsset;
      this.updateMarkers();
    }
  }

  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.assets, event.previousIndex, event.currentIndex);
    this.updateAllMarkerLabels();
  }

  updateAllMarkerLabels(): void {
    let selectedIndex = 1;
    this.assets.forEach((asset) => {
      if (asset.selected) {
        asset.order = selectedIndex.toString();
        this.updateMarkerLabel(asset);
        selectedIndex++;
      }
    });
  }

  clearAssetOrder(): void {
    this.assets.forEach((asset) => {
      asset.order = EMPTY_STRING;
    });
  }

  isSelectAllCheck() {
    if (this.assets.length > 0) {
      // Check if all checkboxes are selected
      this.allSelected = this.assets.every((item) => item.selected);
    }
  }

  updateMarkerLabel(asset: Assets): void {
    const marker = this.markerMap.get(asset.id ?? '');
    if (marker) {
      const label = asset.order ? asset.order.toString() : ''; // Check if order is not null or empty
      const markerIcon = {
        url: this.mapService.getMarkerIcon(
          label,
          this.mapService.pickMarkerColor(asset.status ?? ''),
          true,
          this.mapService.pickMarkerInnerText(asset.type ?? ''),
        ), // Create SVG badge URL  url: this.createBadgeIcon('✔', '#2D4B7F'), colors - #B42A78 , #2D4B7F ,#23D696 ,#003DF7
        scaledSize: new google.maps.Size(48, 48), // Size of the icon
      };
      marker.setIcon(markerIcon);
    }
  }

  async loadAssets(): Promise<void> {
    await this.fetchLevel3Values(this.selectedprimaryComapnyId!);
    this.updateFilterModel();

    (await this.assetService.getAssets(this.filterModel))
      .pipe(
        switchMap(async (data: Assets[]) => {
          this.assets = data;
          if (this.assets.length > 0 && this.selectedLevel3.length > 0) {
            this.filterDataPresent = true;
          }
          // Handle selectedEditRouteAsset logic
          if (
            this.selectedEditRouteAsset &&
            this.selectedEditRouteAsset.length > 0
          ) {
            for (const element of this.selectedEditRouteAsset) {
              const isSelectedAssetPresent = this.assets.some(
                (x) => x.id === element.assetId,
              );
              if (!isSelectedAssetPresent) {
                const asset = await this.mapToAssetThroughAPI(element);
                this.assets.unshift(asset);
              }
            }
          }

          // Handle tempselectedAssets logic
          if (this.tempselectedAssets && this.tempselectedAssets.length > 0) {
            for (const element of this.tempselectedAssets) {
              const isTempAssetPresent = this.assets.some(
                (x) => x.id === element.id,
              );
              if (!isTempAssetPresent) {
                element.selected = true;
                this.assets.unshift(element);
              }
            }
          }

          // Set selected and order properties
          this.assets.forEach((element) => {
            if (
              this.selectedEditRouteAsset?.find(
                (id) => id.assetId === element.id,
              ) ||
              this.tempselectedAssets.find((x) => x.id === element.id)
            ) {
              element.selected = true;
              element.order =
                this.selectedEditRouteAsset?.find(
                  (id) => id.assetId === element.id,
                )?.order ?? '';
            }
          });

          this.assets.sort(
            (a, b) =>
              (b.selected === true ? 1 : 0) - (a.selected === true ? 1 : 0),
          );
          // Sort assets
          this.assets.sort((a, b) => {
            if (a.order && b.order) {
              return parseInt(a.order, 10) - parseInt(b.order, 10);
            } else if (a.order) {
              return -1;
            } else if (b.order) {
              return 1;
            } else {
              return 0;
            }
          });

          this.isSelectAllCheck();
          return this.assets;
        }),
      )
      .subscribe({
        next: () => {
          this.loadMap();
          if (this.map) {
            this.updateExistingMarkers();
          } else {
            this.loadMap();
          }
          this.isLoading = false;
        },
        error: (err: any) => {
          console.error(FAILED_ASSET_LOAD, err);
        },
        complete: () => {
          console.log(ASSET_LOAD_COMPLETE);
        },
      });
  }

  async mapToAssetThroughAPI(
    sraa: SurveyRouteAssociatedAsset,
  ): Promise<Assets> {
    const input: AssetMetadataInput = {
      assetId: sraa.assetId ?? '',
      level1: sraa.level1ValueId ?? '',
      level2: sraa.level2ValueId ?? '',
      level3: sraa.level3ValueId ?? '',
      primaryCompany: this.selectedprimaryComapnyId ?? '',
    };
    const response = await this.assetService.getAssetByIdAPI(input);
    return {
      id: sraa.assetId ?? null,
      pk: this.selectedRoutePk, // Set default values or handle appropriately
      sk: this.selectedRouteSk,
      level1Id: sraa.level1ValueId ?? null,
      level2Id: sraa.level2ValueId ?? null,
      level3Id: sraa.level3ValueId ?? null,
      name: response?.assetName ?? null,
      latitude: response?.latitude ? parseFloat(response?.latitude) : null,
      longitude: response?.longitude ? parseFloat(response?.longitude) : null,
      type: sraa.assetType ?? null,
      selected: true, // Default value
      lastInspectionDate: sraa.lastInspectionDate ?? null,
      assetDelinquencyDate: sraa.assetDelinquencyDate ?? null,
      order: sraa.order ?? '',
      status: sraa.status ?? null,
    };
  }
  private async loadMap(): Promise<void> {
    const { Map } = await google.maps.importLibrary('maps');
    const mapEl = this.mapElementRef.nativeElement;
    if (this.assets.length > 0) {
      const lat = this.assets[0].latitude;
      const long = this.assets[0].longitude;
      const mapValues = { lat: lat, lng: long };

      this.map = new Map(mapEl, {
        center: mapValues,
        zoom: 12,
        mapId: '4504f8b37365c3d7',
        scaleControl: true,
        streetViewControl: false,
        zoomControl: true,
        overviewMapControl: true,
        mapTypeControl: true,
        fullscreenControl: true,
      });

      this.renderer.addClass(mapEl, 'visible');
      this.createMarkers();
      this.initDrawingManager();
    } else {
      const lat = 44.21839866380285;
      const long = -104.11857786015787;
      const mapValues = { lat: lat, lng: long };

      this.map = new Map(mapEl, {
        center: mapValues,
        zoom: 12,
        mapId: '4504f8b37365c3d7',
        scaleControl: true,
        streetViewControl: false,
        zoomControl: true,
        overviewMapControl: true,
        mapTypeControl: true,
        fullscreenControl: true,
      });

      this.renderer.addClass(mapEl, 'visible');
      this.createMarkers();
      this.initDrawingManager();
    }
  }

  private async createMarkers(): Promise<void> {
    const { Marker } = await google.maps.importLibrary('marker');
    this.infoCardElement.nativeElement.classList.add('visible');
    this.assets.forEach((asset) => {
      this.markerLabel = asset.order ? asset.order.toString() : '';

      if (asset.selected) {
        this.markerIcon = {
          url: this.mapService.getMarkerIcon(
            '✔',
            this.mapService.pickMarkerColor(asset.status ?? ''),
            asset.selected,
            this.mapService.pickMarkerInnerText(asset.type ?? ''),
          ), // Create SVG badge URL  url: this.createBadgeIcon('✔', '#2D4B7F'), colors - #B42A78 , #2D4B7F ,#23D696 ,#003DF7
          scaledSize: new google.maps.Size(48, 48), // Size of the icon
        };
      } else {
        this.markerIcon = {
          url: this.mapService.getMarkerIcon(
            this.markerLabel,
            this.mapService.pickMarkerColor(asset.status ?? ''),
            false,
            this.mapService.pickMarkerInnerText(asset.type ?? ''),
          ), // Create SVG badge URL  url: this.createBadgeIcon('✔', '#2D4B7F'), colors - #B42A78 , #2D4B7F ,#23D696 ,#003DF7
          scaledSize: new google.maps.Size(48, 48), // Size of the icon
        };
      }

      const marker = new Marker({
        position: { lat: asset.latitude, lng: asset.longitude },
        map: this.map,
        title: asset.name ?? '',
        icon: this.markerIcon,
      });

      marker.addListener('click', () => this.onMarkerClick(asset));
      marker.addListener('dblclick', () => this.onMarkerDoubleClick(asset));

      this.markerMap.set(asset.id ?? '', marker); // Track marker by ID
    });
  }

  private onMarkerDoubleClick(asset: Assets): void {
    this.assetService.getAssetById(asset.id ?? '').subscribe({
      next: (currentAsset: Assets | undefined) => {
        if (currentAsset) {
          const updatedAsset: Assets = {
            ...currentAsset,
            selected: !currentAsset.selected,
          };
          this.updateAsset(updatedAsset);
        } else {
          console.error(FETCHED_ASSEST_UNDEFINED);
        }
      },
      error: (err: any) => console.error(FAILED_ASSET_FETCH, err),
    });
    this.checkHeaderCheckbox();
    this.updateButtonState();
  }

  private updateAsset(updatedAsset: Assets): void {
    this.tempselectedAssets.push(updatedAsset);
    this.assetService.updateAsset(updatedAsset).subscribe({
      next: (asset: Assets) => {
        const index = this.assets.findIndex((a) => a.id === asset.id);
        if (index !== -1) {
          this.assets[index] = asset;
        }

        this.updateMarkerIcon(asset, asset.selected);
        this.updateGridCheckbox(asset);
      },
      error: (err: any) => console.error(FAILED_ASSET_UPDATE, err),
    });
  }

  private updateMarkerIcon(asset: Assets, selected: boolean): void {
    const marker = this.markerMap.get(asset.id ?? '');
    if (marker) {
      this.markerLabel = asset.order ? asset.order.toString() : '';
      if (this.markerLabel) {
        const markerIcon = {
          url: this.mapService.getMarkerIcon(
            this.markerLabel,
            this.mapService.pickMarkerColor(asset.status ?? ''),
            selected,
            this.mapService.pickMarkerInnerText(asset.type ?? ''),
          ), // Create SVG badge URL  url: this.createBadgeIcon('✔', '#2D4B7F'), colors - #B42A78 , #2D4B7F ,#23D696 ,#003DF7
          scaledSize: new google.maps.Size(48, 48), // Size of the icon
        };
        marker.setIcon(markerIcon);
      } else {
        const markerIcon = {
          url: this.mapService.getMarkerIcon(
            '✔',
            this.mapService.pickMarkerColor(asset.status ?? ''),
            selected,
            this.mapService.pickMarkerInnerText(asset.type ?? ''),
          ), // Create SVG badge URL  url: this.createBadgeIcon('✔', '#2D4B7F'), colors - #B42A78 , #2D4B7F ,#23D696 ,#003DF7
          scaledSize: new google.maps.Size(48, 48), // Size of the icon
        };
        marker.setIcon(markerIcon);
      }
    }
  }

  private async initDrawingManager(): Promise<void> {
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: null, // Disable drawing mode initially
      drawingControl: true,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: [google.maps.drawing.OverlayType.RECTANGLE], // Available drawing modes
      },
      rectangleOptions: {
        editable: true,
        fillColor: '#FF0000',
        fillOpacity: 0.2,
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
      },
    });

    this.drawingManager.setMap(this.map);

    google.maps.event.addListener(
      this.drawingManager,
      'rectanglecomplete',
      (rectangle: any) => {
        this.onRectangleDrawn(rectangle);
      },
    );
  }

  private onRectangleDrawn(rectangle: any): void {
    this.currentRectangle = rectangle; // Store the drawn rectangle
    const bounds = rectangle.getBounds();
    if (!bounds) return;
    // Clear previously selected markers
    this.clearSelectedMarkers();

    // Check and select markers within the drawn rectangle
    const newlySelectedAssets = new Set<string>();
    this.assets.forEach((asset) => {
      const marker = this.markerMap.get(asset.id ?? '');
      if (marker) {
        const position = marker.getPosition();
        if (bounds.contains(position)) {
          newlySelectedAssets.add(asset.id ?? '');
          this.updateMarkerIcon(asset, true); // Update icon to selected
        }
      }
    });

    // Update grid checkboxes based on newly selected assets
    this.updateGridCheckboxes(newlySelectedAssets);
    // Stop drawing mode and remove the rectangle
    this.drawingManager.setDrawingMode(null); // Stop the drawing mode
    this.currentRectangle.setMap(null); // Remove the rectangle from the map
  }

  back() {
    this.currentStep = 1;
    this.displayOrderSelection = !this.displayOrderSelection;
    this.assets = this.tempAssets;
    this.clearMarkers();
    this.createMarkers();
    this.clearAssetOrder();
    this.triggerHeaderCheckboxCheck();
    this.checkHeaderCheckbox();
  }
  resetAssetData() {
    this.assets.forEach((asset) => {
      if (asset.selected) {
        const updatedAsset: Assets = {
          ...asset,
          selected: false,
          order: EMPTY_STRING,
        };
        this.updateAsset(updatedAsset);
        this.updateButtonState();
      }
    });
  }

  cancel() {
    const dialogRef = this.popupDialogService.openDialog(
      CANCEL_TEMPLATE_HEADING,
      CANCEL_TEMPLATE_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => this.router.navigate([REDIRECT_ROUTE_MANAGEMENT]),
      true,
      CANCEL_BTN_TEXT,
      true,
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result === CANCEL_BTN_TEXT) {
        return;
      } else {
        this.resetAssetData();
      }
    });
  }

  saveAndContinue() {
    if (!this.selectedRoute) {
      const dialogRef = this.popupDialogService.openDialog(
        SAVE_TEMPLATE_HEADING,
        SAVE_TEMPLATE_MSG,
        FAILED,
        CONTINUE,
        () => this.router.navigate([]),
        true,
        CANCEL_BTN_TEXT,
        true,
      );
      dialogRef.afterClosed().subscribe(async (result) => {
        if (result === CANCEL_BTN_TEXT) {
          return;
        } else {
          this.isLoading = true;
          if (!this.selectedRoute) {
            const input: SurveyRouteCreateInput = {
              primaryCompanyId: this.selectedprimaryComapnyId ?? '',
              routeDescription: this.routeDescription,
              routeName: this.routeName,
              surveyRouteAssociatedAssetInput: [
                ...this.assets.map((asset) => ({
                  assetId: asset.id,
                  level1ValueId: asset.level1Id ?? null,
                  level2ValueId: asset.level2Id ?? null,
                  level3ValueId: asset.level3Id ?? null,
                  order: asset.order,
                })),
              ],
            };
            const response =
              await this.surveyRouteService.createSurveyRoute(input);
            this.isLoading = false;
            if (response!.status === 'Success') {
              this.popupDialogService.openDialog(
                'Survey Route Created Successfully!',
                "You'll be redirected to Survey Route Overview list page",
                SUCCESS,
                CLOSE,
                () => this.router.navigate([REDIRECT_ROUTE_MANAGEMENT]),
              );
            } else {
              this.popupDialogService.openDialog(
                'Creating Survey Route Failed!',
                'An unexpected error occurred. Please try adding your User again.',
                FAILED,
                CLOSE,
              );
              this.toastr.showError(
                response?.error?.message ?? API_ERROR_MSG,
                ERROR,
              );
              this.isLoading = false;
            }
          }
        }
        this.resetAssetData();
      });
    }

    if (this.selectedRoute) {
      const heading = this.translate.instant(
        'SURVEY_TASK_PREVIEW.SAVE_TASK_HEADING',
      );
      const message = this.translate.instant(
        'SURVEY_TASK_PREVIEW.SAVE_TASK_MESSAGE',
      );
      const failed = this.translate.instant('GENERIC_Buttons.FAILED');
      const Cancel = this.translate.instant('GENERIC_Buttons.CANCEL');
      const Continue = this.translate.instant('GENERIC_Buttons.CONTINUE');

      const dialogRef = this.dialog.open(SurveyRouteEditModalComponent, {
        data: { routeName: this.routeName, isSurveyRouteFlag: true }, // Pass your data here
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          if (result == 2) {
            const dialogRefRoute = this.dialog.open(
              AddSurveyRouteModalComponent,
              {
                data: { allowNavigation: false }, // Directly pass the data object
              },
            );

            dialogRefRoute.afterClosed().subscribe(async (route) => {
              if (route) {
                const input: SurveyRouteCreateInput = {
                  primaryCompanyId: this.selectedprimaryComapnyId ?? '',
                  routeDescription: route.routeDescription,
                  routeName: route.routeName,
                  surveyRouteAssociatedAssetInput: [
                    ...this.assets.map((asset) => ({
                      assetId: asset.id,
                      level1ValueId: asset.level1Id ?? null,
                      level2ValueId: asset.level2Id ?? null,
                      level3ValueId: asset.level3Id ?? null,
                      order: asset.order,
                    })),
                  ],
                };

                const response =
                  await this.surveyRouteService.createSurveyRoute(input);

                this.isLoading = true;
                if (response!.status === 'Success') {
                  this.popupDialogService.openDialog(
                    'Survey Route Created Successfully!',
                    "You'll be redirected to Route Management list page",
                    SUCCESS,
                    CLOSE,
                    () => this.router.navigate([REDIRECT_ROUTE_MANAGEMENT]),
                  );
                } else {
                  this.popupDialogService.openDialog(
                    'Creating Survey Route Failed!',
                    'An unexpected error occurred. Please try adding your User again.',
                    FAILED,
                    CLOSE,
                  );
                  this.toastr.showError(
                    response?.error?.message ?? API_ERROR_MSG,
                    ERROR,
                  );
                }
              }
              this.isLoading = false;
            });
          }
           else if (result == 1) {
            const dialogRef = this.popupDialogService.openDialog(
              heading,
              message,
              failed,
              Continue,
              async () => {
                this.isLoading = true;
                const input: SurveyRouteUpdateInput = {
                  id: this.selectedRoute?.id??'',
                  pk: `PC#${this.selectedprimaryComapnyId}`,
                  sk: `SurveyRoute#${this.selectedRoute?.id??''}`,
                  routeDescription: this.routeDescription,
                  routeName: this.routeName,
                  surveyRouteAssociatedAssetInput: [
                    ...this.assets.map((asset: Assets) => ({
                      assetId: asset.id,
                      level1ValueId: asset.level1Id ?? null,
                      level2ValueId: asset.level2Id ?? null,
                      level3ValueId: asset.level3Id ?? null,
                      order: asset.order,
                    })),
                  ],
                };
                const response =
                  await this.surveyRouteService.updateSurveyRoute(input);
                this.isLoading = false;
                if (response!.status === 'Success') {
                  this.popupDialogService.openDialog(
                    'Survey Route Updated Successfully!',
                    "You'll be redirected to Route Management list page",
                    SUCCESS,
                    CLOSE,
                    () => this.router.navigate([REDIRECT_ROUTE_MANAGEMENT]),
                  );
                } else {
                  this.popupDialogService.openDialog(
                    'Updating Survey Route Failed!',
                    'An unexpected error occurred. Please try again.',
                    FAILED,
                    CLOSE,
                  );
                  this.toastr.showError(
                    response?.error?.message ?? API_ERROR_MSG,
                    ERROR,
                  );
                  this.isLoading = false;
                }
              },
              true,
              Cancel,
              true,
            );
            dialogRef.afterClosed().subscribe(() => {
              dialogRef.close();
            });
          }
        }
      });
    }

    // if (this.selectedRoute) {
    //   this.dialog.open(AddSurveyRouteModalComponent, {
    //     data: {
    //       selectedRowItem: this.selectedRoute,
    //       isEdit: true,
    //       assets: this.assets,
    //     },
    //   });
    // }
  }

  onSelectAllChange(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.assets.forEach((asset) => {
      if (isChecked) {
        if (!asset.selected) {
          // Only update if not already selected
          const updatedAsset: Assets = { ...asset, selected: true };
          this.updateAsset(updatedAsset);
          this.updateButtonState();
        }
      } else {
        if (asset.selected) {
          // Only update if already selected
          const updatedAsset: Assets = { ...asset, selected: false };
          this.updateAsset(updatedAsset);
          this.updateButtonState();
        }
      }
    });
  }
  private clearSelectedMarkers(): void {
    this.selectedAssets.forEach((assetId: string) => {
      const marker = this.markerMap.get(assetId);
      if (marker) {
        const asset = this.assets.find((a) => a.id === assetId);
        if (asset) {
          this.updateMarkerIcon(asset, false); // Update icon to unselected
        }
      }
    });
    this.selectedAssets.clear();
  }

  private onMarkerClick(asset: Assets): void {
    this.selectedAsset = asset;
    this.infoCardElement.nativeElement.classList.add('visible');
  }

  onCheckboxChange(event: Event, asset: Assets): void {
    const inputElement = event.target as HTMLInputElement;
    const selected = inputElement.checked;

    // Add the 'order' property with an empty string
    const updatedAsset: Assets = {
      ...asset,
      selected: selected,
      order: EMPTY_STRING,
    };
    this.updateAsset(updatedAsset);
    this.updateButtonState();
    this.checkHeaderCheckbox();
  }

  updateButtonState(): void {
    this.isOrderButtonEnabled = this.assets.some((asset) => asset.selected);
  }

  private updateGridCheckbox(asset: Assets): void {
    const checkbox = document.querySelector<HTMLInputElement>(
      `input[type="checkbox"][data-asset-id="${asset.id}"]`,
    );
    if (checkbox) {
      checkbox.checked = asset.selected;
    }
  }

  private async updateMarkers(): Promise<void> {
    this.clearMarkers();

    const { Marker } = await google.maps.importLibrary('marker');
    this.assets.forEach((asset) => {
      const label = asset.order ? asset.order.toString() : '';
      const markerIcon = {
        url: this.mapService.getMarkerIcon(
          label,
          this.mapService.pickMarkerColor(asset.status ?? ''),
          true,
          this.mapService.pickMarkerInnerText(asset.type ?? ''),
        ), // Create SVG badge URL  url: this.createBadgeIcon('✔', '#2D4B7F'), colors - #B42A78 , #2D4B7F ,#23D696 ,#003DF7
        scaledSize: new google.maps.Size(48, 48), // Size of the icon
      };
      const marker = new Marker({
        position: { lat: asset.latitude, lng: asset.longitude },
        map: this.map,
        title: asset.name ?? '',
        icon: markerIcon,
      });

      this.markerMap.set(asset.id ?? '', marker);
    });
  }

  private clearMarkers(): void {
    this.markerMap.forEach((marker) => {
      marker.setMap(null);
    });
    this.markerMap.clear();
  }

  private updateGridCheckboxes(selectedAssetIds: Set<string>): void {
    this.assets.forEach((asset) => {
      const checkbox = document.querySelector<HTMLInputElement>(
        `input[type="checkbox"][data-asset-id="${asset.id}"]`,
      );
      if (checkbox) {
        // Keep the checkbox checked if it was already checked, or if it's in the newly selected assets
        const isSelected =
          checkbox.checked || selectedAssetIds.has(asset.id ?? '');
        checkbox.checked = isSelected;

        // Set the asset.selected property to true if the asset is selected
        if (isSelected) {
          asset.selected = true;
        }
      }
    });
    this.updateButtonState();
    this.checkHeaderCheckbox();
  }

  checkHeaderCheckbox() {
    // Uncheck the header checkbox if any asset is unchecked
    const allSelected = this.assets.every((asset) => asset.selected);
    const headerCheckbox = document.getElementById(
      'selectAllCheckbox',
    ) as HTMLInputElement;

    if (headerCheckbox) {
      headerCheckbox.checked = allSelected;
    }
  }

  private updateExistingMarkers(): void {
    this.assets.forEach((asset) => {
      this.updateMarkerIcon(asset, asset.selected);
    });
  }

  triggerHeaderCheckboxCheck() {
    this.shouldCheckHeaderCheckbox = true;
  }

  ngAfterViewChecked() {
    if (this.shouldCheckHeaderCheckbox) {
      this.checkHeaderCheckbox();
      this.shouldCheckHeaderCheckbox = false;
    }
  }

  ngOnDestroy(): void {
    this.resetAssetData();
    if (this.map) {
      google.maps.event.clearInstanceListeners(this.map);
    }
    this.markerMap.forEach((marker) =>
      google.maps.event.clearInstanceListeners(marker),
    );
    this.store.dispatch(new RemoveSurveyRouteData());
  }
}
