import { Injectable } from '@angular/core';
import {
  ColumnReportConnection,
  CreateReportTemplateMutation,
  CreateReportTemplateMutationVariables,
  GetAllColumnsForReportQuery,
  GetAllReportTemplatesQuery,
  GetAllReportTemplatesQueryVariables,
  GetReportTemplateByIdQuery,
  GetReportTemplateByIdQueryVariables,
  ReportInput,
  ReportResponse,
  ReportTemplate,
  ReportTemplateConnection,
  ReportTemplateCreateInput,
  ReportTemplateGridInput,
  ReportTemplateResponse,
  ReportTemplateUpdateInput,
  UpdateReportTemplateMutation,
  UpdateReportTemplateMutationVariables,
} from '../../../awsAppSync/API';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import {
  getAllColumnsForReport,
  getAllReportTemplates,
  getReportTemplateById,
} from '../../../awsAppSync/graphql/queries';
import { GENERIC_FAILED_ERROR } from '../../shared/constants';
import { createReportTemplate, updateReportTemplate } from '../../../awsAppSync/graphql/mutations';
@Injectable({
  providedIn: 'root',
})
export class ReportTemplatesLibraryService {
  constructor(private readonly awsService: BaseAWSRequestService) {}
  async getReportGridData(
    filter: ReportTemplateGridInput,
  ): Promise<ReportTemplateConnection | null> {
    try {
      const variables: GetAllReportTemplatesQueryVariables = { input: filter };
      const result =
        await this.awsService.executeGetQuery<GetAllReportTemplatesQuery>(
          getAllReportTemplates,
          variables,
        );
      return result.getAllReportTemplates ?? null; // Using nullish coalescing operator
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }
  async createReportTemplate(
    input: ReportTemplateCreateInput,
  ): Promise<ReportResponse | null> {
    const mutation = createReportTemplate;
    const variables: CreateReportTemplateMutationVariables = { input };
    try {
      const CreateReportResult = await this.awsService.executeMutation<{
        createReportTemplate: CreateReportTemplateMutation;
      }>(mutation, variables);
      return CreateReportResult.createReportTemplate || null;
    } catch (error) {
      console.error('Error creating Template', error);
      return null;
    }
  }
  async getAllColumnsForReport(): Promise<ColumnReportConnection | null> {
    try {
      const result =
        await this.awsService.executeGetQuery<GetAllColumnsForReportQuery>(
          getAllColumnsForReport,
        );
      return result.getAllColumnsForReport ?? null; // Using nullish coalescing operator
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }
  async updateReportTemplate(
    input: ReportTemplateUpdateInput,
  ): Promise<ReportTemplateResponse | null> {
    const mutation = updateReportTemplate;
    const variables: UpdateReportTemplateMutationVariables = { input };
    try {
      const UpdateReportResult = await this.awsService.executeMutation<{
        updateReportTemplate: UpdateReportTemplateMutation;
      }>(mutation, variables);
      return UpdateReportResult.updateReportTemplate || null;
    } catch (error) {
      console.error('Error Updating Template', error);
      return null;
    }
  }

  async getReportTemplateById(input: ReportInput): Promise<ReportTemplate | null>
  {
    try {
      const variables: GetReportTemplateByIdQueryVariables = { input };
      const result = await this.awsService.executeGetQuery<GetReportTemplateByIdQuery>(
        getReportTemplateById,
        variables,
      );
      return result.getReportTemplateById ?? null; 
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }
}
