<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container-fluid py-2" style="margin-top: -28px">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <h5
          class="mtx-text-primary"
          style="margin-left: -12px; margin-top: 22px"
        >
          Field Data
        </h5>
      </div>
      <div class="col-auto ml-auto">
        <div
          class="d-flex flex-wrap align-items-center gap-2 justify-content-end"
        >
          <div style="width: 330px">
            <app-search
              [placeholder]="searchPlaceholder"
              (searchInput)="handleSearchInput($event)"
            ></app-search>
          </div>
          <div class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <button class="btn mtx-btn-primary" style="height: 38px">
              <span style="vertical-align: middle" class="material-icons"
                >upload</span
              >
              Import Survey
            </button>
          </div>
        </div>
      </div>
    </div>

        <div mt-3>
            <kendo-grid [data]="gridData" [filterable]="'menu'" [pageable]="PAGINATION_ARRAY" [pageSize]="pageSize"
                (dataStateChange)="dataStateChange($event)" class="grid-shadow" [rowSticky]="rowSticky"
                [resizable]="true" [pageable]="true">
                <kendo-grid-column [resizable]="false" [width]="50" [filterable]="false" field="id" title=""
                    [resizable]="false">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <div class="flex-container" style="display: flex; align-items: center">
                            <span class="k-icon k-font-icon my-custom-icon-class" style="position: relative"
                                (click)="openDropdownMenu($event, dataItem,'outerGrid')"></span>
                            <span *ngIf="isSticky(dataItem.id)" class="k-icon k-font-icon k-i-star k-i-pin pin-icon-css"
                                (click)="rowActionStickyRow($event, dataItem)">
                            </span>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="surveyName" title="Survey Name" [width]="300" [minResizableWidth]="150">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="customTxtCss">{{ dataItem.surveyName }}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="surveyType" title="Survey Type" [width]="200" [minResizableWidth]="80">
                    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                        let-filterService="filterService">
                        <div #customFilterContainer>
                            <app-custom-grid-filter fieldName="surveyType" [inputElements]="surveyTypeFilterValues"
                                [parentDiv]="customFilter" [state]="customstate"
                                (selectedValues)="applyFilter($event, filterService, 'surveyType')">
                            </app-custom-grid-filter>
                        </div>
                    </ng-template>

                </kendo-grid-column>
                <kendo-grid-column field="version" title="Version" [width]="120" [minResizableWidth]="60">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="customTxtCss">{{ dataItem.version }}</span>
                    </ng-template>
                    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                        let-filterService="filterService">
                        <div #customFilterContainer>
                            <app-custom-grid-filter fieldName="version" [inputElements]="surveyVersionFilterValues"
                                [parentDiv]="customFilter" [state]="customstate"
                                (selectedValues)="applyFilter($event, filterService, 'version')">
                            </app-custom-grid-filter>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="versionDate" title="Version Date" [width]="230" format="{0:dd/MM/yyyy}"
                    filter="date" [minResizableWidth]="110"></kendo-grid-column>
                <kendo-grid-column field="surveyPriority" title="Survey Priority" [width]="220"
                    [minResizableWidth]="110">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span [ngClass]="getPriorityClass(dataItem.surveyPriority)" style="font-weight: 700">
                            <span class="dot"></span> {{ dataItem.surveyPriority }}
                        </span>
                    </ng-template>
                    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                        let-filterService="filterService">
                        <div #customFilterContainer>
                            <app-custom-grid-filter fieldName="surveyPriority"
                                [inputElements]="surveyPriorityFilterValues" [parentDiv]="customFilter"
                                [state]="customstate"
                                (selectedValues)="applyFilter($event, filterService, 'surveyPriority')">
                            </app-custom-grid-filter>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="surveyStatus" title="Survey Status" [width]="220" [minResizableWidth]="120">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span class="surveyStatus" style="font-weight: 700">
                            <span class="dot"></span> {{ dataItem.surveyStatus }}
                        </span>
                    </ng-template>
                    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column"
                        let-filterService="filterService">
                        <div #customFilterContainer>
                            <app-custom-grid-filter fieldName="surveyStatus" [inputElements]="surveyStatusFilterValues"
                                [parentDiv]="customFilter" [state]="customstate"
                                (selectedValues)="applyFilter($event, filterService, 'surveyStatus')">
                            </app-custom-grid-filter>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="dataCollectionType" title="Data Collection Type" [width]="200"
                    [minResizableWidth]="100"></kendo-grid-column>
                <kendo-grid-column field="dateCompleted" title="Date Completed" [width]="200" format="{0:dd/MM/yyyy}"
                    filter="date" [minResizableWidth]="100"></kendo-grid-column>
                <kendo-grid-column field="dateOfUpload" title="Date of Upload" [width]="200" format="{0:dd/MM/yyyy}"
                    filter="date" [minResizableWidth]="100"></kendo-grid-column>
                <kendo-grid-column field="lastSynch" title="Last Synch" [width]="200" format="{0:dd/MM/yyyy}"
                    filter="date" [minResizableWidth]="100"></kendo-grid-column>
                <kendo-grid-column field="assignee" title="Assignee" [width]="200"
                    [minResizableWidth]="100"></kendo-grid-column>
                <kendo-grid-column field="technician" title="Technician" [width]="200"
                    [minResizableWidth]="100"></kendo-grid-column>
                <kendo-grid-column field="surveyDescription" title="Survey Description" [width]="600"
                    [minResizableWidth]="100"></kendo-grid-column>
                <!-- Detail Template -->
                <ng-template kendoGridDetailTemplate let-dataItem>
                    <kendo-grid [data]="dataItem.surveyVersions" [pageable]="false" [sortable]="false"
                        class="inner-grid">
                        <kendo-grid-column [resizable]="false" [width]="1" [filterable]="false" field="id" title=""
                            [resizable]="false">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <div class="flex-container" style="display: flex; align-items: center">
                                    <span class="k-icon k-font-icon my-custom-icon-class" style="position: relative"
                                        (click)="openDropdownMenu($event, dataItem,'innerGrid')"></span>
                                    <span *ngIf="isSticky(dataItem.id)"
                                        class="k-icon k-font-icon k-i-star k-i-pin pin-icon-css"
                                        (click)="rowActionStickyRow($event, dataItem)">
                                    </span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <!-- <kendo-grid-column field="surveyName" title="Survey Name" [width]="5">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                {{ dataItem.surveyName }}
                            </ng-template>
                        </kendo-grid-column> -->
            <kendo-grid-column
              field="version"
              title="Version"
              [width]="5"
            ></kendo-grid-column>
            <kendo-grid-column
              field="versionDate"
              title="Version Date"
              [width]="25"
            ></kendo-grid-column>
          </kendo-grid>
        </ng-template>
      </kendo-grid>
    </div>
  </div>
</div>

<kendo-dialog *ngIf="isDownloadDialogOpen" class="download-popup" (close)="closeDownloadDialog()" [width]="'450px'"
    [height]="'250px'">
    <kendo-dialog-titlebar>
        <!-- Download Survey File -->
    </kendo-dialog-titlebar>
    <div class="popup-content">
        <p class="download-survey">Download Survey File</p>
        <div class="file-download">
            <span class="download-txt">Download Selected survey file</span>
        </div>
    </div>
    <kendo-dialog-actions>
        <button (click)="downloadFile()" class="upload-btn-css">
            <kendo-icon name="download"></kendo-icon> Download File
        </button>
        <button (click)="closeDownloadDialog()" class="cancel-btn-css">
            Cancel
        </button>

    </kendo-dialog-actions>
</kendo-dialog>

<app-row-actions
  class="row-action"
  [visible]="dropdownVisible"
  [positionX]="dropdownX"
  [width]="ellipsisWidth"
  [positionY]="dropdownY"
  [inputScreen]="inputScreen"
  (visibleChange)="handleDropdownVisibilityChange($event)"
  [rowId]="rowId"
  [selectedRowData]="selectedRowData"
  [isStickyRow]="isStickyRow"
  [permission]="permission"
  (optionSelected)="handleDropdownOption($event)"
></app-row-actions>
