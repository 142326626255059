import { Component, OnInit, inject } from '@angular/core';
import { Observable, of  } from 'rxjs';
import { AuthenticateUserState } from './core/store/authenticate-user.state';
import { Store } from '@ngxs/store';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { PageTitleService } from './core/services/page-title.service';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { HeirarchyFilterServiceService } from './shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { catchError } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent implements OnInit {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  title = 'SYRC';
  isShrunk = false;
  defaultLoad = true;
  isAuthenticated$: Observable<boolean | null | undefined> | undefined;
  pageTitle = '';

  toggleShrink(isShrunk: boolean) {
    this.isShrunk = isShrunk;
  }

  constructor(private store: Store, private router: Router, private activatedRoute: ActivatedRoute,private pageTitleService: PageTitleService,private _heirarchyFilterService : HeirarchyFilterServiceService ) {}

  ngOnInit(): void {
    //Fetch the isAutheticated from store
    const isAuthenticated = this.store.selectSnapshot(AuthenticateUserState.getIsAuthenticated);
    this.isAuthenticated$ = this.store.select(AuthenticateUserState.getIsAuthenticated);
    if (isAuthenticated){
      this.defaultLoad = false;
      this.oidcSecurityService
        .checkAuth()
        .pipe(
          catchError((error) => {
            console.log('Error during authentication check:', error);
            // Handle the error here. You can redirect, show a notification, or log it as needed.
            return of({ isAuthenticated: false } as LoginResponse); // Provide a fallback value
          })
        )
        .subscribe((loginResponse: LoginResponse) => {
          console.log('loginResponse-->',loginResponse);
          const { isAuthenticated } = loginResponse;
          if (!isAuthenticated) {
            this.oidcSecurityService.authorize();
          }
      });
    }

    this.pageTitleService.pageTitle$.subscribe(title => {
      this.pageTitle = title;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      mergeMap(route => route.data)
    ).subscribe(data => {
      const title = data['title'];
      this.pageTitleService.setPageTitle(title);
    });

    this.loadData();
  }

  async loadData() {
    await this._heirarchyFilterService.initialize();
  }
}
