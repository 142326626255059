<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="col-auto">
  <h5 class="mtx-text-primary" style="margin-left: 10px; margin-top: 6px">
    <ng-container *ngIf="isFromWhere === 'editReport'; else createTemplate">
      Edit Report
    </ng-container>
    <ng-template #createTemplate> Create Report </ng-template>
  </h5>
</div>

<div>
  <div class="row">
    <div
      class="mt-3 justify-content-end mb-3"
      style="float: right"
      *ngIf="currentStep === 2"
    >
      <app-heirarchy-filter-buttons
        style="float: right"
        (filterSearchButtonClicked)="filterSearchButtonClicked()"
        [isToggleFilterButtonVisible]="false"
      >
      </app-heirarchy-filter-buttons>
      <app-heirarchy-filter-popup></app-heirarchy-filter-popup>
    </div>
  </div>
  <form *ngIf="currentStep === 2" [formGroup]="dateRangeForm">
    <div class="row" style="justify-content: end">
      <div class="col-md-2">
        <div class="mb-3">
          <div class="label-container">
            <label
              for="startDate"
              style="
                margin-bottom: 5px;
                font-weight: 400;
                color: #737373;
                size: 14px;
              "
              >{{ 'ASSET_HISTORY_PAGE.StartDate' | translate }}</label
            >
          </div>
          <kendo-datepicker
            formControlName="startDate"
            q
            placeholder="Select Start Date"
            format="dd-MMM-yyyy"
            [(ngModel)]="startDate"
          ></kendo-datepicker>
          <div *ngIf="dateRangeForm.errors?.['dateRangeInvalid']">
            <span class="small text-danger">{{
              'ASSET_HISTORY_PAGE.START_DATE_ERROR' | translate
            }}</span>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="mb-3">
          <div class="label-container">
            <label
              for="endDate"
              style="
                margin-bottom: 5px;
                font-weight: 400;
                color: #737373;
                size: 14px;
              "
            >
              {{ 'ASSET_HISTORY_PAGE.EndDate' | translate }}
            </label>
          </div>
          <kendo-datepicker
            formControlName="endDate"
            placeholder="Select End Date"
            format="dd-MMM-yyyy"
            [(ngModel)]="endDate"
          ></kendo-datepicker>
        </div>
      </div>
    </div>
  </form>
  <div class="report-form card">
    <form [formGroup]="reportForm">
      <div class="progress-container mb-4 bs-stepper">
        <div class="step-container">
          <span style="color: #737373"
            >{{ 'CREATE_REPORT.STEPPER_LABEL' | translate }}
            {{ currentStep }}/2</span
          >
          <div class="progress-bars">
            <div
              class="progress-bar"
              [ngClass]="{
                'active-bar': currentStep >= 1,
                'inactive-bar': currentStep < 1,
              }"
              role="progressbar"
            ></div>
            <div
              class="progress-bar"
              [ngClass]="{
                'active-bar': currentStep === 2,
                'inactive-bar': currentStep < 2,
              }"
              role="progressbar"
            ></div>
          </div>
        </div>
      </div>
      <div class="bs-stepper-content">
        <!-- Step 1 Content -->
        <div
          id="step-1"
          class="content"
          role="tabpanel"
          aria-labelledby="step-1-trigger"
          *ngIf="currentStep !== 2"
          [attr.aria-selected]="currentStep === 2"
          [ngClass]="{ active: currentStep === 2 }"
        >
          <div class="header-container">
            <div class="line"></div>
            <div>
              <h5 style="font-size: 20px">
                {{ 'CREATE_REPORT.FILL_IN_DETAILS' | translate }}
              </h5>
              <p style="font-size: 15px">{{ reportSubheading }}</p>
            </div>
          </div>
          <div class="card-body-align">
            <div class="mb-3">
              <label
                for="reportTemplate"
                style="
                  margin-bottom: 5px;
                  font-weight: 400;
                  color: #737373;
                  size: 14px;
                "
                >{{ 'CREATE_REPORT.SELECT_REPORT_TEMPLATE' | translate }}</label
              >
              <select
                id="reportTemplate"
                class="form-select"
                formControlName="selectedTemplate"
                (change)="onTemplateChange()"
                [ngStyle]="{
                  color: reportForm.controls['selectedTemplate'].value
                    ? 'black'
                    : '#737373',
                }"
              >
                <option value="" class="placeholder-option">
                  {{ 'CREATE_REPORT.SELECT_REPORT_TEMPLATE' | translate }}
                </option>
                <option
                  *ngFor="let template of templatesFromAPI"
                  [value]="template.id"
                >
                  {{ template.templateName }}
                </option>
              </select>
            </div>

            <table
              class="table table-bordered"
              *ngIf="selectedColumns && selectedColumns.length > 0"
            >
              <thead>
                <tr>
                  <th class="table-bg" *ngFor="let column of selectedColumns">
                    {{ column }}
                  </th>
                </tr>
              </thead>
            </table>

            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <div class="label-container">
                    <label
                      for="reportName"
                      style="
                        margin-bottom: 5px;
                        font-weight: 400;
                        color: #737373;
                        size: 14px;
                      "
                      >{{ 'CREATE_REPORT.REPORT_NAME' | translate }}
                      <span class="asterisk">*</span></label
                    >
                    <div
                      *ngIf="
                        reportForm.get('reportName')?.hasError('required') &&
                        reportForm.get('reportName')?.touched
                      "
                    >
                      <small class="text-danger">{{
                        'CREATE_REPORT.REPORT_NAME_VALIDATION' | translate
                      }}</small>
                    </div>
                  </div>
                  <input
                    type="text"
                    placeholder="Report Name"
                    maxlength="80"
                    formControlName="reportName"
                    id="reportName"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-3">
                  <div class="label-container">
                    <label
                      for="reportType"
                      style="
                        margin-bottom: 5px;
                        font-weight: 400;
                        color: #737373;
                        size: 14px;
                      "
                      >Report Type <span class="asterisk">*</span></label
                    >
                    <div
                      *ngIf="
                        reportForm.get('reportType')?.hasError('required') &&
                        reportForm.get('reportType')?.touched
                      "
                    >
                      <small class="text-danger">{{
                        'CREATE_REPORT.REPORT_TYPE_VALIDATION' | translate
                      }}</small>
                    </div>
                  </div>
                  <select
                    formControlName="reportType"
                    id="reportType"
                    class="form-select"
                    [ngStyle]="{
                      color: reportForm.controls['reportType'].value
                        ? 'black'
                        : '#737373',
                    }"
                  >
                    <option value="">
                      {{ 'CREATE_REPORT.SELECT_REPORT_TYPE' | translate }}
                    </option>
                    <option value="Audit">
                      {{ 'CREATE_REPORT.AUDIT' | translate }}
                    </option>
                    <option value="Performance">
                      {{ 'CREATE_REPORT.PERFORMANCE' | translate }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="mb-3">
              <div class="label-container">
                <label
                  for="reportCreatorName"
                  style="
                    margin-bottom: 5px;
                    font-weight: 400;
                    color: #737373;
                    size: 14px;
                  "
                  >{{ 'CREATE_REPORT.REPORT_CREATOR_NAME' | translate }}
                  <span class="asterisk">*</span></label
                >
                <div
                  *ngIf="
                    reportForm.get('reportCreatorName')?.hasError('required') &&
                    reportForm.get('reportCreatorName')?.touched
                  "
                >
                  <small class="text-danger">{{
                    'CREATE_REPORT.REPORT_CREATOR_NAME_REQUIRED' | translate
                  }}</small>
                </div>
              </div>
              <input
                type="text"
                placeholder="Name"
                maxlength="80"
                formControlName="reportCreatorName"
                id="reportCreatorName"
                class="form-control"
              />
            </div>

            <div class="mb-3">
              <label
                for="reportDescription"
                style="
                  margin-bottom: 5px;
                  font-weight: 400;
                  color: #737373;
                  size: 14px;
                "
                >{{ 'CREATE_REPORT.REPORT_DESCRIPTION' | translate }}</label
              >
              <textarea
                formControlName="reportDescription"
                placeholder="Enter Report Description"
                id="reportDescription"
                class="form-control"
              ></textarea>
            </div>

            <div class="button-container mb-3">
              <div class="export-modal-textandsupportingtext09">
                <span class="export-modal-text21">
                  <span
                    style="
                      margin-bottom: 5px;
                      font-weight: 660;
                      color: #5b5b5b;
                      size: 16px;
                    "
                    >{{ 'CREATE_REPORT.RECURRING_SCHEDULE' | translate }}</span
                  >
                </span>
              </div>
              <label class="switch">
                <input
                  type="checkbox"
                  id="recurringSchedule"
                  formControlName="recurringSchedule"
                  (change)="onToggleChange($event)"
                />
                <span class="slider round"></span>
              </label>
              <div class="export-modal-textandsupportingtext09">
                <span class="export-modal-text21">
                  <span>{{
                    reportForm.get('recurringSchedule')?.value ? 'ON' : 'OFF'
                  }}</span>
                </span>
              </div>
            </div>

            <div *ngIf="reportForm.get('recurringSchedule')?.value">
              <div class="mb-3">
                <div class="label-container">
                  <label
                    for="reportName"
                    style="
                      margin-bottom: 5px;
                      font-weight: 400;
                      color: #737373;
                      size: 14px;
                    "
                    >{{ 'CREATE_REPORT.EMAIL_LIST' | translate
                    }}<span class="asterisk">*</span></label
                  >
                  <span
                    class="text-danger"
                    style="padding-left: 10px"
                    *ngIf="emailError"
                    >{{ emailError }}</span
                  >
                  <div
                    *ngIf="
                      reportForm.get('emailList')?.hasError('required') &&
                      reportForm.get('emailList')?.touched
                    "
                  >
                    <small class="text-danger">{{
                      'CREATE_REPORT.EMAIL_VALIDATION' | translate
                    }}</small>
                  </div>
                </div>
                <div class="email-input">
                  <input
                    type="text"
                    matInput
                    id="emailList"
                    class="form-control"
                    [matChipInputFor]="chipGrid"
                    (matChipInputTokenEnd)="addChip($event)"
                  />
                </div>

                <mat-chip-grid
                  class="email-list"
                  #chipGrid
                  aria-label="Email List"
                >
                  <mat-chip-row
                    *ngFor="let email of emails"
                    [removable]="true"
                    (removed)="removeChip(email)"
                  >
                    {{ email }}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                </mat-chip-grid>
              </div>
              <b
                style="
                  margin-bottom: 5px;
                  font-weight: 660;
                  color: #5b5b5b;
                  size: 16px;
                "
              >
                {{ 'CREATE_REPORT.EXTRA_CASE' | translate }}
              </b>
              <div class="row" style="margin-top: 10px">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label
                      for="repeatEvery"
                      style="
                        margin-bottom: 5px;
                        font-weight: 400;
                        color: #737373;
                        size: 14px;
                      "
                      >{{ 'CREATE_REPORT.REPEAT_EVERY' | translate }}</label
                    >
                    <input
                      type="number"
                      id="repeatEvery"
                      formControlName="repeatEvery"
                      class="form-control"
                      [(ngModel)]="repeatEveryValue"
                      (input)="onRepeatEveryChange($event)"
                      min="0"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="mb-3">
                    <label
                      for="repeatInterval"
                      style="
                        margin-bottom: 5px;
                        font-weight: 400;
                        color: #737373;
                        size: 14px;
                      "
                    >
                      {{ 'CREATE_REPORT.INTERVAL' | translate }}
                    </label>
                    <select
                      id="repeatInterval"
                      class="form-select"
                      formControlName="repeatFrequency"
                    >
                      <option value="Daily">
                        {{ 'CREATE_REPORT.DAILY' | translate }}
                      </option>
                      <option value="Weekly">
                        {{ 'CREATE_REPORT.WEEKLY' | translate }}
                      </option>
                      <option value="Monthly">
                        {{ 'CREATE_REPORT.MONTHLY' | translate }}
                      </option>
                      <option value="Annually">
                        {{ 'CREATE_REPORT.ANNUALLY' | translate }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="mb-3">
                    <label
                      for="repeatTime"
                      style="
                        margin-bottom: 5px;
                        font-weight: 400;
                        color: #737373;
                        size: 14px;
                      "
                      >{{ 'CREATE_REPORT.TIME' | translate }}</label
                    >
                    <ng-container *ngIf="isScheduleOn">
                      <kendo-timepicker
                        id="repeatTime"
                        format="hh:mm a"
                        class="custom-time-picker"
                        [(ngModel)]="scheduleTime"
                        formControlName="repeatTime"
                        #timepicker
                      >
                      </kendo-timepicker>
                    </ng-container>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <span
                  style="
                    margin-bottom: 5px;
                    font-weight: 660;
                    color: #5b5b5b;
                    size: 16px;
                  "
                  >{{ 'CREATE_REPORT.REPORT_FORMAT' | translate }}</span
                >
                <div class="checkboxgroup">
                  <div
                    class="button-container"
                    (click)="selection('pdf')"
                    (keyup)="selection('pdf')"
                  >
                    <div
                      class="button"
                      [ngClass]="{ selected: selectedFormats.includes('pdf') }"
                    >
                      <div class="featuredicon1">
                        <div class="layerstwo01">
                          <div class="layerstwo011"></div>
                        </div>
                      </div>
                      <div class="textandsupportingtext02">
                        <div class="textandsubtext">
                          <div class="text06">
                            <span>PDF</span>
                            <div
                              class="indicator"
                              [ngClass]="{
                                checkmark: selectedFormats.includes('pdf'),
                              }"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="button-container"
                    (click)="selection('csv')"
                    (keyup)="selection('csv')"
                  >
                    <div
                      class="button"
                      [ngClass]="{ selected: selectedFormats.includes('csv') }"
                    >
                      <div class="featuredicon1">
                        <div class="layerstwo012">
                          <div></div>
                        </div>
                      </div>
                      <div class="textandsupportingtext02">
                        <div class="textandsubtext">
                          <div class="text06">
                            <span>CSV</span>
                            <div
                              class="indicator"
                              [ngClass]="{
                                checkmark: selectedFormats.includes('csv'),
                              }"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="button-container"
                    (click)="selection('excel')"
                    (keyup)="selection('excel')"
                  >
                    <div
                      class="button"
                      [ngClass]="{
                        selected: selectedFormats.includes('excel'),
                      }"
                    >
                      <div class="featuredicon3">
                        <div class="layerstwo013">
                          <div></div>
                        </div>
                      </div>
                      <div class="textandsupportingtext02">
                        <div class="textandsubtext">
                          <div class="text06">
                            <span>Excel</span>
                            <div
                              class="indicator"
                              [ngClass]="{
                                checkmark: selectedFormats.includes('excel'),
                              }"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mb-3">
                <label
                  for="summary"
                  style="
                    margin-bottom: 5px;
                    font-weight: 400;
                    color: #737373;
                    size: 14px;
                  "
                  >{{ 'CREATE_REPORT.SUMMARY' | translate }}</label
                >
                <textarea
                  id="summary"
                  placeholder="Enter summary"
                  class="form-control"
                  formControlName="summary"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-3">
                <button
                  class="btn btn-secondary mtx-btn-secondary mtx-btn-custom"
                  (click)="cancel()"
                  type="submit"
                >
                  {{ 'CREATE_REPORT.CANCEL' | translate }}
                </button>
              </div>
              <div class="col-md-3">
                <button
                  class="btn btn-primary mtx-btn-custom"
                  type="button"
                  (click)="onSaveAndContinue()"
                >
                  {{ 'CREATE_REPORT.SAVE_AND_CONTINUE' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Step 2 Content -->
        <div
          id="step-2"
          class="content"
          role="tabpanel"
          aria-labelledby="step-2-trigger"
          *ngIf="currentStep === 2"
        >
          <div class="card-body-align">
            <!-- Report Format Section -->
            <div class="mb-4">
              <div style="display: flex">
                <label
                  for="reportFormat"
                  class="form-label col-2"
                  style="font-weight: 600"
                  >{{ 'CREATE_REPORT.REPORT_FORMAT' | translate }}</label
                >
                <label
                  for="reportFormat"
                  *ngIf="isTemplateSelected"
                  class="form-label text-end col-md-10"
                  style="color: #3498db"
                  >{{ templateName }}</label
                >
              </div>
              <div id="reportFormat" *ngIf="!isTemplateSelected">
                <div style="display: flex">
                  <button
                    class="btn btn-light dropdown-toggle btn-template"
                    type="button"
                    id="dropdownMenuButton"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span style="color: #3498db; font-weight: 400"
                      >Select Report Columns</span
                    >
                  </button>
                  <ul
                    class="dropdown-menu p-3"
                    style="width: 258px"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <li *ngFor="let column of getFilteredColumns()">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          [id]="column"
                          [checked]="selectedItems[column]"
                          (change)="onCheckboxChange(column, $event)"
                        />
                        <label class="form-check-label" [for]="column">
                          {{ getColumnDisplayName(column) }}</label
                        >
                      </div>
                    </li>

                    <!-- Action Buttons -->
                    <li class="mt-3 text-center">
                      <button
                        class="btn reset-button"
                        (click)="onReset()"
                      >
                        Reset
                      </button>
                      <button
                        class="btn save-button "
                        (click)="onSave()"
                      >
                        Save
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <!-- Report Format Table -->
            <div
              class="card mt-3"
              style="
                border-color: #9adaf8;
                border-radius: 2px;
                overflow-x: auto;
                min-height: 600px;
              "
            >
              <div
                *ngIf="showGrid"
                class="kendo-grid-container"
                style="width: auto"
              >
                <!-- Ensure no overflow is set here -->
                <!-- <kendo-grid
                  [kendoGridBinding]="gridData"
                  style="height: 600px"
                  (filterChange)="onFilterChange($event)"
                  [filterable]="'menu'"
                  [reorderable]="true"
                >
                  <kendo-grid-column
                    *ngFor="let column of displayedColumns"
                    [field]="column.field"
                    [title]="column.field"
                    [filter]="column.type === 'date' ? 'date' : 'text'"
                    [filterable]="true"
                  >
                    <ng-template kendoGridHeaderTemplate>
                      {{ column.field }}
                      <button
                        *ngIf="!selectedTemplate"
                        mat-icon-button
                        (click)="removeColumn(column.field)"
                      >
                        <mat-icon style="margin-bottom: 3px">close</mat-icon>
                      </button>
                    </ng-template>

                    <ng-template kendoGridCellTemplate let-dataItem>
                      <span *ngIf="column.type === 'date'; else normalField">
                        {{ dataItem[column.field] | customDate }}
                      </span>
                      <ng-template #normalField>
                        {{ dataItem[column.field] }}
                      </ng-template>
                    </ng-template>

                    <ng-template
                      *ngIf="column.field === 'Compliance Status'"
                      kendoGridFilterMenuTemplate
                      let-filter
                      let-column="column"
                      let-filterService="filterService"
                    >
                      <div #customFilterContainer>
                        <app-custom-grid-filter></app-custom-grid-filter>
                      </div>
                    </ng-template>
                  </kendo-grid-column>
                </kendo-grid> -->
                <kendo-grid [data]="gridData" [height]="600">
                  <kendo-grid-column
                    *ngFor="let column of selectedColumnsKeyValuePair"
                    [field]="column.field"
                    [title]="column.title"
                  >
                  </kendo-grid-column>
                </kendo-grid>
              </div>
            </div>
          </div>

          <!-- Card Footer with Updated Buttons -->
          <div class="card-footer" style="height: 90px">
            <div class="row">
              <div class="col-md-4"></div>
              <div class="col-md-8 text-end">
                <button
                  class="circular-button"
                  style="margin-right: 10px; margin-top: 10px"
                  (click)="back()"
                >
                  <mat-icon
                    style="
                      margin-right: 10px;
                      justify-content: center;
                      text-align: center;
                    "
                    >keyboard_arrow_left</mat-icon
                  >
                </button>
                <button
                  type="button"
                  class="btn btn-secondary mtx-btn-secondary"
                  style="
                    width: 150px;
                    margin-right: 10px;
                    margin-top: -10px;
                    font-weight: 500;
                  "
                  (click)="cancelOfStep2()"
                >
                  {{ 'CREATE_REPORT.CANCEL' | translate }}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary mtx-btn-secondary"
                  style="
                    width: 150px;
                    margin-right: 10px;
                    margin-top: -10px;
                    font-weight: 500;
                  "
                  (click)="saveReport()"
                >
                  {{ 'CREATE_REPORT.SAVE_REPORT' | translate }}
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  style="
                    width: 150px;
                    margin-right: 10px;
                    margin-top: -10px;
                    font-weight: 500;
                    background-color: #3498db;
                  "
                  (click)="saveAndRun()"
                >
                  {{ 'CREATE_REPORT.SAVE_AND_RUN' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
