import {
  Component,
  ElementRef,
  Inject,
  ViewChild,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-file-upload-popup',
  templateUrl: './file-upload-popup.component.html',
  styleUrl: './file-upload-popup.component.css',
})
export class FileUploadPopupComponent {
  surveyName?: string;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  files: File[] = [];

  constructor(
    public dialogRef: MatDialogRef<FileUploadPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { surveyName: string },
  ) {

    this.surveyName = data.surveyName;
  }
  triggerFileInput(): void {
    this.fileInput.nativeElement.click();
  }
  onFileDropped(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files) {
      this.addFiles(event.dataTransfer.files);
    }
  }

  onFileInputChange(event: any) {
    const files: FileList = event.target.files;
    this.addFiles(files);
  }

  addFiles(files: FileList) {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < files.length; i++) {
      this.files.push(files[i]);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
  }

  showDialog = true;

  openDialog() {
    this.showDialog = true;
  }

  closeDialog() {
    this.showDialog = false;
    this.dialogRef.close();
  }

  onUpload() {
    // Handle the file upload logic here
    console.log('Upload button clicked!');
    this.showDialog = false;
    this.dialogRef.close();
  }
}
