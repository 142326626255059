<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container-fluid">
  <div class="row align-items-center justify-content-between">
    <div class="col-auto">
      <h5 class="mtx-text-primary" style="margin-left: -12px; margin-top: 22px">
        Data Processing
      </h5>
    </div>
    <div class="col-auto ml-auto">
      <div
        class="d-flex flex-wrap align-items-center gap-2 justify-content-end"
      >
        <div style="width: 330px; margin-right: -10px">
          <app-search [placeholder]="searchPlaceholder"></app-search>
        </div>
        <div *ngIf="mapVisibilityBtn">
          <button
            class="btn mtx-btn-primary map-btn"
            (click)="mapVisibility(1)"
          ></button>
        </div>
        <div *ngIf="chartVisibilityBtn">
          <button
            class="btn mtx-btn-primary graph"
            (click)="chartVisibility(1)"
          ></button>
        </div>
      </div>
    </div>
  </div>

  <div class="filter-container">
    <button
      kendoButton
      #Type
      class="filterbutton filterbuttonWidth"
      [ngClass]="{ 'active-filter': isSelectedTypeFilter }"
      style="border-top-left-radius: 7px; border-bottom-left-radius: 7px"
      data-id="1"
      (click)="onButtonClick(1)"
    >
      Type
    </button>
    <kendo-popup
      *ngIf="isTypeFilterOpen"
      (open)="(isTypeFilterOpen)"
      [anchor]="Type"
      (anchorViewportLeave)="onClose()"
      class="kendo-popup HeirarchyFilter"
    >
      <div class="popupcontainer popupcontainerWidth">
        <kendo-listview [data]="types" class="list-container">
          <ng-template kendoListViewItemTemplate let-dataItem>
            <div class="item-container">
              <kendo-checkbox
                #checkedState
                [checkedState]="dataItem.checked"
                [(ngModel)]="dataItem.checked"
                (ngModelChange)="onCheckboxChange(dataItem, 1)"
              ></kendo-checkbox>
              <label>{{ dataItem.label }}</label>
            </div>
          </ng-template>
        </kendo-listview>
        <div class="action-buttons-div">
          <button
            kendoButton
            class="reset-button"
            [disabled]="IsSearchResultNull"
            (click)="OnResetListClicked(1)"
          >
            Reset
          </button>
          <button
            kendoButton
            class="save-button"
            (click)="OnSaveListClicked(1)"
          >
            Save
          </button>
        </div>
      </div>
    </kendo-popup>

    <button
      kendoButton
      #Status
      class="filterbutton filterbuttonWidth"
      [ngClass]="{ 'active-filter': isSelectedStatusFilter }"
      data-id="2"
      (click)="onButtonClick(2)"
    >
      Status
    </button>
    <kendo-popup
      *ngIf="isStatusFilterOpen"
      (open)="(isStatusFilterOpen)"
      [anchor]="Status"
      (anchorViewportLeave)="onClose()"
      class="kendo-popup HeirarchyFilter"
    >
      <div class="popupcontainer popupcontainerWidth">
        <kendo-listview [data]="status" class="list-container">
          <ng-template kendoListViewItemTemplate let-dataItem>
            <div class="item-container">
              <kendo-checkbox
                #checkedState
                [checkedState]="dataItem.checked"
                [(ngModel)]="dataItem.checked"
                (ngModelChange)="onCheckboxChange(dataItem, 2)"
              ></kendo-checkbox>
              <label>{{ dataItem.label }}</label>
            </div>
          </ng-template>
        </kendo-listview>
        <div class="action-buttons-div">
          <button
            kendoButton
            [ngClass]="{
              'disabled-button': IsSearchResultNull,
              'reset-button': !IsSearchResultNull,
            }"
            [disabled]="IsSearchResultNull"
            (click)="OnResetListClicked(2)"
          >
            Reset
          </button>
          <button
            kendoButton
            class="save-button"
            (click)="OnSaveListClicked(2)"
          >
            Save
          </button>
        </div>
      </div>
    </kendo-popup>
    <button
      kendoButton
      #Survey
      class="filterbutton filterbuttonWidthSurvey"
      [ngClass]="{ 'active-filter-Survey': isSelectedSurveyFilter }"
      data-id="3"
      (click)="onButtonClick(3)"
    >
      Survey
    </button>
    <kendo-popup
      *ngIf="isSurveyFilterOpen"
      (open)="(isSurveyFilterOpen)"
      [anchor]="Survey"
      (anchorViewportLeave)="onClose()"
      class="kendo-popup HeirarchyFilter"
    >
      <div class="popupcontainer popupcontainerWidthSurvey">
        <div class="search-bar-Popup">
          <kendo-svgicon
            [icon]="svgSearch"
            style="margin-left: 2px; color: gray"
          ></kendo-svgicon>
          <input
            type="search"
            class="search-input"
            placeholder="Search"
            [(ngModel)]="_searchTerm"
            (ngModelChange)="filterData()"
          />
        </div>
        <kendo-listview [data]="survey" class="list-container">
          <ng-template kendoListViewItemTemplate let-dataItem>
            <div class="item-container">
              <kendo-checkbox
                #checkedState
                [checkedState]="dataItem.checked"
                [(ngModel)]="dataItem.checked"
                (ngModelChange)="onCheckboxChange(dataItem, 3)"
              ></kendo-checkbox>
              <label>{{ dataItem.label }}</label>
            </div>
          </ng-template>
        </kendo-listview>
        <div *ngIf="this.survey.length == 0">
          <label style="text-align: center; margin: 15px; color: gray"
            >No available values based on current selection.</label
          >
        </div>
        <div class="action-buttons-div">
          <button
            kendoButton
            class="reset-button"
            [disabled]="IsSearchResultNull"
            (click)="OnResetListClicked(3)"
          >
            Reset
          </button>
          <button
            kendoButton
            class="save-button"
            [disabled]="IsSearchResultNull"
            (click)="OnSaveListClicked(3)"
          >
            Save
          </button>
        </div>
      </div>
    </kendo-popup>
    <button
      kendoButton
      #Version
      class="filterbutton filterbuttonWidth"
      [ngClass]="{ 'active-filter': isSelectedVersionFilter }"
      data-id="4"
      (click)="onButtonClick(4)"
    >
      Version
    </button>
    <kendo-popup
      *ngIf="isVersionFilterOpen"
      (open)="(isVersionFilterOpen)"
      [anchor]="Version"
      (anchorViewportLeave)="onClose()"
      class="kendo-popup HeirarchyFilter"
    >
      <div class="popupcontainer popupcontainerWidth">
        <kendo-listview [data]="version" class="list-container">
          <ng-template kendoListViewItemTemplate let-dataItem>
            <div class="item-container">
              <kendo-checkbox
                #checkedState
                [checkedState]="dataItem.checked"
                [(ngModel)]="dataItem.checked"
                (ngModelChange)="onCheckboxChange(dataItem, 4)"
              ></kendo-checkbox>
              <label>{{ dataItem.label }}</label>
            </div>
          </ng-template>
        </kendo-listview>
        <div class="action-buttons-div">
          <button
            kendoButton
            class="reset-button"
            (click)="OnResetListClicked(4)"
          >
            Reset
          </button>
          <button
            kendoButton
            class="save-button"
            (click)="OnSaveListClicked(4)"
          >
            Save
          </button>
        </div>
      </div>
    </kendo-popup>

    <button
      kendoButton
      [ngClass]="{
        enableclearfiltersbutton: isAnyFilterSelected,
        disableclearfiltersbutton: !isAnyFilterSelected,
      }"
      #clearfiltersbutton
      (click)="OnClearFilterButtonClick()"
    ></button>
    <button
      kendoButton
      (click)="onFilterSearchButtonClick()"
      class="searchfiltersbutton"
      style="border-top-right-radius: 7px; border-bottom-right-radius: 7px"
      [ngClass]="{
        enablesearchfiltersbutton: isSearchBarEnabled,
        disablesearchfiltersbutton: !isSearchBarEnabled,
      }"
    ></button>
  </div>
</div>

<div class="card" style="margin-top: 70px">
  <div *ngIf="!isSurveyDetails">
    <div
      class="d-flex align-items-center"
      style="margin-top: 20px; margin-bottom: 20px; padding-left: 20px"
    >
      <div class="ellipsis" style="padding-bottom: 10px">
        <kendo-icon
          class="k-icon k-icon-inline text-muted me-2 elipse"
          name="more-vertical"
          style="font-size: 18px"
          (click)="openDropdownMenu($event)"
        ></kendo-icon>
      </div>
      <div class="d-flex" (click)="showSurveyDetails('down')">
        <h6 style="font-size: 20px; color: #6c757d">Survey Details of</h6>
        <h6
          class="card-title mb-0"
          style="
            font-size: 20px;
            font-weight: 600;
            color: #3498db;
            padding-left: 10px;
          "
        >
          {{ selectedSurveyValue }}
        </h6>
        <kendo-icon
          style="
            padding: 15px;
            font-size: 20px;
            color: #3498db;
            cursor: pointer;
          "
          name="arrow-chevron-down"
        ></kendo-icon>
      </div>
    </div>
  </div>

  <div *ngIf="isSurveyDetails" class="card-body" style="padding-bottom: 35px">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <div class="d-flex align-items-center">
        <div class="ellipsis" style="padding-bottom: 10px">
          <kendo-icon
            class="k-icon k-icon-inline text-muted me-2 elipse"
            name="more-vertical"
            style="font-size: 18px"
            (click)="openDropdownMenu($event)"
          ></kendo-icon>
        </div>
        <div class="d-flex" (click)="showSurveyDetails('up')">
          <h6 class="card-title mb-0" style="font-size: 20px; font-weight: 600">
            {{ selectedSurveyValue }}
          </h6>
          <kendo-icon
            style="padding: 15px; font-size: 20px; cursor: pointer"
            name="arrow-chevron-up"
          ></kendo-icon>
        </div>
      </div>
    </div>
    <div class="row content-align">
      <div class="row">
        <div class="equal-column">
          <span class="text-muted">TECHNICIAN</span><br />
          <strong>{{ selectedSurveyData.technician }}</strong>
        </div>
        <div class="equal-column">
          <span class="text-muted">DATE OF UPLOAD </span><br />
          <strong>{{ selectedSurveyData.dateOfUpload | customDate }}</strong>
        </div>
        <div class="equal-column">
          <span class="text-muted">SURVEY TYPE </span><br />
          <strong>{{ selectedSurveyData.surveyType }}</strong>
        </div>
        <div class="equal-column">
          <span class="text-muted"> SURVEY PRIORITY</span><br />
          <strong> {{ selectedSurveyData.surveyPriority }}</strong>
        </div>
        <div class="equal-column">
          <span class="text-muted">VERSION</span><br />
          <strong> {{ selectedSurveyData.version }}</strong>
        </div>
        <div class="equal-column">
          <span class="text-muted">DATA COLLECTION TYPE</span><br />
          <strong>{{ selectedSurveyData.dataCollectionType }}</strong>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showMapDiv" class="mt-3">
    <div class="map-container" style="padding: 20px">
      <div *ngIf="!mapVisibilityBtn" class="dp-close-container">
        <button class="btn dp-close" (click)="mapVisibility(2)"></button>
      </div>
      <!-- Map Wrapper -->
      <div class="map" #map></div>

      <!-- Inline Legends Row -->
      <div class="legend-container" [ngClass]="{ 'legend-container-1': !showChartDiv }">
        <div class="legend-item">
          <span class="legends-text">Legends: </span>
        </div>
        <div class="legend-item">
          <img
            class="legend-icon"
            src="assets/images/Compliant.svg"
            alt="location"
          />
          <span class="legend-text">Compliant</span>
        </div>
        <div class="legend-item">
          <img
            class="legend-icon"
            src="assets/images/Exception.svg"
            alt="location"
          />
          <span class="legend-text">Exception</span>
        </div>
        <div class="legend-item">
          <img
            class="legend-icon"
            src="assets/images/Delinquent.svg"
            alt="location"
          />
          <span class="legend-text">Delinquent</span>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showChartDiv" class="mt-3" style="padding: 20px">
    <div class="charts" >
      <div *ngIf="!chartVisibilityBtn" class="dp-close-chart-container">
        <button class="btn dp-close" (click)="chartVisibility(2)"></button>
      </div>
      <div class="row">
        <div id="chart-container" class="chart-container"></div>
      </div>
    </div>
  </div>

  <app-row-actions
    class="row-action"
    [visible]="dropdownVisible"
    [width]="ellipsisWidth"
    [positionX]="dropdownX"
    [positionY]="dropdownY"
    [inputScreen]="inputScreen"
    [permission]="permission"
    (optionSelected)="handleDropdownOption($event)"
    (visibleChange)="handleDropdownVisibilityChange($event)"
  ></app-row-actions>
</div>
