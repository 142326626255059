<kendo-dialog *ngIf="isDialogOpen" (close)="onCancel()">
  <div class="calendar-icon"></div>
  <div class="button-close-x1">
    <span class="x-close-icon1" (click)="closePopup()"></span>
  </div>

  <!-- Default Selection -->
  <div *ngIf="defaultFrequency">
    <div class="form-check mb-3">
      <input type="checkbox" class="form-check-input" name="frequencyOption" id="defaultOption"
        [checked]="defaultChecked" (change)="onOptionChange()" />
      <label class="form-check-label" for="defaultOption">
        Choose Default -
        <b>{{defaultFrequency}}</b>
      </label>
    </div>
  </div>

  <div class="header-css mt-3">
    <span *ngIf="defaultFrequency">Or</span> Set Custom Frequency
  </div>
  <p class="sub-header-css">Set custom Measurement frequency</p>

  <div class="container">
    <div class="row">
      <div class="col-md-6">
        <input type="number" class="form-control" placeholder="Number" id="customNumber1" [disabled]="defaultChecked"
          [(ngModel)]="numberOfOccurrences1" min="1" max="31" (input)="validateInput($event,'occurrences1')"
          (keydown)="preventInvalidKeys($event)" />
      </div>
      <div class="col-md-6">
        <kendo-dropdownlist [data]="repeatOptions" [(ngModel)]="duration1" [disabled]="defaultChecked"
          [defaultItem]="'Select Duration'">
        </kendo-dropdownlist>
      </div>
    </div>
    <div class="my-3">
      <h6>Not to Exceed</h6>
    </div>

    <div class="row mb-3">
      <div class="col-md-6">
        <input type="number" class="form-control" id="customNumber2" placeholder="Number" [(ngModel)]="numberOfOccurrences2" min="1"
          [disabled]="defaultChecked" (input)="validateInput($event,'occurrences2')"
          (keydown)="preventInvalidKeys($event)" />
      </div>
      <div class="col-md-6">
        <kendo-dropdownlist [data]="repeatOptions" [(ngModel)]="duration2" [disabled]="defaultChecked"
          [defaultItem]="'Select Duration'">
        </kendo-dropdownlist>
      </div>
    </div>

    <!-- <div class="row mb-3">
      <div class="col-12">
        <p class="text-muted">
          <strong>
          Occurs every {{ repeatInterval }}
          {{ selectedRepeatOption.toLowerCase() }} starting from
          {{ startDate | date: 'dd-MM-yyyy' }}.
        </strong>
        </p>
      </div>
    </div> -->
  </div>
  <kendo-dialog-actions>
    <button kendoButton (click)="onSave()" class="btn mtx-btn-primary btn-padding" [primary]="true">
      Save
    </button>
    <button kendoButton (click)="closePopup()" class="btn mtx-btn-secondary btn-padding">
      Cancel
    </button>
  </kendo-dialog-actions>
</kendo-dialog>