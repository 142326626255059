import { Injectable } from '@angular/core';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import {
  DataProcessingMapData,
  DataProcessingMapInput,
  GetDataProcessingMapDataQuery,
  GetDataProcessingMapDataQueryVariables,
  GetSurveyDetailsBySurveyIdAndVersionQuery,
  GetSurveyDetailsBySurveyIdAndVersionQueryVariables,
  GetSurveyDetailsByTypeAndStatusQuery,
  GetSurveyDetailsByTypeAndStatusQueryVariables,
  SurveyDetailsConnection,
  surveyDetailsIdInput,
  SurveyDetailsInput,
  SurveyDetailsResponse,
} from '../../../awsAppSync/API';
import {
  getDataProcessingMapData,
  getSurveyDetailsBySurveyIdAndVersion,
  getSurveyDetailsByTypeAndStatus,
} from '../../../awsAppSync/graphql/queries';
import { GENERIC_FAILED_ERROR } from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class DataProcessingService {
  selectedRowData: any;
  constructor(private awsService: BaseAWSRequestService) {}

  async getDataProcessingMapData(
    input: DataProcessingMapInput,
  ): Promise<(DataProcessingMapData | null)[] | null> {
    try {
      const variables: GetDataProcessingMapDataQueryVariables = {
        input,
      };
      const result =
        await this.awsService.executeGetQuery<GetDataProcessingMapDataQuery>(
          getDataProcessingMapData,
          variables,
        );
      return result.getDataProcessingMapData ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  async getSurveyDetailsByTypeAndStatus(
    input: SurveyDetailsInput,
  ): Promise<SurveyDetailsConnection | null> {
    try {
      const variables: GetSurveyDetailsByTypeAndStatusQueryVariables = {
        input,
      };
      const result =
        await this.awsService.executeGetQuery<GetSurveyDetailsByTypeAndStatusQuery>(
          getSurveyDetailsByTypeAndStatus,
          variables,
        );
      return result.getSurveyDetailsByTypeAndStatus ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  async getSurveyDetailsBySurveyIdAndVersion(
    input: surveyDetailsIdInput,
  ): Promise<SurveyDetailsResponse | null> {
    try {
      const variables: GetSurveyDetailsBySurveyIdAndVersionQueryVariables = {
        input,
      };
      const result =
        await this.awsService.executeGetQuery<GetSurveyDetailsBySurveyIdAndVersionQuery>(
          getSurveyDetailsBySurveyIdAndVersion,
          variables,
        );
      return result.getSurveyDetailsBySurveyIdAndVersion ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }
}
