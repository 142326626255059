/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createTemplate = /* GraphQL */ `mutation CreateTemplate($input: CreateTemplateInput!) {
  createTemplate(input: $input) {
    id
    entityType
    userId
    corViewUserID
    templateName
    templateConfiguration
    createdDate
    updatedDate
    isDeleted
    isActive
    createdBy
    updatedBy
    templateType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTemplateMutationVariables,
  APITypes.CreateTemplateMutation
>;
export const updateTemplate = /* GraphQL */ `mutation UpdateTemplate($input: UpdateTemplateInput!) {
  updateTemplate(input: $input) {
    id
    entityType
    userId
    corViewUserID
    templateName
    templateConfiguration
    createdDate
    updatedDate
    isDeleted
    isActive
    createdBy
    updatedBy
    templateType
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTemplateMutationVariables,
  APITypes.UpdateTemplateMutation
>;
export const deleteTemplate = /* GraphQL */ `mutation DeleteTemplate(
  $userId: String!
  $templateName: String!
  $templateType: templateType!
) {
  deleteTemplate(
    userId: $userId
    templateName: $templateName
    templateType: $templateType
  )
}
` as GeneratedMutation<
  APITypes.DeleteTemplateMutationVariables,
  APITypes.DeleteTemplateMutation
>;
export const createAsset = /* GraphQL */ `mutation CreateAsset($input: AssetCreateInput!) {
  createAsset(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssetMutationVariables,
  APITypes.CreateAssetMutation
>;
export const updateAsset = /* GraphQL */ `mutation UpdateAsset($input: AssetUpdateInput!) {
  updateAsset(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssetMutationVariables,
  APITypes.UpdateAssetMutation
>;
export const initializeFileUpload = /* GraphQL */ `mutation InitializeFileUpload($input: FileUploadInput!) {
  initializeFileUpload(input: $input) {
    contentType
    fileURL
    presignedURL
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.InitializeFileUploadMutationVariables,
  APITypes.InitializeFileUploadMutation
>;
export const bulkImport = /* GraphQL */ `mutation BulkImport($input: importInput!) {
  bulkImport(input: $input) {
    status
    fileURL
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.BulkImportMutationVariables,
  APITypes.BulkImportMutation
>;
export const deleteAssetType = /* GraphQL */ `mutation DeleteAssetType($primaryCompanyId: ID!, $assetTypeId: ID!) {
  deleteAssetType(
    primaryCompanyId: $primaryCompanyId
    assetTypeId: $assetTypeId
  )
}
` as GeneratedMutation<
  APITypes.DeleteAssetTypeMutationVariables,
  APITypes.DeleteAssetTypeMutation
>;
export const deleteMeasurementCriteria = /* GraphQL */ `mutation DeleteMeasurementCriteria(
  $primaryCompanyId: ID!
  $assetTypeId: ID!
  $measurementCriteriaId: ID!
) {
  deleteMeasurementCriteria(
    primaryCompanyId: $primaryCompanyId
    assetTypeId: $assetTypeId
    measurementCriteriaId: $measurementCriteriaId
  )
}
` as GeneratedMutation<
  APITypes.DeleteMeasurementCriteriaMutationVariables,
  APITypes.DeleteMeasurementCriteriaMutation
>;
export const createPrimaryCompany = /* GraphQL */ `mutation CreatePrimaryCompany($input: PrimaryCompanyCreateInput!) {
  createPrimaryCompany(input: $input) {
    item {
      id
      entityType
      createdBy
      createdDate
      updatedBy
      updatedDate
      isDeleted
      primaryCompany
      imageURL
      phoneNumber
      officeNumber
      country
      state
      zipCode
      city
      address
      timeZoneId
      timeZone
      setupStatus
      totalSetup
      corViewCompanyId
      __typename
    }
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePrimaryCompanyMutationVariables,
  APITypes.CreatePrimaryCompanyMutation
>;
export const updatePrimaryCompany = /* GraphQL */ `mutation UpdatePrimaryCompany($input: PrimaryCompanyUpdateInput!) {
  updatePrimaryCompany(input: $input) {
    item {
      id
      entityType
      createdBy
      createdDate
      updatedBy
      updatedDate
      isDeleted
      primaryCompany
      imageURL
      phoneNumber
      officeNumber
      country
      state
      zipCode
      city
      address
      timeZoneId
      timeZone
      setupStatus
      totalSetup
      corViewCompanyId
      __typename
    }
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePrimaryCompanyMutationVariables,
  APITypes.UpdatePrimaryCompanyMutation
>;
export const createAllLevelNames = /* GraphQL */ `mutation CreateAllLevelNames($input: LevelNameCreateInput!) {
  createAllLevelNames(input: $input) {
    item {
      primaryCompanyId
      level1Name
      level2Name
      level3Name
      level4Name
      __typename
    }
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAllLevelNamesMutationVariables,
  APITypes.CreateAllLevelNamesMutation
>;
export const updateAllLevelNames = /* GraphQL */ `mutation UpdateAllLevelNames($input: LevelNameUpdateInput!) {
  updateAllLevelNames(input: $input) {
    item {
      primaryCompanyId
      level1Name
      level2Name
      level3Name
      level4Name
      __typename
    }
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAllLevelNamesMutationVariables,
  APITypes.UpdateAllLevelNamesMutation
>;
export const createAllLevelValuesForPrimaryCompany = /* GraphQL */ `mutation CreateAllLevelValuesForPrimaryCompany($input: LevelValueCreateInput!) {
  createAllLevelValuesForPrimaryCompany(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAllLevelValuesForPrimaryCompanyMutationVariables,
  APITypes.CreateAllLevelValuesForPrimaryCompanyMutation
>;
export const createAssetTypeTemplateForPrimaryCompany = /* GraphQL */ `mutation CreateAssetTypeTemplateForPrimaryCompany(
  $input: CreateAssetTypeTemplateInput!
) {
  createAssetTypeTemplateForPrimaryCompany(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAssetTypeTemplateForPrimaryCompanyMutationVariables,
  APITypes.CreateAssetTypeTemplateForPrimaryCompanyMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser($input: UserCreateInput!) {
  createUser(input: $input) {
    coreViewUserId
    id
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser($input: UserUpdateInput!) {
  updateUser(input: $input) {
    coreViewUserId
    id
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const updateAllLevelValuesForPrimaryCompany = /* GraphQL */ `mutation UpdateAllLevelValuesForPrimaryCompany($input: LevelValueUpdateInput!) {
  updateAllLevelValuesForPrimaryCompany(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAllLevelValuesForPrimaryCompanyMutationVariables,
  APITypes.UpdateAllLevelValuesForPrimaryCompanyMutation
>;
export const updateAssetTypeTemplateForPrimaryCompany = /* GraphQL */ `mutation UpdateAssetTypeTemplateForPrimaryCompany(
  $input: UpdateAssetTypeTemplateInput!
) {
  updateAssetTypeTemplateForPrimaryCompany(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAssetTypeTemplateForPrimaryCompanyMutationVariables,
  APITypes.UpdateAssetTypeTemplateForPrimaryCompanyMutation
>;
export const updateException = /* GraphQL */ `mutation UpdateException($input: UpdateExceptionInput!) {
  updateException(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateExceptionMutationVariables,
  APITypes.UpdateExceptionMutation
>;
export const deleteLevelValueForPrimaryCompany = /* GraphQL */ `mutation DeleteLevelValueForPrimaryCompany($input: LevelValueDeleteInput!) {
  deleteLevelValueForPrimaryCompany(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteLevelValueForPrimaryCompanyMutationVariables,
  APITypes.DeleteLevelValueForPrimaryCompanyMutation
>;
export const initializeDataExport = /* GraphQL */ `mutation InitializeDataExport($input: DataExportInput!) {
  initializeDataExport(input: $input) {
    exportMessage
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.InitializeDataExportMutationVariables,
  APITypes.InitializeDataExportMutation
>;
export const updateDataExportStatus = /* GraphQL */ `mutation UpdateDataExportStatus($input: ExportStatusInput!) {
  updateDataExportStatus(input: $input) {
    userId
    status
    exportMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDataExportStatusMutationVariables,
  APITypes.UpdateDataExportStatusMutation
>;
export const updateBulkImportStatus = /* GraphQL */ `mutation UpdateBulkImportStatus($input: ImportStatusInput!) {
  updateBulkImportStatus(input: $input) {
    userId
    status
    fileURL
    importMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBulkImportStatusMutationVariables,
  APITypes.UpdateBulkImportStatusMutation
>;
export const createSurvey = /* GraphQL */ `mutation CreateSurvey($input: SurveyCreateInput!) {
  createSurvey(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSurveyMutationVariables,
  APITypes.CreateSurveyMutation
>;
export const updateSurvey = /* GraphQL */ `mutation UpdateSurvey($input: SurveyUpdateInput!) {
  updateSurvey(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSurveyMutationVariables,
  APITypes.UpdateSurveyMutation
>;
export const createSurveyRoute = /* GraphQL */ `mutation CreateSurveyRoute($input: SurveyRouteCreateInput!) {
  createSurveyRoute(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSurveyRouteMutationVariables,
  APITypes.CreateSurveyRouteMutation
>;
export const updateSurveyRoute = /* GraphQL */ `mutation UpdateSurveyRoute($input: SurveyRouteUpdateInput!) {
  updateSurveyRoute(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSurveyRouteMutationVariables,
  APITypes.UpdateSurveyRouteMutation
>;
export const createReportTemplate = /* GraphQL */ `mutation CreateReportTemplate($input: ReportTemplateCreateInput!) {
  createReportTemplate(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportTemplateMutationVariables,
  APITypes.CreateReportTemplateMutation
>;
export const updateReportTemplate = /* GraphQL */ `mutation UpdateReportTemplate($input: ReportTemplateUpdateInput!) {
  updateReportTemplate(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportTemplateMutationVariables,
  APITypes.UpdateReportTemplateMutation
>;
export const createReport = /* GraphQL */ `mutation CreateReport($input: ReportCreateInput!) {
  createReport(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    report {
      pk
      sk
      id
      reportTemplateId
      reportName
      reportType
      reportCreatorName
      reportDescription
      selectedColumns
      isRecurringSchedule
      isFavourite
      emailList
      scheduleRepeatNumber
      interval
      reportFormat
      summary
      intervalTime
      entityType
      createdBy
      createdDate
      updatedBy
      updatedDate
      isDeleted
      filter
      startDate
      endDate
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportMutationVariables,
  APITypes.CreateReportMutation
>;
export const updateReport = /* GraphQL */ `mutation UpdateReport($input: ReportUpdateInput!) {
  updateReport(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    report {
      pk
      sk
      id
      reportTemplateId
      reportName
      reportType
      reportCreatorName
      reportDescription
      selectedColumns
      isRecurringSchedule
      isFavourite
      emailList
      scheduleRepeatNumber
      interval
      reportFormat
      summary
      intervalTime
      entityType
      createdBy
      createdDate
      updatedBy
      updatedDate
      isDeleted
      filter
      startDate
      endDate
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportMutationVariables,
  APITypes.UpdateReportMutation
>;
export const assignUserToSurvey = /* GraphQL */ `mutation AssignUserToSurvey($input: AssignUserToSurveyInput!) {
  assignUserToSurvey(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AssignUserToSurveyMutationVariables,
  APITypes.AssignUserToSurveyMutation
>;
export const runReport = /* GraphQL */ `mutation RunReport($input: RunReportInput) {
  runReport(input: $input) {
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.RunReportMutationVariables,
  APITypes.RunReportMutation
>;
export const updateMeasurementForSurvey = /* GraphQL */ `mutation UpdateMeasurementForSurvey($input: DataprocessingUpdateInput!) {
  updateMeasurementForSurvey(input: $input) {
    status
    error {
      message
      details
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMeasurementForSurveyMutationVariables,
  APITypes.UpdateMeasurementForSurveyMutation
>;
