import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import {
  DEFAULT,
  EMPTY_STRING,
  LONG,
  ND,
  PAGINATION_ARRAY,
  RD,
  REPORTDETAILS,
  ST,
  TH,
  ZERO,
} from '../../shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { ExportModalComponent } from '../../shared/export-modal/export-modal.component';
import { CreateReportServiceService } from '../create-report/service/create-report-service.service';
import { ViewReportInput } from '../../../awsAppSync/API';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';

@Component({
  selector: 'app-report-detail',
  templateUrl: './report-detail.component.html',
  styleUrl: './report-detail.component.css',
})
export class ReportDetailComponent implements OnInit {
  @ViewChild('pdfExport', { static: false }) pdfExport!: PDFExportComponent;
  public gridData: any[] = [];
  public isLoading = false;
  originalData: any[] = [];
  public pageSize = 50;
  public currentPage = 1;
  public skip = ZERO;
  public rowId = ZERO;
  pageChanged = false;
  gridDataToExport: any[] = [];
  filteredData: any[] = [];
  getFilterDropdownValue = false;
  public _selectedColumns: string[] = [
    'surveyName',
    'surveyType',
    'surveyPriority',
    'surveyStatus',
    'dueDate',
    'dataCollectionType',
    'technician',
    'supervisor',
    'dateOfCompletion',
    'surveyDescription',
  ];
  public PAGINATION_ARRAY = PAGINATION_ARRAY;
  selectedRowData: any;
  viewReportInput: ViewReportInput = {
    reportId: EMPTY_STRING,
    primaryCompanyId: EMPTY_STRING,
    pageSize: 50,
  };
  primaryCompanyId$: Observable<string | null>;
  storePrimaryCompanyid: string | null = EMPTY_STRING;
  selectedColumns: string[] = [];
  dynamicColumns: { field: string; title: string }[] = [];

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    public dialog: MatDialog,
    public createReportService: CreateReportServiceService,
  ) {
    this.primaryCompanyId$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
  }

  ngOnInit() {
    this.isLoading = true;
    this.primaryCompanyId$.subscribe((id) => {
      this.storePrimaryCompanyid = id;
    });
    this.selectedRowData = this.createReportService.selectedRowData;
    this.createViewReportInput();
    this.bindGridData();
  }

  async createViewReportInput() {
    this.viewReportInput = {
      reportId: this.selectedRowData.id,
      primaryCompanyId: this.storePrimaryCompanyid,
      pageSize: 50,
      lastEvaluatedKey: null,
    };
    await this.getViewReportData();
  }

  async getViewReportData() {
    const response = await this.createReportService.viewReport(
      this.viewReportInput,
    );
    this.selectedColumns = response?.selectedColumns
      ? JSON.parse(response.selectedColumns)
      : [];

    this.dynamicColumns = Object.entries(this.selectedColumns).map(
      ([key, value]) => ({
        field: key,
        title: value,
      }),
    );
    this.gridData = response?.items || [];
    this.isLoading = false;
  }

  async bindGridData() {
    this.originalData = [...this.gridData];
    this.cdr.detectChanges();
    this.loadData(this.gridData);
  }

  loadData(data: any[]) {
    this.originalData = data.map((item) => ({
      ...item,
    }));
    this.gridData = [...this.originalData];
  }

  formatDate(date: Date): string {
    if (!date) return '';

    const day = date.getDate();
    const month = date.toLocaleString(DEFAULT, { month: LONG });
    const year = date.getFullYear();

    const daySuffix = this.getDaySuffix(day);

    return `${day}${daySuffix} ${month}, ${year}`;
  }

  formatDateString(dateString: string | Date | null): string | null {
    if (dateString !== null) {
      const date = new Date(dateString);

      const day = date.getDate();
      const daySuffix = this.getDaySuffix(day);

      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        year: 'numeric',
      };
      const formattedDate = date.toLocaleDateString('en-US', options);

      return `${day}${daySuffix} ${formattedDate}`;
    }
    return null;
  }

  getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return TH;
    switch (day % 10) {
      case 1:
        return ST;
      case 2:
        return ND;
      case 3:
        return RD;
      default:
        return TH;
    }
  }

  getPriorityClass(priority: string): string {
    switch (priority.toLowerCase()) {
      case 'high':
        return 'high-priority';
      case 'medium':
        return 'medium-priority';
      case 'low':
        return 'low-priority';
      default:
        return '';
    }
  }

  openDialog(): void {
    const filteredData = this.gridData.map(() => {
      const filteredItem: Record<string, any> = {};
      return filteredItem;
    });

    this.dialog.open(ExportModalComponent, {
      data: {
        selectedColumnData: filteredData,
        selectedColumn: this._selectedColumns,
        pdfExport: this.pdfExport,
        pageSize: this.pageSize,
        gridData: this.originalData,
        allData: this.originalData,
        flag: REPORTDETAILS,
      },
    });
    this.pageChanged = false;
  }

  // page change event handler
  public onPageChange(event: any): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.currentPage = this.skip / this.pageSize + 1;
    this.updatePagedData();
    this.pageChanged = true;
  }

  // Update the grid data for the current page
  private updatePagedData(): void {
    const startIndex = this.skip;
    const endIndex = startIndex + this.pageSize;
    this.gridDataToExport = this.originalData.slice(startIndex, endIndex);
    this.cdr.markForCheck(); // Notify Angular to check for changes
  }
}