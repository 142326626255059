<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="col-auto">
  <h5 class="mtx-text-primary" style="margin-left: 10px; margin-top: 6px" *ngIf="this.selectedUserIsEditable">
    Edit User
  </h5>
  <h5 class="mtx-text-primary" style="margin-left: 10px; margin-top: 6px" *ngIf="!this.selectedUserIsEditable">
    Add New User
  </h5>
</div>
<div class="company-form card-shadow mt-4 card">
  <div class="card-body">
    <form [formGroup]="form" novalidate>
      <div class="">
        <div class="header-container">
          <div class="line"></div>
          <div>
            <h5 style="font-size: 20px">
              {{ 'USER_OVERVIEW.FORM_HEADER' | translate }}
            </h5>
            <p style="font-size: 15px; color: #a7a7a7" *ngIf="!this.selectedUserIsEditable">
              {{ 'USER_OVERVIEW.FORM_SUBHEADER' | translate }}
            </p>
            <p style="font-size: 15px; color: #a7a7a7" *ngIf="this.selectedUserIsEditable">
              {{ 'USER_OVERVIEW.FORM_SUBHEADER_EDIT' | translate }} : {{ userName }}
            </p>
          </div>
        </div>
        <div class="row mb-3 align-items-center" style="display: flex" *ngIf="!this.selectedUserIsEditable">
          <div class="btn-group" style="width: 50%">
            <button class="no-bg button-container" [ngClass]="{
                'selected-label': form.get('userType')?.value === 'newUser',
              }" (click)="onUserTypeChange('newUser')">
              <span style="color: #5b5b5b; font-weight: 500; padding-left: 10px">
                {{ 'USER_OVERVIEW.NEW_USER' | translate }}</span>
              <span class="icon-container" style="padding-right: 10px">
                <mat-icon class="mat-user-icon mat-icon-checked"
                  *ngIf="form.get('userType')?.value === 'newUser'">check_circle</mat-icon>
                <mat-icon class="mat-user-icon mat-icon-unchecked"
                  *ngIf="form.get('userType')?.value !== 'newUser'">panorama_fish_eye</mat-icon>
              </span>
            </button>

            <div class="text-center" style="width: 10%; margin-top: 20px">
              <span class="font-weight-bold" style="
                  white-space: nowrap;
                  color: #737373;
                  font-size: large;
                  font-weight: 400;
                ">OR</span>
            </div>

            <button class="no-bg button-container" [ngClass]="{
                'selected-label':
                  form.get('userType')?.value === 'existingUser',
              }" (click)="onUserTypeChange('existingUser')">
              <span style="color: #5b5b5b; font-weight: 500; padding-left: 10px">
                {{ 'USER_OVERVIEW.EXISTING_USER' | translate }}</span>
              <span class="icon-container" style="padding-right: 10px">
                <mat-icon class="mat-user-icon mat-icon-checked"
                  *ngIf="form.get('userType')?.value === 'existingUser'">check_circle</mat-icon>
                <mat-icon class="mat-user-icon mat-icon-unchecked"
                  *ngIf="form.get('userType')?.value !== 'existingUser'">panorama_fish_eye</mat-icon>
              </span>
            </button>
          </div>
        </div>

        <div *ngIf="form.get('userType')?.value !== 'existingUser'" class="row mb-3">
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="firstName">{{ 'USER_OVERVIEW.FIRST_NAME' | translate }}
                <span class="asterisk" style="padding-left: 5px">*</span></label>
            </div>
            <input type="text" id="firstName" maxlength="20" class="form-control" placeholder="{{
                'USER_OVERVIEW.FIRST_NAME_PLACEHOLDER' | translate
              }}" formControlName="firstName" (keydown.enter)="preventFormReset($event)" />
            <div *ngIf="
                form.get('firstName')?.touched &&
                form.get('firstName')?.errors?.['required']
              " class="text-danger">
              {{ 'USER_OVERVIEW.FIRST_NAME_REQUIRED' | translate }}
            </div>
          </div>
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="lastName">{{ 'USER_OVERVIEW.LAST_NAME' | translate
                }}<span class="asterisk" style="padding-left: 5px">*</span></label>
            </div>
            <input type="text" id="lastName" maxlength="20" class="form-control" placeholder="{{
                'USER_OVERVIEW.LAST_NAME_PLACEHOLDER' | translate
              }}" formControlName="lastName" (keydown.enter)="preventFormReset($event)" />
            <div *ngIf="
                form.get('lastName')?.touched &&
                form.get('lastName')?.errors?.['required']
              " class="text-danger">
              {{ 'USER_OVERVIEW.LAST_NAME_REQUIRED' | translate }}
            </div>
          </div>
        </div>

        <div *ngIf="form.get('userType')?.value === 'existingUser'" class="row mb-3">
          <div class="col-md-12" *ngIf="!this.selectedUserIsEditable">
            <label for="existingUserDropdown" style="margin-bottom: 5px">
              {{ 'USER_OVERVIEW.SELECT_EXISTING_USER' | translate }}
            </label>
            <!-- <select
              id="existingUserDropdown"
              class="form-select"
              formControlName="existingUserDropdown"
              (change)="existingUserChange($event)"
            >
              <option value="">
                {{ 'USER_OVERVIEW.SELECT_EXISTING_USER' | translate }}
              </option>
              <option
                *ngFor="let existingUser of existingUserDropdown"
                [value]="existingUser.corViewUserId"
              >
                {{ existingUser.username }}
              </option>
            </select> -->
            <kendo-dropdownlist id="existingUserDropdown" class="form-select-k-dropdown"
              formControlName="existingUserDropdown" [data]="existingUserDropdown" textField="username"
              valueField="corViewUserId" [(value)]="selectedExistingUserID" [filterable]="true"
              [defaultItem]="{ username: 'USER_OVERVIEW.SELECT_EXISTING_USER' | translate, corViewUserId: '' }"
              (filterChange)="onDropdownFilterChange($event)" (valueChange)="existingUserChange($event)">
            </kendo-dropdownlist>

          </div>
        </div>

        <div class="d-flex" style="align-items: baseline" *ngIf="!this.selectedUserIsEditable && !adminToggle">
          <div class="labelHeadings">
            Is user a {{displayCompanyName}} Admin
          </div>
          <div class="button-container" style="padding-left: 20px">
            <label class="switch">
              <input type="checkbox" [checked]="adminFlag" [disabled]="adminFlag" (click)="togglePCAdmin()" />
              <span class="slider round"></span>
            </label>
            <div class="export-modal-textandsupportingtext09">
              <span class="export-modal-text21"><span>{{ PCAdminText }}</span></span>
            </div>
          </div>
        </div>



        <div *ngIf="!isPCAdmin && !adminFlag" id="regionlst">
          <div formArrayName="regions">
            <div *ngFor="let region of regions.controls; let i = index" [formGroupName]="i">
              <div class="mb-4 d-flex align-items-center">
                <div class="col-md-3 me-3" id="regionLst">
                  <select formControlName="region" class="form-select"
                    [ngStyle]="{ color: regions.at(i).get('region')?.value ? 'black' : '#8E8E8E' }">
                    <option value="">{{level3Name}}</option>
                    <option *ngFor="let region of level3Values" [value]="region?.id" >
                      {{ region?.level3Value }}
                    </option>
                  </select>
                  <!-- Validation Message for Region -->
                  <div *ngIf="regions.at(i).get('region')?.touched && regions.at(i).get('region')?.invalid"
                    class="text-danger">
                    <small *ngIf="regions.at(i).get('region')?.hasError('required')">{{level3Name}} is required.</small>
                  </div>
                </div>
                <div class="col-md-8 d-flex justify-content-between align-items-center">
                  <div *ngFor="let role of roles" class="role-button-wrapper d-flex align-items-center me-2"
                    style="white-space: nowrap;">
                    <button class="no-bg-roles button-container" (click)="onRoleChange(i, role)">
                      {{ role?.name }}
                      <span class="icon-container ms">
                        <mat-icon class="mat-user-icon mat-icon-checked"
                          *ngIf="regions.at(i).get('selectedRole')?.value === role?.id">check_circle</mat-icon>
                        <mat-icon class="mat-user-icon mat-icon-unchecked"
                          *ngIf="regions.at(i).get('selectedRole')?.value !== role?.id">panorama_fish_eye</mat-icon>
                      </span>
                    </button>
                  </div>
                  <button *ngIf="regions.length > 1"
                    class="k-icon k-font-icon k-i-close-outline k-i-x-outline k-i-error delete-btn" type="button"
                    (click)="removeRegion(i)"></button><!-- Validation Message for Selected Role -->
                  <br>
                  <div>
                    <div
                      *ngIf="regions.at(i).get('selectedRole')?.touched && regions.at(i).get('selectedRole')?.invalid"
                      class="text-danger mt-2">
                      <span *ngIf="regions.at(i).get('selectedRole')?.hasError('required')" style="display: block;">Role
                        is required.</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button class="region-button my-3" (click)="addRegion()">
            <div class="region-text">
              <span class="region-text">
                <span class="button-text-alignment">Add {{level3Name}}</span>
              </span>
            </div>
          </button>
          <div *ngIf="form.get('regions')?.hasError('duplicateRegions')" class="mb-3">
            <small class="text-danger">Duplicate {{level3Name}} are not allowed!</small>
          </div>
        </div>
        <div class="labelHeadings">
          {{ 'USER_OVERVIEW.PERSONAL_DETAILS' | translate }}
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="primaryCompany">{{ 'USER_OVERVIEW.PRIMARY_COMPANY' | translate
                }}<span class="asterisk" style="padding-left: 5px">*</span></label>
            </div>
            <input type="text" id="primaryCompany" maxlength="20" class="form-control" formControlName="primaryCompany"
              [ngStyle]="{ color: this.form.get('userType')?.value === 'existingUser' ? 'black' : '#8E8E8E' }" />
          </div>
          <!-- <div class="col-md-6">
            <label for="regionAccess" style="margin-bottom: 5px">{{ 'USER_OVERVIEW.REGION_ACCESS' | translate
              }}<span class="asterisk" style="padding-left: 5px">*</span></label>
            <select class="form-select" id="regionAccess" class="form-select" formControlName="regionAccess"
              (change)="regionChange($event)" [ngStyle]="{ color: selectedRegionName ? 'black' : '#8E8E8E' }">
              <option value="">
                {{ 'USER_OVERVIEW.SELECT_REGION' | translate }}
              </option>
              <option *ngFor="let region of regionAccess" [value]="region.id">
                {{ region.displayName }}
              </option>
            </select>
            <div *ngIf="
                form.get('regionAccess')?.touched &&
                form.get('regionAccess')?.errors?.['required']
              " class="text-danger">
              {{ 'USER_OVERVIEW.REGION_ACCESS_REQ' | translate }}
            </div>
          </div> -->
        </div>
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="userName">
                {{ 'USER_OVERVIEW.USERNAME' | translate }}
                <span class="asterisk" style="padding-left: 5px">*</span></label>
            </div>
            <input type="text" id="userName" maxlength="20" class="form-control" placeholder="{{
                'USER_OVERVIEW.USERNAME_PLACEHOLDER' | translate
              }}" formControlName="userName" (keydown.enter)="preventFormReset($event)" />
            <div *ngIf="
                form.get('userName')?.touched &&
                form.get('userName')?.errors?.['required']
              " class="text-danger">
              {{ 'USER_OVERVIEW.USERNAME_REQ' | translate }}
            </div>
          </div>
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="emailId">{{ 'USER_OVERVIEW.EMAIL_ID' | translate
                }}<span class="asterisk" style="padding-left: 5px">*</span></label>
            </div>
            <input type="text" id="emailId" maxlength="150" class="form-control" placeholder=" {{
                'USER_OVERVIEW.EMAIL_ID_PLACEHOLDER' | translate
              }}" formControlName="emailId" (keydown.enter)="preventFormReset($event)" />
            <div *ngIf="
                form.get('emailId')?.touched &&
                form.get('emailId')?.errors?.['required']
              " class="text-danger">
              {{ 'USER_OVERVIEW.EMAIL_ID_REQ' | translate }}
            </div>
            <div class="text-danger" *ngIf="form.get('emailId')?.errors?.['pattern']">
              {{ 'USER_OVERVIEW.EMAIL_ID_VALID' | translate }}
            </div>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <div class="label-container">
              <label for="phoneNumber" style="margin-bottom: 5px">{{
                'CREATE_PRIMARY_PAGE.PHONE_NUMBER' | translate
                }}</label>

            </div>
            <input type="text" id="phoneNumber" maxlength="20" class="form-control" placeholder="{{
                'CREATE_PRIMARY_PAGE.PHONE_NUMBER_PLACEHOLDER' | translate
              }}" formControlName="phoneNumber" (keydown.enter)="preventFormReset($event)" />
            <div style="margin-left: 5px" *ngIf="
                  form.get('phoneNumber')?.touched &&
                  form.get('phoneNumber')?.errors?.['pattern']
                " class="text-danger">
              {{
              'CREATE_PRIMARY_PAGE.CORPORATE_OFFICE_NUMBER_NUMERIC'
              | translate
              }}
            </div>
          </div>
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="corporateOfficeNumber">{{ 'USER_OVERVIEW.CORPORATE_OFFICE_NUMBER' | translate}}
                <span class="asterisk" style="padding-left: 5px">*</span>
              </label>

            </div>
            <input type="text" id="corporateOfficeNumber" maxlength="20" class="form-control" placeholder="{{
                'CREATE_PRIMARY_PAGE.PHONE_NUMBER_PLACEHOLDER' | translate
              }}" formControlName="corporateOfficeNumber" (keydown.enter)="preventFormReset($event)" />
            <div style="margin-left: 5px" *ngIf="
              form.get('corporateOfficeNumber')?.touched &&
              form.get('corporateOfficeNumber')?.errors?.['pattern']
            " class="text-danger">
              {{
              'CREATE_PRIMARY_PAGE.CORPORATE_OFFICE_NUMBER_NUMERIC'
              | translate
              }}
            </div>
            <div *ngIf="
            form.get('corporateOfficeNumber')?.touched &&
            form.get('corporateOfficeNumber')?.errors?.['required']
          " class="text-danger">
              Corporate Office Number is required
            </div>
          </div>
          <!-- <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="corporateOfficeNumber"
                >{{ 'USER_OVERVIEW.CORPORATE_OFFICE_NUMBER' | translate
                }}<span class="asterisk" style="padding-left: 5px"
                  >*</span
                ></label>
                <div
                style="margin-left: 5px"
                *ngIf="
                  form.get('corporateOfficeNumber')?.touched &&
                  form.get('corporateOfficeNumber')?.errors?.['pattern']
                "
                class="text-danger"
              >
                {{
                  'CREATE_PRIMARY_PAGE.CORPORATE_OFFICE_NUMBER_NUMERIC'
                    | translate
                }}
              </div>
            </div>
            <input
              type="text"
              id="corporateOfficeNumber"
              maxlength="20"
              class="form-control"
              formControlName="corporateOfficeNumber"
              placeholder="Enter Corporate Office Number"
            />
          </div> -->
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <label for="country" style="margin-bottom: 5px">{{
              'CREATE_PRIMARY_PAGE.COUNTRY' | translate
              }}</label>
            <select id="country" class="form-select" formControlName="country" [disabled]="this.selectedUserIsEditable"
              (change)="onCountryChange($event)" style="color: #8E8E8E;"
              [ngStyle]="{ color: selectedCountryName ? 'black' : '#8E8E8E' }">
              <option value="">
                {{ 'CREATE_PRIMARY_PAGE.SELECT_COUNTRY' | translate }}
              </option>
              <option *ngFor="let country of countries" [value]="country?.name">
                {{ country?.name }}
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <label for="stateProvince" style="margin-bottom: 5px">{{
              'CREATE_PRIMARY_PAGE.STATE' | translate
              }}</label>
            <select id="state" class="form-select" formControlName="stateProvince" (change)="onStateChange($event)"
              [disabled]="this.selectedUserIsEditable" [ngStyle]="{ color: selectedStateName ? 'black' : '#8E8E8E' }">
              <option value="">
                {{ 'CREATE_PRIMARY_PAGE.SELECT_STATE' | translate }}
              </option>
              <option *ngFor="let state of states" [value]="state.name">
                {{ state.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="postalZipCode">{{
                'CREATE_PRIMARY_PAGE.ZIPCODE' | translate
                }}</label>
              <div *ngIf="
                  form.get('postalZipCode')?.touched &&
                  form.get('postalZipCode')?.invalid
                " class="text-danger" style="margin-left: 5px"></div>
            </div>
            <input type="text" id="postalZipCode" maxlength="20" class="form-control" placeholder="{{
                'CREATE_PRIMARY_PAGE.ZIPCODE_PLACEHOLDER' | translate
              }}" formControlName="postalZipCode" (keydown.enter)="preventFormReset($event)" />
          </div>
          <div class="col-md-6">
            <label for="city" style="margin-bottom: 5px">{{
              'CREATE_PRIMARY_PAGE.CITY' | translate
              }}</label>
            <select id="city" class="form-select" formControlName="city" (change)="onCityChange($event)"
              [disabled]="this.selectedUserIsEditable" [ngStyle]="{ color: selectedCityName ? 'black' : '#8E8E8E' }">
              <option value="">
                {{ 'CREATE_PRIMARY_PAGE.SELECT_CITY' | translate }}
              </option>
              <option *ngFor="let city of cities" [value]="city.name">
                {{ city.name }}
              </option>
            </select>
          </div>
        </div>

        <div class="row mb-3">
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="address">{{ 'CREATE_PRIMARY_PAGE.ADDRESS' | translate }}
              </label>
            </div>
            <input type="text" id="address" class="form-control" placeholder="{{
                'CREATE_PRIMARY_PAGE.ADDRESS_PLACEHOLDER' | translate
              }}" formControlName="address" (keydown.enter)="preventFormReset($event)" />
          </div>
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="timeZone">{{ 'CREATE_PRIMARY_PAGE.TIMEZONE' | translate }}
                <span class="asterisk" style="padding-left: 5px">*</span></label>
            </div>
            <select id="timeZone" (change)="onTimeZoneChange($event)" class="form-select" formControlName="timeZone"
              [ngStyle]="{ color: selectedTimeZone ? 'black' : '#8E8E8E' }">
              <option value="">
                {{ 'CREATE_PRIMARY_PAGE.SELECT_TIMEZONE' | translate }}
              </option>
              <option *ngFor="let tz of allTimeZone" [value]="tz?.id">
                {{ tz?.name }}
              </option>
            </select>
            <div *ngIf="
                form.get('timeZone')?.touched && form.get('timeZone')?.invalid
              " class="text-danger">
              {{ 'CREATE_PRIMARY_PAGE.TIMEZONE_REQUIRED' | translate }}
            </div>
          </div>
        </div>
        <div class="d-flex" style="align-items: baseline">
          <div class="labelHeadings">
            {{ 'USER_OVERVIEW.ACTIVE' | translate }}
          </div>
          <div class="button-container" style="padding-left: 20px">
            <label class="switch">
              <input #activeCheckbox id="active" [(ngModel)]="isActive" (click)="toggleActive()" type="checkbox"
                [disabled]="isCheckboxDisabled()" formControlName="active" (keydown.enter)="preventFormReset($event)" />
              <span class="slider round"></span>
            </label>
            <div class="export-modal-textandsupportingtext09">
              <span class="export-modal-text21"><span>{{ isActiveText }}</span></span>
            </div>
          </div>
        </div>

        <div *ngIf="form.get('userType')?.value !== 'existingUser'" class="row mb-3">
          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="password">{{ 'USER_OVERVIEW.PASSWORD' | translate
                }}<span class="asterisk" style="padding-left: 5px">*</span></label>
            </div>
            <div class="input-group custom-form-field">
              <input type="{{ showPassword ? 'text' : 'password' }}" id="password" formControlName="password"
                class="form-control" placeholder="{{
                  'USER_OVERVIEW.PASSWORD_PLACEHOLDER' | translate
                }}" (keydown.enter)="preventFormReset($event)" />
              <div class="input-icon" (click)="togglePasswordVisibility('password')">
                <mat-icon>{{
                  showPassword ? 'visibility_off' : 'visibility'
                  }}</mat-icon>
              </div>
            </div>
            <div *ngIf="
                form.get('password')?.touched && form.get('password')?.invalid
              ">
              <small class="text-danger" *ngIf="form.get('password')?.errors?.['required']">
                {{ 'USER_OVERVIEW.PASSWORD_REQ' | translate }}
              </small>
              <div class="text-danger" *ngIf="form.get('password')?.errors?.['passwordStrength']">
                <div class="password-error" *ngIf="
                    !form.get('password')?.errors?.['passwordStrength']
                      ?.isValidLength
                  ">
                  {{ 'USER_OVERVIEW.PASSWORD_ERROR_LENGTH' | translate }}
                </div>
                <div class="password-error" *ngIf="
                    !form.get('password')?.errors?.['passwordStrength']
                      ?.hasUpperCase
                  ">
                  {{ 'USER_OVERVIEW.PASSWORD_ERROR_UPPERCASE' | translate }}
                </div>
                <div class="password-error" *ngIf="
                    !form.get('password')?.errors?.['passwordStrength']
                      ?.hasLowerCase
                  ">
                  {{ 'USER_OVERVIEW.PASSWORD_ERROR_LOWERCASE' | translate }}
                </div>
                <div class="password-error" *ngIf="
                    !form.get('password')?.errors?.['passwordStrength']
                      ?.hasSpecialChar
                  ">
                  {{
                  'USER_OVERVIEW.PASSWORD_ERROR_SPECIAL_CHARACTER' | translate
                  }}
                </div>
                <div class="password-error" *ngIf="
                    !form.get('password')?.errors?.['passwordStrength']
                      ?.noMoreThanTwoRepeatingChars
                  ">
                  {{ 'USER_OVERVIEW.PASSWORD_ERROR_NO_REPEATING' | translate }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <div class="label-container" style="margin-bottom: 5px">
              <label for="confirmPassword">{{ 'USER_OVERVIEW.CONFIRM_PASSWORD' | translate
                }}<span class="asterisk" style="padding-left: 5px">*</span></label>
            </div>
            <div class="input-group custom-form-field">
              <input type="{{ showConfirmPassword ? 'text' : 'password' }}" id="confirmPassword"
                formControlName="confirmPassword" class="form-control" placeholder=" {{
                  'USER_OVERVIEW.CONFIRM_PASSWORD_PLACEHOLDER' | translate
                }}" (keydown.enter)="preventFormReset($event)" />
              <div class="input-icon" (click)="togglePasswordVisibility('confirmPassword')">
                <mat-icon>{{
                  showConfirmPassword ? 'visibility_off' : 'visibility'
                  }}</mat-icon>
              </div>
            </div>
            <div *ngIf="
                form.get('confirmPassword')?.touched &&
                form.get('confirmPassword')?.invalid
              ">
              <small class="text-danger" *ngIf="form.get('confirmPassword')?.errors?.['required']">
                {{ 'USER_OVERVIEW.CONFIRM_PASSWORD_REQ' | translate }}
              </small>
            </div>
            <div *ngIf="
                form.errors?.['passwordMismatch'] &&
                form.get('confirmPassword')?.touched
              ">
              <small class="text-danger">{{
                'USER_OVERVIEW.PASSWORD_DOESNOT_MATCH' | translate
                }}</small>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="card-footer d-grid d-md-flex justify-content-md-end">
      <div style="margin-top: 10px">
        <button class="btn button btn-cancel" type="button" (click)="onCancel()">
          <span>{{ 'HEIRARCHY_SETUP_LEVEL.CANCEL' | translate }}</span>
        </button>
        <button class="btn button mtx-btn-primary" type="button" (click)="saveAndExit()">
          <span>{{ 'HEIRARCHY_SETUP_LEVEL.SAVE_EXIT' | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</div>