import { Component, EventEmitter, Output, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PermissionService } from '../../../core/services/permission.service';
import {
  ASSET_OVERVIEW_SCREEN,
  SURVEY_OVERVIEW_SCREEN,
  SURVEY_ROUTE_SCREEN,
  COMPANY_OVERVIEW_SCREEN,
  USER_OVERVIEW_SCREEN,
  INSPECTION_SCREEN,
  EXCEPTION_SCREEN,
  ASSET_HISTORY_SCREEN,
  REPORT_MANAGEMENT_SCREEN,
  REPORT_TEMPLATE_SCREEN,
  PC_ADMIN_ROLE_ID,
} from '../../constants';
import {
  Roles,
  SetUserProfileData,
  UserProfileDataState,
} from '../../../core/store/user-profile.state';
import { forkJoin, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../../core/store/authenticate-user.state';
import { TopNavigationService } from '../../../core/services/top-navigation.service';
import { PrimaryCompanyModel } from '../../../../awsAppSync/API';
import { UserService } from '../../../core/user.service';

interface NestedSubMenuItem {
  label: string;
  route?: string;
  active: boolean;
}

interface SubMenuItem {
  label: string;
  icon?: string;
  route?: string;
  active: boolean;
  submenu: NestedSubMenuItem[] | null | undefined;
}

interface MenuItem {
  label: string;
  icon: string;
  active: boolean;
  submenu: SubMenuItem[] | null | undefined;
  route?: string;
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css'],
})
export class SidebarComponent implements OnInit {
  isShrunk = false;
  assetOverviewNoAccessPermission = false;
  assetHistoryNoAccessPermission = false;
  surveyOverviewNoAccessPermission = false;
  surveyRouteNoAccessPermission = false;
  companyOverviewNoAccessPermission = false;
  userOverviewNoAccessPermission = false;
  inspectionNoAccessPermission = false;
  exceptionsNoAccessPermission = false;
  reportManagementNoAccessPermission = false;
  reportTemplateNoAccessPermission = false;
  corviewUserId = '';
  selectedPrimaryCompany = '';
  selectedLevel3Value = '';
  selectedLevel3Id = '';
  corviewUserId$: Observable<string | null>;
  level3Value$: Observable<string | null>;
  level3Id$: Observable<string | null>;
  primaryCompany$: Observable<string | null>;
  @Output() shrink = new EventEmitter<boolean>();

  menuItems: MenuItem[] = [
    {
      label: 'SIDE_NAV_MAIN_MENU.COMPLIANCE',
      icon: 'complianceIcon',
      active: false,
      submenu: [
        {
          label: 'SIDE_NAV_SUB_MENU.INSPECTION',
          route: '/compliance/inspection',
          active: false,
          submenu: null,
        },
        {
          label: 'SIDE_NAV_SUB_MENU.EXCEPTION',
          route: '/compliance/exception',
          active: false,
          submenu: null,
        },
      ],
    },
    {
      label: 'SIDE_NAV_MAIN_MENU.REPORTS',
      icon: 'reportsIcon',
      active: false,
      submenu: [
        {
          label: 'SIDE_NAV_SUB_MENU.REPORT_MANAGEMENT',
          route: '/reports/report-management',
          active: false,
          submenu: null,
        },
        {
          label: 'SIDE_NAV_SUB_MENU.REPORT_TEMPLATES_LIBRARY',
          route: '/reports/report-templates-library',
          active: false,
          submenu: null,
        },
      ],
      route: '/reports',
    },
    {
      label: 'SIDE_NAV_MAIN_MENU.ASSETS',
      icon: 'assetsIcon',
      active: false,
      submenu: [
        {
          label: 'SIDE_NAV_SUB_MENU.ASSET_HISTORY',
          route: '/assets/asset-history',
          active: false,
          submenu: null,
        },
        {
          label: 'Asset Setup',
          route: '/assets/asset-setup',
          active: false,
          submenu: null,
        },
      ],
    },
    {
      label: 'SIDE_NAV_MAIN_MENU.SURVEYS',
      icon: 'surveyIcon',
      active: false,
      submenu: [
        {
          label: 'SIDE_NAV_SUB_MENU.SURVEY_OVERVIEW',
          route: '/survey/survey-overview',
          active: false,
          submenu: null,
        },
        {
          label: 'SIDE_NAV_SUB_MENU.FIELD_DATA_REVIEW',
          route: '/survey/field-data-review',
          active: false,
          submenu: null,
        },
        {
          label: 'SIDE_NAV_SUB_MENU.ROUTE_MANAGEMENT',
          route: '/survey/route-management',
          active: false,
          submenu: null,
        },
        {
          label: 'SIDE_NAV_SUB_MENU.DATA_PROCESSING',
          route: '/survey/data-processing',
          active: false,
          submenu: null,
        },
        {
          label: 'SIDE_NAV_SUB_MENU.SURVEY_LIBRARY',
          route: '/survey/survey-library',
          active: false,
          submenu: null,
        },
      ],
    },
  ];

  setup: MenuItem = {
    label: 'SIDE_NAV_MAIN_MENU.SETUP',
    icon: 'setupIcon',
    active: false,
    submenu: [
      {
        label: 'SIDE_NAV_SUB_MENU.COMPANY',
        icon: 'setupIcon',
        active: false,
        submenu: [
          {
            label: 'SIDE_NAV_CHILD_SUB_MENU.COMPANY_OVERVIEW',
            route: '/setup/company-overview',
            active: false,
          },
          {
            label: 'SIDE_NAV_CHILD_SUB_MENU.ASSET_OVERVIEW',
            route: '/setup/asset-overview',
            active: false,
          },
        ],
      },
      {
        label: 'SIDE_NAV_SUB_MENU.USERS',
        route: 'setup/users',
        active: false,
        submenu: null,
      },
      {
        label: 'SIDE_NAV_SUB_MENU.DEVICES',
        route: '/devices',
        active: false,
        submenu: null,
      },
    ],
  };

  constructor(
    private store: Store,
    private router: Router,
    private translate: TranslateService,
    private topNavigationService: TopNavigationService,
    public permissionService: PermissionService,
    private userService: UserService,
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.updateActiveStates();
      });
    this.corviewUserId$ = this.store.select(
      AuthenticateUserState.getLoggedInCorViewUserId,
    );

    this.level3Value$ = this.store.select(
      UserProfileDataState.getSelectedLevel3Value,
    );
    this.level3Id$ = this.store.select(
      UserProfileDataState.getSelectedLevel3Id,
    );
    this.primaryCompany$ = this.store.select(
      UserProfileDataState.getSelectedPrimaryCompany,
    );
  }

  ngOnInit() {
    //need to uncoment this code once BA permits for now it is hard-coded to ENGLISH
    // const savedLanguage = localStorage.getItem('selectedLanguage') ?? 'en';
    // const savedLanguage = 'en';
    //this.translate.use(savedLanguage);
    this.corviewUserId$.subscribe((corviewUserID) => {
      this.corviewUserId = corviewUserID ?? '';
    });

    this.level3Value$.subscribe((level3Value) => {
      this.selectedLevel3Value = level3Value ?? '';
    });

    this.level3Id$.subscribe((level3Id) => {
      this.selectedLevel3Id = level3Id ?? '';
    });

    this.primaryCompany$.subscribe((primaryCompany) => {
      this.selectedPrimaryCompany = primaryCompany ?? '';
    });

    forkJoin([this.storeProfileMenuData()]).subscribe(() => {
      this.assetOverviewNoAccessPermission =
        this.permissionService.hasPermission(ASSET_OVERVIEW_SCREEN, 'noAccess');
      this.surveyOverviewNoAccessPermission =
        this.permissionService.hasPermission(
          SURVEY_OVERVIEW_SCREEN,
          'noAccess',
        );
      this.surveyRouteNoAccessPermission = this.permissionService.hasPermission(
        SURVEY_ROUTE_SCREEN,
        'noAccess',
      );
      this.companyOverviewNoAccessPermission =
        this.permissionService.hasPermission(
          COMPANY_OVERVIEW_SCREEN,
          'noAccess',
        );
      this.userOverviewNoAccessPermission =
        this.permissionService.hasPermission(USER_OVERVIEW_SCREEN, 'noAccess');
      this.inspectionNoAccessPermission = this.permissionService.hasPermission(
        INSPECTION_SCREEN,
        'noAccess',
      );
      this.exceptionsNoAccessPermission = this.permissionService.hasPermission(
        EXCEPTION_SCREEN,
        'noAccess',
      );
      this.assetHistoryNoAccessPermission =
        this.permissionService.hasPermission(ASSET_HISTORY_SCREEN, 'noAccess');
      this.reportManagementNoAccessPermission =
        this.permissionService.hasPermission(
          REPORT_MANAGEMENT_SCREEN,
          'noAccess',
        );
      this.reportTemplateNoAccessPermission =
        this.permissionService.hasPermission(
          REPORT_TEMPLATE_SCREEN,
          'noAccess',
        );

      this.setup.submenu = this.setup.submenu?.filter((item) => {
        if (item.label === 'SIDE_NAV_SUB_MENU.USERS') {
          return !this.userOverviewNoAccessPermission;
        }

        if (item.label === 'SIDE_NAV_SUB_MENU.COMPANY') {
          item.submenu = item.submenu?.filter((subItem) => {
            if (subItem.label === 'SIDE_NAV_CHILD_SUB_MENU.ASSET_OVERVIEW') {
              return !this.assetOverviewNoAccessPermission;
            }
            if (subItem.label === 'SIDE_NAV_CHILD_SUB_MENU.COMPANY_OVERVIEW') {
              return !this.companyOverviewNoAccessPermission;
            }
            return true;
          });
        }

        return true; // Keep other items in the main submenu
      });

      this.menuItems?.forEach((item) => {
        if (item.label === 'SIDE_NAV_MAIN_MENU.COMPLIANCE') {
          item.submenu = item.submenu?.filter((subItem) => {
            if (subItem.label === 'SIDE_NAV_SUB_MENU.INSPECTION') {
              return !this.inspectionNoAccessPermission;
            }
            if (subItem.label === 'SIDE_NAV_SUB_MENU.EXCEPTION') {
              return !this.exceptionsNoAccessPermission;
            }
            return true;
          });
        }

        if (item.label === 'SIDE_NAV_MAIN_MENU.ASSETS') {
          item.submenu = item.submenu?.filter((subItem) => {
            if (subItem.label === 'Asset Setup') {
              return !this.assetOverviewNoAccessPermission;
            }
            if (subItem.label === 'SIDE_NAV_SUB_MENU.ASSET_HISTORY') {
              return !this.assetHistoryNoAccessPermission;
            }
            return true;
          });
        }
        if (item.label === 'SIDE_NAV_MAIN_MENU.SURVEYS') {
          item.submenu = item.submenu?.filter((subItem) => {
            if (subItem.label === 'SIDE_NAV_SUB_MENU.SURVEY_OVERVIEW') {
              return !this.surveyOverviewNoAccessPermission;
            }
            if (subItem.label === 'SIDE_NAV_SUB_MENU.ROUTE_MANAGEMENT') {
              return !this.surveyRouteNoAccessPermission;
            }
            return true;
          });
        }

        if (item.label === 'SIDE_NAV_MAIN_MENU.REPORTS') {
          item.submenu = item.submenu?.filter((subItem) => {
            if (subItem.label === 'SIDE_NAV_SUB_MENU.REPORT_MANAGEMENT') {
              return !this.reportManagementNoAccessPermission;
            }
            if (
              subItem.label === 'SIDE_NAV_SUB_MENU.REPORT_TEMPLATES_LIBRARY'
            ) {
              return !this.reportTemplateNoAccessPermission;
            }
            return true;
          });
        }
      });
      this.updateActiveStates();
    });
  }

  _pcAdminRegions: (Roles | null)[] = [];
  async storeProfileMenuData() {
    this.topNavigationService.sidebarApiCallCompleted$.next(false);
    const _result = await this.topNavigationService.getUserProfileByUserId(
      this.corviewUserId,
    );

    const roles = (_result?.roles || []).map((role) => ({
      level1Id: role?.level1Id || null,
      level2Id: role?.level2Id || null,
      level3Id: role?.level3Id || null,
      level3Value: role?.level3Value || null,
      roleId: role?.roleId || null,
      rolename: role?.rolename || null,
      permissions: role?.permissions || null,
    })) as (Roles | null)[];
    const isAdminRolePresent = roles?.some(
      (role) => role?.roleId === PC_ADMIN_ROLE_ID,
    );

    if (isAdminRolePresent) {
      if (_result?.level3Values) {
        this._pcAdminRegions = await this.userService.mapLevel3ValuesToRoles(
          _result?.level3Values,
          roles,
        );
      }
    }
    if (_result?.isMTXUser) {
      const _syrcPrimaryCompanies =
        await this.topNavigationService.getSyrcPrimaryCompanies();
      if (
        _syrcPrimaryCompanies?.items &&
        _syrcPrimaryCompanies.items.length > 0
      ) {
        if (!this.selectedPrimaryCompany) {
          this.selectedPrimaryCompany =
            _syrcPrimaryCompanies.items[0]?.name ?? '';
        }
      }

      this.store.dispatch(
        new SetUserProfileData(
          _result?.primaryCompany || ({} as PrimaryCompanyModel),
          _result?.userId || '',
          _result?.userName || '',
          _result?.userEmail || '',
          _result?.isMTXUser || false,
          isAdminRolePresent ? this._pcAdminRegions : roles,
          _syrcPrimaryCompanies?.items,
          this.selectedPrimaryCompany,
          '',
          '',
        ),
      );
      const permission = roles
        .filter((role) => role !== null)
        .map((role) => role?.permissions || [])
        .flat();
      this.permissionService.getdatefromlogin(permission);
    } else {
      if (!this.selectedLevel3Value) {
        this.selectedLevel3Value = roles[0]?.level3Value ?? '';
      }
      if (!this.selectedLevel3Id) {
        this.selectedLevel3Id = roles[0]?.level3Id ?? '';
      }
      this.store.dispatch(
        new SetUserProfileData(
          _result?.primaryCompany || ({} as PrimaryCompanyModel),
          _result?.userId || '',
          _result?.userName || '',
          _result?.userEmail || '',
          _result?.isMTXUser || false,
          isAdminRolePresent ? this._pcAdminRegions : roles,
          null,
          '',
          this.selectedLevel3Value,
          this.selectedLevel3Id,
        ),
      );
      this.permissionService.transformData();
      const permission = roles
        .filter(
          (role) =>
            role !== null && role.level3Value === this.selectedLevel3Value,
        )
        .map((role) => role?.permissions || [])
        .flat();
      this.permissionService.getdatefromlogin(permission);
    }
    this.topNavigationService.sidebarApiCallCompleted$.next(true);
  }
  toggleSidebar() {
    this.isShrunk = !this.isShrunk;
    this.shrink.emit(this.isShrunk);
  }

  toggleSubmenu(item: MenuItem) {
    // If sidebar is shrunk, expand it
    if (this.isShrunk) {
      this.isShrunk = false;
      this.shrink.emit(this.isShrunk);
    }

    // If the clicked item is already active, collapse it
    if (item.active) {
      item.active = false;
      if (item.submenu) {
        item.submenu.forEach((sub) => (sub.active = false));
      }
      return; // Exit method as we don't need to deactivate other menus
    }

    // Deactivate all main menu items and their submenus
    this.menuItems.forEach((menu) => {
      menu.active = false;
      if (menu.submenu) {
        menu.submenu.forEach((sub) => (sub.active = false));
      }
    });

    // Deactivate setup menu if it is active
    this.setup.active = false;

    // Activate the selected menu item's submenu
    item.active = true;
    if (item.submenu) {
      item.submenu.forEach((sub) => (sub.active = this.isSubItemActive(sub)));
    }
  }

  toggleSetupSubmenu() {
    if (this.isShrunk) {
      this.isShrunk = false;
      this.shrink.emit(this.isShrunk);
    }

    // Deactivate all main menu items and their submenus
    this.menuItems.forEach((menu) => {
      menu.active = false;
      if (menu.submenu) {
        menu.submenu.forEach((sub) => (sub.active = false));
      }
    });

    // Toggle the setup menu's submenu
    this.setup.active = !this.setup.active;
    if (this.setup.submenu) {
      this.setup.submenu.forEach(
        (sub) => (sub.active = this.isSubItemActive(sub)),
      );
    }
  }

  activateLink(item: MenuItem) {
    // Deactivate all main menu items and submenus
    this.menuItems.forEach((menu) => {
      menu.active = item === menu;
      if (menu.submenu) {
        menu.submenu.forEach((sub) => (sub.active = this.isSubItemActive(sub)));
      }
    });

    // Deactivate setup menu if it is not the selected item
    if (item !== this.setup) {
      this.setup.active = false;
    }
  }

  // Method to activate first-level subitems and collapse nested subitems when toggling
  activateSubLink(subItem: any, setup: any) {
    // Deactivate all other subitems and their nested submenus
    setup.submenu.forEach((item: any) => {
      if (item !== subItem) {
        item.active = false;
        // Deactivate nested subitems
        if (item.submenu) {
          item.submenu.forEach((nested: any) => (nested.active = false));
        }
      }
    });

    // Toggle the clicked subitem's active state
    subItem.active = !subItem.active;

    // If the subitem is deactivated, collapse its nested subitems
    if (!subItem.active && subItem.submenu) {
      subItem.submenu.forEach((nested: any) => (nested.active = false));
    }
  }

  // Method to activate nested subitems
  activateNestedSubLink(nestedSubItem: any, subItem: any) {
    // Deactivate all other nested subitems of the current subItem
    if (subItem.submenu) {
      subItem.submenu.forEach((item: any) => {
        if (item !== nestedSubItem) {
          item.active = false;
        }
      });
    }

    // Toggle the clicked nested subitem's active state
    nestedSubItem.active = !nestedSubItem.active;
  }

  isActive(item: MenuItem): boolean {
    if (item.route) {
      return this.router.url === item.route;
    }
    if (item.submenu) {
      return item.submenu.some(
        (sub) => sub.route && this.router.url.includes(sub.route),
      );
    }
    return false;
  }

  isSubmenuActive(item: MenuItem): boolean {
    if (item.submenu) {
      return item.submenu.some(
        (sub) => sub.route && this.router.url.includes(sub.route),
      );
    }
    return false;
  }

  isSubItemActive(subItem: SubMenuItem): boolean {
    if (subItem.route) {
      return this.router.url.includes(subItem.route);
    }
    return false;
  }

  isSetupActive(): boolean {
    return (
      this.setup.submenu?.some((sub) => this.router.url === sub.route) || false
    );
  }

  private updateActiveStates() {
    this.menuItems.forEach((item) => {
      item.active = this.isActive(item);
      if (item.submenu) {
        item.submenu.forEach((sub) => (sub.active = this.isSubItemActive(sub)));
      }
    });
    this.setup.active = this.isSetupActive();
    if (this.setup.submenu) {
      this.setup.submenu.forEach(
        (sub) => (sub.active = this.isSubItemActive(sub)),
      );
    }
  }
}
