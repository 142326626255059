import { Injectable } from '@angular/core';
import {
  ColumnReportConnection,
  ConfigureReportInput,
  ConfigureReportQuery,
  ConfigureReportQueryVariables,
  CreateReportMutation,
  CreateReportMutationVariables,
  GetAllColumnsForReportQuery,
  GetReportByIdQuery,
  GetReportByIdQueryVariables,
  Report,
  ReportCreateInput,
  ReportDataModel,
  ReportInput,
  ReportResponse,
  ReportUpdateInput,
  RunReportInput,
  RunReportMutation,
  RunReportMutationVariables,
  RunReportResponse,
  UpdateReportMutation,
  UpdateReportMutationVariables,
  ViewReportInput,
  ViewReportQuery,
  ViewReportQueryVariables,
} from '../../../../awsAppSync/API';
import {
  configureReport,
  getAllColumnsForReport,
  getReportById,
  viewReport,
} from '../../../../awsAppSync/graphql/queries';
import { GENERIC_FAILED_ERROR } from '../../../shared/constants';
import { BaseAWSRequestService } from '../../../shared/service/base-services/base-aws-request.service';
import {
  createReport,
  runReport,
  updateReport,
} from '../../../../awsAppSync/graphql/mutations';

@Injectable({
  providedIn: 'root',
})
export class CreateReportServiceService {
  public gridData: any[] = [];
  selectedRowData: any;
  runReportInput: RunReportInput = {};
  constructor(private awsService: BaseAWSRequestService) {
    this.gridData = this.generatesimilarData(10);
  }

  getRandomDate(start: Date, end: Date): Date {
    const startTime = start.getTime();
    const endTime = end.getTime();
    const randomTime = startTime + Math.random() * (endTime - startTime);

    const randomDate = new Date(randomTime);
    randomDate.setHours(0, 0, 0, 0);

    return randomDate;
  }

  generatesimilarData(numRows: number): any[] {
    const dt = [];
    const startDate = new Date(2023, 0, 1); // January 1, 2023
    const endDate = new Date(2024, 11, 31); // December 31, 2024
    for (let i = 1; i <= numRows; i++) {
      const lastInspectionDate = this.getRandomDate(startDate, endDate);
      const data = {
        'Asset Type': 'Test Point ' + i,
        'Asset Name': 'TP-005+CB1' + i,
        Station: '10+001.4',
        'Compliance Status': '850mv IR',
        'Compliance Criteria': '950mv IR',
        'Next Inspection Date': lastInspectionDate,
        'Last Compliance Read': lastInspectionDate,
        Technician: 'Michael jordan',
      };
      dt.push(data);
    }
    return dt;
  }

  async getALLColumnsForReport(): Promise<ColumnReportConnection | null> {
    try {
      const response =
        await this.awsService.executeGetQuery<GetAllColumnsForReportQuery>(
          getAllColumnsForReport,
        );
      return response.getAllColumnsForReport ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  async viewReport(input: ViewReportInput): Promise<ReportDataModel | null> {
    try {
      const variables: ViewReportQueryVariables = {
        input,
      };
      const result = await this.awsService.executeGetQuery<ViewReportQuery>(
        viewReport,
        variables,
      );
      return result.viewReport ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  async configureReport(
    // for grid data on step 2, restrict to 10 rows
    input: ConfigureReportInput,
  ): Promise<ReportDataModel | null> {
    try {
      const variables: ConfigureReportQueryVariables = {
        input,
      };
      const result =
        await this.awsService.executeGetQuery<ConfigureReportQuery>(
          configureReport,
          variables,
        );
      return result.configureReport ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  async updateReport(input: ReportUpdateInput): Promise<ReportResponse | null> {
    try {
      const variables: UpdateReportMutationVariables = {
        input,
      };
      const result =
        await this.awsService.executeGetQuery<UpdateReportMutation>(
          updateReport,
          variables,
        );
      return result.updateReport ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  async createReport(input: ReportCreateInput): Promise<ReportResponse | null> {
    const mutation = createReport;
    const variables: CreateReportMutationVariables = { input };
    try {
      const CreateReportResult = await this.awsService.executeMutation<{
        createReport: CreateReportMutation;
      }>(mutation, variables);
      return CreateReportResult.createReport || null;
    } catch (error) {
      console.error('Error creating Template', error);
      return null;
    }
  }

  async getReportById(input: ReportInput): Promise<Report | null> {
    try {
      const variables: GetReportByIdQueryVariables = { input };
      const result = await this.awsService.executeGetQuery<GetReportByIdQuery>(
        getReportById,
        variables,
      );
      return result.getReportById ?? null; // Using nullish coalescing operator
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  async runReport(input: RunReportInput): Promise<RunReportResponse | null> {
    try {
      const variables: RunReportMutationVariables = {
        input,
      };
      const result = await this.awsService.executeGetQuery<RunReportMutation>(
        runReport,
        variables,
      );
      return result.runReport ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }
}
