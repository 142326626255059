import { Injectable } from '@angular/core';
import {
  SurveyFilterInput,
  GetAllSurveyFieldDataQueryVariables,
  SurveyFieldDataConnection,
  SurveyInput,
  SurveyResponseExport,
  ExportSurveyToJsonQueryVariables,
} from '../../../awsAppSync/API';
import {
  exportSurveyToJson,
  getAllSurveyFieldData,
} from '../../../awsAppSync/graphql/queries';
import {
  ERROR_FETCHING_SURVEY_OVERVIEW,
  ERROR_FETCHING_SURVEY_TO_JSON,
} from '../../shared/constants';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';

@Injectable({
  providedIn: 'root',
})
export class SurveyFieldDataReviewService {
  constructor(private awsService: BaseAWSRequestService) {}

  public async getAllSurveyFieldData(
    input: SurveyFilterInput,
  ): Promise<SurveyFieldDataConnection | null> {
    const query = getAllSurveyFieldData; // Ensure this is your GraphQL query string or document
    const variables: GetAllSurveyFieldDataQueryVariables = {
      input: input,
    };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAllSurveyFieldData: SurveyFieldDataConnection;
      }>(query, variables);
      return result.getAllSurveyFieldData || null;
    } catch (error) {
      console.error(ERROR_FETCHING_SURVEY_OVERVIEW, error);
      return null;
    }
  }

  public async exportSurveyToJson(
    input: SurveyInput,
  ): Promise<SurveyResponseExport | null> {
    const query = exportSurveyToJson; // Ensure this is your GraphQL query string or document
    const variables: ExportSurveyToJsonQueryVariables = {
      input: input,
    };
    try {
      const result = await this.awsService.executeGetQuery<{
        exportSurveyToJson: SurveyResponseExport;
      }>(query, variables);
      return result.exportSurveyToJson || null;
    } catch (error) {
      console.error(ERROR_FETCHING_SURVEY_TO_JSON, error);
      return null;
    }
  }
}
