import {
  ChangeDetectorRef,
  Component,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ReportTemplatesLibraryService } from '../../core/services/report-templates-library.service';
import {
  BUTTON_BACKGROUND_COLOR,
  CREATE_REPORT,
  SEARCHREPORTS,
  DEFAULTPAGESIZE,
  REPORT_TEMPLATE_SCREEN,
  ZERO,
  PAGINATION_ARRAY,
  FETCHING_TEMPLATES_ERROR,
  REDIRECT_CREATE_REPORT,
  EDIT_MODE_REPORT,
  CREATE_REPORT_FROM_TEMPLATE,
  EMPTY_STRING,
} from '../../shared/constants';
import { Router } from '@angular/router';
import { ReportTemplateLibrary } from '../../core/models/report-templates-library.module';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { SetTemplate } from '../../core/store/template.state';
import {
  DataStateChangeEvent,
  GridComponent,
} from '@progress/kendo-angular-grid';
import { PermissionService } from '../../core/services/permission.service';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { KendoGridFilters, ReportTemplate } from '../../../awsAppSync/API';
import { Observable } from 'rxjs';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { LastEvaluatedKeyModel } from '../../core/models/lastevaluatedkey';

@Component({
  selector: 'app-report-templates-library',
  templateUrl: './report-templates-library.component.html',
  styleUrl: './report-templates-library.component.css',
})
export class ReportTemplatesLibraryComponent implements OnInit {

  @ViewChild('grid') grid!: GridComponent;
  gridData: ReportTemplateLibrary[] = [];
  selectedTemplate: any = null;
  public originalData: any[] = [];
  public pageSize = DEFAULTPAGESIZE;
  public expandedRows: Record<string, boolean> = {};
  disableCreateReport = true;
  public searchPlaceholder = SEARCHREPORTS;
  isFrom = 'fromTemplateLibrary';
  permission = false;
  public skip = ZERO;
  currentPage = 1;
  pageChanged = false;
  public gridDataToExport: any[] = [];
  public isLoading = true;
  searchText = '';
  public PAGINATION_ARRAY = PAGINATION_ARRAY;
  kendoGridFilters: KendoGridFilters = {};
  storePrimaryCompanyID$: Observable<string | null>;
  selectedprimaryCompanyId: string | null = '';
  customstate: { filter: CompositeFilterDescriptor } = {
    filter: { logic: 'and', filters: [] },
  };
  lastEvaluatedKey: LastEvaluatedKeyModel | null = null;
  public nextKey: LastEvaluatedKeyModel | null = null;
  public previousKey: LastEvaluatedKeyModel | null = null;
  public state: State = {
    skip: 0,
    take: this.pageSize || DEFAULTPAGESIZE,
    group: [],
    sort: [],
  };
  currentDataItem: any;
  constructor(
    private reportTemplatesService: ReportTemplatesLibraryService,
    public router: Router,
    public dialog: MatDialog,
    private store: Store,
    private renderer: Renderer2,
    public permissionService: PermissionService,
    private cdr: ChangeDetectorRef,
  ) {
    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
  }

  navigateToCreateNewTemplate() {
    this.router.navigate(['reports/report-templates-library/create']);
  }

  onEditTemplateClick(selectedRow: any) {
    this.isFrom = EDIT_MODE_REPORT;
    this.store.dispatch(
      new SetTemplate(
        this.isFrom,
        selectedRow.templateName,
        selectedRow.reportType,
        selectedRow.id,
      ), 
    );
    this.router.navigate(['reports/report-templates-library/edit']);
  }

  ngOnInit() {
    this.permission = this.permissionService.hasPermission(
      REPORT_TEMPLATE_SCREEN,
      'fullAccess',
    );
    this.storePrimaryCompanyID$.subscribe((id) => {
      this.selectedprimaryCompanyId = '';
      this.selectedprimaryCompanyId = id;
    });
    this.loadReports();
  }
  // getAssetDetails(dataItem: ReportTemplateLibrary) {
  //   return dataItem.selectedColumns.map((detail) => ({ detail }));
  // }

  onTemplateSelect(template: any): void {
    this.store.dispatch(
      new SetTemplate(this.isFrom, template.templateName, template.type),
    );
    this.selectedTemplate = template;
    const element = document.getElementById(CREATE_REPORT);
    if (element) {
      this.renderer.addClass(element, BUTTON_BACKGROUND_COLOR);
    }
  }

  onCreateReportClick(): void {
    this.isFrom = CREATE_REPORT_FROM_TEMPLATE;
          this.store.dispatch(
            new SetTemplate(
              this.isFrom,
              EMPTY_STRING,
              EMPTY_STRING,
              this.selectedTemplate.id
            ), 
          );
    this.router.navigate([REDIRECT_CREATE_REPORT]);
  }

  handleSearchInput(changeWord: string) {
    if (changeWord.length < 3 && this.searchText.length > 2) {
      this.searchText = '';
      this.nextKey = null;
      this.currentPage = 1;
      this.loadReports();
    } else if (changeWord.length > 2) {
      this.searchText = changeWord;
      this.nextKey = null;
      this.currentPage = 1;
      this.loadReports();
    }
  }

  containsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === 'string') {
      return field.toLowerCase().includes(searchTerm);
    }
    return false;
  }

  public toggleDescription(rowId: string): void {
    this.expandedRows[rowId] = !this.expandedRows[rowId];
  }

  public isExpanded(rowId: string): boolean {
    return !!this.expandedRows[rowId];
  }
  public onPageChange(event: any): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.currentPage = this.skip / this.pageSize + 1;
    this.updatePagedData();
    this.pageChanged = true;
  }
  // Update the grid data for the current page
  private updatePagedData(): void {
    const startIndex = this.skip;
    const endIndex = startIndex + this.pageSize;
    this.gridDataToExport = this.originalData.slice(startIndex, endIndex);
    this.cdr.markForCheck(); // Notify Angular to check for changes
  }

  public dataStateChange(state: DataStateChangeEvent): void {
    this.isLoading = true;
    this.state = state;
    this.currentPage = state.skip / this.pageSize + 1;
    this.kendoGridFilters = this.state.filter as KendoGridFilters;
    const filter = state.filter as CompositeFilterDescriptor;
    this.customstate = { ...this.customstate, filter };
    this.nextKey = null;
    this.currentPage = 1;
    this.currentPage = 1;
    this.cdr.detectChanges();
    this.loadReports();
  }

  async loadReports(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await this.reportTemplatesService.getReportGridData({
        searchText: this.searchText,
        pageSize: this.state.take ?? 50,
        pageNumber: this.currentPage,
        primaryCompanyId: this.selectedprimaryCompanyId ?? '',
        lastEvaluatedKey: this.currentPage == 1 ? null : this.nextKey,
      });
      if (response?.items) {
        this.gridData = response.items
          ?.filter((item): item is ReportTemplate => item != null)
          .map((item: ReportTemplate) => ({
            id: item?.id,
            templateName: item?.templateName,
            description: item?.description,
            reportType: item?.reportType,
            lastUsedOn: item?.lastUsedOn,
            templateColumns: this.convertJsonStringToArray(
              item.selectedColumns,
            ),
          }));

        if (response.lastEvaluatedKey != null) {
          if (response.lastEvaluatedKey.pk != null) {
            this.lastEvaluatedKey = {
              pk: response?.lastEvaluatedKey?.pk,
              sk: response?.lastEvaluatedKey?.sk,
            };
          } else {
            this.lastEvaluatedKey = null;
          }
        } else {
          this.lastEvaluatedKey = null;
        }
        this.nextKey = this.lastEvaluatedKey;
        this.originalData = this.gridData;
        this.isLoading = false;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      }
      this.originalData = [...this.gridData];
    } catch (error) {
      console.error(FETCHING_TEMPLATES_ERROR, error);
    }
  }
  convertJsonStringToArray(jsonstring: string | null | undefined) {
    if (jsonstring) {
      const jsonObject = JSON.parse(jsonstring);

      // Extract all the values from the object and store them in a string array
      const valuesArray: string[] = Object.values(jsonObject);
      return valuesArray;
    } else {
      return [];
    }
  }
}
