import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  Output,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
@Component({
  selector: 'app-custom-frequency',
  templateUrl: './custom-frequency.component.html',
  styleUrl: './custom-frequency.component.css',
})
export class CustomFrequencyComponent implements OnChanges {
  @Input() OpenDialog = false;
  @Input() defaultFrequency = '';
  @Output() isDialogOpenFlag = new EventEmitter<boolean>();
  @Output() customFrequency = new EventEmitter<{
    isDefaultFrequency: boolean | null;
    numberOfOccurrences1: number | null;
    numberOfOccurrences2: number | null;
    duration1: string | null;
    duration2: string | null;
  }>();

  public startDate: Date = new Date();
  public repeatOptions: string[] = ['Months', 'Years'];
  public repeatInterval = 2;
  isDialogOpen = false; // Initially, the dialog is closed

  customVal = '';
  numberOfOccurrences1 = 1;
  numberOfOccurrences2 = 1;
  duration1 = 'Months';
  duration2 = 'Months';
  defaultChecked = false;
  constructor(private datePipe: DatePipe) {}
  ngOnChanges(changes: SimpleChanges) {
    if (changes['OpenDialog']) {
      this.isDialogOpen = this.OpenDialog;
      this.defaultChecked = false;
    }
  }

  public open(): void {
    this.isDialogOpen = true;
  }

  public closePopup() {
    this.isDialogOpen = false;
    this.isDialogOpenFlag.emit(this.isDialogOpen);
  }

  onOptionChange() {
    this.defaultChecked = !this.defaultChecked;
  }

  public onSave(): void {
    this.isDialogOpen = false;
    this.generateCustomValue();
  }

  public generateCustomValue(): void {
    // const date = new Date(this.startDate);
    // Format the date as dd-MM-yyyy using DatePipe
    // const formattedDate = this.datePipe.transform(date, 'dd-MM-yyyy');
    // const customFrequencyString = `Occurs every ${this.repeatInterval} ${this.selectedRepeatOption.toLowerCase()} starting from ${formattedDate}`;
    // // this.customFrequency.emit(this.customVal);

    const frequencyDetails = {
      isDefaultFrequency: this.defaultChecked,
      numberOfOccurrences1: this.numberOfOccurrences1,
      numberOfOccurrences2: this.numberOfOccurrences2,
      duration1: this.duration1,
      duration2: this.duration2,
    };
    this.customFrequency.emit(frequencyDetails);
  }

  public onCancel(): void {
    this.isDialogOpen = false;
    this.isDialogOpenFlag.emit(this.isDialogOpen);
  }
  preventInvalidKeys(event: KeyboardEvent): void {
    const invalidKeys = ['e', 'E', '-', '+'];
    if (invalidKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

  validateInput(event: Event, label: string): void {
    const input = event.target as HTMLInputElement;
    if (parseInt(input.value, 10) < 1) {
      input.value = '1'; // Set the value to the minimum allowed
      if (label === 'occurrences1')
        this.numberOfOccurrences1 = 1; // Ensure the model is updated
      else this.numberOfOccurrences2 = 1;
    }
  }
}
