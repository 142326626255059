import { Component, OnInit } from '@angular/core';
import { SurveyRouteService } from '../../core/services/survey-route.service';
import { ZERO } from '@angular/cdk/keycodes';
import { SURVEYOVERVIEW_ELLIPSIS_WIDTH, SURVEYROUTEMANAGEMENT, DEFAULTPAGESIZE, FETCHING_SURVEY_ROUTE_ERROR, SURVEY_ROUTE_SCREEN } from '../../shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { AddSurveyRouteModalComponent } from '../add-survey-route-modal/add-survey-route-modal.component';
import { Assets } from '../../core/models/assets';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import {
  DataStateChangeEvent,
  GridDataResult
} from '@progress/kendo-angular-grid';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { SurveyRoute } from '../../../awsAppSync/API';
import { PermissionService } from '../../core/services/permission.service';
import { SurveyRouteAssociatedAsset } from '../../core/models/survey-routes';

@Component({
  selector: 'app-survey-route-management',
  templateUrl: './survey-route-management.component.html',
  styleUrl: './survey-route-management.component.css'
})
export class SurveyRouteManagementComponent implements OnInit {

  public gridData: GridDataResult = { data: [], total: 0 };
  surveyRoutes: SurveyRoute[] = [];
  assets: Assets[] = [];
  surveyRouteAssets: SurveyRouteAssociatedAsset[] = [];
  public expandedRows: Record<string, boolean> = {};
  title = "SIDE_NAV_SUB_MENU.ROUTE_MANAGEMENT";
  currentDataItem: any;
  dropdownVisible = false;
  dropdownX = ZERO;
  dropdownY = ZERO;
  selectedRowData: any;
  public rowId = ZERO;
  ellipsisWidth = SURVEYOVERVIEW_ELLIPSIS_WIDTH;
  public inputScreen = SURVEYROUTEMANAGEMENT;
  public isLoading = true;
  currentPage = 1;
  public pageSize = DEFAULTPAGESIZE;
  storePrimaryCompanyID$: Observable<string | null>;
  selectedprimaryCompanyId : string | null = "";
  public originalData: any[] = [];
  permission = false;
  
  constructor(private surveyRouteService: SurveyRouteService, public dialog: MatDialog, private store: Store, public permissionService: PermissionService,) {
    this.storePrimaryCompanyID$= this.store.select(AuthenticateUserState.getSyrcPrimaryCompanyId);
  }

  ngOnInit(): void {
    this.permission = this.permissionService.hasPermission(SURVEY_ROUTE_SCREEN, 'fullAccess')
    this.storePrimaryCompanyID$.subscribe(id => {
      this.selectedprimaryCompanyId = "";
      this.selectedprimaryCompanyId = id;
    });
    this.loadSurveyRoutes();
  }

  async loadSurveyRoutes(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await this.surveyRouteService.getAllSurveyRoute(
        {
          pageNumber : this.currentPage,
          pageSize : this.state.take?? 50,
          primaryCompanyId: this.selectedprimaryCompanyId ?? "",
        }
      );
  
      if (response?.items) {
        this.gridData ={
          data: response.items.filter(
            (item): item is SurveyRoute => item !== null),
            //total :response?.totalCount != null ? response?.totalCount : 0
            total : 50
        }
       
        this.originalData = this.gridData.data;
        this.isLoading = false;
        
      }
    } catch (error) {
      console.error(FETCHING_SURVEY_ROUTE_ERROR, error);
    }
  }

  public toggleDescription(id: string): void {
    this.expandedRows[id] = !this.expandedRows[id];
  }

  public isExpanded(id: string): boolean {
    return !!this.expandedRows[id];
  }

  getAssetNames(surveyRouteAssociatedAsset: SurveyRouteAssociatedAsset[]): string {
    return surveyRouteAssociatedAsset.map(asset => asset.assetName).filter(assetName => assetName !== null).join(", ");
  }

  createSurveyRoute() {
   
    this.dialog.open(AddSurveyRouteModalComponent, {
      data: { allowNavigation: true } // Directly pass the data object
    });
  }

  handleDropdownOption(option: number) {
    if (option === 2) {
      console.log("This will be replaced by other story , added to avoid checkin issues");
    } 
    
  }
  handleDropdownVisibilityChange(visible: boolean) {
    this.dropdownVisible = visible;
  }



  openDropdownMenu(event: MouseEvent, dataItem: any) {
    event.stopPropagation();
    this.dropdownVisible = true;
    this.currentDataItem = dataItem;
    this.rowId = this.currentDataItem.id;
    const gridElement = document.querySelector('.k-grid') as HTMLElement;
    const dropdownHeight = 100;
    const dropdownWidth = 200;

    if (gridElement) {
      const gridRect = gridElement.getBoundingClientRect();
      const clickX = event.clientX - gridRect.left + gridElement.scrollLeft;
      const clickY = event.clientY - gridRect.top + gridElement.scrollTop;

      const spaceBelow =
        window.innerHeight -
        (event.clientY - gridRect.top + gridElement.scrollTop);

      if (spaceBelow < dropdownHeight) {
        this.dropdownY = clickY - dropdownHeight;
      } else {
        this.dropdownY = clickY;
      }
      this.dropdownX = Math.max(
        0,
        Math.min(clickX, gridElement.clientWidth - dropdownWidth),
      );

      this.dropdownX += gridRect.left;
      this.dropdownY = Math.max(0, this.dropdownY + 205);
      if (this.dropdownY + dropdownHeight > window.innerHeight) {
        this.dropdownY = window.innerHeight - dropdownHeight;
      }
    }
    this.selectedRowData = dataItem;
    
  }

  customstate: { filter: CompositeFilterDescriptor } = {
    filter: { logic: 'and', filters: [] },
  };

  public state: State = {
    skip: 0,
    take: this.pageSize,
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.isLoading = true;
    this.state = state;
    this.currentPage = state.skip / this.pageSize + 1;
    this.loadSurveyRoutes();
  }

}
