<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div mat-dialog-content class="dialog-content">
  <div style="margin-right: 10px; margin-top: 10px">
    <div class="dialog-header">
      <div class="run-report-featuredicon"></div>
      <div class="dialog-close">
        <kendo-icon (click)="onClose()" name="close" size="medium"></kendo-icon>
      </div>
    </div>
    <div class="dialog-body" style="margin: 25px">
      <span class="report-modal-text">Run Report</span>
      <p class="report-modal-text02">
        Generate report to your customized format
      </p>
    </div>
    <form [formGroup]="runReportForm">
      <div class="mb-3" style="margin-left: 18px; margin-right: 32px">
        <div class="label-container">
          <label for="reportEmailID" class="form-label"
            >Enter Email ID <span class="asterisk">*</span></label
          >
          <span
            class="text-danger"
            style="padding-left: 10px"
            *ngIf="emailError"
            >{{ emailError }}</span
          >
        </div>
        <div class="email-input">
          <input
            type="email"
            matInput
            id="email"
            class="form-control"
            formControlName="email"
            [ngClass]="{
              'is-invalid': email?.invalid && (email?.dirty || email?.touched),
            }"
            placeholder="johndoe@gmail.com"
            [matChipInputFor]="chipGrid"
            (matChipInputTokenEnd)="addChip($event)"
          />
        </div>
        <mat-chip-grid class="email-list" #chipGrid aria-label="Email List">
          <div class="chip-row">
            <div style="margin-left: 10px">
              <mat-chip-row
                *ngFor="let email of emails"
                [removable]="true"
                (removed)="removeChip(email)"
              >
                {{ email }}
                <button matChipRemove>
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            </div>
          </div>
        </mat-chip-grid>
      </div>
    </form>
    <div mat-dialog-actions class="dialog-footer container">
      <div class="row">
        <div class="col-md-6">
          <button mat-dialog-close class="dialog-button-default"  (click)="onClose()">
            <strong>Cancel</strong>
          </button>
        </div>
        <div class="col-md-6">
          <button
            mat-button
            class="dialog-button run-report-button"
            (click)="runReport()"
          >
            <span style="padding-left: 10px;">Run Report</span> 
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
