<!-- create-single-asset.component.html -->
<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="col-auto">
  <h5 class="mtx-text-primary" style="margin-left: 10px; margin-top: 6px">
    <ng-container *ngIf="!isEdit; else editTemplate">
      Create Single Asset
    </ng-container>
    <ng-template #editTemplate> Edit Single Asset </ng-template>
  </h5>
</div>
<div class="asset-form card">
  <div class="container-fluid header-container m-3">
    <div class="line"></div>
    <div>
      <h5 style="font-size: 20px">
        <ng-container *ngIf="!isEdit; else editTemplate">
          Create Single Asset
        </ng-container>
        <ng-template #editTemplate> Edit Single Asset </ng-template>
      </h5>
      <p style="font-size: 15px">
        <ng-container *ngIf="!isEdit; else editTemplate">
          to add a single asset
        </ng-container>
        <ng-template #editTemplate> to edit a single asset </ng-template>
      </p>
    </div>
  </div>

  <form #assetForm="ngForm" novalidate>
    <div class="container-fluid p-4">
      <div class="row">
        <div class="col-md-4">
          <label for="primaryCompany" class="form-label">Primary Company</label>
          <input
            type="text"
            class="form-control"
            name="primaryCompany"
            [value]="primaryCompanyName"
            disabled
          />
        </div>
        <!-- Level 1 -->

        <div *ngIf="isLevel1visible" class="col-md-4">
          <label for="level1" class="form-label"
            >{{ level1 }} <span class="text-danger">*</span>
            <span
              *ngIf="level1Value.invalid && level1Value.touched"
              class="text-danger"
            >
              {{ level1 }} is required.
            </span></label
          >
          <kendo-dropdownlist
            [data]="level1Details"
            [defaultItem]="level1defaultItem"
            name="level1"
            [(ngModel)]="singleAssetInput.level1"
            textField="text"
            valueField="value"
            required
            #level1Value="ngModel"
            (valueChange)="level1DropDownChangeEvent($event)"
            [valuePrimitive]="true"
          ></kendo-dropdownlist>
        </div>

        <!-- Level 2 -->
        <div *ngIf="isLevel2visible" class="col-md-4">
          <label for="level2" class="form-label">
            {{ level2 }}<span class="text-danger">*</span>
            <span
              *ngIf="level2Value.invalid && level2Value.touched"
              class="text-danger"
            >
              {{ level2 }} is required.
            </span>
          </label>
          <kendo-dropdownlist
            [data]="level2Details"
            [defaultItem]="level2defaultItem"
            name="level2"
            [(ngModel)]="singleAssetInput.level2"
            textField="text"
            valueField="value"
            required
            #level2Value="ngModel"
            (valueChange)="level2DropDownChangeEvent($event)"
            [valuePrimitive]="true"
          ></kendo-dropdownlist>
        </div>
      </div>
      <div class="box-layer my-4 p-2">
        <div class="p-3">
          <p style="font-weight: 600; font-size: 16px">Asset Hierarchy</p>
          <div
            *ngIf="singleAssetInput.assetHierarchy.length > 0"
            class="row"
            style="color: #737373"
          >
            <div class="col-md-3">
              <label for="level3" class="form-label"
                >{{ level3 }} <span class="text-danger">*</span></label
              >
            </div>
            <div class="col-md-3">
              <label for="level4" class="form-label"
                >{{ level4 }} <span class="text-danger">*</span></label
              >
            </div>
            <div class="col-md-3">
              <label for="segmentRelation" class="form-label"
                >Segment Relation <span class="text-danger">*</span></label
              >
            </div>
            <div class="col-md-3">
              <label for="segmentType">Segment Type</label>
            </div>
          </div>
          <div
            *ngFor="let asset of singleAssetInput.assetHierarchy; let j = index"
          >
            <div class="row mb-4">
              <div class="col-md-3">
                <!-- Level 3 Dropdown with Validation -->
                <kendo-dropdownlist
                  [data]="level3Details"
                  [defaultItem]="level3defaultItem"
                  [(ngModel)]="asset.level3"
                  name="level3-{{ j }}"
                  required
                  #level3="ngModel"
                  textField="text"
                  valueField="value"
                  (valueChange)="level3DropDownChangeEvent($event, j)"
                  [valuePrimitive]="true"
                ></kendo-dropdownlist>
                <div
                  *ngIf="level3.invalid && level3.touched"
                  class="text-danger"
                >
                  {{ level3ValidationMsg }}
                </div>
              </div>

              <div class="col-md-3">
                <!-- Level 4 Dropdown with Validation -->
                <kendo-dropdownlist
                  [data]="asset.level4List"
                  [defaultItem]="level4defaultItem"
                  [(ngModel)]="asset.level4"
                  name="level4-{{ j }}"
                  required
                  #level4="ngModel"
                  textField="text"
                  valueField="value"
                  (valueChange)="level4DropDownValueChange($event, j)"
                  [valuePrimitive]="true"
                ></kendo-dropdownlist>
                <div
                  *ngIf="level4.invalid && level4.touched"
                  class="text-danger"
                >
                  {{ level4ValidationMsg }}
                </div>
              </div>

              <div class="col-md-3">
                <!-- Segment Relation Dropdown with Validation -->
                <kendo-dropdownlist
                  [data]="segmentRelation"
                  [(ngModel)]="asset.segmentRelation"
                  [disabled]="
                    asset.segmentRelation === 'Associated' && isPrimarySelected
                  "
                  name="segmentRelation-{{ j }}"
                  required
                  #segmentRelationdt="ngModel"
                  [defaultItem]="segmentRelationdefaultItem"
                  textField="text"
                  valueField="value"
                  (valueChange)="segmentRelationDropDownValueChange($event, j)"
                  [valuePrimitive]="true"
                ></kendo-dropdownlist>

                <div
                  *ngIf="segmentRelationdt.invalid && segmentRelationdt.touched"
                  class="text-danger"
                >
                  Segment Relation is required.
                </div>
              </div>

              <div class="col-md-2">
                <label for="segmentType" name="segmentType">{{
                  asset.segmentType
                }}</label>
              </div>
              <div class="col-md-1 d-flex justify-content-end">
                <button
                  *ngIf="singleAssetInput.assetHierarchy.length > 1"
                  class="k-icon k-font-icon k-i-close-outline k-i-x-outline k-i-error delete-btn"
                  type="button"
                  (click)="deleteAsset(j)"
                ></button>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <button
              class="btn btn-outline-primary"
              id="assignHierarchyBtn"
              type="button"
              (click)="addAssetDetails()"
            >
              <i class="k-icon k-font-icon k-i-plus"></i> Assign Hierarchy
            </button>
          </div>
        </div>
      </div>

      <div class="row my-4">
        <div class="col-md-3">
          <label for="assetType" class="form-label"
            >Asset Type <span class="text-danger">*</span>
            <span
              *ngIf="assetTypeValue.invalid && assetTypeValue.touched"
              class="text-danger"
            >
              Asset Type is required.
            </span></label
          >
          <kendo-dropdownlist
            [data]="assetType"
            [defaultItem]="assetTypedefaultItem"
            textField="text"
            name="assetType"
            valueField="value"
            [valuePrimitive]="true"
            (valueChange)="onAssetTypeChange($event)"
            required
            #assetTypeValue="ngModel"
            [(ngModel)]="singleAssetInput.assetType"
          ></kendo-dropdownlist>
        </div>
        <div class="col-md-3">
          <label for="assetTemplate" class="form-label"
            >Asset Template <span class="text-danger">*</span>
            <span
              *ngIf="assetTypeValue.invalid && assetTypeValue.touched"
              class="text-danger"
            >
              Asset Template is required.
            </span></label
          >
          <kendo-dropdownlist
            [data]="assetTemplates"
            [defaultItem]="assetTemplatedefaultItem"
            textField="text"
            name="assetTemplate"
            valueField="value"
            [valuePrimitive]="true"
            (valueChange)="onAssetTemplateChange($event)"
            required
            #assetTempateValue="ngModel"
            [disabled]="isDisabledAssetTemplate"
            [(ngModel)]="singleAssetInput.assetTemplate"
          ></kendo-dropdownlist>
        </div>
        <div class="col-md-6">
          <label for="assetName" class="form-label"
            >Asset Name <span class="text-danger">*</span>
            <span
              *ngIf="assetName.invalid && assetName.touched"
              class="text-danger"
            >
              Asset Name is required.
            </span></label
          >
          <input
            type="text"
            class="form-control"
            required
            #assetName="ngModel"
            placeholder="Enter Asset Name"
            name="assetName"
            [(ngModel)]="singleAssetInput.assetName"
          />
        </div>
      </div>

      <div class="box-layer my-4 p-2 table-container">
        <div class="p-3">
          <p style="font-weight: 600; font-size: 16px">
            Measurement Types and Criteria
          </p>
          <!-- table  -->
          <table
            class="table table-bordered align-middle text-center"
            *ngIf="singleAssetInput.measurementCriteriaInputs?.length > 0"
          >
            <!-- Table Header -->
            <thead class="table-light">
              <tr>
                <th>Measurement Source</th>
                <th>Measurement Type</th>
                <th>Operator</th>
                <th>Associated Metadata</th>
                <th>Criteria / Threshold</th>
                <th>Segment</th>
                <th>
                  Segment <br />
                  Relation
                </th>
                <th>
                  Compliance <br />
                  Measurement
                </th>
                <th>
                  View in <br />
                  Main Grid
                </th>
                <th></th>
              </tr>
            </thead>

            <!-- Table Body -->
            <tbody>
              <tr
                class="measurementrows"
                *ngFor="
                  let measurement of singleAssetInput.measurementCriteriaInputs;
                  let j = index
                "
              >
                <!-- Measurement Source -->
                <td *ngIf="!isMeasurementSourceDropdown">
                  <input
                    id="source-{{ j }}"
                    type="text"
                    class="form-control"
                    placeholder="+ Meas. Source"
                    name="measurementSource{{ j }}"
                    [(ngModel)]="measurement.measurementSource"
                  />
                </td>

                <td *ngIf="isMeasurementSourceDropdown">
                  <kendo-dropdownlist
                    [data]="measurementSourceForUI"
                    name="measurementSource{{ j }}"
                    [(ngModel)]="measurement.measurementSource"
                    [valuePrimitive]="true"
                    [defaultItem]="measurementSourceForUIdefaultItem"
                    (valueChange)="
                      onMeasurementSourceChange($event, measurement)
                    "
                    textField="text"
                    valueField="value"
                  >
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                      <span
                        [ngClass]="{ 'label-css': dataItem.value === '' }"
                        >{{ dataItem.text }}</span
                      >
                    </ng-template>
                  </kendo-dropdownlist>
                </td>

                <!-- Measurement Type -->
                <td>
                  <kendo-dropdownlist
                    [data]="
                      isMeasurementSourceDropdown
                        ? measurement.measurementTypesForUI
                        : measurementTypesForUI
                    "
                    name="measurementType{{ j }}"
                    [(ngModel)]="measurement.measurementType"
                    [valuePrimitive]="true"
                    (open)="onMeasurementTypeOpen(measurement)"
                    [defaultItem]="measurementTypesdefaultItem"
                    (valueChange)="onTypeChange(measurement)"
                    textField="text"
                    valueField="value"
                  >
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                      <span
                        [ngClass]="{ 'label-css': dataItem.value === '' }"
                        >{{ dataItem.text }}</span
                      >
                    </ng-template>
                  </kendo-dropdownlist>
                </td>

                <!-- Operator -->
                <td>
                  <kendo-dropdownlist
                    [data]="operatorOptions"
                    name="measurementOperator{{ j }}"
                    [(ngModel)]="measurement.measurementOperator"
                    [valuePrimitive]="true"
                    [defaultItem]="operatorDefaultItem"
                    textField="text"
                    valueField="value"
                  >
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                      <span
                        [ngClass]="{ 'label-css': dataItem.value === '' }"
                        >{{ dataItem.text }}</span
                      >
                    </ng-template>
                  </kendo-dropdownlist>
                </td>

                <!-- Associated Metadata -->
                <td>
                  <input
                    type="text"
                    class="form-control"
                    name="metadata-1-{{ j }}"
                    id="metadata-1-{{ j }}"
                    [required]="measurement.associatedMetadataKey"
                    #associatedmetadata="ngModel"
                    name="associatedmetadata"
                    [(ngModel)]="measurement.associatedMetadataValue"
                    [placeholder]="
                      measurement.associatedMetadataKey
                        ? measurement.associatedMetadataKey
                        : '-'
                    "
                    [disabled]="isMetaDataDisabled(measurement)"
                  />
                  @if (measurement.associatedMetadataKey) {
                    <span
                      *ngIf="
                        associatedmetadata.invalid && associatedmetadata.touched
                      "
                      class="text-danger"
                    >
                      Associated Metadata is required.
                    </span>
                  }
                </td>

                <!-- Criteria / Threshold -->
                <td>
                  <!-- Criteria -->
                  @if (measurement.compliance) {
                    <!-- if complience -->
                    <div
                      *ngIf="measurement.measurementOperator === 'between'"
                      class="d-flex"
                    >
                      <div class="input-with-unit">
                        <kendo-dropdownlist
                          [data]="criteriaOptions"
                          name="criteria-1-{{ j }}"
                          required
                          #criteria1Value="ngModel"
                          [(ngModel)]="measurement.criteria1"
                          [valuePrimitive]="true"
                          [defaultItem]="criteriaDefaultItem"
                          textField="text"
                          valueField="value"
                          (valueChange)="segmentDropDownChange($event, j)"
                        >
                          <ng-template
                            kendoDropDownListValueTemplate
                            let-dataItem
                          >
                            <span
                              [ngClass]="{ 'label-css': dataItem.value === '' }"
                              >{{ dataItem.text }}</span
                            >
                          </ng-template>
                        </kendo-dropdownlist>
                      </div>
                      <div class="input-with-unit ms-1">
                        <kendo-dropdownlist
                          [data]="criteriaOptions"
                          name="criteria-2-{{ j }}"
                          required
                          #criteriaValue="ngModel"
                          [(ngModel)]="measurement.criteria2"
                          [valuePrimitive]="true"
                          [defaultItem]="criteriaDefaultItem"
                          textField="text"
                          valueField="value"
                          (valueChange)="segmentDropDownChange($event, j)"
                        >
                          <ng-template
                            kendoDropDownListValueTemplate
                            let-dataItem
                          >
                            <span
                              [ngClass]="{ 'label-css': dataItem.value === '' }"
                              >{{ dataItem.text }}</span
                            >
                          </ng-template>
                        </kendo-dropdownlist>
                      </div>
                    </div>
                    <div
                      *ngIf="measurement.measurementOperator !== 'between'"
                      class="input-with-unit"
                    >
                      <kendo-dropdownlist
                        [data]="criteriaOptions"
                        name="criteria-1-{{ j }}"
                        required
                        #criteriaValue="ngModel"
                        [(ngModel)]="measurement.criteria"
                        [valuePrimitive]="true"
                        [defaultItem]="criteriaDefaultItem"
                        textField="text"
                        valueField="value"
                        (valueChange)="segmentDropDownChange($event, j)"
                      >
                        <ng-template
                          kendoDropDownListValueTemplate
                          let-dataItem
                        >
                          <span
                            [ngClass]="{ 'label-css': dataItem.value === '' }"
                            >{{ dataItem.text }}</span
                          >
                        </ng-template>
                      </kendo-dropdownlist>
                    </div>
                    <!-- Threshold -->
                  } @else {
                    <!-- if not complience -->
                    <div
                      *ngIf="measurement.measurementOperator === 'between'"
                      class="d-flex"
                    >
                      <div class="input-with-unit">
                        <input
                          id="Threshold-1-{{ j }}"
                          type="text"
                          class="form-control"
                          placeholder="Enter"
                          name="threshold1{{ j }}"
                          [(ngModel)]="measurement.threshold1"
                          (keypress)="allowOnlyNumbersAndSymbols($event)"
                        />
                        <span class="unit">{{ measurement.unit }}</span>
                      </div>
                      <div class="input-with-unit ms-1">
                        <input
                          id="threshold-2-{{ j }}"
                          type="text"
                          class="form-control"
                          placeholder="Enter"
                          name="threshold2-{{ j }}"
                          [(ngModel)]="measurement.threshold2"
                          (keypress)="allowOnlyNumbersAndSymbols($event)"
                        />
                        <span class="unit">{{ measurement.unit }}</span>
                      </div>
                    </div>
                    <div
                      *ngIf="measurement.measurementOperator !== 'between'"
                      class="input-with-unit"
                    >
                      <input
                        id="threshold-{{ j }}"
                        type="text"
                        class="form-control"
                        placeholder="Enter Threshold"
                        name="threshold-{{ j }}"
                        [(ngModel)]="measurement.threshold"
                        (keypress)="allowOnlyNumbersAndSymbols($event)"
                      />
                      <span class="unit">{{ measurement.unit }}</span>
                    </div>
                  }
                </td>

                <!-- Segment -->
                <td>
                  <kendo-dropdownlist
                    [data]="distinctedSegmentValues"
                    name="distinctedSegmentValues{{ j }}"
                    required
                    #distinctedSegment="ngModel"
                    [(ngModel)]="measurement.level4ValueId"
                    [valuePrimitive]="true"
                    [defaultItem]="segmentDefaultItem"
                    textField="text"
                    valueField="value"
                    (valueChange)="segmentDropDownChange($event, j)"
                  >
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                      <span
                        [ngClass]="{ 'label-css': dataItem.value === '' }"
                        >{{ dataItem.text }}</span
                      >
                    </ng-template>
                  </kendo-dropdownlist>
                  <div
                    *ngIf="
                      distinctedSegment.invalid && distinctedSegment.touched
                    "
                    class="text-danger error-message"
                  >
                    Select Segment.
                  </div>
                </td>

                <!-- Segment Relation -->
                <td>
                  <label for="segmentRelation">
                    {{ measurement.segmentRelation }}</label
                  >
                </td>

                <!-- Compliance Measurement -->
                <td class="checkbox-cell">
                  <input
                    id="compliance-{{ j }}"
                    type="checkbox"
                    class="form-check-input"
                    name="compliance{{ j }}"
                    (change)="complianceChange($event.target, j, measurement)"
                    [(ngModel)]="measurement.compliance"
                  />
                </td>

                <!-- View in Main Grid -->
                <td class="checkbox-cell">
                  <input
                    id="viewmaingrid-{{ j }}"
                    type="checkbox"
                    class="form-check-input"
                    name="viewmaingrid{{ j }}"
                    [(ngModel)]="measurement.viewInMainGrid"
                  />
                </td>

                <!-- Action -->
                <td>
                  <button
                    *ngIf="
                      singleAssetInput.measurementCriteriaInputs.length > 1
                    "
                    class="k-icon k-font-icon k-i-close-outline k-i-x-outline k-i-error delete-btn"
                    type="button"
                    (click)="deleteMeasurement(j)"
                  ></button>
                </td>
              </tr>

              <!-- Add New Row -->
            </tbody>
          </table>
          <!-- Labels for Measurement Fields (only rendered once) -->
          <!-- <div
            *ngIf="singleAssetInput.measurementCriteriaInputs.length > 0"
            class="row"
            style="color: #737373"
          >
            <div class="col-md-2">
              <label for="measurementSource">Measurement Source</label>
            </div>
            <div class="col-md-2">
              <label for="measurementType">Measurement Type</label>
            </div>
            <div class="col-md-2">
              <label for="operator">Operator</label>
            </div>
            <div class="col-md-2">
              <label for="criteria">Criteria</label>
            </div>
            <div class="col-md-2">
              <label for="segment">Segment</label>
            </div>
            <div class="col-md-2">
              <label for="segmentRelation">Segment Relation</label>
            </div>
            <div class="col-md-1">
              <label for="complianceMeas">Compliance Meas?</label>
            </div>
          </div> -->
        </div>
        <!-- <div
          *ngFor="
            let measurement of singleAssetInput.measurementCriteriaInputs;
            let j = index
          "
        >
          <div *ngIf="!measurement.isDeleted" class="row p-3">
            <div class="col-md-2">
              <input
                id="source-{{ j }}"
                type="text"
                class="form-control"
                placeholder="+ Meas. Source"
                name="measurementSource{{ j }}"
                [(ngModel)]="measurement.measurementSource"
              />
            </div>
            <div class="col-md-2">
              <kendo-dropdownlist
                [data]="measurementTypes"
                name="measurementType{{ j }}"
                [(ngModel)]="measurement.measurementType"
                [valuePrimitive]="true"
                [defaultItem]="measurementTypesdefaultItem"
                (valueChange)="onTypeChange(measurement)"
                textField="text"
                valueField="value"
              >
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span [ngClass]="{ 'label-css': dataItem.value === '' }">{{
                    dataItem.text
                  }}</span>
                </ng-template>
              </kendo-dropdownlist>
            </div>
            <div class="col-md-2">
              <kendo-dropdownlist
                [data]="operatorOptions"
                name="measurementOperator{{ j }}"
                [(ngModel)]="measurement.measurementOperator"
                [valuePrimitive]="true"
                [defaultItem]="operatorDefaultItem"
                textField="text"
                valueField="value"
              >
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span [ngClass]="{ 'label-css': dataItem.value === '' }">{{
                    dataItem.text
                  }}</span>
                </ng-template>
              </kendo-dropdownlist>
            </div>
            <div class="col-md-2">
              <div
                *ngIf="measurement.measurementOperator === 'between'"
                class="d-flex"
              >
                <div class="input-with-unit">
                  <input
                    id="criteria-1-{{ j }}"
                    type="text"
                    class="form-control"
                    placeholder="Enter"
                    name="criteria1{{ j }}"
                    [(ngModel)]="measurement.criteria1"
                    (keypress)="allowOnlyNumbersAndSymbols($event)"
                  />
                  <span class="unit">{{ measurement.unit }}</span>
                </div>
                <div class="input-with-unit ms-1">
                  <input
                    id="criteria-2-{{ j }}"
                    type="text"
                    class="form-control"
                    placeholder="Enter"
                    name="criteria2{{ j }}"
                    [(ngModel)]="measurement.criteria2"
                    (keypress)="allowOnlyNumbersAndSymbols($event)"
                  />
                  <span class="unit">{{ measurement.unit }}</span>
                </div>
              </div>
              <div
                *ngIf="measurement.measurementOperator !== 'between'"
                class="input-with-unit"
              >
                <input
                  id="criteria-{{ j }}"
                  type="text"
                  class="form-control"
                  placeholder="Enter Criteria"
                  name="criteria{{ j }}"
                  [(ngModel)]="measurement.criteria"
                  (keypress)="allowOnlyNumbersAndSymbols($event)"
                />
                <span class="unit">{{ measurement.unit }}</span>
              </div>
            </div>
            <div class="col-md-2">
              <kendo-dropdownlist
                [data]="distinctedSegmentValues"
                name="distinctedSegmentValues{{ j }}"
                required
                #distinctedSegment="ngModel"
                [(ngModel)]="measurement.level4ValueId"
                [valuePrimitive]="true"
                [defaultItem]="segmentDefaultItem"
                textField="text"
                valueField="value"
                (valueChange)="segmentDropDownChange($event, j)"
              >
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  <span [ngClass]="{ 'label-css': dataItem.value === '' }">{{
                    dataItem.text
                  }}</span>
                </ng-template>
              </kendo-dropdownlist>
              <div
                *ngIf="distinctedSegment.invalid && distinctedSegment.touched"
                class="text-danger"
              >
                Select Segment.
              </div>
            </div>
            <div class="col-md-2">
              <label for="segmentRelation">
                {{ measurement.segmentRelation }}</label
              >
            </div>
            <div
              class="col-md-1 d-flex justify-content-between align-items-center"
            >
              <div class="col-md-6 d-flex justify-content-center">
                <input
                  id="compliance-{{ j }}"
                  type="checkbox"
                  class="form-check-input"
                  name="compliance{{ j }}"
                  [(ngModel)]="measurement.compliance"
                />
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <button
                  *ngIf="singleAssetInput.measurementCriteriaInputs.length > 1"
                  class="k-icon k-font-icon k-i-close-outline k-i-x-outline k-i-error delete-btn"
                  type="button"
                  (click)="deleteMeasurement(j)"
                ></button>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-md-3 p-3">
          <button
            type="button"
            class="btn btn-outline-primary"
            id="addNewMeasurement"
            (click)="addNewMeasurement()"
          >
            <i class="k-icon k-font-icon k-i-plus"></i> Add New Measurement
          </button>
        </div>
      </div>
      <div class="col-md-6 py-1">
        <label for="inspectionDate" class="form-label label-css"
          >Date of Inspection</label
        >
        <kendo-datepicker
          id="inspectionDate"
          name="inspectionDate"
          [(ngModel)]="singleAssetInput.lastInspectionDate"
        ></kendo-datepicker>
      </div>
      <div class="row col-md-12 py-3">
        <div class="col-md-6">
          <label for="mappingAssetId" class="form-label"
            >Mapping Asset ID</label
          >
          <input
            type="text"
            class="form-control"
            name="mappingAssetId"
            [(ngModel)]="singleAssetInput.mappingAssetId"
          />
        </div>
        <div class="col-md-6">
          <label for="milestone" class="form-label"
            >Mileage <span class="text-danger">*</span>
            <span
              *ngIf="milestoneValue.invalid && milestoneValue.touched"
              class="text-danger"
            >
              Mileage is required.
            </span>
          </label>
          <input
            type="text"
            class="form-control"
            name="milestone"
            required
            #milestoneValue="ngModel"
            [(ngModel)]="singleAssetInput.milestone"
          />
        </div>
      </div>
      <div class="col-md-6 py-3">
        <label for="effectiveDate" class="form-label label-css"
          >Date of Creation <span class="text-danger">*</span>
          <span
            *ngIf="effectiveDateValue.invalid && effectiveDateValue.touched"
            class="text-danger"
          >
            Date of Creation is required.
          </span>
        </label>
        <kendo-datepicker
          id="effectiveDate"
          name="effectiveDate"
          required
          #effectiveDateValue="ngModel"
          [(ngModel)]="singleAssetInput.effectiveDate"
        ></kendo-datepicker>
      </div>
      <div class="row col-md-12 py-3">
        <div class="col-md-6">
          <label for="latitude" class="form-label"
            >Latitude <span class="text-danger">*</span>
            <span
              *ngIf="
                latitudeValue.touched && latitudeValue.errors?.['required']
              "
              class="text-danger"
            >
              Latitude is required.
            </span>
            <span
              *ngIf="latitudeValue.errors?.['pattern'] && latitudeValue.touched"
              class="text-danger"
            >
              Please enter a valid latitude.
            </span>
          </label>
          <input
            type="text"
            class="form-control"
            name="latitude"
            required
            #latitudeValue="ngModel"
            [pattern]="latitudePattern"
            [(ngModel)]="singleAssetInput.latitude"
          />
        </div>
        <div class="col-md-6">
          <label for="longitude" class="form-label"
            >Longitude <span class="text-danger">*</span>
            <span
              *ngIf="
                longitudeValue.touched && longitudeValue.errors?.['required']
              "
              class="text-danger"
            >
              Longitude is required.
            </span>
            <span
              *ngIf="
                longitudeValue.errors?.['pattern'] && longitudeValue.touched
              "
              class="text-danger"
            >
              Please enter a valid latitude.
            </span>
          </label>
          <input
            type="text"
            class="form-control"
            name="longitude"
            required
            #longitudeValue="ngModel"
            [pattern]="longitudePattern"
            [(ngModel)]="singleAssetInput.longitude"
          />
        </div>
      </div>
      <div class="col-md-12">
        <label for="comments" class="form-label">Comments</label>
        <textarea
          class="form-control"
          name="comments"
          [(ngModel)]="singleAssetInput.comments"
        ></textarea>
      </div>
      <div class="col-md-12">
        <label for="locationDescription" class="form-label"
          >Location Description</label
        >
        <textarea
          class="form-control"
          name="locationDescription"
          [(ngModel)]="singleAssetInput.locationDescription"
        ></textarea>
      </div>
      <div *ngIf="selectedAssetTypeName === 'Rectifier'">
        <div class="row col-md-12 py-3">
          <div class="col-md-6">
            <label for="rectifierManufacturer" class="form-label"
              >Rectifier Manufacturer
            </label>
            <input
              type="text"
              class="form-control"
              name="rectifierManufacturer"
              [(ngModel)]="singleAssetInput.rectifierType.rectifierManufacturer"
            />
          </div>
          <div class="col-md-6">
            <label for="dateOfCommissioning" class="form-label"
              >Rectifier Date of Commissioning
            </label>
            <kendo-datepicker
              id="dateOfCommissioning"
              name="dateOfCommissioning"
              [(ngModel)]="singleAssetInput.rectifierType.dateOfCommissioning"
            ></kendo-datepicker>
          </div>
        </div>
        <div class="row col-md-12 py-3">
          <div class="col-md-6">
            <label for="rectifierType" class="form-label"
              >Rectifier Type
            </label>
            <input
              type="text"
              class="form-control"
              name="rectifierType"
              [(ngModel)]="singleAssetInput.rectifierType.rectifierType"
            />
          </div>
          <div class="col-md-6">
            <label for="outputVoltage" class="form-label"
              >Rectifier Max Output Voltage</label
            >
            <input
              type="text"
              class="form-control"
              name="outputVoltage"
              [(ngModel)]="singleAssetInput.rectifierType.outputVoltage"
            />
          </div>
        </div>
        <div class="row col-md-12 py-3">
          <div class="col-md-6">
            <label for="outputAmperage" class="form-label"
              >Rectifier Max Output Amperage</label
            >
            <input
              type="text"
              class="form-control"
              name="outputAmperage"
              [(ngModel)]="singleAssetInput.rectifierType.outputAmperage"
            />
          </div>
          <div class="col-md-6">
            <label for="shuntValues" class="form-label"
              >Rectifier Shunt Values</label
            >
            <input
              type="text"
              class="form-control"
              name="shuntValues"
              [(ngModel)]="singleAssetInput.rectifierType.shuntValues"
            />
          </div>
        </div>
        <div class="row col-md-12 py-3">
          <div class="col-md-6">
            <label for="meterNumber" class="form-label"
              >Rectifier Meter Number</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.rectifierType.meterNumber"
            />
          </div>
          <div class="col-md-6">
            <label for="groundbedType" class="form-label"
              >Groundbed Type (Conventional Vs. MMO)</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.rectifierType.groundbedType"
            />
          </div>
        </div>
        <div class="row col-md-12 py-3">
          <div class="col-md-6">
            <label for="groundbedOrientation" class="form-label"
              >Groundbed Orientation (Deep Vs Shallow)</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.rectifierType.groundbedOrientation"
            />
          </div>
          <div class="col-md-6">
            <label for="anodeType" class="form-label">Anode Type</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.rectifierType.anodeType"
            />
          </div>
        </div>
        <div class="row col-md-12 py-3">
          <div class="col-md-6">
            <label for="numberOfAnodes" class="form-label"
              >Number of anodes</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.rectifierType.numberOfAnodes"
            />
          </div>
          <div class="col-md-6">
            <label for="groundbedDateOfCommissioning" class="form-label"
              >Groundbed Date of Commissioning</label
            >
            <kendo-datepicker
              id="groundbedDateOfCommissioning"
              [(ngModel)]="
                singleAssetInput.rectifierType.groundbedDateOfCommissioning
              "
            ></kendo-datepicker>
          </div>
        </div>
        <div class="row col-md-12 py-3">
          <div class="col-md-6">
            <label for="backfillMass" class="form-label">Backfill Mass</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.rectifierType.backfillMass"
            />
          </div>
          <div class="col-md-6">
            <label for="backfillDiameter" class="form-label"
              >Backfill diameter if deepwell</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.rectifierType.backfillDiameter"
            />
          </div>
        </div>
        <div class="col-md-12">
          <label for="maintenanceDetails" class="form-label"
            >Maintenance details(rehydration,known rectifier off periods)
          </label>
          <textarea
            class="form-control"
            [(ngModel)]="singleAssetInput.rectifierType.maintenanceDetails"
          ></textarea>
        </div>
      </div>
      <div *ngIf="selectedAssetTypeName === 'Bond'">
        <div class="col-md-6">
          <label for="bondShuntValue" class="form-label"
            >Bond Shunt Value</label
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="singleAssetInput.bondType.bondShuntValue"
          />
        </div>
      </div>
      <div *ngIf="selectedAssetTypeName === 'Test Stations'">
        <div class="row col-md-12 py-3">
          <div class="col-md-6">
            <label for="electrodeManufacturer" class="form-label"
              >Ref. Electrode Manufacturer</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.testStations.electrodeManufacturer"
            />
          </div>
          <div class="col-md-6">
            <label for="electrodeType" class="form-label"
              >Ref. Electrode Type</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.testStations.electrodeType"
            />
          </div>
        </div>
        <div class="row col-md-12 py-3">
          <div class="col-md-6">
            <label for="electrodeChemistry" class="form-label"
              >Ref. Electrode Chemistry</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.testStations.electrodeChemistry"
            />
          </div>
          <div class="col-md-6">
            <label for="electrodeInstallationDate" class="form-label"
              >Ref. Electrode Installation Date</label
            >
            <kendo-datepicker
              id="electrodeInstallationDate"
              [(ngModel)]="
                singleAssetInput.testStations.electrodeInstallationDate
              "
            ></kendo-datepicker>
          </div>
        </div>
        <div class="row col-md-12 py-3">
          <div class="col-md-6">
            <label for="acCouponSize" class="form-label"
              >AC Coupon Size (m^2)</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.testStations.acCouponSize"
            />
          </div>
          <div class="col-md-6">
            <label for="dcCouponSize" class="form-label"
              >DC Coupon Size (m^2)</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.testStations.dcCouponSize"
            />
          </div>
        </div>
      </div>
      <div *ngIf="selectedAssetTypeName === 'ER Probes'">
        <div class="row col-md-12 py-3">
          <div class="col-md-6">
            <label for="probeManufacturer" class="form-label"
              >ER Probe manufacturer</label
            >
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.eRProbes.probeManufacturer"
            />
          </div>
          <div class="col-md-6">
            <label for="probeType" class="form-label">ER Probe Type</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="singleAssetInput.eRProbes.probeType"
            />
          </div>
        </div>
        <div class="row col-md-6 py-3">
          <div class="col-md-12">
            <label for="installDate" class="form-label">Install date</label>
            <kendo-datepicker
              id="installDate"
              [(ngModel)]="singleAssetInput.eRProbes.installDate"
            ></kendo-datepicker>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-12">
          <div class="label-container" style="margin-bottom: 5px">
            <label
              for="assetImage"
              style="margin-bottom: 5px; font-weight: 500"
            >
              Upload Asset Image
              <span *ngIf="!isEdit" class="asterisk">*</span>
              <span
                *ngIf="uploadedFileUrl.length == 0 && isImageUploadTouched"
                class="text-danger"
              >
                Please Upload Image.
              </span>
            </label>
          </div>
          <div class="p-2 upload-area">
            <label class="custom-file-upload">
              <input
                type="file"
                id="assetImage"
                (change)="onFileSelected($event)"
                (change)="onFileInputChange($event)"
                accept=".jpg, .jpeg, .png"
                required
                #assetImageValue
                name="assetImage"
                #fileInput
              />
              Upload Image
            </label>
            <span style="margin-left: 15px">
              {{
                uploadedFileName.length > 0
                  ? uploadedFileName.join(' ')
                  : 'Upload logo in jpg, jpeg, or png format'
              }}
            </span>
          </div>
        </div>
      </div>

      <div
        *ngIf="uploadedFileUrl && uploadedFileUrl.length > 0"
        class="uploaded-file-info groupLayout-left"
        style="margin-bottom: 5px; position: relative"
      >
        <ng-container *ngFor="let url of uploadedFileUrl; let i = index">
          <span class="image-icon">
            <img
              [src]="url"
              alt="Logo Preview"
              style="max-width: 120px; max-height: 100px"
            />
          </span>
          <span class="icon-group close-icon" (click)="removeFile(i)">
            <kendo-icon name="close"></kendo-icon>
          </span>
        </ng-container>
      </div>
    </div>
    <div class="card-footer d-grid d-md-flex justify-content-md-end">
      <div style="margin-top: 10px">
        <button
          class="btn button btn-cancel"
          type="button"
          (click)="onCancel()"
        >
          <span style="font-weight: 500"> Cancel </span>
        </button>
        <button
          class="btn button btn-cancel mtx-font-weight-500"
          (click)="onSaveAndExit(assetForm)"
          type="button"
        >
          <span style="font-weight: 500"> Save & Exit </span>
        </button>
        <button
          class="btn button mtx-btn-primary"
          (click)="onSaveAndAddNew(assetForm)"
          type="button"
        >
          <span style="font-weight: 500"> Save & Add New </span>
        </button>
      </div>
    </div>
  </form>
</div>
