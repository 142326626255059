import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ReportManagementService } from '../../core/services/report-management.service';
import { ReportManagement } from '../../core/models/report-management.module';
import {
  AUDIT,
  DEFAULT,
  DEFAULTPAGESIZE,
  EDIT_MODE_REPORT,
  EMPTY_STRING,
  LONG,
  ND,
  PAGINATION_ARRAY,
  PERFORMANCE,
  RD,
  REDIRECT_CREATE_REPORT_FROM_MANAGEMENT,
  REDIRECT_DETAILS_FROM_MANAGEMENT,
  REDIRECT_EDIT_REPORT,
  REPORT,
  REPORT_ELLIPSIS_WIDTH,
  REPORT_TYPE,
  SEARCHREPORTS,
  ST,
  TH,
  ZERO,
  REPORT_MANAGEMENT_SCREEN,
  FETCHING_REPORT_ERROR,
} from '../../shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  DataStateChangeEvent,
  FilterService,
  GridComponent,
  RowArgs,
  RowStickyFn,
} from '@progress/kendo-angular-grid';
import { Store } from '@ngxs/store';
import { SetTemplate } from '../../core/store/template.state';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
} from '@progress/kendo-data-query';
import { PermissionService } from '../../core/services/permission.service';
import { Observable } from 'rxjs';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { KendoGridFilters, Report } from '../../../awsAppSync/API';
import { LastEvaluatedKeyModel } from '../../core/models/lastevaluatedkey';

@Component({
  selector: 'app-report-management',
  templateUrl: './report-management.component.html',
  styleUrl: './report-management.component.css',
})
export class ReportManagementComponent implements OnInit {
  @ViewChild('grid') grid!: GridComponent;
  isFrom = EMPTY_STRING;
  public searchPlaceholder = SEARCHREPORTS;
  public originalData: any[] = [];

  public isAuditReport = true;
  public isPerformanceReport = true;
  currentDataItem: any;
  isStickyRow = false;
  public myStickyRows: string[] = [];
  selectedRowData: any;
  public rowId = ZERO;
  gridData: ReportManagement[] = [];
  public expandedRows: Record<string, boolean> = {};
  dropdownVisible = false;
  dropdownX = ZERO;
  dropdownY = ZERO;
  ellipsisWidth = REPORT_ELLIPSIS_WIDTH;
  public inputScreen = REPORT;
  public reportTypeFilterValues: any[] = [];
  @ViewChild('customFilterContainer', { static: false })
  customFilter!: ElementRef;
  permission = false;
  //
  public isLoading = false;
  public skip = ZERO;
  currentPage = 1;
  pageChanged = false;
  public gridDataToExport: any[] = [];
  searchText = '';
  public PAGINATION_ARRAY = PAGINATION_ARRAY;
  public pageSize = DEFAULTPAGESIZE;
  reportTypes = [AUDIT, PERFORMANCE];
  storePrimaryCompanyID$: Observable<string | null>;
  selectedprimaryCompanyId: string | null = '';
  lastEvaluatedKey: LastEvaluatedKeyModel | null = null;
  public nextKey: LastEvaluatedKeyModel | null = null;
  public previousKey: LastEvaluatedKeyModel | null = null;
  kendoGridFilters: KendoGridFilters = {};

  constructor(
    private readonly reportManagementService: ReportManagementService,
    public dialog: MatDialog,
    private readonly router: Router,
    private readonly store: Store,
    public permissionService: PermissionService,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
  }
  ngOnInit() {
    this.permission = this.permissionService.hasPermission(
      REPORT_MANAGEMENT_SCREEN,
      'fullAccess',
    );
    this.storePrimaryCompanyID$.subscribe((id) => {
      this.selectedprimaryCompanyId = '';
      this.selectedprimaryCompanyId = id;
    });

    this.getAllReports();
  }

  async getAllReports() {
    try {
      this.isLoading = true;
      let reportType = '';
      if (this.isAuditReport && !this.isPerformanceReport) {
        reportType = AUDIT;
      } else if (this.isPerformanceReport && !this.isAuditReport) {
        reportType = PERFORMANCE;
      }
      const response = await this.reportManagementService.getAllReports({
        searchText: this.searchText,
        pageSize: this.state.take ?? 50,
        reportType: reportType,
        pageNumber: this.currentPage,
        primaryCompanyId: this.selectedprimaryCompanyId ?? '',
        lastEvaluatedKey: this.currentPage == 1 ? null : this.nextKey,
      });
      if (response?.items) {
        this.gridData = response.items
          ?.filter((item): item is Report => item != null)
          .map((item: Report) => ({
            id: item.id,
            reportName: item.reportName,
            description: item.reportDescription,
            formattedCreatedDate: this.formatDate(new Date(item.createdDate)),
            formattedExportedDate: null,
            reportType: item.reportType,
            createdDate: new Date(item.createdDate),
            exportedDate: item.updatedDate ? new Date(item.updatedDate) : null,
            createdBy: item.createdBy ? item.createdBy : '',
            schedule: item.isRecurringSchedule ? item.isRecurringSchedule : false,
          }));

        if (response.lastEvaluatedKey != null) {
          if (response.lastEvaluatedKey.pk != null) {
            this.lastEvaluatedKey = {
              pk: response?.lastEvaluatedKey?.pk,
              sk: response?.lastEvaluatedKey?.sk,
            };
          } else {
            this.lastEvaluatedKey = null;
          }
        } else {
          this.lastEvaluatedKey = null;
        }
        this.nextKey = this.lastEvaluatedKey;
        this.originalData = this.gridData;
        this.isLoading = false;
        this.getDistinctStatusValues(REPORT_TYPE);
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      }
      this.originalData = [...this.gridData];
    } catch (error) {
      console.error(FETCHING_REPORT_ERROR, error);
    }
  }

  handleDropdownVisibilityChange(visible: boolean) {
    this.dropdownVisible = visible;
  }

  openAddReportPage() {
    this.router.navigate([REDIRECT_CREATE_REPORT_FROM_MANAGEMENT]);
  }

  openDropdownMenu(event: MouseEvent, dataItem: any) {
    event.stopPropagation();
    this.dropdownVisible = true;
    this.currentDataItem = dataItem;
    this.rowId = this.currentDataItem.id;
    const gridElement = document.querySelector('.k-grid') as HTMLElement;
    const dropdownHeight = 100;
    const dropdownWidth = 200;

    if (gridElement) {
      const gridRect = gridElement.getBoundingClientRect();
      const clickX = event.clientX - gridRect.left + gridElement.scrollLeft;
      const clickY = event.clientY - gridRect.top + gridElement.scrollTop;

      const spaceBelow =
        window.innerHeight -
        (event.clientY - gridRect.top + gridElement.scrollTop);
      if (spaceBelow < dropdownHeight) {
        this.dropdownY = clickY - dropdownHeight;
      } else {
        this.dropdownY = clickY;
      }
      this.dropdownX = Math.max(
        0,
        Math.min(clickX, gridElement.clientWidth - dropdownWidth),
      );

      this.dropdownX += gridRect.left;
      this.dropdownY = Math.max(0, this.dropdownY + 150);
      if (this.dropdownY + dropdownHeight > window.innerHeight) {
        this.dropdownY = window.innerHeight - dropdownHeight;
      }
    }
    const rowId = dataItem.id;
    this.selectedRowData = dataItem;
    //we need to call the isSticky function to check the cooresponding row is sticky or not
    //here we have used the order by to check either row is sticky or not
    if (this.isSticky(rowId)) {
      this.isStickyRow = true;
    } else {
      this.isStickyRow = false;
    }
  }

  handleDropdownOption(option: number) {
    if (option === 2) {
      this.router.navigate([REDIRECT_DETAILS_FROM_MANAGEMENT]);
    } 
    else if (option === 4) {
      this.isFrom = EDIT_MODE_REPORT;
      this.store.dispatch(
        new SetTemplate(
          this.isFrom,
          EMPTY_STRING,
          EMPTY_STRING,
          this.currentDataItem.id,
        ), // Data will be sent will the API will be integrated
      );
      this.router.navigate([REDIRECT_EDIT_REPORT]);
    }
    else if (option === 3) {
      this.toggleStickyRow(this.currentDataItem.id);
    }
  }

  public rowSticky: RowStickyFn = (args: RowArgs) =>
    this.isSticky(args.dataItem.id);

  public isSticky(id: string): boolean {
    return this.myStickyRows.indexOf(id) >= 0;
  }

  public toggleStickyRow(id: string): void {
    if (this.isSticky(id)) {
      this.myStickyRows = this.myStickyRows.filter(
        (stickyId) => stickyId !== id,
      );
    } else {
      this.myStickyRows.push(id);
    }
    this.sortGridData();
  }
  private sortGridData(): void {
    // Create a copy of gridData to avoid modifying the original array in place
    const sorteddt = [...this.gridData];

    // Sort the copied array
    sorteddt.sort((a, b) => {
      // Check if a or b is null or undefined
      if (a === null || a === undefined) return 1;
      if (b === null || b === undefined) return -1;

      const isASticky = this.isSticky(a.id);
      const isBSticky = this.isSticky(b.id);

      if (isASticky && !isBSticky) {
        return -1;
      } else if (!isASticky && isBSticky) {
        return 1;
      } else {
        // Maintain the original order if both are non-sticky
        return a.id.localeCompare(b.id); // Use localeCompare for string comparison
      }
    });
    this.gridData = sorteddt;
  }

  containsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === 'string') {
      return field.toLowerCase().includes(searchTerm);
    }
    return false;
  }

  loadData(data: any[]) {
    this.originalData = data.map((item) => ({
      ...item,
      formattedCreatedDate: this.formatDate(new Date(item.createdDate)),
      formattedExportedDate: this.formatDate(new Date(item.exportedDate)),
    }));
    this.gridData = [...this.originalData];
  }

  formatDate(date: Date): string {
    if (!date) return '';

    const day = date.getDate();
    const month = date.toLocaleString(DEFAULT, { month: LONG });
    const year = date.getFullYear();

    const daySuffix = this.getDaySuffix(day);

    return `${day}${daySuffix} ${month}, ${year}`;
  }

  formatDateString(dateString: string | Date | null): string | null {
    if (dateString !== null) {
      const date = new Date(dateString);

      const day = date.getDate();
      const daySuffix = this.getDaySuffix(day);

      const options: Intl.DateTimeFormatOptions = {
        month: 'long',
        year: 'numeric',
      };
      const formattedDate = date.toLocaleDateString('en-US', options);

      return `${day}${daySuffix} ${formattedDate}`;
    }
    return null;
  }

  getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return TH;
    switch (day % 10) {
      case 1:
        return ST;
      case 2:
        return ND;
      case 3:
        return RD;
      default:
        return TH;
    }
  }
  onReportTypeChange() {
    // let reportData;

    // if (this.isAuditReport && this.isPerformanceReport) {
    //   reportData =
    //     this.reportManagementService.getSpecificReportsData(AUDIT_PERFORMANCE);
    // } else if (this.isAuditReport) {
    //   reportData = this.reportManagementService.getSpecificReportsData(AUDIT);
    // } else if (this.isPerformanceReport) {
    //   reportData =
    //     this.reportManagementService.getSpecificReportsData(PERFORMANCE);
    // } else {
    //   reportData = this.reportManagementService.getSpecificReportsData('');
    // }

    // // Map over the data and apply date formatting
    // this.gridData = reportData.map((item) => ({
    //   ...item,
    //   formattedCreatedDate: this.formatDateString(item.createdDate),
    //   formattedExportedDate: this.formatDateString(item.exportedDate),
    // }));
    this.getAllReports();
  }
  public toggleDescription(id: string): void {
    this.expandedRows[id] = !this.expandedRows[id];
  }

  public isExpanded(id: string): boolean {
    return !!this.expandedRows[id];
  }

  rowActionStickyRow(event: MouseEvent, dataItem: any) {
    event.stopPropagation(); // Prevent the click event from propagating to the document
    //we want to change the text from Upin to Pin of drop-down that we are making this call
    const rowId = dataItem.id;
    //we need to call the isSticky function to check the cooresponding row is sticky or not
    //here we have used the order by to check either row is sticky or not
    if (this.isSticky(rowId)) {
      this.isStickyRow = true;
    } else {
      this.isStickyRow = false;
    }
    //this is used to make the row unsticky
    this.toggleStickyRow(rowId);
  }

  public applyFilter(
    selectedValues: any[],
    filterService: FilterService,
    field:string
  ): void {
    const filters: FilterDescriptor[] = [];
    for (const value of selectedValues) {
      if (value.selected) {
        filters.push({
          field:field,
          operator: 'eq',
          value: value.text,
        });
      }
    }
    const rootFilter: CompositeFilterDescriptor = {
      logic: 'or',
      filters: filters,
    };

    filterService.filter(rootFilter);
  }

  public state: State = {
    skip: 0,
    take: this.pageSize,
    group: [],
    filter: { filters: [], logic: 'and' },
    sort: [],
  };
  onFilterChange(filter: CompositeFilterDescriptor): void {
    const hasFieldFilter = this.hasFilterForField(filter, REPORT_TYPE);
    if (!hasFieldFilter) {
      this.reportTypeFilterValues.forEach((item) => (item.selected = false));
    }
    this.state = { ...this.state, filter };
  }

  private hasFilterForField(
    filter: CompositeFilterDescriptor,
    field: string,
  ): boolean {
    return filter.filters.some(
      (f: FilterDescriptor | CompositeFilterDescriptor) => {
        if ((f as CompositeFilterDescriptor).filters) {
          return this.hasFilterForField(f as CompositeFilterDescriptor, field);
        } else {
          return (f as FilterDescriptor).field === field;
        }
      },
    );
  }

  getDistinctStatusValues(columnName: string): void {
    // Ensure columnName is a valid key
    const columnKey = columnName;
  
    // Clear previous filter values to avoid duplicates
    this.reportTypeFilterValues = [];
  
    // Create a Set to store distinct non-null, trimmed, and case-normalized values
    const distinctValues = Array.from(
      new Set(
        this.gridData
          .map((item) => item![columnKey as keyof ReportManagement])
          .filter(
            (value): value is string => value !== null && value !== undefined
          )
          .map((value) => value.trim()) // Normalize values
      )
    );
  
    // Add distinct values to reportTypeFilterValues
    distinctValues.forEach((item) => {
      this.reportTypeFilterValues.push({ text: item, selected: false });
    });
  }

  isFavouriteOnly = false;

  toggleFavouriteFilter() {
    if (this.isFavouriteOnly) {
      this.removeFavouriteFilter();
      this.sortGridData();
    } else {
      this.getFavouriteRows();
    }
  }
  getFavouriteRows() {
    this.isFavouriteOnly = true;
    this.gridData = this.gridData.filter((record) =>
      this.myStickyRows.includes(record.id),
    );
  }
  removeFavouriteFilter() {
    this.isFavouriteOnly = false;
    this.gridData = this.originalData;
  }
  customstate: { filter: CompositeFilterDescriptor } = {
    filter: { logic: 'and', filters: [] },
  };
  public dataStateChange(state: DataStateChangeEvent): void {
    this.isLoading = true;
    this.state = state;
    this.currentPage = state.skip / this.pageSize + 1;
    this.kendoGridFilters = this.state.filter as KendoGridFilters;
    const filter = state.filter as CompositeFilterDescriptor;
    this.customstate = { ...this.customstate, filter };
    this.nextKey = null;
    this.currentPage = 1;
    this.currentPage = 1;
    this.cdr.detectChanges();
    this.getAllReports();
  }
  handleSearchInput(changeWord: string) {
    if (changeWord.length < 3 && this.searchText.length > 2) {
      this.searchText = '';
      this.nextKey = null;
      this.currentPage = 1;
      this.getAllReports();
    } else if (changeWord.length > 2) {
      this.searchText = changeWord;
      this.nextKey = null;
      this.currentPage = 1;
      this.getAllReports();
    }
  }
}
