import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ADD_EMAIL,
  EMAIL_ALREADY_EXISTS,
  EMAIL_REGEX,
  EMPTY_STRING,
  REDIRECT_DETAILS_FROM_MANAGEMENT_Main,
  SUCCESS1,
  VALID_EMAIL,
} from '../constants';
import { RunReportInput } from '../../../awsAppSync/API';
import { CreateReportServiceService } from '../../feature/create-report/service/create-report-service.service';
import { CustomToastrService } from '../ngx-toastr/custom-toastr.service';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { Router } from '@angular/router';
@Component({
  selector: 'app-run-report',
  templateUrl: './run-report.component.html',
  styleUrl: './run-report.component.css',
})
export class RunReportComponent implements OnInit {
  runReportForm: FormGroup;
  isLoading = false;
  emails: string[] = [];
  emailError: string | null = null;
  runReportInput: RunReportInput = {};
  selectedRowData: any;
  selectedprimaryComapnyId: string | null = '';
  storePrimaryCompanyID$: Observable<string | null>;
  currentReportId = EMPTY_STRING;

  constructor(
    public dialogRef: MatDialogRef<RunReportComponent>,
    public service: CreateReportServiceService,
    private toastr: CustomToastrService,
    private router: Router,
    private store: Store,
    private fb: FormBuilder,
  ) {
    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );

    this.runReportForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    this.storePrimaryCompanyID$.subscribe((id) => {
      this.selectedprimaryComapnyId = '';
      this.selectedprimaryComapnyId = id;
    });
    this.runReportInput = this.service.runReportInput;
    this.selectedRowData = this.service.selectedRowData;
    this.currentReportId = this.selectedRowData.id;
  }

  get email() {
    return this.runReportForm.get('email');
  }

  onClose(): void {
    this.dialogRef.close();
    this.router.navigate([REDIRECT_DETAILS_FROM_MANAGEMENT_Main]);
  }

  addChip(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value && !this.emails.includes(value)) {
      if (this.isValidEmail(value)) {
        this.emails.push(value);
        this.emailError = null;
      } else {
        this.emailError = VALID_EMAIL;
      }
    } else if (this.emails.includes(value)) {
      this.emailError = EMAIL_ALREADY_EXISTS;
    }
    event.chipInput!.clear();
  }

  removeChip(email: string): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
  }

  runReport() {
    if (!this.runReportInput.primaryCompanyId) {
      this.runReportInput = {
        reportId: this.currentReportId,
        primaryCompanyId: this.selectedprimaryComapnyId,
        emails: EMPTY_STRING,
        isRunReport: true,
      };
    }
    this.runReportInput.emails = JSON.stringify(this.emails);
    this.runReportForm.markAllAsTouched();
    if (this.runReportForm.valid && this.emails.length > 0) {
      this.executeRunReport();
    } else {
      this.emailError = ADD_EMAIL;
    }
  }

  async executeRunReport() {
    this.isLoading = true;
    const response = await this.service.runReport(this.runReportInput);
    if (response?.status == SUCCESS1) {
      this.toastr.showSuccess(
        'Report generation is triggered, check/refresh the provided email. ',
      );
      this.router.navigate([REDIRECT_DETAILS_FROM_MANAGEMENT_Main]);
      this.runReportInput = {};
      this.service.runReportInput = {};
      this.dialogRef.close();
      this.isLoading = false;
    }
    console.log(response);
  }

  isValidEmail(email: string): boolean {
    const emailPattern = EMAIL_REGEX;
    return emailPattern.test(email);
  }
}
