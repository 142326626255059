/* eslint-disable @typescript-eslint/no-unused-vars */
import { Injectable } from '@angular/core';
import {
  AssetTypeTemplate,
  // AssetTypeTemplateConnection,
  // AssetTypeTemplateCreateInput,
  AssetTypeTemplateUpdateInput,
  AssetTypeTemplateResponse,
  CreateAssetTypeTemplateForPrimaryCompanyMutationVariables,
  UpdateAssetTypeTemplateForPrimaryCompanyMutationVariables,
  GetAssetTypeTemplateByPrimaryCompanyIdQueryVariables,
  CoreAssetTypeConnection,
  CoreAssetType,
  GetAllCoreAssetTypesQuery,
  CreateAssetTypeTemplateInput,
  CoreMeasurementType,
  GetAllCoreMeasurementTypesByAssetCategoryQueryVariables,
  GetAllCoreMeasurementTypesByAssetCategoryQuery,
  GetAllCoreAssetTemplatesByAssetCategoryQueryVariables,
  GetAllCoreAssetTemplatesByAssetCategoryQuery,
  AssetType,
  GetAssetTypeTemplateByPrimaryCompanyIdQuery,
  AssetTypeConnection,
  UpdateAssetTypeTemplateInput,
} from '../../../awsAppSync/API';
import { createAssetTypeTemplateForPrimaryCompany } from '../../../awsAppSync/graphql/mutations';
import { updateAssetTypeTemplateForPrimaryCompany } from '../../../awsAppSync/graphql/mutations';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import {
  getAllCoreAssetTemplatesByAssetCategory,
  getAllCoreAssetTypes,
  getAllCoreMeasurementTypesByAssetCategory,
  getAssetTypeTemplateByPrimaryCompanyId,
} from '../../../awsAppSync/graphql/queries';
import {
  ERROR_FETCHING_ASSET_TYPE,
  ERROR_FETCHING_ASSET_TYPE_CATEGORY,
  ERROR_FETCHING_GET_ASSET_TYPE,
  ERROR_FETCHING_GET_MEASUREMENT_BY_ASSETCATEGORY,
} from '../../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class CreateAssetTypeService {
  constructor(
    private awsService: BaseAWSRequestService,
    private toastr: CustomToastrService,
  ) {}

  // public async createAssetTypeAndMC(
  //   input: AssetTypeTemplateCreateInput,
  // ): Promise<AssetTypeTemplateResponse | null> {
  //   const mutation = createAssetTypeTemplateForPrimaryCompany; // Ensure this is your GraphQL mutation string or document
  //   const variables: CreateAssetTypeTemplateForPrimaryCompanyMutationVariables =
  //     { input };

  //   const result = await this.awsService.executeMutation<{
  //     createAssetType: AssetTypeTemplateResponse;
  //   }>(mutation, variables);
  //   if (result.createAssetTypeTemplateForPrimaryCompany.status == 'Error') {
  //     console.log(
  //       result.createAssetTypeTemplateForPrimaryCompany.error.message,
  //     );
  //   }
  //   return result.createAssetTypeTemplateForPrimaryCompany || null;
  // }

  // public async updateAssetTypeAndMC(
  //   input: AssetTypeTemplateUpdateInput,
  // ): Promise<AssetTypeTemplateResponse | null> {
  //   const mutation = updateAssetTypeTemplateForPrimaryCompany; // Ensure this is your GraphQL mutation string or document
  //   const variables: UpdateAssetTypeTemplateForPrimaryCompanyMutationVariables =
  //     { input };

  //   const result = await this.awsService.executeMutation<{
  //     createAssetType: AssetTypeTemplateResponse;
  //   }>(mutation, variables);
  //   if (result.updateAssetTypeTemplateForPrimaryCompany.status == 'Error') {
  //     console.log(
  //       result.updateAssetTypeTemplateForPrimaryCompany.error.message,
  //     );
  //   }
  //   return result.updateAssetTypeTemplateForPrimaryCompany || null;
  // }

  public async getAssetTypeByPrimayCompanyId(
    id: string,
  ): Promise<(AssetType | null)[] | null> {
    const variables: GetAssetTypeTemplateByPrimaryCompanyIdQueryVariables = {
      id,
    };
    const query = getAssetTypeTemplateByPrimaryCompanyId;
    try {
      const response = await this.awsService.executeGetQuery<{
        getAssetTypeTemplateByPrimaryCompanyId: AssetTypeConnection;
      }>(query, variables);
      return response.getAssetTypeTemplateByPrimaryCompanyId.items || null;
    } catch (error) {
      console.error(ERROR_FETCHING_ASSET_TYPE, error);
      return null;
    }
  }

  async getAssetTypeCategoy(): Promise<(CoreAssetType | null)[] | null> {
    try {
      const query = getAllCoreAssetTypes;
      const response =
        await this.awsService.executeGetQuery<GetAllCoreAssetTypesQuery>(query);
      return response?.getAllCoreAssetTypes?.items || null;
    } catch (error) {
      console.error(ERROR_FETCHING_ASSET_TYPE_CATEGORY, error);
      return null;
    }
  }

  async getAllCoreMeasurementTypesByAssetCategory(
    assetCategoryId: string,
  ): Promise<(CoreMeasurementType | null)[] | null> {
    try {
      const query = getAllCoreMeasurementTypesByAssetCategory;
      const variables: GetAllCoreMeasurementTypesByAssetCategoryQueryVariables =
        { assetCategoryId };
      const response =
        await this.awsService.executeGetQuery<GetAllCoreMeasurementTypesByAssetCategoryQuery>(
          query,
          variables,
        );
      return response?.getAllCoreMeasurementTypesByAssetCategory?.items || null;
    } catch (error) {
      console.error(ERROR_FETCHING_GET_MEASUREMENT_BY_ASSETCATEGORY, error);
      return null;
    }
  }
  async getAllCoreAssetTemplatesByAssetCategory(
    assetCategoryId: string,
  ): Promise<AssetType | null> {
    try {
      const query = getAllCoreAssetTemplatesByAssetCategory;
      const variables: GetAllCoreAssetTemplatesByAssetCategoryQueryVariables = {
        assetCategoryId,
      };
      const response =
        await this.awsService.executeGetQuery<GetAllCoreAssetTemplatesByAssetCategoryQuery>(
          query,
          variables,
        );
      return response?.getAllCoreAssetTemplatesByAssetCategory || null;
    } catch (error) {
      console.error(ERROR_FETCHING_GET_MEASUREMENT_BY_ASSETCATEGORY, error);
      return null;
    }
  }

  public async createAssetTypeAndMC(
    input: CreateAssetTypeTemplateInput,
  ): Promise<AssetTypeTemplateResponse | null> {
    const mutation = createAssetTypeTemplateForPrimaryCompany; // Ensure this is your GraphQL mutation string or document
    const variables: CreateAssetTypeTemplateForPrimaryCompanyMutationVariables =
      { input };

    const result = await this.awsService.executeMutation<{
      createAssetTypeTemplateForPrimaryCompany: AssetTypeTemplateResponse;
    }>(mutation, variables);
    if (result.createAssetTypeTemplateForPrimaryCompany.status == 'Error') {
      console.log(
        result.createAssetTypeTemplateForPrimaryCompany.error.message,
      );
    }
    return result.createAssetTypeTemplateForPrimaryCompany || null;
  }
  public async updateAssetTypeAndMC(
    input: UpdateAssetTypeTemplateInput,
  ): Promise<AssetTypeTemplateResponse | null> {
    const mutation = updateAssetTypeTemplateForPrimaryCompany; // Ensure this is your GraphQL mutation string or document
    const variables: UpdateAssetTypeTemplateForPrimaryCompanyMutationVariables =
      { input };

    const result = await this.awsService.executeMutation<{
      updateAssetType: AssetTypeTemplateResponse;
    }>(mutation, variables);
    if (result.updateAssetTypeTemplateForPrimaryCompany.status == 'Error') {
      console.log(
        result.updateAssetTypeTemplateForPrimaryCompany.error.message,
      );
    }
    return result.updateAssetTypeTemplateForPrimaryCompany || null;
  }

  async getAssetTypeTemplateByPrimaryCompanyId(
    primaryCompanyId: string,
  ): Promise<(AssetType | null)[] | null> {
    try {
      const variables: GetAssetTypeTemplateByPrimaryCompanyIdQueryVariables = {
        id: primaryCompanyId,
      };
      const query = getAssetTypeTemplateByPrimaryCompanyId;
      const response =
        await this.awsService.executeGetQuery<GetAssetTypeTemplateByPrimaryCompanyIdQuery>(
          query,
          variables,
        );

      return response.getAssetTypeTemplateByPrimaryCompanyId?.items || null;
    } catch (error) {
      console.error(ERROR_FETCHING_GET_ASSET_TYPE, error);
      return null;
    }
  }
}
