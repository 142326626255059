<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container-fluid py-2" style="margin-top: -28px">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <h5
          class="mtx-text-primary"
          style="margin-left: -12px; margin-top: 22px"
        >
          {{ title | translate }}
        </h5>
      </div>
      <div class="col-auto ml-auto">
        <div
          class="d-flex flex-wrap align-items-center gap-2 justify-content-end"
        >
          <div style="width: 330px">
            <app-search
              [placeholder]="searchPlaceholder"
              (searchInput)="handleSearchInput($event)"
            ></app-search>
          </div>
          <div class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <app-template
              [templateTypeInput]="templateTypeInput"
            ></app-template>
          </div>

          <div class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <button
              class="btn mtx-btn-primary"
              style="height: 38px"
              (click)="openDialog()"
            >
              <span style="vertical-align: middle" class="material-icons"
                >download</span
              >
              <!-- {{ 'GENERIC_Buttons.EXPORT' | translate }} -->
              Export
            </button>
          </div>
          <div class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <app-columnadjustment
              (columnPosition)="reorderColumnPosition($event)"
              [templateTypeInput]="templateTypeInput"
              (selectedColumnsChange)="OnSelectedTemplateColumnsChange($event)"
            ></app-columnadjustment>
          </div>
        </div>
      </div>
    </div>
    <div
      class="row mt-3 justify-content-end mb-3"
      style="float: right"
      *ngIf="showHierarchicalFilter"
    >
      <app-heirarchy-filter-buttons
        (isTrayCollapsed)="collapseFilterTray()"
        [isSurveyRouteFilterRequired]="true"
      >
      </app-heirarchy-filter-buttons>
      <app-heirarchy-filter-popup></app-heirarchy-filter-popup>
    </div>
    <div class="mt-3">
      <kendo-grid
        [data]="gridData"
        [filterable]="'menu'"
        [pageable]="PAGINATION_ARRAY"
        [pageSize]="pageSize"
        class="grid-shadow"
        [rowSticky]="rowSticky"
        [resizable]="true"
        [skip]="state.skip"
        [pageable]="true"
        [pageSize]="state.take"
        [filter]="state.filter"
        (dataStateChange)="dataStateChange($event)"
      >
        <kendo-grid-column
          [resizable]="false"
          [width]="80"
          [filterable]="false"
          field="id"
          title=""
          [resizable]="false"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <div
              class="flex-container"
              style="display: flex; align-items: center"
            >
              <span
                class="k-icon k-font-icon my-custom-icon-class"
                style="position: relative"
                (click)="openDropdownMenu($event, dataItem)"
              ></span>
              <span
                *ngIf="isSticky(dataItem.id)"
                class="k-icon k-font-icon k-i-star k-i-pin pin-icon-css"
                (click)="rowActionStickyRow($event, dataItem)"
              >
              </span>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="surveyName"
          title="Survey Name"
          [width]="300"
          [minResizableWidth]="150"
          *ngIf="IsColumnSelected('surveyName')"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="customTxtCss">{{ dataItem.surveyName }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="surveyType"
          title="Survey Type"
          [width]="200"
          *ngIf="IsColumnSelected('surveyType')"
          [minResizableWidth]="100"
        >
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <div #customFilterContainer>
              <app-custom-grid-filter
                fieldName="surveyType"
                [inputElements]="surveyTypeFilterValues"
                [parentDiv]="customFilter"
                [state]="customstate"
                (selectedValues)="
                  applyFilter($event, filterService, 'surveyType')
                "
              >
              </app-custom-grid-filter>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="version"
          title="Version"
          [width]="120"
          [minResizableWidth]="60"
          *ngIf="IsColumnSelected('version')"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span class="customTxtCss">{{ dataItem.version }}</span>
          </ng-template>
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <div #customFilterContainer>
              <app-custom-grid-filter
                fieldName="version"
                [inputElements]="surveyVersionFilterValues"
                [parentDiv]="customFilter"
                [state]="customstate"
                (selectedValues)="applyFilter($event, filterService, 'version')"
              >
              </app-custom-grid-filter>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="versionDate"
          title="Version Date"
          [width]="230"
          format="{0:dd/MM/yyyy}"
          filter="date"
          [minResizableWidth]="110"
          *ngIf="IsColumnSelected('versionDate')"
        ></kendo-grid-column>
        <kendo-grid-column
          field="surveyPriority"
          title="Survey Priority"
          [width]="220"
          [minResizableWidth]="110"
          *ngIf="IsColumnSelected('surveyPriority')"
        >
          <ng-template kendoGridCellTemplate let-dataItem>
            <span
              [ngClass]="getPriorityClass(dataItem.surveyPriority)"
              style="font-weight: 700"
            >
              <span class="dot"></span> {{ dataItem.surveyPriority }}
            </span>
          </ng-template>
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <div #customFilterContainer>
              <app-custom-grid-filter
                fieldName="surveyPriority"
                [inputElements]="surveyPriorityFilterValues"
                [parentDiv]="customFilter"
                [state]="customstate"
                (selectedValues)="
                  applyFilter($event, filterService, 'surveyPriority')
                "
              >
              </app-custom-grid-filter>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="surveyStatus"
          title="Survey Status"
          [width]="220"
          [minResizableWidth]="120"
        >
          <ng-template
            kendoGridCellTemplate
            let-dataItem
            *ngIf="IsColumnSelected('surveyStatus')"
          >
            <span class="surveyStatus" style="font-weight: 700">
              <span class="dot"></span> {{ dataItem.surveyStatus }}
            </span>
          </ng-template>
          <ng-template
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
          >
            <div #customFilterContainer>
              <app-custom-grid-filter
                fieldName="surveyStatus"
                [inputElements]="surveyStatusFilterValues"
                [parentDiv]="customFilter"
                [state]="customstate"
                (selectedValues)="
                  applyFilter($event, filterService, 'surveyStatus')
                "
              >
              </app-custom-grid-filter>
            </div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column
          field="dataCollectionType"
          title="Data Collection Type"
          [width]="200"
          *ngIf="IsColumnSelected('dataCollectionType')"
        ></kendo-grid-column>
        <kendo-grid-column
          field="dateCompleted"
          title="Date Completed"
          [width]="200"
          format="{0:dd/MM/yyyy}"
          filter="date"
          [minResizableWidth]="100"
          *ngIf="IsColumnSelected('dateCompleted')"
        ></kendo-grid-column>
        <kendo-grid-column
          field="dateOfUpload"
          title="Date of Upload"
          [width]="200"
          format="{0:dd/MM/yyyy}"
          filter="date"
          [minResizableWidth]="100"
          *ngIf="IsColumnSelected('dateOfUpload')"
        ></kendo-grid-column>
        <kendo-grid-column
          field="lastSynch"
          title="Last Synch"
          [width]="200"
          format="{0:dd/MM/yyyy}"
          filter="date"
          [minResizableWidth]="100"
          *ngIf="IsColumnSelected('lastSynch')"
        ></kendo-grid-column>
        <kendo-grid-column
          field="assignee"
          title="Assignee"
          [width]="200"
          [minResizableWidth]="100"
          *ngIf="IsColumnSelected('assignee')"
        ></kendo-grid-column>
        <kendo-grid-column
          field="technician"
          title="Technician"
          [width]="200"
          [minResizableWidth]="100"
          *ngIf="IsColumnSelected('technician')"
        ></kendo-grid-column>
        <kendo-grid-column
          field="surveyDescription"
          title="Survey Description"
          [width]="600"
          [minResizableWidth]="100"
          *ngIf="IsColumnSelected('surveyDescription')"
        ></kendo-grid-column>
        <ng-template kendoGridNoRecordsTemplate>
          <div>{{ 'GRID.NO_RECORDS' | translate }}</div>
        </ng-template>
      </kendo-grid>
    </div>
    <app-row-actions
      class="row-action"
      [visible]="dropdownVisible"
      [positionX]="dropdownX"
      [width]="ellipsisWidth"
      [positionY]="dropdownY"
      [inputScreen]="inputScreen"
      (visibleChange)="handleDropdownVisibilityChange($event)"
      [rowId]="rowId"
      [isStickyRow]="isStickyRow"
      [permission]="permission"
      (optionSelected)="handleDropdownOption($event)"
    ></app-row-actions>
  </div>
</div>

<kendo-dialog
  *ngIf="isDialogOpen"
  (close)="closeDialog()"
  title="Assign Survey"
  id="assignPopup"
  class="custom-dialog"
>
  <p class="survey-header">
    {{ this.surveyName }} <span class="survey-header-span">Direct Survey</span>
  </p>
  <div>
    <label for="assignTo" class="form-label">Assign to *</label>
    <kendo-dropdownlist
      [data]="userList"
      [(ngModel)]="selectedValue"
      [defaultItem]="'Unassigned'"
      textField="value"
      valueField="id"
      style="height: 45%"
      name="assignTo"
    ></kendo-dropdownlist>
  </div>
  <kendo-dialog-actions>
    <button
      (click)="onMoveToFieldData()"
      [disabled]="!selectedValue"
      class="upload-btn-css"
    >
      Move to Field Data
    </button>
    <button (click)="onCancel()" class="cancel-btn-css">Cancel</button>
  </kendo-dialog-actions>
</kendo-dialog>
