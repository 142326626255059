<div *ngIf="isLoading">
  <app-loader></app-loader>
</div>
<div class="container-fluid py-2" style="margin-top: -28px">
  <div class="container-fluid">
    <div class="row align-items-center justify-content-between">
      <div class="col-auto">
        <h5
          class="mtx-text-primary"
          style="margin-left: -12px; margin-top: 22px"
        >
          {{ 'SIDE_NAV_SUB_MENU.USER' | translate }}
        </h5>
      </div>
      <div class="col-auto ml-auto">
        <div
          class="d-flex flex-wrap align-items-center gap-2 justify-content-end"
        >
          <div style="width: 330px">
            <app-search
              [placeholder]="searchPlaceholder"
              (searchInput)="handleSearchInput($event)"
            ></app-search>
          </div>
          <div *ngIf="permission" class="col-xs-6 col-sm-auto col-md-auto col-lg-auto">
            <button class="asset-modal-button" (click)="createUser()">
              <div class="asset-modal-text">
                <span class="asset-modal-text">
                  <span class="button-text-alignment">{{
                    'USER_OVERVIEW.USER' | translate
                  }}</span>
                </span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-3">
    <kendo-grid
      [data]="gridData"
      [filterable]="'menu'"    
      class="grid-shadow"
      [resizable]="true"
      [skip]="state.skip"
      [pageable]="false"
      [filter]="state.filter"
      (dataStateChange)="dataStateChange($event)"
    >
      <kendo-grid-column
        [resizable]="false"
        [width]="80"
        [filterable]="false"
        field="id"
        title=""
        [resizable]="false"
      >
        <ng-template kendoGridCellTemplate let-dataItem>
          <div
            class="flex-container"
            style="display: flex; align-items: center"
          >
            <span
              class="k-icon k-font-icon my-custom-icon-class"
              (click)="openDropdownMenu($event, dataItem)"
            ></span>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [headerClass]="'firstColGridHeader'"
        field="primaryCompanyName"
        title="Primary Company"
        [width]="150"
        [minResizableWidth]="80"
      >
      <ng-template
      kendoGridFilterMenuTemplate
      let-filter
      let-column="column"
      let-filterService="filterService"
    >
      <div #customFilterContainer>
        <app-custom-grid-filter
          [inputElements]="primaryCompanyFilterValues"
          [parentDiv]="customFilter"
          [state]="customstate"
          (selectedValues)="applyFilter($event, filterService, 'primaryCompanyName')"
        ></app-custom-grid-filter>
      </div>
    </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        [headerClass]="'firstColGridHeader'"
        field="level3DisplayNames"
        [title]="level3name"
        [width]="200"
        [minResizableWidth]="100"
      >
      <ng-template
      kendoGridFilterMenuTemplate
      let-filter
      let-column="column"
      let-filterService="filterService"
    >
      <div #customFilterContainer>
        <app-custom-grid-filter
          [inputElements]="level3FilterValues"
          [parentDiv]="customFilter"
          [state]="customstate"
          (selectedValues)="applyFilter($event, filterService, 'level3DisplayNames')"
        ></app-custom-grid-filter>
      </div>
    </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="firstName"
        title="First Name"
        [width]="150"
        [minResizableWidth]="100"
      ></kendo-grid-column>
      <kendo-grid-column
        field="lastName"
        title="Last Name"
        [width]="140"
        [minResizableWidth]="80"
      >
      </kendo-grid-column>
      <kendo-grid-column
        field="username"
        title="Username"
        [width]="140"
        [minResizableWidth]="80"
      >
      </kendo-grid-column>
      <kendo-grid-column
        field="userRoleNames"
        title="Roles"
        [width]="140"
        [minResizableWidth]="80"
      >
      <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <div #customFilterContainer>
            <app-custom-grid-filter
              [inputElements]="roleNameFilterValues"
              [parentDiv]="customFilter"
              [state]="customstate"
              (selectedValues)="applyFilter($event, filterService, 'userRoleNames')"
            ></app-custom-grid-filter>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="email"
        title="Email ID"
        [width]="250"
        [minResizableWidth]="80"
      >
      </kendo-grid-column>
      <kendo-grid-column
        field="addr1"
        title="Address"
        [width]="180"
        [minResizableWidth]="90"
      ></kendo-grid-column>
      <kendo-grid-column
        field="addrCity"
        title="City"
        [width]="150"
        [minResizableWidth]="80"
      >
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <div #customFilterContainer>
            <app-custom-grid-filter
              [inputElements]="cityFilterValues"
              [parentDiv]="customFilter"
              [state]="customstate"
              (selectedValues)="applyFilter($event, filterService, 'addrCity')"
            ></app-custom-grid-filter>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="stateProvince"
        title="State/Province"
        [width]="150"
        [minResizableWidth]="70"
      >
      <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <div #customFilterContainer>
            <app-custom-grid-filter
              [inputElements]="stateProvinceFilterValues"
              [parentDiv]="customFilter"
              [state]="customstate"
              (selectedValues)="
                applyFilter($event, filterService, 'stateProvince')
              "
            ></app-custom-grid-filter>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="postalZip"
        title="Postal Zipcode"
        [width]="150"
        [minResizableWidth]="80"
      >
      </kendo-grid-column>
      <kendo-grid-column
        field="country"
        [width]="150"
        title="Country"
        [minResizableWidth]="150"
      >
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <div #customFilterContainer>
            <app-custom-grid-filter
              [inputElements]="countryFilterValues"
              [parentDiv]="customFilter"
              [state]="customstate"
              (selectedValues)="applyFilter($event, filterService, 'country')"
            ></app-custom-grid-filter>
          </div>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        field="timeZone"
        [width]="150"
        title="Report Time Zone"
        [minResizableWidth]="150"
      >
      </kendo-grid-column>
      <kendo-grid-column
        field="accountStatus"
        [width]="150"
        title="Account Status"
        [minResizableWidth]="150"
      >
        <ng-template
          kendoGridFilterMenuTemplate
          let-filter
          let-column="column"
          let-filterService="filterService"
        >
          <div #customFilterContainer>
            <app-custom-grid-filter
              [inputElements]="accountStatusFilterValues"
              [parentDiv]="customFilter"
              [state]="customstate"
              (selectedValues)="
                applyFilter($event, filterService, 'accountStatus')
              "
            ></app-custom-grid-filter>
          </div>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <span
            [ngClass]="getPriorityClass(dataItem.active)"
            style="font-weight: 700"
          >
            <span class="dot"></span> {{ dataItem.accountStatus }}
          </span>
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>

  <app-pagination 
  [currentPage]="pageNumber" 
  [pageSize]="size" 
  [pageLoad]="pageLoad" 
  [nextKey]="nextKey"
  (pageChanged)="onCustomPageChanged($event)">
</app-pagination>
  
<app-row-actions
    class="row-action"
    [visible]="dropdownVisible"
    [positionX]="dropdownX"
    [width]="ellipsisWidth"
    [positionY]="dropdownY"
    [inputScreen]="inputScreen"
    (visibleChange)="handleDropdownVisibilityChange($event)"
    [rowId]="rowId"
    [isStickyRow]="false"
    [selectedRowData]="selectedRowData"
    [permission] = "permission"
    (optionSelected)="handleDropdownOption($event)"
  ></app-row-actions>
</div>