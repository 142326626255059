import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  SimpleChanges,
  OnInit,
  OnChanges,
} from '@angular/core';
import { RowActionsService } from '../service/row-actions/row-actions.service';
import { LocalStorageService } from '../service/local-storage/local-storage.service';
import { Router } from '@angular/router';
import {
  ASSET_DETAILS_ROUTE,
  ASSETSETUP,
  EXCEPTION,
  INSPECTION,
  DETAILS,
  EDIT_ASSET_ROUTE,
  EMPTY_STRING,
  GLOBAL_ELLIPSIS_WIDTH,
  REDIRECT_ASSET_HISTORY,
  REDIRECT_INSPECTION,
  REPORT,
  SURVEYROUTEMANAGEMENT,
  COMPANY_OVERVIEW,
  EDIT_PRIMARY_COMPANY,
  SETUP_SEGMENTS_ASSETS,
  USEROVERVIEW,
  REDIRECT_SURVEY_EDIT_ROUTE,
  MOBILTEXMEMBERS,
  SETUP_IMPORT_INSPECTION,
  REDIRECT_DETAILS_FROM_MANAGEMENT,
  SURVEY_FIELD_DATA_SCREEN_SUB_GRID,
  RUN_REPORT,
  REDIRECT_DATA_PROCESSING,
} from '../constants';
import { Store } from '@ngxs/store';
import { SetAsset } from '../../core/store/asset-setup.state';
import { MatDialog } from '@angular/material/dialog';
import { AssetHistoryService } from '../../core/services/asset-history.service';
import { SetSurveyRouteData } from '../../core/store/survey-route.state';
import { SurveyRouteService } from '../../core/services/survey-route.service';
import { InspectionService } from '../../core/services/inspection.service';
import { CreateReportServiceService } from '../../feature/create-report/service/create-report-service.service';
import { RunReportComponent } from '../run-report/run-report.component';
import { DataProcessingService } from '../../core/services/data-processing.service';

@Component({
  selector: 'app-row-actions',
  templateUrl: './row-actions.component.html',
  styleUrl: './row-actions.component.css',
})
export class RowActionsComponent implements OnInit, OnChanges {
  @Input() visible = false;
  @Input() width: string | null = null;
  elementWidth: string = GLOBAL_ELLIPSIS_WIDTH;
  @Input() positionX = 0;
  @Input() positionY = 0;
  @Input() isStickyRow = false;
  @Input() inputScreen = '';
  @Input() rowId = 0;
  @Input() selectedRowData: any;
  @Input() permission = false;
  @Output() optionSelected = new EventEmitter<number>();
  @Output() visibleChange = new EventEmitter<boolean>();
  menuOptions: {
    id: number;
    name: string;
    imgType: string;
    isEnable: boolean;
  }[] = [];
  public encodedValue = '';
  constructor(
    private elementRef: ElementRef,
    private rowActions: RowActionsService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private store: Store,
    public dialog: MatDialog,
    public createReportService: CreateReportServiceService,
    public dataProcessingService: DataProcessingService,
    public assetHistoryservice: AssetHistoryService,
    private surveyRouteService: SurveyRouteService,
    public inspectionService: InspectionService,
  ) {}
  ngOnInit() {
    this.rowActions.permission = this.permission;
    this.menuOptions = this.rowActions.assignRowActionElements(
      this.inputScreen,
      this.selectedRowData,
    );
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['width'] && this.width) {
      this.elementWidth = this.width;
    }
    if (changes['inputScreen']) {
      this.menuOptions = this.rowActions.assignRowActionElements(
        this.inputScreen,
        this.selectedRowData,
      );
    } else if (changes['rowId'] || changes['isStickyRow']) {
      this.menuOptions = this.rowActions.assignRowActionElements(
        this.inputScreen,
        this.selectedRowData,
      );
      this.performTaskOnStickyRowChange(this.isStickyRow);
      const rowData = this.localStorageService.getItem('rowData');
      if (rowData !== null && rowData !== '' && rowData !== undefined) {
        if (this.inputScreen === INSPECTION) {
          const complianceType = 'Compliant';
          const indexToRemove = 1;
          if (
            rowData?.ComplianceStatus?.toLowerCase() ==
            complianceType?.toLowerCase()
          ) {
            this.menuOptions.splice(indexToRemove, 1); // Removes 1 element at index 2
          }
          this.encodedValue = encodeURIComponent(rowData.AssetName);
        } else if (this.inputScreen === EXCEPTION) {
          this.encodedValue = encodeURIComponent(rowData.assetName);
        } else if (this.inputScreen === ASSETSETUP) {
          this.encodedValue = encodeURIComponent(rowData.assetName);
        }

        this.localStorageService.removeItem('rowData');
      }
    }
  }

  navigate(refLink: string) {
    if (refLink === REDIRECT_ASSET_HISTORY) {
      this.getAssetData(false, EMPTY_STRING);
      this.assetHistoryservice.selectedRowData = this.selectedRowData;
      this.router.navigate([refLink]);
    } else if (refLink === REDIRECT_INSPECTION) {
      this.inspectionService.selectedRowData = this.selectedRowData;
      if (this.selectedRowData.isMeasurementAvailable) {
        this.getAssetData(false, EMPTY_STRING);
        this.router.navigate([refLink]);
      } else {
        return;
      }
    } else if (refLink === ASSET_DETAILS_ROUTE) {
      this.getAssetData(false, DETAILS);
      this.router.navigate([refLink]);
    } else if (refLink === EDIT_ASSET_ROUTE) {
      this.getAssetData(true, EMPTY_STRING);
      this.router.navigate([refLink]);
    } else if (refLink === EDIT_PRIMARY_COMPANY) {
      const primaryCompanyId = this.selectedRowData.id;
      this.router.navigate([refLink], {
        queryParams: { Id: primaryCompanyId },
      });
    } else if (refLink === SETUP_SEGMENTS_ASSETS) {
      this.router.navigate([refLink]);
    } else if (refLink === SETUP_IMPORT_INSPECTION) {
      this.router.navigate([refLink]);
    } else if (refLink === REDIRECT_DETAILS_FROM_MANAGEMENT) {
      this.createReportService.selectedRowData = this.selectedRowData;
    } else if (refLink === RUN_REPORT) {
      this.createReportService.selectedRowData = this.selectedRowData;
      this.dialog.open(RunReportComponent);
    } else if (refLink === REDIRECT_DATA_PROCESSING) {
      this.dataProcessingService.selectedRowData = this.selectedRowData;
      this.router.navigate([refLink]);
    }
    //commented by kunal
    // else {
    //   this.router.navigate([refLink]);
    // }
  }

  getAssetData(isEditable: boolean, mode: string) {
    this.store.dispatch(
      new SetAsset(
        isEditable,
        mode,
        this.selectedRowData.primaryCompany,
        this.selectedRowData.company,
        this.selectedRowData.region,
        this.selectedRowData.segment,
        this.selectedRowData.station,
        this.selectedRowData.assetType,
        this.selectedRowData.assetName,
        this.selectedRowData.assetTypeTemplate,
        this.selectedRowData.latitude,
        this.selectedRowData.longitude,
        this.selectedRowData.id,
        this.selectedRowData.createdDate,
        this.selectedRowData.isMeasurementAvailable,
      ),
    );
  }

  performTaskOnStickyRowChange(currentValue: any) {
    //here we are using the concept of deep copy over Shallow copy

    if (this.inputScreen != REPORT) {
      if (this.inputScreen != SURVEYROUTEMANAGEMENT) {
        if (this.inputScreen != COMPANY_OVERVIEW) {
          if (this.inputScreen != USEROVERVIEW) {
            if (this.inputScreen != MOBILTEXMEMBERS) {
              if (this.inputScreen != SURVEY_FIELD_DATA_SCREEN_SUB_GRID) {
                if (currentValue === true) {
                  this.menuOptions[0] = {
                    id: 1,
                    name: 'Unpin Row',
                    imgType: 'unpin',
                    isEnable: true,
                  };
                } else {
                  this.menuOptions[0] = {
                    id: 1,
                    name: 'Pin Row',
                    imgType: 'pin',
                    isEnable: true,
                  };
                }
              }
            }
          }
        }
      }
    } else {
      if (currentValue === true) {
        this.menuOptions[1] = {
          id: 3,
          name: 'Unfavoured',
          imgType: 'fav',
          isEnable: true,
        };
      } else {
        this.menuOptions[1] = {
          id: 3,
          name: 'Add to favourite',
          imgType: 'fav',
          isEnable: true,
        };
      }
    }
    this.menuOptions = JSON.parse(JSON.stringify(this.menuOptions));
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: MouseEvent) {
    if (this.visible && !this.elementRef.nativeElement.contains(event.target)) {
      this.visible = false;
      this.visibleChange.emit(this.visible);
    }
  }
  selectOption(dataItem: any, option: any) {
    if (dataItem.name === 'Edit Survey Route') {
      this.router.navigate([REDIRECT_SURVEY_EDIT_ROUTE]);
      const routeName = this.selectedRowData.routeName;
      const routeDescription = this.selectedRowData.routeDescription;
      const selectedRoute = this.selectedRowData;
      this.surveyRouteService.setRouteData({
        routeName,
        routeDescription,
        selectedRow: selectedRoute,
      });
      this.store.dispatch(
        new SetSurveyRouteData(routeName, routeDescription, selectedRoute),
      );
    }
    this.optionSelected.emit(option);
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }
}
