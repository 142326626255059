import { Injectable } from '@angular/core';
import { User } from './models/user.module';
import { BaseAWSRequestService } from '../shared/service/base-services/base-aws-request.service';
import {
  AddUpdateUserResponse,
  CreateUserMutationVariables,
  FilterDropdownInput,
  GetAllLevel3ValueByPrimaryCompanyQueryVariables,
  GetAllRolesQuery,
  GetAllRolesQueryVariables,
  GetAllUserOptionQuery,
  GetAllUserOptionQueryVariables,
  GetAllUserQuery,
  GetAllUserQueryVariables,
  GetUserByCoreViewUserIdQueryVariables,
  KendoGridInput,
  Level3Value,
  Level3ValueConnection,
  RolesConnection,
  UpdateUserMutationVariables,
  UserConnection,
  UserCreateInput,
  UserOptionConnection,
  UserUpdateInput,
} from '../../awsAppSync/API';
import { EMPTY_STRING, GENERIC_FAILED_ERROR } from '../shared/constants';
import {
  getAllLevel3ValueByPrimaryCompany,
  getAllRoles,
  getAllUser,
  getAllUserOption,
  getUserByCoreViewUserId,
} from '../../awsAppSync/graphql/queries';
import { createUser, updateUser } from '../../awsAppSync/graphql/mutations';
import { BehaviorSubject } from 'rxjs';
import { Roles } from './store/user-profile.state';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public id: string = EMPTY_STRING;
  public authApiCallCompleted$ = new BehaviorSubject<boolean>(false);

  constructor(private awsService: BaseAWSRequestService) {}

  public async getAllUser(
    gridInput: KendoGridInput,
  ): Promise<UserConnection | null> {
    try {
      const variables: GetAllUserQueryVariables = {
        input: {
          pageNumber: gridInput.pageNumber,
          pageSize: gridInput.pageSize,
          gridFilters: gridInput.gridFilters,
          searchText: gridInput.searchText,
          isMTXAdmin: gridInput.isMTXAdmin,
          lastEvaluatedKey: gridInput.lastEvaluatedKey,
        },
      };
      const result = await this.awsService.executeGetQuery<GetAllUserQuery>(
        getAllUser,
        variables,
      );
      return result.getAllUser ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getUserByCoreViewUserId(
    coreViewUserId: string,
  ): Promise<User | null> {
    try {
      const variables: GetUserByCoreViewUserIdQueryVariables = {
        id: coreViewUserId,
      };
      const query = getUserByCoreViewUserId;
      const result = await this.awsService.executeGetQuery<{
        getUserByCoreViewUserId: User; // Change here to match User type
      }>(query, variables);

      return result.getUserByCoreViewUserId || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getAllUserOption(): Promise<UserOptionConnection | null> {
    try {
      const variables: GetAllUserOptionQueryVariables = {};
      const result =
        await this.awsService.executeGetQuery<GetAllUserOptionQuery>(
          getAllUserOption,
          variables,
        );
      return result.getAllUserOption ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getAllUserRoles(
    isMTXRole: boolean,
  ): Promise<RolesConnection | null> {
    try {
      const variables: GetAllRolesQueryVariables = { isMTXRole };
      const result = await this.awsService.executeGetQuery<GetAllRolesQuery>(
        getAllRoles,
        variables,
      );
      return result.getAllRoles ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }
  async createUser(
    input: UserCreateInput,
  ): Promise<AddUpdateUserResponse | null> {
    const mutation = createUser;
    const variables: CreateUserMutationVariables = { input };
    try {
      const userResult = await this.awsService.executeMutation<{
        createUser: AddUpdateUserResponse;
      }>(mutation, variables);
      const userResponse = userResult.createUser || null;
      this.id = userResponse?.item?.id || null;
      return userResult.createUser || null;
    } catch (error) {
      console.error('Error creating user', error);
      return null;
    }
  }

  async updateUser(
    input: UserUpdateInput,
  ): Promise<AddUpdateUserResponse | null> {
    const mutation = updateUser;
    const variables: UpdateUserMutationVariables = { input };
    try {
      const userResult = await this.awsService.executeMutation<{
        updateUser: AddUpdateUserResponse;
      }>(mutation, variables);
      const userResponse = userResult.updateUser || null;
      this.id = userResponse?.item?.id || null;
      return userResult.updateUser || null;
    } catch (error) {
      console.error('Error updating user', error);
      return null;
    }
  }

  public async getAllRoles(
    isMTXRole: boolean,
  ): Promise<RolesConnection | null> {
    try {
      const variables: GetAllRolesQueryVariables = { isMTXRole };
      const result = await this.awsService.executeGetQuery<GetAllRolesQuery>(
        getAllRoles,
        variables,
      );
      return result.getAllRoles ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getAllLevel3ValueByPrimaryCompany(
    primaryCompanyId: string,
  ): Promise<Level3ValueConnection | null> {
    const filterModel: FilterDropdownInput = {};
    filterModel.primaryCompanyId = primaryCompanyId;
    filterModel.isMTXUser = true;
    const query = getAllLevel3ValueByPrimaryCompany; // Ensure this is your GraphQL query string or document
    const variables: GetAllLevel3ValueByPrimaryCompanyQueryVariables = {
      input: filterModel,
    };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAllLevel3ValueByPrimaryCompany: Level3ValueConnection;
      }>(query, variables);
      return result.getAllLevel3ValueByPrimaryCompany || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async mapLevel3ValuesToRoles(
    level3Values: (Level3Value | null)[],
    roles: (Roles | null)[],
  ): Promise<(Roles | null)[]> {
    // Take the first role from the roles array (index 0)
    const baseRole = roles[0] || {
      roleId: null,
      rolename: null,
      permissions: null,
    };

    // Map all level3Values to roles while using the base role data
    return level3Values.map((value) => ({
      level1Id: value?.level1ValueId || null,
      level2Id: value?.level2ValueId || null,
      level3Id: value?.id || null,
      level3Value: value?.level3Value || null,
      roleId: baseRole.roleId,
      rolename: baseRole.rolename,
      permissions: baseRole.permissions,
    }));
  }
}
