import { Component, OnDestroy } from '@angular/core';
import {
  ASSET_MEASUREMENT,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  EMPTY_STRING,
  ERROR,
  FAILED,
  NO,
  SETUP_IMPORT_INSPECTION,
  SETUP_IMPORT_INSPECTION_DATA,
  SURE_CANCEL_HEADER,
  SURE_CANCEL_MSG,
  TOASTER_SUCCESS,
} from '../../shared/constants';
import { Store } from '@ngxs/store';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { Router } from '@angular/router';
import { ImportInspectionDataService } from '../../core/services/import-inspection-data.service';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { FileUploadService } from '../../core/services/file-upload.service';
import {
  BulkImportStatus,
  ImportService,
} from '../../core/services/import.service';
import * as XLSX from 'xlsx';
import moment from 'moment';
import { firstValueFrom, Observable, of, Subscription } from 'rxjs';
import { SetupHierarchyState } from '../../core/store/setup-hierarchy.state';
import {
  fileUploadEntityType,
  FileUploadInput,
  fileUploadType,
  importInput,
} from '../../../awsAppSync/API';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';

@Component({
  selector: 'app-import-bulk-asset-inspection-data',
  templateUrl: './import-bulk-asset-inspection-data.component.html',
  styleUrl: './import-bulk-asset-inspection-data.component.css',
})
export class ImportBulkAssetInspectionDataComponent implements OnDestroy{
  files: any;
  assetType: string | null = EMPTY_STRING;
  assetTypeId: string | null = EMPTY_STRING;
  fileData: any = null;
  fileExtension = '';
  fileName = '';
  file!: File;
  fileToUpload = '';
  currentAssetColumnDataType = '';
  formatDoesNotMatch = false;
  enableSave = false;
  uploadedFileColumnTypes: Record<string, string> = {};
  displayedColumns: string[] = [];
  tableData: any[] = [];
  errorMessages: string[] = [];
  availableOptions: string[] = [];
  selectedColumns: (string | null)[] = [];
  validationErrors: boolean[] = [];
  validationMessages: string[] = [];
  mappedColumns: (string | null)[] = [];
  mappedPreviewData: any[] = [];
  mappedData: any[] = [];
  assetsMandatoryColumns: string[] = [];
  currentColumnMapped: string[] = [];
  invalidValues: string[] = [];
  isLoading = false;
  syrcUserId: string | null = null;
  userId$: Observable<string | null> = of(null);
  importStatusSubscription: Subscription | undefined;
  currentColumnTypes: Record<string, string> = {};
  importMessage = EMPTY_STRING;
  constructor(
    private store: Store,
    private toastr: CustomToastrService,
    private router: Router,
    private importInspectionData: ImportInspectionDataService,
    private popupDialogService: PopupDialogService,
    private fileUploadService: FileUploadService,
    private importService: ImportService,
  ) {
    this.userId$ = this.store.select(AuthenticateUserState.getSyrcUserId);
  }
  ngOnDestroy(): void {
   this.removeDataFile();
  }

  ngOnInit(): void {
    this.displayedColumns = this.importInspectionData.displayedColumns;
    this.files = this.importInspectionData.files;
    this.assetType = this.importInspectionData.assetType;
    this.assetTypeId = this.importInspectionData.assetTypeId;
    this.assetsMandatoryColumns =
      this.importInspectionData.currentAssetmandatoryColumns;
    this.uploadedFileColumnTypes = this.importInspectionData.columnTypes;
    this.currentColumnTypes = this.uploadedFileColumnTypes;
    this.onFileSelected(this.files);
    this.userId$.subscribe((userId) => {
      this.syrcUserId = userId ?? '';
    });
  }

  onFileSelected(event: any) {
    const file = event[0];
    this.processFile(file);
  }

  processFile(file: File) {
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension === 'xlsx' || fileExtension === 'csv') {
      this.fileName = file.name;
      this.fileExtension = fileExtension;
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          raw: false,
        });
        if (Array.isArray(sheetData[0])) {
          sheetData[0] = sheetData[0].map((cell: string) => {
            return typeof cell === 'string' ? cell.replace(/\*/g, '') : cell;
          });
        }
        this.tableData = sheetData;

        const validColumnIndexes = this.tableData[0]
          .map((_: any, columnIndex: any) => columnIndex)
          .filter((columnIndex: number) => !this.isColumnBlank(columnIndex));

        this.tableData = this.tableData.map((row) =>
          validColumnIndexes.map((index: any) => row[index]),
        );

        this.availableOptions = this.tableData[0].map((option: string) =>
          option.replace('*', ''),
        );

        this.selectedColumns = new Array(this.displayedColumns.length).fill(
          null,
        );
        this.mappedColumns = new Array(this.displayedColumns.length).fill(null);
        this.validationErrors = new Array(this.displayedColumns.length).fill(
          false,
        );
        this.validationMessages = new Array(this.displayedColumns.length).fill(
          '',
        );
        this.errorMessages = [];
      };
      reader.readAsArrayBuffer(file);
    } else {
      this.toastr.showWarning(
        'Only XLSX and CSV file formats are supported!',
        'Warning',
      );
    }
  }

  isColumnBlank(columnIndex: number): boolean {
    return this.tableData
      .slice(1)
      .every(
        (row) =>
          row[columnIndex] === null ||
          row[columnIndex] === '' ||
          row[columnIndex] === undefined,
      );
  }

  removeFile() {
    this.fileData = null;
    this.fileExtension = '';
    this.fileName = '';
    this.tableData = [];
    this.errorMessages = [];
    this.availableOptions = [];
    this.selectedColumns = new Array(this.displayedColumns.length).fill(null);
    this.mappedColumns = new Array(this.displayedColumns.length).fill(null);
    this.mappedPreviewData = [];
    this.mappedData = [];
    this.validationErrors = [];
    this.router.navigate([SETUP_IMPORT_INSPECTION], { replaceUrl: true });
    this.importInspectionData.isFreshRoute = true;
  }

  removeDataFile() {
    this.fileData = null;
    this.fileExtension = '';
    this.fileName = '';
    this.tableData = [];
    this.errorMessages = [];
    this.availableOptions = [];
    this.selectedColumns = new Array(this.displayedColumns.length).fill(null);
    this.mappedColumns = new Array(this.displayedColumns.length).fill(null);
    this.mappedPreviewData = [];
    this.mappedData = [];
    this.validationErrors = [];
   // this.router.navigate([SETUP_IMPORT_INSPECTION_DATA], { replaceUrl: true });
  }

  onColumnSelected(index: number, selectedValue: string) {
    this.onColumnUnmapped(index);
    this.validateColumnMapping(index, selectedValue);
    this.updateAvailableOptions(index, selectedValue);
  }

  onColumnUnmapped(index: number) {
    const unmappedValue = this.selectedColumns[index];
    const originalIndex = this.tableData[0].indexOf(unmappedValue);
    this.removeElementInPlace(this.currentColumnMapped, unmappedValue);
    if (unmappedValue && originalIndex > -1) {
      this.availableOptions.splice(originalIndex, 0, unmappedValue);
    }
    this.selectedColumns[index] = null;
    this.validationErrors[index] = false;
    this.mappedColumns[index] = null;
    this.mappedPreviewData = this.mappedPreviewData.map((row) => {
      row[this.displayedColumns[index]] = null;
      return row;
    });
    this.mappedData = this.mappedData.map((row) => {
      row[this.displayedColumns[index]] = null;
      return row;
    });
    this.validationMessages[index] = '';
    this.checkMandatoryColumns();
    this.invalidValues = [];
  }
  removeElementInPlace(arr: string[], valueToRemove: string | null): void {
    if (valueToRemove) {
      let index = arr.indexOf(valueToRemove);
      while (index !== -1) {
        arr.splice(index, 1);
        index = arr.indexOf(valueToRemove);
      }
    }
  }
  formatCellValue(value: any) {
    if (value !== null && this.isValidDate(value)) {
      const date = new Date(value);
      return date.toLocaleDateString();
    }
    return value;
  }

  isValidDate(dateString: string): boolean {
    // Define a list of possible date formats
    const formats = [
      'YYYY-MM-DD',
      'MM/DD/YYYY',
      'DD/MM/YYYY',
      'YYYY/MM/DD',
      'YYYY-MM-DDTHH:mm:ssZ',
      'MM-DD-YYYY',
      'DD-MM-YYYY',
      'YYYY-MM-DDTHH:mm:ss',
      'YYYY-MM-DDTHH:mm:ss.SSSZ',
      'YYYY-MM-DDTHH:mm:ss.SSS',
      'M/D/YY',
      'MM/DD/YY',
      'DD/MM/YY',
      'YYYY/MM/DD',
      'YY/MM/DD',
    ];

    // Try to parse the date string with the defined formats
    const isValid = formats.some((format) =>
      moment(dateString, format, true).isValid(),
    );

    return isValid;
  }
  checkMandatoryColumns(): void {
    this.enableSave = this.assetsMandatoryColumns.every((column) =>
      this.currentColumnMapped.includes(column),
    );
  }

  updateAvailableOptions(index: number, selectedValue: string) {
    const selectedIndex = this.availableOptions.indexOf(selectedValue);
    if (selectedIndex > -1) {
      this.availableOptions.splice(selectedIndex, 1);
    }
    this.mappedColumns[index] = selectedValue;
  }

  validateColumnMapping(index: number, selectedValue: string): void {
    if (this.selectedColumns.includes(selectedValue)) {
      this.selectedColumns[index] = selectedValue;
      this.validationErrors[index] = true;
      this.validationMessages[index] = 'Already Selected';
    } else {
      this.selectedColumns[index] = selectedValue;
      const column = this.displayedColumns[index];
      this.currentColumnMapped.push(column);
      const columnKey = this.displayedColumns[index];
      this.currentAssetColumnDataType = this.currentColumnTypes[columnKey];
      const selectedColumn = this.selectedColumns[index];
      const columnIndex = this.tableData[0]
        .map((option: string) => option.replace('*', ''))
        .indexOf(selectedColumn);
      const uploadedColumnDataType =
        this.uploadedFileColumnTypes[selectedValue];
      if (columnIndex === -1) {
        this.validationErrors[index] = true;
        this.validationMessages[index] = 'Column not found in the data';
        return;
      }

      const values = this.tableData
        .slice(1)
        .map((row) => this.cleanValue(row[columnIndex]))
        .slice(0, 10); // Consider the first 10 rows for validation

      if (this.allValuesBlank(values)) {
        this.validationErrors[index] = true;
        this.validationMessages[index] = 'Column contains all blank values';
      } else {
        this.formatDoesNotMatch =
          this.currentAssetColumnDataType == uploadedColumnDataType
            ? false
            : true;
        if (this.formatDoesNotMatch) {
          this.invalidValues.push('True');
        }
        if (this.invalidValues.length > 0) {
          this.validationErrors[index] = true;
          this.validationMessages[index] = "Format doesn't match";
        } else {
          this.validationErrors[index] = false;
          this.validationMessages[index] = '';
        }
      }
      this.mapDataToPreview();
      this.checkMandatoryColumns();
    }
  }

  mapDataToPreview() {
    this.mappedPreviewData = this.tableData
      .slice(1)
      .slice(0, 10)
      .map((row) => {
        const mappedRow: any = {};
        this.selectedColumns.forEach((col, i) => {
          const columnIndex = this.tableData[0].indexOf(col);
          if (columnIndex > -1) {
            mappedRow[this.displayedColumns[i]] = row[columnIndex];
          } else {
            mappedRow[this.displayedColumns[i]] = null;
          }
        });
        return mappedRow;
      });
    this.mappedData = this.tableData.slice(1).map((row) => {
      const mappedRow: any = {};
      this.selectedColumns.forEach((col, i) => {
        const columnIndex = this.tableData[0].indexOf(col);
        if (columnIndex > -1) {
          mappedRow[this.displayedColumns[i]] = row[columnIndex];
        } else {
          mappedRow[this.displayedColumns[i]] = null;
        }
      });
      return mappedRow;
    });
    this.mappedColumns = this.selectedColumns;
  }

  cleanValue(value: any): string {
    if (typeof value === 'string') {
      return value.trim();
    }
    return value;
  }

  allValuesBlank(values: any[]): boolean {
    return values.every(
      (value) => value === null || value === '' || value === undefined,
    );
  }

  onCancel() {
    this.popupDialogService.openDialog(
      SURE_CANCEL_HEADER,
      SURE_CANCEL_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () =>
        this.router.navigate([SETUP_IMPORT_INSPECTION], { replaceUrl: true }),
      true,
      NO,
    );
    this.importInspectionData.isFreshRoute = true;
  }

  async saveFileImport(remainOnSamePage: boolean) {
    const hasValidationErrors = this.validationErrors.some(
      (value) => value === true,
    );
    if (this.fileName === '') {
      this.toastr.showWarning('Please upload file', 'Warning');
    } else if (hasValidationErrors) {
      this.toastr.showWarning(
        'Please correct the errors in the column mappings',
        'Warning',
      );
    } 
    else {
      this.isLoading = true;
      this.uploadFile();
      if (!remainOnSamePage) {
        this.router.navigate([SETUP_IMPORT_INSPECTION_DATA]);
      }
    }
  }

  createExcel() {
    this.mappedData = this.mappedData.map(item => {
      const updatedItem: any = {};

      Object.keys(item).forEach(key => {
        const newKey = this.assetsMandatoryColumns.includes(key) ? `${key}*` : key;
        updatedItem[newKey] = item[key];
      });

      return updatedItem;
    });
    const headers = this.mappedData.length > 0 ? Object.keys(this.mappedData[0]) : [];
    if (!this.mappedData || !Array.isArray(this.mappedData)) {
      throw new Error('Invalid data format for Excel generation');
    }

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.mappedData,
      {
        header: headers,
      },
    );

    const workbook: XLSX.WorkBook = {
      Sheets: { Sheet1: worksheet },
      SheetNames: ['Sheet1'],
    };
    const fileName =
      (this.assetType || 'Data').replace(/ /g, '_') + '_data.xlsx';

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const fileBlob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    const file = new File([fileBlob], fileName, {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });

    return file;
  }

  async uploadFile() {
    const file = this.createExcel();
    const primaryCompanyId = await firstValueFrom(
      this.store.select(SetupHierarchyState.getPrimaryCompanyId),
    );
    if (primaryCompanyId) {
      const _fileInputData: FileUploadInput = {
        filename: file.name,
        entityType: fileUploadEntityType.PrimaryCompany,
        entityTypeId: primaryCompanyId,
        fileUploadType: fileUploadType.Import,
      };

      const _getPresignedURL =
        await this.importService.uploadExcelFile(_fileInputData);
      await this.fileUploadService.uploadToS3(
        String(_getPresignedURL?.presignedURL),
        file,
        String(_getPresignedURL?.contentType),
      );

      if (this.syrcUserId) {
        this.importStatusSubscription = this.importService
          .executeImportSubscription(this.syrcUserId)
          .subscribe({
            next: (data: BulkImportStatus) => {
              console.log('Subscription data received:', data);
              this.importMessage = data.importMessage;
              if (data.status === 'Error') {
                this.importInspectionData.invalidFileUrl = data.fileURL ?? '';
                this.toastr.showError('Uploaded File is invalid', ERROR);
                this.router.navigate([SETUP_IMPORT_INSPECTION]);
                this.isLoading = false;
                this.importStatusSubscription?.unsubscribe();
              } else if (data.status === 'Success') {
                this.toastr.showSuccess(
                  'File Imported Successfully!',
                  TOASTER_SUCCESS,
                );
                this.router.navigate([SETUP_IMPORT_INSPECTION]);
                this.isLoading = false;
                this.importStatusSubscription?.unsubscribe();
              }
            },
            error: (err: any) => {
              console.error('Subscription error:', err);
              this.importStatusSubscription?.unsubscribe();
            },
          });
      }

      const _input: importInput = {
        fileURL: _getPresignedURL?.fileURL ?? '',
        entityType: ASSET_MEASUREMENT,
        entityValue: JSON.stringify({
          primaryCompanyId: primaryCompanyId,
        }),
      };
      this.importService.bulkImport(_input);
      this.importInspectionData.assetType = this.assetType;
      this.importInspectionData.isFreshRoute = false;
    }
  }
}
