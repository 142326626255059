import {
  ChangeDetectorRef,
  Component,
  QueryList,
  ViewChild,
  ViewChildren,
  OnInit,
  ElementRef,
} from '@angular/core';
import {
  DEFAULT,
  DEFAULTPAGESIZE,
  LONG,
  ND,
  PAGINATION_ARRAY,
  RD,
  SEARCHSURVEYS,
  ST,
  REDIRECT_CREATE_SURVEY,
  SURVEYOVERVIEW,
  SURVEYOVERVIEW_ELLIPSIS_WIDTH,
  TH,
  ZERO,
  ERROR_FETCHING_SURVEY_OVERVIEW,
  EMPTY_STRING,
  SURVEY_OVERVIEW_SCREEN,
  ASSIGN_USER_TO_SURVEY_ERROR,
  REDIRECT_FIELD_DATA
} from '../../shared/constants';
import { SurveyOverviewService } from '../../core/services/survey-overview.service';
import {
  ColumnBase,
  ColumnComponent,
  GridComponent,
  RowArgs,
  RowStickyFn,
  DataStateChangeEvent,
  GridDataResult,
  FilterService,
} from '@progress/kendo-angular-grid';
import { MatDialog } from '@angular/material/dialog';
import { ExportModalComponent } from '../../shared/export-modal/export-modal.component';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { Router } from '@angular/router';
import {
  KendoGridFilters,
  Survey,
  templateType,
  UserSYRCOptionInput,
} from '../../../awsAppSync/API';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import {
  RemoveSurveyOverviewData,
  SetSurveyOverviewData,
} from '../../core/store/survey-overview.state';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
} from '@progress/kendo-data-query';
import { PermissionService } from '../../core/services/permission.service';
import { CreateSurveyService } from '../../core/services/create-survey.service';
import { LastEvaluatedKeyModel } from '../../core/models/lastevaluatedkey';

@Component({
  selector: 'app-survey-overview',
  templateUrl: './survey-overview.component.html',
  styleUrl: './survey-overview.component.css',
})
export class SurveyOverviewComponent implements OnInit {
  @ViewChild('grid') grid!: GridComponent;
  @ViewChildren(ColumnComponent) columns!: QueryList<ColumnComponent>;
  @ViewChild('pdfExport', { static: false }) pdfExport!: PDFExportComponent;
  @ViewChild('customFilterContainer', { static: false })
  customFilter!: ElementRef;
  public PAGINATION_ARRAY = PAGINATION_ARRAY;
  public pageSize = DEFAULTPAGESIZE;
  public searchPlaceholder = SEARCHSURVEYS;
  public originalData: any[] = [];
  public expandedRows: Record<string, boolean> = {};
  currentDataItem: any;
  dropdownVisible = false;
  dropdownX = ZERO;
  dropdownY = ZERO;
  selectedRowData: any;
  public rowId = ZERO;
  isStickyRow = false;
  public myStickyRows: string[] = [];
  ellipsisWidth = SURVEYOVERVIEW_ELLIPSIS_WIDTH;
  public inputScreen = SURVEYOVERVIEW;
  public templateTypeInput = templateType.survey_overview;
  public skip = ZERO;
  searchText = '';
  public gridDataToExport: any[] = [];
  pageChanged = false;
  currentPage = 1;
  title = 'SIDE_NAV_SUB_MENU.SURVEY_OVERVIEW';
  primaryCompanyId: string | null = null;
  storeCoreViewPrimaryCompanyID$: Observable<string | null>;
  coreViewPrimaryCompanyId: string | null = null;
  userList: any[] = [];
  surveyTypeFilterValues: any[] = [];
  surveyPriorityFilterValues: any[] = [];
  surveyStatusFilterValues: any[] = [];
  surveyDataCollectionFilterValues: any[] = [];
  public nextKey: LastEvaluatedKeyModel | null = null;
  public previousKey: LastEvaluatedKeyModel | null = null;
  public size = 10; // default page size
  public pageNumber = 1;
  public pageLoad = false;

  public _selectedColumns: string[] = [
    'surveyName',
    'surveyType',
    'surveyPriority',
    'surveyStatus',
    'dueDate',
    'dataCollectionType',
    'assignedTo',
    'supervisor',
    'dateOfCompletion',
    'surveyDescription',
  ];
  public isLoading = true;
  public gridData: GridDataResult = { data: [], total: 0 };

  kendoGridFilters: KendoGridFilters = {};
  storePrimaryCompanyID$: Observable<string | null>;
  selectedprimaryCompanyId: string | null = '';
  permission = false;
  IsAssignToButtonDisabled = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private surveyOverviewService: SurveyOverviewService,
    public dialog: MatDialog,
    private router: Router,
    private store: Store,
    public permissionService: PermissionService,
    private createSurveyService: CreateSurveyService,
  ) {
    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );

    this.storeCoreViewPrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getCoreViewPrimaryCompanyId,
    );
  }
  ngOnInit() {
    this.permission = this.permissionService.hasPermission(
      SURVEY_OVERVIEW_SCREEN,
      'fullAccess',
    );
    this.storePrimaryCompanyID$.subscribe((id) => {
      this.selectedprimaryCompanyId = '';
      this.selectedprimaryCompanyId = id;
    });
    this.storeCoreViewPrimaryCompanyID$.subscribe((id) => {
      this.coreViewPrimaryCompanyId = id;
    });
    this.loadSurveys();
    this.bindSYRCUserList();
  }

  async loadSurveys(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await this.surveyOverviewService.getAllSurveyLaunchpad({
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
        gridFilters: this.kendoGridFilters,
        searchText: this.searchText,
        primaryCompanyId: this.selectedprimaryCompanyId ?? '',
        lastEvaluatedKey: this.currentPage == 1 ? null : this.nextKey,
      });

      if (response?.items) {
        this.gridData = {
          data: response.items.filter((item): item is Survey => item !== null),
          //total :response?.totalCount != null ? response?.totalCount : 0
          total: 50,
        };

        this.originalData = this.gridData.data;
        this.getDistinctValues('dataCollectionType');
        this.getDistinctValues('surveyType');
        this.getDistinctValues('surveyPriority');
        this.getDistinctValues('surveyStatus');
        this.isLoading = false;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      }
    } catch (error) {
      console.error(ERROR_FETCHING_SURVEY_OVERVIEW, error);
    }
  }

  height = 780; // initial height
  showHierarchicalFilter = false;
  showFilterBtn = true;

  toggleFilterTray() {
    this.showHierarchicalFilter = !this.showHierarchicalFilter;
    this.showFilterBtn = !this.showFilterBtn;
    if (this.showHierarchicalFilter) {
      this.height = 830;
    } else {
      this.height = 780;
    }
  }

  collapseFilterTray() {
    this.toggleFilterTray();
  }

  public toggleDescription(id: string): void {
    this.expandedRows[id] = !this.expandedRows[id];
  }

  public isExpanded(id: string): boolean {
    return !!this.expandedRows[id];
  }
  getPriorityClass(priority: string): string {
    const p = priority?.toLowerCase();
    switch (p) {
      case 'high':
        return 'high-priority';
      case 'medium':
        return 'medium-priority';
      case 'low':
        return 'low-priority';
      default:
        return '';
    }
  }
  surveyName = '';

  openDropdownMenu(event: MouseEvent, dataItem: any) {
    event.stopPropagation();
    this.dropdownVisible = true;
    this.currentDataItem = dataItem;
    this.surveyName = this.currentDataItem.surveyName;
    this.rowId = this.currentDataItem.id;
    const gridElement = document.querySelector('.k-grid') as HTMLElement;
    const dropdownHeight = 100;
    const dropdownWidth = 200;

    if (gridElement) {
      const gridRect = gridElement.getBoundingClientRect();
      const clickX = event.clientX - gridRect.left + gridElement.scrollLeft;
      const clickY = event.clientY - gridRect.top + gridElement.scrollTop;

      const spaceBelow =
        window.innerHeight -
        (event.clientY - gridRect.top + gridElement.scrollTop);

      if (spaceBelow < dropdownHeight) {
        this.dropdownY = clickY - dropdownHeight;
      } else {
        this.dropdownY = clickY;
      }
      this.dropdownX = Math.max(
        0,
        Math.min(clickX, gridElement.clientWidth - dropdownWidth),
      );

      this.dropdownX += gridRect.left;
      this.dropdownY = Math.max(0, this.dropdownY + 150);
      if (this.dropdownY + dropdownHeight > window.innerHeight) {
        this.dropdownY = window.innerHeight - dropdownHeight;
      }
    }
    const rowId = dataItem.id;
    this.selectedRowData = dataItem;
    if (this.isSticky(rowId)) {
      this.isStickyRow = true;
    } else {
      this.isStickyRow = false;
    }
  }
  handleDropdownOption(option: number) {
    if (option === 1) {
      this.toggleStickyRow(this.currentDataItem.id);
    }
    if (option === 2) {
      this.store.dispatch(
        new SetSurveyOverviewData(
          this.selectedRowData.id,
          this.selectedRowData.pk,
          this.selectedRowData.sk,
          EMPTY_STRING,
          EMPTY_STRING,
          null,
          EMPTY_STRING,
          EMPTY_STRING,
          null,
          EMPTY_STRING,
          EMPTY_STRING,
          EMPTY_STRING,
          EMPTY_STRING,
          EMPTY_STRING,
          true,
        ),
      );
      this.router.navigate([REDIRECT_CREATE_SURVEY]);
    }
    if (option === 3) {
      this.isDialogOpen = true;
    }
  }

  public rowSticky: RowStickyFn = (args: RowArgs) =>
    this.isSticky(args.dataItem.id);

  public isSticky(id: string): boolean {
    return this.myStickyRows.indexOf(id) >= 0;
  }

  public toggleStickyRow(id: string): void {
    if (this.isSticky(id)) {
      this.myStickyRows = this.myStickyRows.filter(
        (stickyId) => stickyId !== id,
      );
    } else {
      this.myStickyRows.push(id);
    }
    this.sortGridData();
  }
  private sortGridData(): void {
    // Create a copy of gridData to avoid modifying the original array in place
    const sorteddt = [...this.gridData.data];

    // Sort the copied array
    sorteddt.sort((a, b) => {
      // Check if a or b is null or undefined
      if (a === null || a === undefined) return 1;
      if (b === null || b === undefined) return -1;

      const isASticky = this.isSticky(a.id);
      const isBSticky = this.isSticky(b.id);

      if (isASticky && !isBSticky) {
        return -1;
      } else if (!isASticky && isBSticky) {
        return 1;
      } else {
        // Maintain the original order if both are non-sticky
        return a.id.localeCompare(b.id); // Use localeCompare for string comparison
      }
    });
    this.gridData.data = sorteddt;
  }
  handleDropdownVisibilityChange(visible: boolean) {
    this.dropdownVisible = visible;
  }
  rowActionStickyRow(event: MouseEvent, dataItem: any) {
    event.stopPropagation(); // Prevent the click event from propagating to the document
    //we want to change the text from Upin to Pin of drop-down that we are making this call
    const rowId = dataItem.id;
    //we need to call the isSticky function to check the cooresponding row is sticky or not
    //here we have used the order by to check either row is sticky or not
    if (this.isSticky(rowId)) {
      this.isStickyRow = true;
    } else {
      this.isStickyRow = false;
    }
    //this is used to make the row unsticky
    this.toggleStickyRow(rowId);
  }

  handleSearchInput(changeWord: string) {
    if (changeWord.length < 3 && this.searchText.length > 2) {
      this.searchText = '';
      this.loadSurveys();
    } else if (changeWord.length > 2) {
      this.searchText = changeWord;
      this.loadSurveys();
    }
  }

  containsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === 'string') {
      return field.toLowerCase().includes(searchTerm);
    }
    return false;
  }

  formatDate(date: Date): string {
    if (!date) return '';

    const day = date.getDate();
    const month = date.toLocaleString(DEFAULT, { month: LONG });
    const year = date.getFullYear();

    const daySuffix = this.getDaySuffix(day);

    return `${day}${daySuffix} ${month}, ${year}`;
  }

  formatDateString(dateString: string | Date | null): string | null {
    if (dateString !== null) {
      const date = new Date(dateString);

      const day = date.getDate();
      const daySuffix = this.getDaySuffix(day);

      const month = date.toLocaleDateString('en-US', { month: 'short' });
      const year = date.toLocaleDateString('en-US', { year: 'numeric' });

      // Return formatted string with the day, day suffix, month, comma, and year
      return `${day}${daySuffix} ${month}, ${year}`;
    }
    return null;
  }

  getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return TH;
    switch (day % 10) {
      case 1:
        return ST;
      case 2:
        return ND;
      case 3:
        return RD;
      default:
        return TH;
    }
  }

  openDialog(): void {
    const currentPageData = this.gridData.data.slice(
      this.skip,
      this.skip + this.pageSize,
    );
    const dataToExport = this.pageChanged
      ? this.gridDataToExport
      : currentPageData;
    const filteredData = dataToExport.map((item) => {
      const filteredItem: Record<string, any> = {};
      this._selectedColumns.forEach((column) => {
        filteredItem[column] = item[column];
      });
      return filteredItem;
    });

    this.dialog.open(ExportModalComponent, {
      data: {
        selectedColumnData: filteredData,
        selectedColumn: this._selectedColumns,
        pdfExport: this.pdfExport,
        pageSize: this.pageSize,
        gridData: dataToExport,
        allData: this.originalData,
        flag: SURVEYOVERVIEW,
        filters: {
          pageNumber: this.currentPage,
          pageSize: this.state.take ?? 50,
          gridFilters: this.kendoGridFilters,
          searchText: this.searchText,
          primaryCompanyId: this.selectedprimaryCompanyId ?? '',
        },
      },
    });
    this.pageChanged = false;
  }

  // page change event handler
  public onPageChange(event: any): void {
    this.skip = event.skip;
    this.pageSize = event.take;
    this.currentPage = this.skip / this.pageSize + 1;
    this.updatePagedData();
    this.pageChanged = true;
  }

  // Update the grid data for the current page
  private updatePagedData(): void {
    const startIndex = this.skip;
    const endIndex = startIndex + this.pageSize;
    this.gridDataToExport = this.originalData.slice(startIndex, endIndex);
    this.cdr.markForCheck(); // Notify Angular to check for changes
  }

  public OnSelectedTemplateColumnsChange(selectedColumns: string[]) {
    this._selectedColumns = selectedColumns;
    this._selectedColumns.forEach((columnName) => {
      this.IsColumnSelected(columnName);
    });
  }

  public IsColumnSelected(columnName: string): boolean {
    return this._selectedColumns.includes(columnName);
  }

  public reorderColumnPosition(columnPosition: {
    previousIndexOfCol: number;
    newIndexOfCol: number;
    columnName: string;
  }): void {
    const fieldName = columnPosition.columnName;
    const index = columnPosition.newIndexOfCol + 1;
    const column = this.columns.find((col) => col.field === fieldName);
    if (column) {
      if (columnPosition.newIndexOfCol < columnPosition.previousIndexOfCol) {
        this.grid.reorderColumn(column as ColumnBase, index, { before: true });
      } else {
        this.grid.reorderColumn(column as ColumnBase, index);
      }
      this.columns.reset([...this.columns.toArray()]);
    }
  }

  createSurveyTask() {
    this.store.dispatch(new RemoveSurveyOverviewData());
    this.router.navigate([REDIRECT_CREATE_SURVEY]);
  }

  customstate: { filter: CompositeFilterDescriptor } = {
    filter: { logic: 'and', filters: [] },
  };

  public state: State = {
    skip: 0,
    take: this.pageSize,
    group: [],
    filter: { filters: [], logic: 'and' },
    sort: [],
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.isLoading = true;
    this.state = state;
    this.currentPage = state.skip / this.pageSize + 1;
    this.kendoGridFilters = this.state.filter as KendoGridFilters;
    const filter = state.filter as CompositeFilterDescriptor;
    this.customstate = { ...this.customstate, filter };
    this.loadSurveys();
  }

  dropdownData: string[] = ['User A', 'User B', 'User C'];
  selectedValue: { id: string; value: string } | null = null;

  onCancel(): void {
    console.log('Cancel clicked');
    this.isDialogOpen = false;
    // Close the dialog or clear selections
  }

  async onMoveToFieldData(surveyId: any): Promise<void> {
    console.log('Move to Field Data clicked', this.selectedValue);
    
    if (!this.selectedValue) {
      console.error('No selected value provided.');
      return;
    }

    const response = await this.surveyOverviewService.assignUserToSurvey({
      assignedTo: {
        id: this.selectedValue.id,
        name: this.selectedValue.value
      },
      surveyId:surveyId,
      primaryCompany: this.selectedprimaryCompanyId ?? ''
    });
    
    if (response?.status == "Error") {
      console.error(ASSIGN_USER_TO_SURVEY_ERROR,response?.error);
      return;
    }
    this.router.navigate([REDIRECT_FIELD_DATA]);
  }

  isDialogOpen = false;

  closeDialog(): void {
    this.isDialogOpen = false;
  }

  async bindSYRCUserList() {
    try {
      const usersSyrcOptionInput: UserSYRCOptionInput = {
        corViewCompanyId: this.coreViewPrimaryCompanyId ?? '',
        syrcPrimaryCompanyId: this.selectedprimaryCompanyId ?? '',
      };
      const syrcUserList =
        await this.createSurveyService.getAllSYRCUsers(usersSyrcOptionInput);
      if (syrcUserList) {
        this.userList =
          syrcUserList?.items?.map((item: any) => ({
            id: item?.id ?? '',
            value: `${item?.firstName} ${item?.lastName}`,
          })) || [];
      }
      this.selectedValue = this.userList[0];
      this.IsAssignToButtonDisabled = true;
    } finally {
      //this.isLoading = false;
    }
  }

  assignToUserChange(selectedUser: any) {
    if(selectedUser.id == this.userList[0].id){
      this.IsAssignToButtonDisabled = true;
    }
    else{
      this.IsAssignToButtonDisabled = false;
    }
  }

  public applyFilter(
    selectedValues: any[],
    filterService: FilterService,
    field: string,
  ): void {
    const filters: FilterDescriptor[] = [];
    for (const value of selectedValues) {
      if (value.selected) {
        filters.push({
          field: field,
          operator: 'eq',
          value: value.text,
        });
      }
    }
    const rootFilter: CompositeFilterDescriptor = {
      logic: 'or',
      filters: filters,
    };

    filterService.filter(rootFilter);
  }
  getDistinctValues(columnName: any): void {
    // Create a Set to store distinct non-null values for the specified column
    const distinctValues = new Set<string>(
      this.gridData.data
        .map((item) => item![columnName])
        .filter(
          (value): value is string =>
            value !== null && value != '' && value !== undefined,
        ),
    );

    // Create a new filter array for the column if it doesn't exist
    const filterValuesMap: Record<
      string,
      { text: string; selected: boolean }[]
    > = {
      surveyType: this.surveyTypeFilterValues,
      surveyPriority: this.surveyPriorityFilterValues,
      surveyStatus: this.surveyStatusFilterValues,
      dataCollectionType: this.surveyDataCollectionFilterValues,
    };

    // Add distinct values to the respective filter array based on the column name
    distinctValues.forEach((item) => {
      const filterItem = { text: item, selected: false };
      const filterArray = filterValuesMap[columnName];
      if (filterArray) {
        // Check if the item already exists to prevent duplicates
        if (!filterArray.some((f) => f.text === item)) {
          filterArray.push(filterItem);
        }
      }
    });
  }
}
