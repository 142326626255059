import { Injectable } from '@angular/core';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import {
  SurveyConnection,
  SurveyFilterInput,
  GetAllPublishedSurveyQueryVariables,
} from '../../../awsAppSync/API';
import {
  getAllPublishedSurvey,
} from '../../../awsAppSync/graphql/queries';
import {
  ERROR_FETCHING_PUBLISHED_SURVEY,
} from '../../shared/constants';
@Injectable({
  providedIn: 'root'
})
export class SurveyLibraryService {
  constructor(
    private awsService: BaseAWSRequestService,
  ) {}

  public async getAllPublishedSurvey(
    input: SurveyFilterInput,
  ): Promise<SurveyConnection | null> {
    const query = getAllPublishedSurvey; // Ensure this is your GraphQL query string or document
    const variables: GetAllPublishedSurveyQueryVariables = {
      input: input,
    };

    try {
      const result = await this.awsService.executeGetQuery<{
        getAllPublishedSurvey: SurveyConnection;
      }>(query, variables);
      return result.getAllPublishedSurvey || null;
    } catch (error) {
      console.error(ERROR_FETCHING_PUBLISHED_SURVEY, error);
      return null;
    }
  }


}
