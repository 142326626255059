import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  CLOSE,
  DEFAULTPAGESIZE,
  DOWNLOAD_FAILED,
  DOWNLOAD_INITIATED,
  ERROR,
  ERROR_FETCHING_SURVEY_FIELD_DATA,
  PAGINATION_ARRAY,
  SEARCHFIELDDATA,
  SUCCESS,
  SURVEY_FIELD_DATA_ELLIPSIS_WIDTH,
  SURVEY_FIELD_DATA_SCREEN,
  SURVEY_FIELD_DATA_SCREEN_SUB_GRID,
  ZERO,
} from '../../shared/constants';
import {
  DataStateChangeEvent,
  FilterService,
  GridDataResult,
  RowArgs,
  RowStickyFn,
} from '@progress/kendo-angular-grid';
import { MatDialog } from '@angular/material/dialog';
import { FileUploadPopupComponent } from '../../shared/file-upload-popup/file-upload-popup.component';
import { PermissionService } from '../../core/services/permission.service';
import {
  CompositeFilterDescriptor,
  FilterDescriptor,
  State,
} from '@progress/kendo-data-query';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { SurveyFieldDataReviewService } from '../../core/services/survey-field-data-review.service';
import {
  KendoGridFilters,
  SurveyFieldData,
  SurveyInput,
} from '../../../awsAppSync/API';
import { LastEvaluatedKeyModel } from '../../core/models/lastevaluatedkey';
import { Observable } from 'rxjs';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-survey-field-data',
  templateUrl: './survey-field-data.component.html',
  styleUrl: './survey-field-data.component.css',
})
export class SurveyFieldDataComponent {
  @ViewChild('customFilterContainer', { static: false })
  customFilter!: ElementRef;
  isLoading = false;
  public PAGINATION_ARRAY = PAGINATION_ARRAY;
  public pageSize = DEFAULTPAGESIZE;
  // public gridData: GridDataResult = { data: [], total: 0 };
  public gridData: GridDataResult = { data: [], total: 0 };
  currentDataItem: any;
  surveyName = '';
  dropdownVisible = false;
  dropdownX = ZERO;
  dropdownY = ZERO;
  selectedRowData: any;
  public rowId = ZERO;
  isStickyRow = false;
  permission = false;
  public myStickyRows: string[] = [];
  public searchPlaceholder = SEARCHFIELDDATA;
  ellipsisWidth = SURVEY_FIELD_DATA_ELLIPSIS_WIDTH;
  public inputScreen = SURVEY_FIELD_DATA_SCREEN;
  surveyTypeFilterValues: any[] = [];
  surveyPriorityFilterValues: any[] = [];
  surveyStatusFilterValues: any[] = [];
  surveyVersionFilterValues: any[] = [];
  public nextKey: LastEvaluatedKeyModel | null = null;
  public previousKey: LastEvaluatedKeyModel | null = null;
  currentPage = 1;
  public size = 10; // default page size
  public pageNumber = 1;
  public pageLoad = false;
  searchText = '';
  kendoGridFilters: KendoGridFilters = {};
  storePrimaryCompanyID$: Observable<string | null>;
  storeCoreViewPrimaryCompanyID$: Observable<string | null>;
  selectedprimaryCompanyId: string | null = '';
  coreViewPrimaryCompanyId: string | null = null;
  public originalData: any[] = [];
  lastEvaluatedKey: LastEvaluatedKeyModel | null = null;
  constructor(
    private dialog: MatDialog,
    private fieldDataService: SurveyFieldDataReviewService,
    public permissionService: PermissionService,
    private popupDialogService: PopupDialogService,
    private store: Store,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
  ) {
    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );

    this.storeCoreViewPrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getCoreViewPrimaryCompanyId,
    );
  }

  ngOnInit() {
    this.storePrimaryCompanyID$.subscribe((id) => {
      this.selectedprimaryCompanyId = '';
      this.selectedprimaryCompanyId = id;
    });
    this.storeCoreViewPrimaryCompanyID$.subscribe((id) => {
      this.coreViewPrimaryCompanyId = id;
    });

    this.loadSurveyFieldData();

    //  this.getGridData();
  }

  async loadSurveyFieldData(): Promise<void> {
    try {
      this.isLoading = true;
      const response = await this.fieldDataService.getAllSurveyFieldData({
        pageNumber: this.currentPage,
        pageSize: this.pageSize,
        gridFilters: this.kendoGridFilters,
        searchText: this.searchText,
        primaryCompanyId: this.selectedprimaryCompanyId ?? '',
        lastEvaluatedKey: this.currentPage == 1 ? null : this.nextKey,
      });

      if (response?.items) {
        this.gridData = {
          data: response.items.filter(
            (item): item is SurveyFieldData => item !== null,
          ),
          total: 50,
        };
        if (response.lastEvaluatedKey != null) {
          if (response.lastEvaluatedKey.pk != null) {
            this.lastEvaluatedKey = {
              pk: response?.lastEvaluatedKey?.pk,
              sk: response?.lastEvaluatedKey?.sk,
            };
          } else {
            this.lastEvaluatedKey = null;
          }
        } else {
          this.lastEvaluatedKey = null;
        }

        this.nextKey = this.lastEvaluatedKey;
        this.originalData = this.gridData.data;
        this.getDistinctValues('version');
        this.getDistinctValues('surveyType');
        this.getDistinctValues('surveyPriority');
        this.getDistinctValues('surveyStatus');
        this.isLoading = false;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      }
    } catch (error) {
      console.error(ERROR_FETCHING_SURVEY_FIELD_DATA, error);
    }
  }

  handleSearchInput(changeWord: string) {
    if (changeWord.length < 3 && this.searchText.length > 2) {
      this.searchText = '';
      this.nextKey = null;
      this.currentPage = 1;
      this.pageNumber = 1;
      this.pageLoad = !this.pageLoad;
      this.loadSurveyFieldData();
    } else if (changeWord.length > 2) {
      this.searchText = changeWord;
      this.nextKey = null;
      this.currentPage = 1;
      this.pageNumber = 1;
      this.pageLoad = !this.pageLoad;
      this.loadSurveyFieldData();
    }
  }
  public rowSticky: RowStickyFn = (args: RowArgs) =>
    this.isSticky(args.dataItem.id);

  public isSticky(id: string): boolean {
    return this.myStickyRows.indexOf(id) >= 0;
  }

  public toggleStickyRow(id: string): void {
    if (this.isSticky(id)) {
      this.myStickyRows = this.myStickyRows.filter(
        (stickyId) => stickyId !== id,
      );
    } else {
      this.myStickyRows.push(id);
    }
    this.sortGridData();
  }
  private sortGridData(): void {
    const sorteddt = [...this.gridData.data];

    // Sort the copied array
    sorteddt.sort((a, b) => {
      // Check if a or b is null or undefined
      if (a === null || a === undefined) return 1;
      if (b === null || b === undefined) return -1;

      const isASticky = this.isSticky(a.id);
      const isBSticky = this.isSticky(b.id);

      if (isASticky && !isBSticky) {
        return -1;
      } else if (!isASticky && isBSticky) {
        return 1;
      } else {
        // Maintain the original order if both are non-sticky
        return a.id.localeCompare(b.id); // Use localeCompare for string comparison
      }
    });
    this.gridData.data = sorteddt;
  }

  openDropdownMenu(event: MouseEvent, dataItem: any, gridType: string) {
    if (gridType === 'innerGrid') {
      this.inputScreen = SURVEY_FIELD_DATA_SCREEN_SUB_GRID;
    } else {
      this.inputScreen = SURVEY_FIELD_DATA_SCREEN;
    }

    event.stopPropagation();
    this.dropdownVisible = true;
    this.currentDataItem = dataItem;
    this.rowId = this.currentDataItem.id;
    this.surveyName = this.currentDataItem.surveyName;
    const gridElement = document.querySelector('.k-grid') as HTMLElement;
    const dropdownHeight = 100;
    const dropdownWidth = 200;

    if (gridElement) {
      const gridRect = gridElement.getBoundingClientRect();
      const clickX = event.clientX - gridRect.left + gridElement.scrollLeft;
      const clickY = event.clientY - gridRect.top + gridElement.scrollTop;

      const spaceBelow =
        window.innerHeight -
        (event.clientY - gridRect.top + gridElement.scrollTop);

      if (spaceBelow < dropdownHeight) {
        this.dropdownY = clickY - dropdownHeight;
      } else {
        this.dropdownY = clickY;
      }
      this.dropdownX = Math.max(
        0,
        Math.min(clickX, gridElement.clientWidth - dropdownWidth),
      );

      this.dropdownX += gridRect.left;
      this.dropdownY = Math.max(0, this.dropdownY + 150);
      if (this.dropdownY + dropdownHeight > window.innerHeight) {
        this.dropdownY = window.innerHeight - dropdownHeight;
      }
    }
    const rowId = dataItem.id;
    this.selectedRowData = dataItem;
    if (this.isSticky(rowId)) {
      this.isStickyRow = true;
    } else {
      this.isStickyRow = false;
    }
    this.selectedRowData = dataItem;
  }
  rowActionStickyRow(event: MouseEvent, dataItem: any) {
    event.stopPropagation();
    const rowId = dataItem.id;
    if (this.isSticky(rowId)) {
      this.isStickyRow = true;
    } else {
      this.isStickyRow = false;
    }
    this.toggleStickyRow(rowId);
  }
  getPriorityClass(priority: string): string {
    const p = priority?.toLowerCase();
    switch (p) {
      case 'high':
        return 'high-priority';
      case 'medium':
        return 'medium-priority';
      case 'low':
        return 'low-priority';
      default:
        return '';
    }
  }

  openDialog() {
    this.dialog.open(FileUploadPopupComponent, {
      width: '500px',
      data: { surveyName: this.surveyName },
    });
  }

  // getGridData() {
  //   this.gridData = this.fieldDataService.gridData.data;
  // }
  handleDropdownVisibilityChange(visible: boolean) {
    this.dropdownVisible = visible;
  }
  handleDropdownOption(option: number) {
    if (option === 1) {
      this.toggleStickyRow(this.currentDataItem.id);
    } else if (option === 2) {
      this.openDownloadDialog();
    } else if (option == 3) {
      this.openDialog();
    }
  }

  getDistinctValues(columnName: any): void {
    // Create a Set to store distinct non-null values for the specified column
    const distinctValues = new Set<string>(
      this.gridData.data
        .map((item) => item![columnName])
        .filter(
          (value): value is string =>
            value !== null && value != '' && value !== undefined,
        ),
    );

    // Create a new filter array for the column if it doesn't exist
    const filterValuesMap: Record<
      string,
      { text: string; selected: boolean }[]
    > = {
      surveyType: this.surveyTypeFilterValues,
      surveyPriority: this.surveyPriorityFilterValues,
      surveyStatus: this.surveyStatusFilterValues,
      version: this.surveyVersionFilterValues,
    };

    // Add distinct values to the respective filter array based on the column name
    distinctValues.forEach((item) => {
      const filterItem = { text: item, selected: false };
      const filterArray = filterValuesMap[columnName];
      if (filterArray) {
        // Check if the item already exists to prevent duplicates
        if (!filterArray.some((f) => f.text === item)) {
          filterArray.push(filterItem);
        }
      }
    });
  }

  public applyFilter(
    selectedValues: any[],
    filterService: FilterService,
    field: string,
  ): void {
    const filters: FilterDescriptor[] = [];
    for (const value of selectedValues) {
      if (value.selected) {
        filters.push({
          field: field,
          operator: 'eq',
          value: value.text,
        });
      }
    }
    const rootFilter: CompositeFilterDescriptor = {
      logic: 'or',
      filters: filters,
    };

    filterService.filter(rootFilter);
  }
  isDownloadDialogOpen = false;
  preSignedUrl = '';
  async getSurveyFileForDownload() {
    let responseFlag = false;
    const inputObject: SurveyInput = {
      id: this.currentDataItem.id,
      pk: this.currentDataItem.pk,
      sk: this.currentDataItem.sk,
    };

    const response =
      await this.fieldDataService.exportSurveyToJson(inputObject);
    if (response) {
      if (response.status?.toLowerCase() === 'success') {
        responseFlag = true;
        this.preSignedUrl = response.exportFilePreSignedURL || '';
      }
    }
    return responseFlag;
  }
  async openDownloadDialog(): Promise<void> {
    this.isDownloadDialogOpen = true;
  }

  closeDownloadDialog(): void {
    this.isDownloadDialogOpen = false;
  }

  async downloadFile(): Promise<void> {
    this.closeDownloadDialog();
    this.isLoading = true;
    const result = await this.getSurveyFileForDownload();
    if (result) {
      this.http.get(this.preSignedUrl, { responseType: 'blob' }).subscribe(
        (blob) => {
          // Create a link element
          const a = document.createElement('a');
          const objectUrl = URL.createObjectURL(blob);
          a.href = objectUrl;

          // Set the download attribute (you can customize the file name here)
          a.download = this.currentDataItem.surveyName + '.mtx';
          a.click();

          // Release the object URL
          URL.revokeObjectURL(objectUrl);
          this.isLoading = false;
          this.popupDialogService.openDialog(
            DOWNLOAD_INITIATED,
            'The file has been successfully saved to your system',
            SUCCESS,
            CLOSE,
          );
        },
        (error) => {
          this.isLoading = false;
          console.error('Error downloading the file:', error);
          this.popupDialogService.openDialog(
            DOWNLOAD_FAILED,
            'Something Went Wrong to Download File',
            ERROR,
            CLOSE,
          );
        },
      );
    }
  }

  customstate: { filter: CompositeFilterDescriptor } = {
    filter: { logic: 'and', filters: [] },
  };

  public state: State = {
    skip: 0,
    take: this.pageSize,
    group: [],
    filter: { filters: [], logic: 'and' },
    sort: [],
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.isLoading = true;
    this.state = state;
    this.currentPage = state.skip / this.pageSize + 1;
    this.kendoGridFilters = this.state.filter as KendoGridFilters;
    const filter = state.filter as CompositeFilterDescriptor;
    this.customstate = { ...this.customstate, filter };
    this.nextKey = null;
    this.currentPage = 1;
    this.pageNumber = 1;
    this.pageLoad = !this.pageLoad;
    this.cdr.detectChanges();
    this.loadSurveyFieldData();
  }

  onCustomPageChanged(event: {
    page: number;
    pageSize: number;
    nextKey: LastEvaluatedKeyModel | null;
  }) {
    if (this.currentPage != event.page) {
      //this.pageNumber = event.page;
      this.currentPage = event.page;
      this.pageSize = event.pageSize;
      this.nextKey = event.nextKey; // Handle the new nextKey received
      this.loadSurveyFieldData();
    }

    if (this.size != event.pageSize) {
      this.pageNumber = event.page;
      this.currentPage = event.page;
      this.pageSize = event.pageSize;
      this.nextKey = event.nextKey;
      this.loadSurveyFieldData();
    }
  }
}
