import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  ChangeDetectorRef,
  ViewChildren,
  QueryList,
  Renderer2,
  HostListener,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { SVGIcon, searchIcon } from '@progress/kendo-svg-icons';
import {
  ColumnBase,
  ColumnComponent,
  ColumnReorderEvent,
  DataStateChangeEvent,
  GridComponent,
  GridDataResult,
  RowArgs,
  RowStickyFn,
} from '@progress/kendo-angular-grid';
import { ExceptionService } from './service/exception.service';
import { ExportModalComponent } from '../../shared/export-modal/export-modal.component';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { MatDialog } from '@angular/material/dialog';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../shared/service/local-storage/local-storage.service';
import {
  CLOSE,
  DEFAULT,
  EXCEPTION,
  EXCEPTION_ELLIPSIS_WIDTH,
  LONG,
  ND,
  PAGINATION_ARRAY,
  RD,
  REDIRECT_ASSET_HISTORY,
  REDIRECT_ASSET_SETUP,
  REDIRECT_INSPECTION,
  REDIRECT_MAPS,
  SEARCHEXCEPTION,
  ST,
  SUCCESS,
  SUCCESS_EXCLAIM,
  TH,
  EXCEPTION_SCREEN,
} from '../../shared/constants';
import {
  Exceptions,
  FilterInput,
  HierarchyFilterInput,
  KendoGridFilters,
  SurveyRouteFilterInput,
  templateType,
  UpdateExceptionInput,
} from '../../../awsAppSync/API';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import { Level3State } from '../../core/store/filter-level3.state';
import { SegmentTypeState } from '../../core/store/filter-segmenttype.state';
import { SegmentState } from '../../core/store/filter-segments';
import { SurveyRouteState } from '../../core/store/filter-serveyrotes';
import { AssetTypeState } from '../../core/store/filter-assettype';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import {
  ExceptionsModel,
  MeasurementModel,
  ProcessedMeasurement,
  ProcessedSurveyData,
} from '../../core/models/exception.module';
import { Column } from '../../core/models/inspection.module';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import { PermissionService } from '../../core/services/permission.service';
import { InspectionService } from '../../core/services/inspection.service';

@Component({
  selector: 'app-exception',
  templateUrl: './exception.component.html',
  styleUrl: './exception.component.css',
})
export class ExceptionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('columnadjustmentbutton', { static: false })
  columnadjustmentbutton!: ElementRef<HTMLButtonElement>;
  @ViewChild('pdfExport', { static: false }) pdfExport!: PDFExportComponent;
  @ViewChild('grid') grid!: GridComponent;
  @ViewChildren(ColumnComponent) columns!: QueryList<ColumnComponent>;
  public templateTypeInput = templateType.exception;
  public inputScreen = EXCEPTION;
  ellipsisWidth = EXCEPTION_ELLIPSIS_WIDTH;
  public _selectedColumns: string[] = [
    'assetType',
    'assetName',
    'targetRemediationDate',
    'assetDelinquencyDate',
    'workFlowStatus',
    'comments',
    'technician',
    'specialist',
    'rootCause',
  ];
  public gridData: GridDataResult = { data: [], total: 0 };
  public originalData: any[] = [];
  public showColumnAdjustmentPopup = false;
  public svgSearch: SVGIcon = searchIcon;
  public addedTemplateId = 0;
  public message = '';
  public templateId = 0;
  public pageSize = 50; // default page size
  public rowId = 0;
  currentPage = 0;
  pageChanged = false;
  public searchPlaceholder = SEARCHEXCEPTION;
  public gridDataToExport: any[] = [];
  public hiddenColumns: string[] = [];
  filteredData: any[] = [];
  private scrollListener: (() => void) | undefined;
  public isLoading = false;
  filterModel: FilterInput = {};
  storePrimaryCompanyID$: Observable<string | null>;
  selectedprimaryComapnyId: string | null = '';
  storeSelectedLevel3$: Observable<string[]>;
  selectedLevel3: string[] = [];
  storeSegmentTypeSet$: Observable<string[]>;
  selectedSegmentType: string[] = [];
  storeSelectedSegments$: Observable<string[]>;
  selectedSegments: string[] = [];
  storeSelectedSurveyRoute$: Observable<string[]>;
  selectedSurveyRoute: string[] = [];
  storeSelectedAssetType$: Observable<string[]>;
  selectedAssetType: string[] = [];
  hierarchyFilterInput: HierarchyFilterInput[] = [];
  surveyRouteFilterInput: SurveyRouteFilterInput[] = [];
  kendoGridFilters: KendoGridFilters = {};
  searchText = '';
  dynamicColumns: Column[] = [];
  public PAGINATION_ARRAY = PAGINATION_ARRAY;
  rootCause: string[] = [];
  showCalendarPopup = false;
  loggedUserName$: Observable<string | null | undefined> | undefined;
  loggedUserName = '';
  selectedRowData : any;
  filterDataPresent = false;
  public expandedRowId: any = null;
  globalClickListener: ((event: MouseEvent) => void) | null = null;
  permission = false;

  constructor(
    public exceptionService: ExceptionService,
    public dialog: MatDialog,
    private popupDialogService: PopupDialogService,
    private toastr: CustomToastrService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private store: Store,
    private hierarchyFilter: HeirarchyFilterServiceService,
    private elementRef: ElementRef,
    private router: Router,
    public permissionService: PermissionService,
    public inspectionService: InspectionService,
  ) {
    this.loggedUserName$ = this.store.select(
      AuthenticateUserState.getLoggedInName,
    );
    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
    this.storeSelectedLevel3$ = this.store.select(
      Level3State.getSelectedLevel3Name,
    );
    this.storeSegmentTypeSet$ = this.store.select(
      SegmentTypeState.getSelectedSegmentType,
    );
    this.storeSelectedSegments$ = this.store.select(
      SegmentState.getSelectedSegmentIds,
    );
    this.storeSelectedSurveyRoute$ = this.store.select(
      SurveyRouteState.getSelectedSurveyRouteIds,
    );
    this.storeSelectedAssetType$ = this.store.select(
      AssetTypeState.getSelectedAssetTypeIds,
    );
  }

  ngAfterViewInit(): void {
    this.globalClickListener = this.handleClickOutside.bind(this);
    document.addEventListener(
      'click',
      this.globalClickListener as EventListener,
      true,
    );
    const gridWrapper =
      this.grid.wrapper.nativeElement.querySelector('.k-grid-content');
    this.scrollListener = this.renderer.listen(
      gridWrapper,
      'scroll',
      this.onScroll.bind(this),
    );
  }

  handleClickOutside(event: MouseEvent): void {
    const gridElement =
      this.elementRef.nativeElement.querySelector('kendo-grid');

    if (gridElement && !gridElement.contains(event.target as Node)) {
      this.collapseAllRows();
    }
  }

  collapseAllRows(): void {
    const totalRows = this.gridData.data.length;

    for (let i = 0; i < totalRows; i++) {
      this.grid.collapseRow(i);
    }
  }
  ngOnDestroy(): void {
    if (this.globalClickListener) {
      document.removeEventListener(
        'click',
        this.globalClickListener as EventListener,
        true,
      );
    }
    window.removeEventListener('scroll', this.onWindowScroll, true);
    if (this.scrollListener) {
      this.scrollListener();
    }
  }

  async ngOnInit() {
    this.permission = this.permissionService.hasPermission(
      EXCEPTION_SCREEN,
      'fullAccess',
    );
    window.addEventListener('scroll', this.onWindowScroll, true);
    //this.originalData = [...this.gridData];
    this.route.queryParamMap.subscribe((params) => {
      const assetName = params.get('assetName') || undefined;
      if (assetName) {
        const decodedValue = decodeURIComponent(assetName);
        this.urlFilter(decodedValue);
      }
    });
    this.loggedUserName$?.subscribe((name) => {
      this.loggedUserName = name!;
    });
    this.storePrimaryCompanyID$.subscribe((id) => {
      this.selectedprimaryComapnyId = '';
      this.selectedprimaryComapnyId = id;
    });
    this.storeSelectedLevel3$.subscribe((level3name) => {
      this.selectedLevel3 = level3name;
      this.hierarchyFilterInput = [];
      if (this.selectedprimaryComapnyId && this.selectedLevel3.length > 0) {
        this.fetchLevel3Values(this.selectedprimaryComapnyId);
      }
    });
    this.storeSegmentTypeSet$.subscribe((segmentType) => {
      this.selectedSegmentType = [];
      this.selectedSegmentType = segmentType;
    });
    this.storeSelectedSegments$.subscribe((segment) => {
      this.selectedSegments = [];
      this.selectedSegments = segment;
    });
    this.storeSelectedSurveyRoute$.subscribe((Surveyroute) => {
      this.surveyRouteFilterInput = [];
      this.selectedSurveyRoute = Surveyroute;
      if (
        this.selectedSurveyRoute.length > 0 &&
        this.selectedprimaryComapnyId
      ) {
        this.fetchSurveyRouteValues(this.selectedprimaryComapnyId);
      }
    });
    this.storeSelectedAssetType$.subscribe((assettype) => {
      this.selectedAssetType = [];
      this.selectedAssetType = assettype;
    });
    this.hierarchyFilter.isClearFilterClick.subscribe((value) => {
      this.onClearFilterPressed(value);
    });

    await this.loadExceptionData();
    // if(this.selectedSurveyRoute.length > 0){
    //   this.fetchSurveyRouteValues(this.selectedprimaryComapnyId!);
    // }
    // else{
    //   this.fetchLevel3Values(this.selectedprimaryComapnyId!);
    // }
  }

  onClearFilterPressed(value: boolean) {
    if(value){
      this.filterDataPresent = false;
    }
    this.updateFilterModel(value);
  }

  // Optional: Use @HostListener to listen for scroll events
  @HostListener('window:scroll', ['$event'])
  onHostWindowScroll(event: Event): void {
    this.onWindowScroll(event);
  }

  onWindowScroll(_event: any): void {
    this.dropdownVisible = false;
  }

  onScroll(_event: any): void {
    this.dropdownVisible = false;
  }

  urlFilter(assetName: string) {
    // Convert the search term to lower case for case insensitive comparison
    const lowerCaseChangeWord = assetName.toLowerCase();
    const newData = this.originalData.filter((item: any) => {
      return this.equalsIgnoreCase(item.assetName, lowerCaseChangeWord);
    });
    this.gridData.data = newData;
  }

  equalsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === 'string') {
      return field.toLowerCase() === searchTerm.toLowerCase();
    }
    return false;
  }

  async fetchLevel3Values(primaryCompanyId: string): Promise<void> {
    const allLevel3Values: HierarchyFilterInput[] = [];
    const level3Values =
      await this.hierarchyFilter.getAllLevel3ValueByPrimaryCompany(
        primaryCompanyId,
      );
    if (level3Values?.items) {
      level3Values.items.forEach((item) => {
        if (item) {
          if (this.selectedLevel3.includes(item.id))
            allLevel3Values.push({
              level1: item.level1ValueId || '',
              level2: item.level2ValueId || '',
              level3: item.id || '',
            });
        }
      });
    }
    this.hierarchyFilterInput = allLevel3Values;
    //this.loadExceptionData();
  }

  async fetchSurveyRouteValues(primaryCompanyId: string): Promise<void> {
    const allSurveyRouteFilterValues: SurveyRouteFilterInput[] = [];
    const surveyRouteValues =
      await this.hierarchyFilter.getAllSurveyRouteBySubFilterInput({
        primaryCompanyId: primaryCompanyId,
      });
    if (surveyRouteValues?.items) {
      surveyRouteValues.items.forEach((item) => {
        if (item) {
          if (this.selectedSurveyRoute.includes(item.id)) {
            item.RouteAssociateAssetList?.forEach((level) => {
              level?.level3ValueIdList?.forEach((l3) => {
                allSurveyRouteFilterValues.push({
                  level1: level?.level1ValueId,
                  level2: level?.level2ValueId,
                  level3: l3,
                  assetId: level.assetId,
                });
              });
            });
          }
        }
      });
    }
    this.surveyRouteFilterInput = allSurveyRouteFilterValues;
    //this.loadExceptionData();
  }

  public ColumnAdjustmentButtonClicked() {
    this.showColumnAdjustmentPopup = !this.showColumnAdjustmentPopup;
  }

  public OnSelectedTemplateColumnsChange(selectedColumns: string[]) {
    this._selectedColumns = selectedColumns;
    this._selectedColumns.forEach((columnName) => {
      this.IsColumnSelected(columnName);
    });
  }

  public IsColumnSelected(columnName: string): boolean {
    return this._selectedColumns.includes(columnName);
  }

  openDialog(): void {
    const currentPageData = this.gridData.data.slice(
      this.state.skip,
      this.state ? (this.state.skip as number) + this.pageSize : 0,
    );
    const dataToExport = this.pageChanged
      ? this.gridDataToExport
      : currentPageData;

    this.filteredData = dataToExport.map((item) => {
      const filteredItem: Record<string, any> = {};
      this._selectedColumns.forEach((column) => {
        filteredItem[column] = item[column];
      });
      filteredItem['dynamicValues'] = item.dynamicValues;
      return filteredItem;
    });

    this.dialog.open(ExportModalComponent, {
      data: {
        selectedColumnData: this.filteredData,
        selectedColumn: this._selectedColumns,
        pdfExport: this.pdfExport,
        pageSize: this.pageSize,
        gridData: dataToExport,
        allData: this.originalData,
        flag: EXCEPTION,
        filters: this.filterModel,
      },
    });

    // Reset pageChanged flag if needed
    if (this.pageChanged) {
      this.pageChanged = false;
    }
  }

  handleSearchInput(changeWord: string) {
    if (changeWord.length < 3 && this.searchText.length > 2) {
      this.searchText = '';
      this.loadExceptionData();
    } else if (changeWord.length > 2) {
      this.searchText = changeWord;
      this.loadExceptionData();
    }
  }

  rootCauseContainsValue(field: any, searchTerm: string) {
    return field.some((item: string) =>
      item.toLowerCase().includes(searchTerm.toLowerCase()),
    );
  }

  // Helper function to check if a field contains the search term
  containsIgnoreCase(field: any, searchTerm: string): boolean {
    if (typeof field === 'string') {
      //return field.toLowerCase().includes(searchTerm);
      return field
        .toString()
        .toLowerCase()
        .trim()
        .includes(searchTerm.toLowerCase().trim());
    }
    return false;
  }

  loadData(data: any[]) {
    this.originalData = data.map((item) => ({
      ...item,
      formattedLastInspectionDate: this.formatDate(
        new Date(item.lastInspectionDate),
      ),
    }));
    this.gridData.data = [...this.originalData];
  }

  formatDate(date: Date): string {
    if (!date) return '';

    const day = date.getDate();
    const month = date.toLocaleString(DEFAULT, { month: LONG });
    const year = date.getFullYear();

    const daySuffix = this.getDaySuffix(day);

    return `${day}${daySuffix} ${month}, ${year}`;
  }

  getDaySuffix(day: number): string {
    if (day > 3 && day < 21) return TH;
    switch (day % 10) {
      case 1:
        return ST;
      case 2:
        return ND;
      case 3:
        return RD;
      default:
        return TH;
    }
  }

  public isHidden(columnName: string): boolean {
    return this.hiddenColumns.indexOf(columnName) > -1;
  }

  public myStickyRows: number[] = [];

  public rowSticky: RowStickyFn = (args: RowArgs) =>
    this.isSticky(args.dataItem.id);

  public isSticky(id: number): boolean {
    return this.myStickyRows.indexOf(id) >= 0;
  }
  public toggleStickyRow(id: number): void {
    if (this.isSticky(id)) {
      this.myStickyRows = this.myStickyRows.filter(
        (stickyId) => stickyId !== id,
      );
    } else {
      this.myStickyRows.push(id);
    }
    this.sortGridData();
  }
  private sortGridData(): void {
    const sorteddt = [...this.gridData.data];
    sorteddt.sort((a, b) => {
      const isASticky = this.isSticky(a.id);
      const isBSticky = this.isSticky(b.id);
      if (isASticky && !isBSticky) {
        return -1;
      } else if (!isASticky && isBSticky) {
        return 1;
      } else {
        return a.id - b.id;
      }
    });
    this.gridData.data = sorteddt;
  }
  dropdownVisible = false;
  dropdownX = 0;
  dropdownY = 0;
  currentDataItem: any;
  isStickyRow = false;

  openDropdownMenu(event: MouseEvent, dataItem: any) {
    event.stopPropagation();
    this.dropdownVisible = true;
    this.currentDataItem = dataItem;
    this.rowId = this.currentDataItem.id;
    this.localStorageService.setItem('rowData', this.currentDataItem);

    const gridElement = document.querySelector('.k-grid') as HTMLElement; // Replace '.k-grid' with the appropriate selector for your Kendo grid
    const dropdownHeight = 150; // Set this to the height of your dropdown menu
    const dropdownWidth = 200; // Set this to the width of your dropdown menu

    if (gridElement) {
      const gridRect = gridElement.getBoundingClientRect();
      const clickX = event.clientX - gridRect.left + gridElement.scrollLeft;
      const clickY = event.clientY - gridRect.top + gridElement.scrollTop;

      // Calculate space available below and above the click position
      const spaceBelow =
        window.innerHeight -
        (event.clientY - gridRect.top + gridElement.scrollTop);

      // Adjust dropdownY based on available space
      if (spaceBelow < dropdownHeight) {
        // Not enough space below, position dropdown above
        this.dropdownY = clickY - dropdownHeight;
      } else {
        // Enough space below, position dropdown below
        this.dropdownY = clickY;
      }

      // Ensure the dropdown doesn't overflow horizontally
      this.dropdownX = Math.max(
        0,
        Math.min(clickX, gridElement.clientWidth - dropdownWidth),
      );

      // Adjust dropdownX to account for the grid's left boundary
      this.dropdownX += gridRect.left;

      // Ensure dropdownY stays within the viewport
      this.dropdownY = Math.max(0, this.dropdownY + 150);

      // If dropdownY exceeds the viewport height, position it at the bottom
      if (this.dropdownY + dropdownHeight > window.innerHeight) {
        this.dropdownY = window.innerHeight - dropdownHeight;
      }
    }

    const rowId = dataItem.id;
    this.selectedRowData = dataItem;
    this.isStickyRow = this.isSticky(rowId);
  }

  handleDropdownOption(option: number) {
    if (option === 1) {
      this.toggleStickyRow(this.currentDataItem.id);
    } else if (option === 4) {
      this.showCalendarPopup = true;
    } else if (option === 2) {
      this.router.navigate([REDIRECT_MAPS], {});
    } else if (option === 5) {
      this.inspectionService.selectedRowData = this.selectedRowData;
       this.router.navigate([REDIRECT_INSPECTION], {});
    } else if (option === 6) {
      this.router.navigate([REDIRECT_ASSET_HISTORY], {});
    } else if (option === 7) {
      this.router.navigate([REDIRECT_ASSET_SETUP], {});
    }
  }

  handleDropdownVisibilityChange(visible: boolean) {
    this.dropdownVisible = visible;
  }

  rowActionStickyRow(event: MouseEvent, dataItem: any) {
    event.stopPropagation();
    const rowId = dataItem.id;
    if (this.isSticky(rowId)) {
      this.isStickyRow = true;
    } else {
      this.isStickyRow = false;
    }
    this.toggleStickyRow(rowId);
  }

  public onColumnReorder(event: ColumnReorderEvent): void {
    const lockedColumns = ['assetType', 'assetName', 'station', 'rootCause'];
    const field = this.getColumnField(event.column);
    if (
      (field && lockedColumns.includes(field)) ||
      this.isNonReorderableColumn(event.column) ||
      this.isNewIndexInFirstThree(event.newIndex)
    ) {
      this.toastr.showInfo('Mandatory Columns cannot be re-ordered', 'Info');
      event.preventDefault();
    }
  }

  getColumnField(column: ColumnBase): string | null {
    if ('field' in column) {
      return (column as any).field;
    }
    return null;
  }

  private isNonReorderableColumn(column: ColumnBase): boolean {
    const headerClass = column.headerClass;

    if (typeof headerClass === 'string') {
      return headerClass.includes('non-reorderable');
    }
    return false;
  }
  private isNewIndexInFirstThree(newIndex: number): boolean {
    const columns = this.grid.columns.toArray();
    const lastIndex = columns.length - 1;
    const manditoryColsIndexUpto = 4;
    return newIndex < manditoryColsIndexUpto || newIndex == lastIndex;
  }

  //Commnts Pop-Up code
  public actionRowId = 0;
  public showPopup = false;
  public assetTypeForComment = '';
  public popupOffset = { left: 0, top: 0 };
  @ViewChild('anchor', { static: true }) public anchor!: ElementRef;
  public togglePopup(event: Event, dataItem: any): void {
    event.preventDefault();
    this.assetTypeForComment = dataItem.assetName;
    this.showPopup = true;
    this.actionRowId = dataItem.id;
    // Prevent background scrolling
    document.body.style.overflow = 'hidden';
  }
  closePopup(): void {
    this.showPopup = false;
    // Allow background scrolling
    document.body.style.overflow = 'auto';
  }

  calculateMaxRootCauseLength(): number {
    // Calculate the maximum length of the rootCause array among all rows
    return Math.max(...this.gridData.data.map((item) => item.rootCause.length));
  }

  calculateColumnWidth(): number {
    // Calculate the width based on the maximum length of the rootCause array
    // Adjust the multiplier or constant as needed
    return Math.max(200, this.calculateMaxRootCauseLength() * 150);
  }

  exceptionComment = '';
  saveTheFirstExceptionComment() {
    if (this.exceptionComment === '') {
      this.toastr.showWarning(
        'Please write your comments before submitting.',
        'warning',
      );
    }
    //as indexing of array starts from 0
    const currentRowId = this.actionRowId - 1;
    this.gridData.data[currentRowId].Comments = this.exceptionComment;
    this.exceptionComment = '';
    //deep copy concept
    //this.gridData = JSON.parse(JSON.stringify(this.gridData));
    this.showPopup = false;
  }

  // Method to truncate the comment if it exceeds 10 words
  truncateComment(comment: string): string {
    const words = comment.split(' ');
    if (words.length > 10) {
      return words.slice(0, 10).join(' ');
    }
    return comment;
  }

  // Method to check if the comment is truncated
  isCommentTruncated(comment: string): boolean {
    return comment.split(' ').length > 10;
  }

  //col-reorder code
  //col-reorder code i.e this method will be invoked when there is change in drag/drop  template
  public reorderColumnPosition(columnPosition: {
    previousIndexOfCol: number;
    newIndexOfCol: number;
    columnName: string;
  }): void {
    const fieldName = columnPosition.columnName;
    const index = columnPosition.newIndexOfCol + 1;
    const column = this.columns.find((col) => col.field === fieldName);
    if (column) {
      if (columnPosition.newIndexOfCol < columnPosition.previousIndexOfCol) {
        this.grid.reorderColumn(column as ColumnBase, index, { before: true });
      } else {
        this.grid.reorderColumn(column as ColumnBase, index);
      }
      this.columns.reset([...this.columns.toArray()]);
    }
  }

  height = 780; // initial height
  showHierarchicalFilter = false;
  showFilterBtn = true;
  toggleFilterTray() {
    this.showHierarchicalFilter = !this.showHierarchicalFilter;
    this.showFilterBtn = !this.showFilterBtn;
    if (this.showHierarchicalFilter) {
      this.height = 830;
    } else {
      this.height = 780;
    }
  }
  collapseFilterTray() {
    this.toggleFilterTray();
  }

  filterSearchButtonClicked(): void {
    this.loadExceptionData();
    this.myStickyRows = [];
  }

  async loadExceptionData(): Promise<void> {
    try {
      this.isLoading = true;
      await this.fetchLevel3Values(this.selectedprimaryComapnyId!);
      await this.fetchSurveyRouteValues(this.selectedprimaryComapnyId!);
      this.updateFilterModel(false);
      this.rootCause = [
        'Additional CP Needed',
        'Rectifier Off',
        'Interference',
        'Additional CP Needed',
      ];
      const response = await this.exceptionService.getAllExceptionsData(
        this.filterModel,
      );
      if (response?.totalCount === 0 && this.selectedLevel3.length > 0) {
        this.filterDataPresent = true;
      }
      if (response?.items) {
        const items = response.items.filter(
          (item): item is Exceptions => item !== null,
        );
        const exceptionModels: ExceptionsModel[] =
          this.mapExceptionToModels(items);
        this.dynamicColumns = this.generateDynamicColumns(exceptionModels);
        this.exceptionService.exceptiondynamicColumns = this.dynamicColumns;
        const exceptionModules =
          this.mapExceptionsAndMeasurments(exceptionModels);
        this.gridData = {
          data: exceptionModules,
          total: response?.totalCount != null ? response?.totalCount : 0,
        };
        this.originalData = exceptionModules;
        this.cdr.markForCheck();
        this.isLoading = false;
        if (items.length > 0) {
          this.filterDataPresent = false;
        }
      } else {
        this.dynamicColumns = [];
        this.exceptionService.exceptiondynamicColumns = this.dynamicColumns;
        this.gridData = {
          data: [],
          total: 0,
        };
      }
    } catch (error) {
      console.error(error);
    }
  }

  private updateFilterModel(value: boolean) {
    if (value) {
      this.gridData = {
        data: [],
        total: 0,
      };
    } else {
      this.filterModel = {
        primaryCompany: this.selectedprimaryComapnyId,
        level4: this.selectedSegments,
        assetTypes: this.selectedAssetType,
        segmentTypes: this.selectedSegmentType,
        surveyRouteFilterInputs: this.surveyRouteFilterInput,
        hierarchyFilterInputs: this.hierarchyFilterInput,
        pageSize: this.pageSize,
        pageNumber: this.currentPage,
        searchText: this.searchText,
        gridFilters: this.kendoGridFilters,
      };
    }
  }

  mapExceptionToModels(exceptions: Exceptions[]): ExceptionsModel[] {
    return exceptions.map((exception) => ({
      __typename: 'Exceptions',
      id: exception.id ?? null,
      pk: exception.pk ?? null,
      assetName: exception.assetName ?? null,
      assetType: exception.assetType ?? null,
      assetTypeId: exception.assetTypeId ?? null,
      complianceStatus: exception.complianceStatus ?? null,
      comments: exception.comments ?? null,
      workFlowStatus: exception.workFlowStatus ?? null,
      specialist: exception.specialist ?? null,
      technician: exception.technician ?? null,
      measurements: exception.measurements ?? null,
      targetRemediationDate: exception.targetRemediationDate ?? null,
      dynamicValues: {},
      assetDelinquencyDate: exception.assetDelinquencyDate,
      measurementsProcesses: null,
    }));
  }

  generateDynamicColumns(data: ExceptionsModel[] | null): Column[] {
    const columns: Column[] = [];
    data?.forEach((item) => {
      item.dynamicValues = item.dynamicValues || {};
      if (Array.isArray(item.measurements) && item.measurements.length > 0) {
        item.measurements.forEach((measurement) => {
          if (measurement?.compliance === true) {
            const columnName = `${measurement.measurementType} - ${measurement.level4Value}`;
            const value = `${measurement.measurementValue} ${measurement.unit}`;
            if (!columns.find((col) => col.field === columnName)) {
              columns.push({
                field: columnName,
                title: columnName,
                width: 200,
              });
            }
            item.dynamicValues[columnName] = value;
          }
        });
      }
      columns.forEach((col) => {
        if (!item.dynamicValues[col.field]) {
          item.dynamicValues[col.field] = '';
        }
      });
    });
    return columns;
  }

  private mapExceptionsAndMeasurments(
    exceptions: ExceptionsModel[],
  ): ExceptionsModel[] {
    return exceptions.map((ex) => {
      // Process measurements
      const measurements = ex.measurements ?? []; // Default to an empty array if null or undefined
      const processedMeasurements = this.processSurveyData(
        measurements.filter(
          (measurement): measurement is MeasurementModel =>
            measurement !== null,
        ),
      );
      return {
        __typename: 'Exceptions',
        id: ex.id ?? null,
        pk: ex.pk ?? null,
        assetName: ex.assetName ?? null,
        assetType: ex.assetType ?? null,
        assetTypeId: ex.assetTypeId ?? null,
        complianceStatus: ex.complianceStatus ?? null,
        comments: ex.comments ?? null,
        workFlowStatus: ex.workFlowStatus ?? null,
        targetRemediationDate: ex.targetRemediationDate ?? null,
        technician: ex.technician ?? null,
        measurements: ex.measurements ?? [],
        specialist: ex.specialist ?? null,
        dynamicValues: ex.dynamicValues,
        measurementsProcesses: processedMeasurements,
        measurementPoint: ex.measurementPoint,
        assetDelinquencyDate: ex.assetDelinquencyDate,
      };
    });
  }

  processSurveyData = (
    measurements: MeasurementModel[] | null | undefined,
  ): ProcessedSurveyData => {
    const dates = new Set<string>();
    const table: Record<string, ProcessedMeasurement> = {};
    let measurementPointCounter = 1; // Initialize the counter
    if (measurements) {
      measurements.forEach((measurement) => {
        const surveyDate = measurement.surveyDate?.split('T')[0];
        if (surveyDate) {
          dates.add(surveyDate);
        }
        const measurementType = measurement.measurementType ?? '';
        const source = measurement.source ?? '';
        const status = measurement.isCompliant ? 'Compliant' : 'Non Compliant';
        const unit = measurement.unit ?? '';
        const criteria = measurement.criteria + ' ' + unit;
        const level4Value = measurement.level4Value ?? '';
        const key = `${source}-${measurementType}`;

        if (!table[key]) {
          table[key] = {
            measurementPoint: measurementPointCounter++, // Assign and increment the counter
            source,
            measurementType,
            status,
            criteria,
            level4Value,
          };
        } else {
          const existingValues = table[key].level4Value
            ?.split(', ')
            .map((value) => value.trim());
          if (!existingValues?.includes(level4Value)) {
            table[key].level4Value += `, ${level4Value}`;
          }
        }

        if (surveyDate) {
          let value = measurement.measurementValue ?? '';
          value = value + ' ' + unit;
          table[key][`Measurement On ${surveyDate}`] = value;
        }
      });
    }

    // Convert table record to an array
    const formattedTable = Object.values(table);

    return {
      table: formattedTable,
      dates: Array.from(dates),
    };
  };

  public onPageSizeChange(_e: any): void {
    // this.pageSize = event;
    // this.currentPage = 1;
    // this.updatePagedData();
  }

  getStatusClass(priority: string): string {
    switch (priority) {
      case 'Exception Open':
        return 'Exception_Open';
      case 'Exception On-Hold':
        return 'Exception_OnHold';
      case 'Exception Close':
        return 'Exception_Close';
      default:
        return '';
    }
  }

  getMeasurementStatusClass(status: string): string {
    switch (status?.toLowerCase()) {
      case 'compliant':
        return 'compliant';
      case 'non compliant':
        return 'non-compliant';
      default:
        return '';
    }
  }

  selectedDate: Date | null = null;

  close(): void {
    this.showCalendarPopup = false;
  }

  confirm(): void {
    const isoDate = this.selectedDate?.toISOString();
    this.UpdateRedemtionDate(isoDate);
  }

  async UpdateRedemtionDate(isoDate: string | undefined) {
    this.showCalendarPopup = false;
    this.isLoading = true;
    const exceptionData = this.updateException(isoDate);
    console.log(exceptionData);
    const response = await this.exceptionService.updateException(exceptionData);
    console.log(response);
    this.isLoading = false;
    if (response?.status === 'Success') {
      this.popupDialogService.openDialog(
        SUCCESS_EXCLAIM,
        'Exception updated successfully',
        SUCCESS,
        CLOSE,
      );
    }
    this.ngOnInit();
  }

  updateException(isoDate: string | undefined) {
    const updatedUser: UpdateExceptionInput = {
      id: this.currentDataItem.id,
      pk: this.currentDataItem.pk,
      targetRemediationDate: isoDate!,
      updatedBy: this.loggedUserName,
    };
    return updatedUser;
  }

  customstate: { filter: CompositeFilterDescriptor } = {
    filter: { logic: 'and', filters: [] },
  };

  public state: State = {
    skip: 0,
    take: this.pageSize,
    group: [],
    filter: { filters: [], logic: 'and' },
    sort: [],
  };

  public dataStateChange(state: DataStateChangeEvent): void {
    this.myStickyRows = [];
    this.isLoading = true;
    this.state = state;
    this.currentPage = state.skip / this.pageSize + 1;
    this.kendoGridFilters = this.state.filter as KendoGridFilters;
    const filter = state.filter as CompositeFilterDescriptor;
    this.customstate = { ...this.customstate, filter };
    this.loadExceptionData();
  }
}
