import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import {
  SetAuthenticateUser,
  UpdateTokens,
  AuthenticateUserState,
} from '../../core/store/authenticate-user.state';
import { HeirarchyFilterServiceService } from '../../shared/service/heirarchy-filter-service/heirarchy-filter-service.service';
import { forkJoin, of, Observable } from 'rxjs';
import { UserService } from '../../core/user.service';
import { User, PrimaryCompanyModel } from '../../../awsAppSync/API';
import { TopNavigationService } from '../../core/services/top-navigation.service';
import { Roles, SetUserProfileData } from '../../core/store/user-profile.state';
import { PC_ADMIN_ROLE_ID } from '../../shared/constants';
import { PermissionService } from '../../core/services/permission.service';

@Component({
  selector: 'app-auth-return',
  templateUrl: './auth-return.component.html',
  styleUrls: ['./auth-return.component.css'],
})
export class AuthReturnComponent implements OnInit {
  private readonly oidcSecurityService = inject(OidcSecurityService);
  private readonly router = inject(Router);

  configuration$ = this.oidcSecurityService.getConfiguration();
  userData$ = this.oidcSecurityService.userData$;
  checkSessionChanged$ = this.oidcSecurityService.checkSessionChanged$;
  selectedPrimaryCompany = '';
  selectedLevel3Value = '';
  selectedLevel3Id = '';
  private isAuthenticated = false;
  private isAuthenticated$: Observable<boolean> = of(false);

  constructor(
    private store: Store,
    private _hierarchicalFilter: HeirarchyFilterServiceService,
    private userService: UserService,
    private topNavigationService: TopNavigationService,
    private permissionService: PermissionService,
  ) {
    this.isAuthenticated$ = this.store.select(
      AuthenticateUserState.getIsAuthenticated,
    );
  }

  ngOnInit(): void {
    this.isAuthenticated$.subscribe((authenticated) => {
      this.isAuthenticated = authenticated;
    });

    // call below function onetime only
    if (!this.isAuthenticated) {
      // First, ensure the authentication state is checked and updated.
      this.oidcSecurityService
        .checkAuth()
        .subscribe(({ isAuthenticated, idToken, accessToken, userData }) => {
          this.userService.authApiCallCompleted$.next(false);
          if (isAuthenticated) {
            const corViewUserId = userData.sub;
            const loggedInName = userData.given_name;
            this.store.dispatch(
              new SetAuthenticateUser(
                true,
                idToken,
                accessToken,
                corViewUserId,
                loggedInName,
                '',
                '',
                '',
                '',
                '',
              ),
            );

            this.getLoginUserDetails(corViewUserId)
              .then((user) => {
                if (user) {
                  this.store.dispatch(
                    new SetAuthenticateUser(
                      true,
                      idToken,
                      accessToken,
                      corViewUserId,
                      loggedInName,
                      user.coreViewPrimaryCompany.id ?? '',
                      user.coreViewPrimaryCompany.displayName ?? '',
                      user.syrcPrimaryCompany.id ?? '',
                      user.syrcPrimaryCompany.displayName ?? '',
                      user.id,
                    ),
                  );
                  this.userService.authApiCallCompleted$.next(true);
                } else {
                  console.log('User not found');
                  this.userService.authApiCallCompleted$.next(false);
                }
              })
              .catch((error) => {
                console.error('Error fetching user details:', error);
              });

            // set the userProfileDataSetup in the store
            // forkJoin([this.storeProfileMenuData(corViewUserId)]).subscribe(() => {
            //   // call the filter APIs
            //   this.intializeLevelNames();
            // });

            //this.router.navigate(['/dashboard']);
          }
        });
    }

    //subscribing to isAuthenticated$ to listen for authentication status changes when token is refresh
    this.oidcSecurityService.isAuthenticated$.subscribe(
      (isAuthenticatedResult) => {
        console.log('isAuthenticatedResult--->', isAuthenticatedResult);
        if (isAuthenticatedResult.isAuthenticated) {
          try {
            this.handleTokenRenewal();
          } catch (err) {
            console.warn(err);
          }
        } else if (
          isAuthenticatedResult.allConfigsAuthenticated.length > 0 &&
          this.isAuthenticated
        ) {
          console.log(
            'Refresh token request failed, redirecting to authorize...',
          );
          this.oidcSecurityService.authorize();
        }
      },
    );
  }
  async intializeLevelNames() {
    await this._hierarchicalFilter.initialize();
  }

  async getLoginUserDetails(coreViewUserId: string): Promise<User | null> {
    try {
      return await this.userService.getUserByCoreViewUserId(coreViewUserId);
    } catch (error) {
      console.error('Error fetching user details:', error);
      return null; // Return null in case of an error
    }
  }

  handleTokenRenewal() {
    // Subscribe to the idToken, accessToken and userData observables to get the actual values
    forkJoin({
      idToken: this.oidcSecurityService.getIdToken(),
      accessToken: this.oidcSecurityService.getAccessToken(),
    }).subscribe(({ idToken, accessToken }) => {
      if (idToken && accessToken) {
        this.store.dispatch(new UpdateTokens(idToken ?? '', accessToken ?? ''));
      }
    });
  }

  _pcAdminRegions: (Roles | null)[] = [];
  async storeProfileMenuData(corviewUserId: string) {
    const _result =
      await this.topNavigationService.getUserProfileByUserId(corviewUserId);

    const roles = (_result?.roles || []).map((role) => ({
      level1Id: role?.level1Id || null,
      level2Id: role?.level2Id || null,
      level3Id: role?.level3Id || null,
      level3Value: role?.level3Value || null,
      roleId: role?.roleId || null,
      rolename: role?.rolename || null,
      permissions: role?.permissions || null,
    })) as (Roles | null)[];

    const isAdminRolePresent = roles?.some(
      (role) => role?.roleId === PC_ADMIN_ROLE_ID,
    );
    if (isAdminRolePresent) {
      // Your logic here
      console.log('Admin role is present.');
      if (_result?.level3Values) {
        this._pcAdminRegions = await this.userService.mapLevel3ValuesToRoles(
          _result?.level3Values,
          roles,
        );
      }
    }
    if (_result?.isMTXUser) {
      const _syrcPrimaryCompanies =
        await this.topNavigationService.getSyrcPrimaryCompanies();
      if (
        _syrcPrimaryCompanies?.items &&
        _syrcPrimaryCompanies.items.length > 0
      ) {
        this.selectedPrimaryCompany =
          _syrcPrimaryCompanies.items[0]?.name ?? '';
      }

      this.store.dispatch(
        new SetUserProfileData(
          _result?.primaryCompany || ({} as PrimaryCompanyModel),
          _result?.userId || '',
          _result?.userName || '',
          _result?.userEmail || '',
          _result?.isMTXUser || false,
          isAdminRolePresent ? this._pcAdminRegions : roles,
          _syrcPrimaryCompanies?.items,
          this.selectedPrimaryCompany,
          '',
          '',
        ),
      );
    } else {
      this.selectedLevel3Value = roles[0]?.level3Value ?? '';
      this.selectedLevel3Id = roles[0]?.level3Id ?? '';

      this.store.dispatch(
        new SetUserProfileData(
          _result?.primaryCompany || ({} as PrimaryCompanyModel),
          _result?.userId || '',
          _result?.userName || '',
          _result?.userEmail || '',
          _result?.isMTXUser || false,
          isAdminRolePresent ? this._pcAdminRegions : roles,
          null,
          '',
          this.selectedLevel3Value,
          this.selectedLevel3Id,
        ),
      );
    }
    this.permissionService.transformData();
  }
}
