import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ANODE,
  ASSET_HISTORY_ELLIPSIS_WIDTH,
  BOND,
  CARD_BODY,
  CASING,
  COMPLIANT_AH,
  CTS,
  DELINQUENT_AH,
  EMPTY_STRING,
  ER_PROBE,
  EXCEPTION_AH,
  ISOLATIONJOINT,
  RECTIFIER,
  SEARCHDATAPROCESSING,
  TEST_POINT,
  ZERO,
} from '../../shared/constants';
import { DataProcessingService } from '../../core/services/data-processing.service';
import { SVGIcon, searchIcon } from '@progress/kendo-svg-icons';
import { GoogleMaps } from '../../core/models/google-maps';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { AuthenticateUserState } from '../../core/store/authenticate-user.state';
import {
  DataProcessingMapInput,
  surveyDetailsIdInput,
  SurveyDetailsInput,
} from '../../../awsAppSync/API';
declare let google: GoogleMaps;

@Component({
  selector: 'app-data-processing',
  templateUrl: './data-processing.component.html',
  styleUrl: './data-processing.component.css',
})
export class DataProcessingComponent implements OnInit, OnDestroy {
  isLoading = false;
  isTypeFilterOpen = false;
  isStatusFilterOpen = false;
  isSurveyFilterOpen = false;
  isVersionFilterOpen = false;
  public searchPlaceholder = SEARCHDATAPROCESSING;
  isAnyFilterSelected = false;
  isSearchBarEnabled = false;
  isSurveyDetails = false;
  selectedRowData: any;
  selectedSurveyData: any;
  _filteredItems: {
    id: string;
    label: string;
    checked: boolean;
  }[] = [];

  selectedSurveyValue = EMPTY_STRING;

  selectedTypeFilter = EMPTY_STRING;
  selectedStatusFilter = EMPTY_STRING;
  selectedSurveyFilter = EMPTY_STRING;
  selectedVersionFilter = EMPTY_STRING;

  isSelectedTypeFilter = false;
  isSelectedStatusFilter = false;
  isSelectedSurveyFilter = false;
  isSelectedVersionFilter = false;

  types = this._filteredItems;
  status = this._filteredItems;
  survey = this._filteredItems;
  version = this._filteredItems;
  ellipsisWidth = ASSET_HISTORY_ELLIPSIS_WIDTH;
  dropdownX = ZERO;
  dropdownY = ZERO;
  dropdownVisible = false;
  inputScreen = 'Data Processing';
  permission = true;
  _searchTerm = '';
  IsSearchResultNull = false;
  svgSearch: SVGIcon = searchIcon;
  @ViewChild('map', { static: false }) mapElementRef!: ElementRef;
  center: { lat: number; lng: number } | undefined; // No default center
  map: any;
  allAssetsData: any[] = [];
  markerMap = new Map();
  storePrimaryCompanyID$: Observable<string | null>;
  selectedprimaryComapnyId: string | null = '';
  selectedSurveyId = EMPTY_STRING;
  assetData: any[] = [];
  mapVisibilityBtn = false;
  showMapDiv = true;
  chartVisibilityBtn = false;
  showChartDiv = true;

  constructor(
    public dataProcessingService: DataProcessingService,
    private eRef: ElementRef,
    private store: Store,
  ) {
    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
  }

  ngAfterViewInit(): void {
    this.loadMap();
  }

  ngOnDestroy(): void {
    this.dataProcessingService.selectedRowData = null;
  }

  async ngOnInit() {
    this.storePrimaryCompanyID$.subscribe((id) => {
      this.selectedprimaryComapnyId = '';
      this.selectedprimaryComapnyId = id;
    });
    this.selectedRowData = this.dataProcessingService.selectedRowData;
    this.types = [
      { id: '1', label: 'Direct', checked: false },
      { id: '2', label: 'Indirect', checked: false },
      { id: '3', label: 'Stationary', checked: false },
    ];
    this.status = [
      { id: '1', label: 'Completed', checked: false },
      { id: '2', label: 'In Progress', checked: false },
      { id: '3', label: 'Published', checked: false },
    ];
    this.version = [
      { id: '1', label: 'Raw', checked: false },
      { id: '2', label: 'Edited', checked: false },
    ];

    await this.getSurveyNames();
    if (this.selectedRowData) {
      await this.getSurveyDetails(this.selectedRowData.id);
      if (this.selectedSurveyData) {
        if (this.selectedSurveyData.surveyType) {
          this.types.forEach((item) => {
            if (item.label == this.selectedSurveyData.surveyType) {
              item.checked = true;
              this.isSelectedTypeFilter = true;
              this.isAnyFilterSelected = true;
              this.isSearchBarEnabled = true;
            }
          });
        }
        if (this.selectedSurveyData.surveyStatus) {
          this.status.forEach((item) => {
            if (item.label == this.selectedSurveyData.surveyStatus) {
              item.checked = true;
            }
          });
        }
        if (this.selectedRowData.surveyName) {
          this.selectedSurveyId = this.selectedSurveyData.id;
          this.selectedSurveyValue = this.selectedRowData.surveyName;
          this.survey.forEach((item) => {
            if (item.label == this.selectedRowData.surveyName) {
              item.checked = true;
              this.isSelectedSurveyFilter = true;
              this.isAnyFilterSelected = true;
              this.isSearchBarEnabled = true;
            }
          });
        }
        if (this.selectedSurveyData.surveyVersions) {
          this.version.forEach((item) => {
            if (item.label == this.selectedSurveyData.version) {
              item.checked = true;
            }
          });
        }
      }
      this.loadSurveyAssetMapData();
    }
  }

  async getSurveyDetails(id: string) {
    const input: surveyDetailsIdInput = {
      primaryCompanyId: this.selectedprimaryComapnyId,
      surveyId: id,
    };
    this.selectedSurveyData =
      await this.dataProcessingService.getSurveyDetailsBySurveyIdAndVersion(
        input,
      );
  }

  async getSurveyNames() {
    const input: SurveyDetailsInput = {
      primaryCompanyId: this.selectedprimaryComapnyId,
      status: EMPTY_STRING,
      type: EMPTY_STRING,
    };
    const response =
      await this.dataProcessingService.getSurveyDetailsByTypeAndStatus(input);
    if (response) {
      this.survey = response.items!.map((item: any) => ({
        id: item.SurveyID,
        label: item.SurveyName,
        checked: false,
      }));
    }
  }

  showSurveyDetails(icon: string) {
    this.isSurveyDetails = icon == 'down' ? true : false;
  }

  async loadSurveyAssetMapData() {
    const input: DataProcessingMapInput = {
      primaryCompanyId: this.selectedprimaryComapnyId ?? EMPTY_STRING,
      surveyId: this.selectedSurveyId,
    };

    const response =
      await this.dataProcessingService.getDataProcessingMapData(input);
    this.assetData = response!;
    this.center = this.getMapCenter();
    this.loadMap();
  }

  @HostListener('document:click', ['$event'])
  clickout(event: Event) {
    const currentEvent = event.target as HTMLElement;
    if (!this.eRef.nativeElement.contains(event.target)) {
      if (
        !currentEvent.classList.contains('filterbutton') &&
        !currentEvent.classList.contains('k-button-text')
      ) {
        this.isTypeFilterOpen = false;
        this.isStatusFilterOpen = false;
        this.isSurveyFilterOpen = false;
        this.isVersionFilterOpen = false;
      }
    }
    this.undoSelections();
  }

  undoSelections() {
    if (!this.isSelectedTypeFilter) {
      this.types.forEach((item) => {
        item.checked = false;
      });
    }
    if (!this.isSelectedStatusFilter) {
      this.status.forEach((item) => {
        item.checked = false;
      });
    }
    if (!this.isSelectedSurveyFilter) {
      this.survey.forEach((item) => {
        item.checked = false;
      });
    }
    if (!this.isSelectedVersionFilter) {
      this.version.forEach((item) => {
        item.checked = false;
      });
    }
  }

  onButtonClick(id: number) {
    if (id == 1) {
      this.isTypeFilterOpen = this.isTypeFilterOpen ? false : true;
      this.isStatusFilterOpen = false;
      this.isSurveyFilterOpen = false;
      this.isVersionFilterOpen = false;
    } else if (id == 2) {
      this.isStatusFilterOpen = this.isStatusFilterOpen ? false : true;
      this.isTypeFilterOpen = false;
      this.isSurveyFilterOpen = false;
      this.isVersionFilterOpen = false;
    } else if (id == 3) {
      this.isSurveyFilterOpen = this.isSurveyFilterOpen ? false : true;
      this.isTypeFilterOpen = false;
      this.isStatusFilterOpen = false;
      this.isVersionFilterOpen = false;
    } else if (id == 4) {
      this.isVersionFilterOpen = this.isVersionFilterOpen ? false : true;
      this.isTypeFilterOpen = false;
      this.isStatusFilterOpen = false;
      this.isSurveyFilterOpen = false;
    } else {
      this.isTypeFilterOpen = false;
      this.isStatusFilterOpen = false;
      this.isSurveyFilterOpen = false;
      this.isVersionFilterOpen = false;
    }
  }

  onClose(): void {
    this.isTypeFilterOpen = false;
    this.isStatusFilterOpen = false;
    this.isSurveyFilterOpen = false;
    this.isVersionFilterOpen = false;
  }

  onCheckboxChange(changedItem: any, input: number): void {
    if (input === 1 && changedItem.checked) {
      this.types.forEach((item) => {
        if (item !== changedItem) {
          item.checked = false;
        }
      });
    }
    if (input === 2 && changedItem.checked) {
      this.status.forEach((item) => {
        if (item !== changedItem) {
          item.checked = false;
        }
      });
    }
    if (input === 3 && changedItem.checked) {
      this.survey.forEach((item) => {
        if (item !== changedItem) {
          item.checked = false;
        }
      });
    }
    if (input === 4 && changedItem.checked) {
      this.version.forEach((item) => {
        if (item !== changedItem) {
          item.checked = false;
        }
      });
    }
  }

  openDropdownMenu(event: MouseEvent) {
    event.stopPropagation();
    this.dropdownVisible = true;
    const gridElement = document.querySelector(CARD_BODY) as HTMLElement;
    const dropdownHeight = 150;
    if (gridElement) {
      const gridRect = gridElement.getBoundingClientRect();
      const scrollTop = gridElement.scrollTop;
      const clickX = event.clientX - gridRect.left + gridElement.scrollLeft;
      const clickY = event.clientY - gridRect.top + scrollTop;

      const spaceBelow = window.innerHeight - clickY;
      if (spaceBelow < dropdownHeight) {
        this.dropdownY = clickY - dropdownHeight;
      } else {
        this.dropdownY = clickY + 10;
      }
      this.dropdownX = Math.max(0, clickX + 15);
    }
  }

  handleDropdownVisibilityChange(visible: boolean) {
    this.dropdownVisible = visible;
  }

  handleDropdownOption(option: number) {
    if (option === 2) {
      console.log('Navigate to desired component');
    }
  }

  filterData(): void {
    if (this._searchTerm) {
      const searchItems = this.survey.filter((item) =>
        item.label.toLowerCase().includes(this._searchTerm.toLowerCase()),
      );
      if (searchItems.length > 0) {
        this.IsSearchResultNull = false;
        this.survey = this.survey.filter((item) =>
          item.label.toLowerCase().includes(this._searchTerm.toLowerCase()),
        );
      } else {
        this.IsSearchResultNull = true;
      }
    } else {
      this.IsSearchResultNull = false;
      this.survey = this._filteredItems;
    }
  }

  generateInputForSurveyList(text: string, from: number) {
    if (from == 1 && !this.selectedStatusFilter) {
      const input: SurveyDetailsInput = {
        primaryCompanyId: this.selectedprimaryComapnyId,
        status: EMPTY_STRING,
        type: text,
      };
      this.getSurveyNamesList(input);
    } else if (this.selectedTypeFilter && !this.selectedStatusFilter) {
      const input: SurveyDetailsInput = {
        primaryCompanyId: this.selectedprimaryComapnyId,
        status: EMPTY_STRING,
        type: this.selectedTypeFilter,
      };
      this.getSurveyNamesList(input);
    } else if (from == 2 && !this.selectedTypeFilter) {
      const input: SurveyDetailsInput = {
        primaryCompanyId: this.selectedprimaryComapnyId,
        status: text,
        type: EMPTY_STRING,
      };
      this.getSurveyNamesList(input);
    } else if (!this.selectedTypeFilter && this.selectedStatusFilter) {
      const input: SurveyDetailsInput = {
        primaryCompanyId: this.selectedprimaryComapnyId,
        status: this.selectedStatusFilter,
        type: EMPTY_STRING,
      };
      this.getSurveyNamesList(input);
    } else if (this.selectedTypeFilter && this.selectedStatusFilter) {
      const input: SurveyDetailsInput = {
        primaryCompanyId: this.selectedprimaryComapnyId,
        status: this.selectedStatusFilter,
        type: this.selectedTypeFilter,
      };
      this.getSurveyNamesList(input);
    } else {
      this.getSurveyNames();
    }
  }

  async getSurveyNamesList(input: any) {
    const response =
      await this.dataProcessingService.getSurveyDetailsByTypeAndStatus(input);
    if (response?.items) {
      this.survey = response!.items!.map((item: any) => ({
        id: item.SurveyID,
        label: item.SurveyName,
        checked: false,
      }));
      this.isLoading = false;
    }
  }

  async OnSaveListClicked(id: number) {
    if (id == 1) {
      this.isLoading = true;
      const value = this.types
        .filter((item) => item.checked)
        .map((item) => item.label);
      if (value.length > 0) {
        this.selectedTypeFilter = value[0];
        this.generateInputForSurveyList(this.selectedTypeFilter, 1);
        this.isTypeFilterOpen = false;
        this.isSelectedTypeFilter = true;
      } else {
        this.selectedTypeFilter = EMPTY_STRING;
        this.generateInputForSurveyList(this.selectedTypeFilter, 1);
        this.isTypeFilterOpen = false;
        this.isSelectedTypeFilter = false;
      }
    } else if (id == 2) {
      this.isLoading = true;
      const value = this.status
        .filter((item) => item.checked)
        .map((item) => item.label);
      if (value.length > 0) {
        this.selectedStatusFilter = value[0];
        this.generateInputForSurveyList(this.selectedStatusFilter, 2);
        this.isStatusFilterOpen = false;
        this.isSelectedStatusFilter = true;
      } else {
        this.selectedStatusFilter = EMPTY_STRING;
        this.generateInputForSurveyList(this.selectedStatusFilter, 2);
        this.isStatusFilterOpen = false;
        this.isSelectedStatusFilter = false;
      }
    } else if (id == 3) {
      this.isLoading = true;
      const value = this.survey
        .filter((item) => item.checked)
        .map((item) => item.id);

      const name = this.survey
        .filter((item) => item.checked)
        .map((item) => item.label);

      this.selectedSurveyValue = name[0];
      if (value.length > 0) {
        this.selectedSurveyFilter = value[0];
        this.selectedSurveyId = this.selectedSurveyFilter;
        await this.getSurveyDetails(this.selectedSurveyFilter);
        this.isSurveyFilterOpen = false;
        this.isSelectedSurveyFilter = true;
        this.loadSurveyAssetMapData();
      } else {
        this.selectedSurveyFilter = EMPTY_STRING;
        this.isSurveyFilterOpen = false;
        this.isSelectedSurveyFilter = false;
      }
    } else if (id == 4) {
      const value = this.version
        .filter((item) => item.checked)
        .map((item) => item.label);
      if (value.length > 0) {
        this.selectedVersionFilter = value[0];
        this.isVersionFilterOpen = false;
        this.isSelectedVersionFilter = true;
      } else {
        this.selectedVersionFilter = EMPTY_STRING;
        this.isVersionFilterOpen = false;
        this.isSelectedVersionFilter = false;
      }
    } else {
      this.resetSelectedFilterValues();
    }
    this.isLoading = false;
    this.updateButtonVisibility();
  }

  updateButtonVisibility() {
    if (
      this.isSelectedTypeFilter ||
      this.isSelectedStatusFilter ||
      this.isSelectedSurveyFilter ||
      this.isSelectedVersionFilter
    ) {
      this.isAnyFilterSelected = true;
      this.isSearchBarEnabled = true;
    }
    if (
      !this.isSelectedTypeFilter &&
      !this.isSelectedStatusFilter &&
      !this.isSelectedSurveyFilter &&
      !this.isSelectedVersionFilter
    ) {
      this.isAnyFilterSelected = false;
      this.isSearchBarEnabled = false;
    }
  }

  resetSelectedFilterValues() {
    this.selectedTypeFilter = EMPTY_STRING;
    this.selectedStatusFilter = EMPTY_STRING;
    this.selectedSurveyFilter = EMPTY_STRING;
    this.selectedVersionFilter = EMPTY_STRING;
  }

  resetSelectedFilterFlags() {
    this.isSelectedTypeFilter = false;
    this.isSelectedStatusFilter = false;
    this.isSelectedSurveyFilter = false;
    this.isSelectedVersionFilter = false;
  }

  OnResetListClicked(id: number) {
    if (id == 1) {
      this.types.forEach((item) => {
        item.checked = false;
      });
      this.isSelectedTypeFilter = false;
      this.OnSaveListClicked(1);
    } else if (id == 2) {
      this.status.forEach((item) => {
        item.checked = false;
      });
      this.isSelectedStatusFilter = false;
      this.OnSaveListClicked(2);
    } else if (id == 3) {
      this.survey.forEach((item) => {
        item.checked = false;
      });
      this.isSelectedSurveyFilter = false;
    } else if (id == 4) {
      this.version.forEach((item) => {
        item.checked = false;
      });
      this.isSelectedVersionFilter = false;
    }
    this.updateButtonVisibility();
  }

  OnClearFilterButtonClick(): void {
    this.types.forEach((item) => {
      item.checked = false;
    });
    this.status.forEach((item) => {
      item.checked = false;
    });
    this.survey.forEach((item) => {
      item.checked = false;
    });
    this.version.forEach((item) => {
      item.checked = false;
    });
    this.resetSelectedFilterValues();
    this.resetSelectedFilterFlags();
    this.isAnyFilterSelected = false;
    this.isSearchBarEnabled = false;
  }

  onFilterSearchButtonClick() {
    console.log('Hello');
  }

  async loadMap(): Promise<void> {
    if (!this.mapElementRef) {
      console.error('Map element is not available.');
      return;
    }

    const { Map } = await google.maps.importLibrary('maps');
    const mapEl = this.mapElementRef.nativeElement;

    if (this.center) {
      this.map = new Map(mapEl, {
        center: this.center,
        zoom: 12,
        mapId: '4504f8b37365c3d01',
        scaleControl: true,
        streetViewControl: false,
        zoomControl: true,
        overviewMapControl: true,
        mapTypeControl: true,
        fullscreenControl: true,
      });

      this.plotMarkers();
    }
  }

  plotMarkers(): void {
    this.assetData.forEach((data: any) => {
      const latLng = new google.maps.LatLng(
        parseFloat(data.latitude),
        parseFloat(data.longitude),
      );

      const assetType = data.assetType;
      const assetstatus = data.status;
      let fillColor = '#22C55E';
      let markertext = 'M';
      if (assetType == RECTIFIER) {
        markertext = 'R';
      } else if (assetType == TEST_POINT) {
        markertext = 'T';
      } else if (assetType == BOND) {
        markertext = 'B';
      } else if (assetType == ANODE) {
        markertext = 'A';
      } else if (assetType == CASING) {
        markertext = 'CS';
      } else if (assetType == CTS) {
        markertext = 'CP';
      } else if (assetType == ER_PROBE) {
        markertext = 'ER';
      } else if (assetType == ISOLATIONJOINT) {
        markertext = 'IJ';
      } else {
        const firstLetter = assetType.charAt(0).toUpperCase();
        markertext = firstLetter;
      }

      if (assetstatus == COMPLIANT_AH) {
        fillColor = '#22C55E';
      } else if (assetstatus == EXCEPTION_AH) {
        fillColor = '#E5AB09';
      } else if (assetstatus == DELINQUENT_AH) {
        fillColor = '#E77A3C';
      }

      const customMarker = {
        path: `M12 2C8.13 2 5 5.13 5 9c0 3.87 7 13 7 13s7-9.13 7-13c0-3.87-3.13-7-7-7z`,
        fillColor: fillColor,
        fillOpacity: 1,
        strokeWeight: 3,
        strokeColor: '#fff',
        scale: 2,
        labelOrigin: new google.maps.Point(12, 10),
      };

      const marker = new google.maps.Marker({
        position: latLng,
        map: this.map,
        title: data.assetName,
        icon: customMarker,
        label: {
          text: markertext,
          color: 'white',
          fontSize: '16px',
          fontWeight: 'bold',
        },
      });

      this.markerMap.set(data.id, marker);
    });
  }

  setMapCenter(): void {
    if (this.assetData.length > 0) {
      const latitudes = this.assetData.map((data) => parseFloat(data.latitude));
      const longitudes = this.assetData.map((data) =>
        parseFloat(data.longitude),
      );

      const avgLatitude = latitudes[0];
      const avgLongitude = longitudes[0];

      this.center = { lat: avgLatitude, lng: avgLongitude };
    } else if (this.assetData.length == 0) {
      this.center = { lat: 44.21839866380285, lng: -104.11857786015787 };
    } else {
      console.error('No asset data available to calculate center.');
    }
  }

  getMapCenter(): { lat: number; lng: number } | undefined {
    this.setMapCenter();
    return this.center;
  }

  mapVisibility(from: number) {
    if (from === 1) {
      this.mapVisibilityBtn = false;
      this.showMapDiv = true;
      setTimeout(() => this.loadMap(), 0);
    } else if (from == 2) {
      this.mapVisibilityBtn = true;
      this.showMapDiv = false;
    } else {
      this.mapVisibilityBtn = false;
      this.showMapDiv = true;
      setTimeout(() => this.loadMap(), 0);
    }
  }

  chartVisibility(from: number) {
    if (from === 1) {
      this.chartVisibilityBtn = false;
      this.showChartDiv = true;
    } else if (from == 2) {
      this.chartVisibilityBtn = true;
      this.showChartDiv = false;
    } else {
      this.chartVisibilityBtn = false;
      this.showChartDiv = true;
    }
  }
}
