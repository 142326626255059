import { Injectable } from '@angular/core';
import {
  FilterInput,
  GetAllInspectionsQuery,
  GetAllInspectionsQueryVariables,
  InspectionConnection,
} from '../../../awsAppSync/API';
import { FETCHING_INSPECTION_DATA_ERROR } from '../../shared/constants';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import { getAllInspections } from '../../../awsAppSync/graphql/queries';
@Injectable({
  providedIn: 'root',
})
export class InspectionService {
  selectedRowData: any;
  constructor(
    private awsService: BaseAWSRequestService,
  ) {}

  async getAllInspectionData(filter: FilterInput): Promise<InspectionConnection | null> {
    
    try {
      if(this.selectedRowData){
        if(this.selectedRowData.assetName){
          filter.searchText = this.selectedRowData.assetName;
          this.selectedRowData = null;
        }
      }
      const variables: GetAllInspectionsQueryVariables = { filter };
      const result = await this.awsService.executeGetQuery<GetAllInspectionsQuery>(
        getAllInspections,
        variables
      );
      return result.getAllInspections ?? null; // Using nullish coalescing operator
    } catch (error) {
      console.error(FETCHING_INSPECTION_DATA_ERROR, error);
      return null;
    }
}
  
}
