<kendo-dialog *ngIf="showDialog" [width]="600" [minHeight]="400" class="upload-dialog"
     (close)="closeDialog()">
    <kendo-dialog-titlebar>
        <span class="dialog-title-static">Upload Survey Data of &nbsp;</span>
        <span class="dialog-title-dynamic">{{ surveyName }}</span>
    </kendo-dialog-titlebar>
    <div>
        <p class="survey-txt">Survey File Type:&nbsp;<strong>Direct</strong></p>
        <p class="native-file">Select Native File</p>
        <p class="info">
            <i class="k-icon k-font-icon k-i-information"></i> Importing will override any
            duplicates
        </p>
        <div class="file-drop-zone" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event)"
            (drop)="onFileDropped($event)">
            <button class="browse-button" type="button" (click)="triggerFileInput()">
                <input #fileInput type="file" accept="application/json"  (change)="onFileInputChange($event)" multiple hidden   />
                <span class="browse-container">
                    <i class="k-icon k-font-icon k-i-upload" style="font-size: 20px;"></i>
                    Browse
                  </span>
            </button>
            <p class="drop-text">Drag or drop Native file here</p>
        </div>
    </div>

    <div class="actions">
        <button  (click)="closeDialog()" class="cancel-btn-css">
            Cancel
        </button>
        <button  (click)="onUpload()" class="upload-btn-css" [disabled]="files.length === 0">
            Upload
        </button>
    </div>
    <ul>
        <li *ngFor="let file of files">{{ file.name }}</li>
    </ul>
</kendo-dialog>