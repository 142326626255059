/* eslint-disable @typescript-eslint/no-unused-vars */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PopupDialogService } from '../../shared/popup-dialog/popup-dialog.service';
import {
  CANCEL_TEMPLATE_HEADING,
  CANCEL_TEMPLATE_MSG,
  FAILED,
  CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
  NO,
  SETUP_COMPANY_OVERVIEW,
  SUCCESS_CREATE_ASSET_SETUP,
  SETUP_HIERARCHY_LEVELS,
  FAILED_CREATE_ASSET_SETUP,
  ERROR_CREATE_ASSET_TYPE,
  ERROR,
  FREQUENCIES,
  MEASUREMENT_TYPES,
  OPERATOR_OPTIONS,
  TOASTER_SUCCESS,
  EMPTY_STRING,
  ERROR_FETCHING_ASSET_TYPE,
  CREATE_NEW_ASSET_TEMPLATE_FAILED,
  TEST_STATION_MEASUREMENT_SOURCE,
} from '../../shared/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomToastrService } from '../../shared/ngx-toastr/custom-toastr.service';
import { CreateAssetTypeService } from '../../core/services/create-asset-type.service';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { SetupHierarchyState } from '../../core/store/setup-hierarchy.state';
import { DatePipe } from '@angular/common';
import {
  // AssetTypeTemplateCreateInput,
  AssetTypeTemplateUpdateInput,
  AssetTypeTemplateResponse,
  CoreAssetType,
  CreateAssetTypeTemplateInput,
  UpdateAssetTypeTemplateInput,
  AssetType,
} from '../../../awsAppSync/API';

@Component({
  selector: 'app-create-asset-type',
  templateUrl: './create-asset-type.component.html',
  styleUrl: './create-asset-type.component.css',
})
export class CreateAssetTypeComponent implements OnInit, OnDestroy {
  assets: any;
  currentStep = 2;
  createAssetTypeMC: CreateAssetTypeTemplateInput;
  updateAssetTypeMC: UpdateAssetTypeTemplateInput;
  isLoader = false;
  public frequencies: { text: string; value: string }[] = [];
  measurementTypes = MEASUREMENT_TYPES;
  // Add this in your component
  operatorOptions = OPERATOR_OPTIONS;
  testStationMeasurementSource = TEST_STATION_MEASUREMENT_SOURCE;
  frequenciedefaultItem = { text: 'Measurement Frequency', value: '' };
  operatorDefaultItem = { text: 'Select Operator', value: '' };
  categoryDefaultItem = { id: '', assetType: 'Select Asset Type Category' };
  measurementTypesdefaultItem = {
    measurementType: 'Select Measurement Type',
    id: '',
  };
  measurementSourcedefaultItem = {
    text: 'Select Measurement Source',
    value: '',
  };
  isSaveClicked = false;
  isDialogOpen = false;
  primaryCompanyId!: string;
  primaryCompanyId$: Observable<string | null>;
  isEdit = false;
  annualComplainceFlag = true;
  periodicComplainceFlag = true;
  customFrequencyStartDateISOFormat = EMPTY_STRING;
  assetTemplateData: any = [];
  isLeftArrowDisabled = false;
  isRightArrowDisabled = false;
  templateData: any = [];
  assetTypeCategory = 'Test';
  assetTypeCategoryList: (CoreAssetType | null)[] | null = [];
  currentIndex = 0; // Track the currently displayed data
  frequencyIndexFlag = 0;
  nextBtnFlag = true;
  originalFormData = {};
  isFormChanged = true;
  selectedAssetTypeCategory: CoreAssetType | null | undefined;
  numberOfOccurrences1Val = 0;
  numberOfOccurrences2Val = 0;
  duration1Val = '';
  duration2Val = '';
  custFrequency = '';
  isCreateAPIFlag = true;
  ngOnInit() {
    this.getAssetTypeCategory();
    this.isEdit = false;
    this.route.queryParams.subscribe((params) => {
      this.primaryCompanyId = params['id'];
      if (params['edit'] && params['edit'].toLocaleLowerCase() == 'true') {
        this.isEdit = true;
        this.isCreateAPIFlag = false;
      }
    });
    this.assets = {
      primaryCompanyId: this.primaryCompanyId,
      createdBy: '',
      updatedBy: '',
      assetTypeInputs: [
        {
          id: '',
          pk: '',
          sk: '',
          assetType: '',
          assetTypeId: '',
          activeAssetTypeInputs: 0,
          measurementTypesList: [],
          assetTypeTemplateInputs: [
            {
              id: '',
              pk: '',
              sk: '',
              assetTypeTemplateName: '',
              customFrequencyString: '',
              isDefaultFrequency: true,
              numberOfOccurrences1: '',
              duration1: '',
              numberOfOccurrences2: '',
              duration2: '',
              isDeleted: false,
              activeMeasurementCriteriasCount: 0,
              isAnnualCompliance: true,
              isPeriodicCompliance: true,
              isAssetConfigured: false,
              measurementCriteriaInputs: [
                {
                  pk: '',
                  sk: '',
                  id: '',
                  compliance: false,
                  viewInMainGrid: false,
                  measurementSource: '',
                  measurementType: '',
                  isCustomTestStationOptionSelected: false,
                  isDeleted: false,
                },
              ],
            },
          ],
        },
      ],
    };
    this.frequencies = FREQUENCIES;

    this.originalFormData = structuredClone(
      this.assets.assetTypeInputs[this.currentIndex],
    );
    if (this.isEdit) {
      this.isLoader = true;
      this.nextBtnFlag = false;
      this.getEditAssetTemplate();
    }
  }
  async getEditAssetTemplate() {
    const responseArray =
      await this.assetTypeService.getAssetTypeTemplateByPrimaryCompanyId(
        this.primaryCompanyId,
      );
    await this.transformAssetsForEdit(responseArray);
    this.isLoader = false;
  }

  async transformAssetsForEdit(responseArray: any) {
    this.assets = {
      primaryCompanyId: this.primaryCompanyId,
      createdBy: '',
      assetTypeInputs: await Promise.all(
        responseArray?.map(async (response: any) => {
          const measurementTypesList = response?.id
            ? await this.assetTypeService.getAllCoreMeasurementTypesByAssetCategory(
                response?.id || '',
              )
            : [];

          return {
            assetType: response?.assetType || '',
            assetTypeId: response?.id || '',
            pk: response?.pk || '',
            sk: response?.sk || '',
            id: response?.id || '',
            activeAssetTypeInputs: this.getActiveAssetTypeInputs(
              response?.assetTypeTemplates,
            ),
            measurementTypesList, // Populated asynchronously
            assetTypeTemplateInputs: response?.assetTypeTemplates?.map(
              (template: any) => ({
                pk: template?.pk || '',
                sk: template?.sk || '',
                id: template?.id || '',
                assetTypeTemplateName: template?.assetTypeTemplateName || '',
                customFrequencyString:
                  template.numberOfOccurrences1 && template.numberOfOccurrences2
                    ? template.numberOfOccurrences1 +
                      ' ' +
                      template.duration1 +
                      ' not to exceed ' +
                      template.numberOfOccurrences2 +
                      ' ' +
                      template.duration2
                    : '',
                isDefaultFrequency: template?.isDefaultFrequency || null,
                numberOfOccurrences1: template?.numberOfOccurrences1 || null,
                duration1: template?.duration1 || null,
                numberOfOccurrences2: template?.numberOfOccurrences2 || null,
                duration2: template?.duration2 || null,
                isDeleted: template?.isDeleted || false,
                activeMeasurementCriteriasCount:
                  this.getActiveMeasurementCriteriasCount(
                    template?.measurementTypes,
                  ), // You can calculate this if needed
                isAnnualCompliance: template?.isAnnualCompliance || false,
                isPeriodicCompliance: template?.isPeriodicCompliance || false,
                isAssetConfigured: template?.isAssetConfigured || false,
                measurementCriteriaInputs: template?.measurementTypes?.map(
                  (measurement: any) => ({
                    pk: measurement?.pk || '',
                    sk: measurement?.sk || '',
                    id: measurement?.id || '',
                    compliance: measurement?.compliance || false,
                    viewInMainGrid: measurement?.viewInMainGrid || false,
                    measurementSource: measurement?.measurementSource || '',
                    isCustomTestStationOptionSelected:
                      response?.assetType.trim().toLowerCase() ===
                      'test station'
                        ? this.checkIsTestStationCustomOption(
                            measurement?.measurementSource,
                          )
                        : false,
                    measurementType: measurement?.measurementType || '',
                    isDeleted: measurement?.isDeleted || false,
                  }),
                ),
              }),
            ),
          };
        }),
      ),
    };
    this.originalFormData = structuredClone(
      this.assets.assetTypeInputs[this.currentIndex],
    );
  }

  checkIsTestStationCustomOption(measurementVal: string): boolean {
    // Check if measurementVal matches either text or value in the array
    const isContains = !this.testStationMeasurementSource.some(
      (item: any) => item.text === measurementVal,
    );
    return isContains;
  }
  constructor(
    private popupDialogService: PopupDialogService,
    private router: Router,
    private toastr: CustomToastrService,
    private route: ActivatedRoute,
    private assetTypeService: CreateAssetTypeService,
    private store: Store,
    private datePipe: DatePipe,
  ) {
    this.primaryCompanyId$ = this.store.select(
      SetupHierarchyState.getPrimaryCompanyId,
    );
    this.updateAssetTypeMC = {
      primaryCompanyId: this.primaryCompanyId,
      updatedBy: '',
      assetTypeUpdateInputs: [
        {
          id: '',
          pk: '',
          sk: '',
          assetType: '',
          assetTypeId: '',
          assetTypeTemplateUpdateInputs: [
            {
              id: '',
              pk: '',
              sk: '',
              assetTypeTemplateName: '',
              isAnnualCompliance: true,
              isPeriodicCompliance: true,
              isAssetConfigured: false,
              measurementFrequency: '',
              isDeleted: false,
              isDefaultFrequency: true,
              numberOfOccurrences1: null,
              duration1: null,
              numberOfOccurrences2: null,
              duration2: null,
              measurementTypeUpdateInputs: [
                {
                  id: '',
                  pk: '',
                  sk: '',
                  compliance: false,
                  viewInMainGrid: false,
                  measurementSource: '',
                  measurementType: '',
                  isDeleted: false,
                },
              ],
            },
          ],
        },
      ],
    };

    this.createAssetTypeMC = {
      primaryCompanyId: this.primaryCompanyId,
      createdBy: '',
      assetTypeInputs: [
        {
          assetType: '',
          assetTypeId: '',
          assetTypeTemplateInputs: [
            {
              assetTypeTemplateName: '',
              isAnnualCompliance: true,
              isPeriodicCompliance: true,
              isDefaultFrequency: true,
              numberOfOccurrences1: null,
              duration1: '',
              numberOfOccurrences2: null,
              duration2: '',
              isAssetConfigured: false,
              measurementTypeInputs: [
                {
                  compliance: false,
                  viewInMainGrid: false,
                  measurementSource: '',
                  measurementType: '',
                },
              ],
            },
          ],
        },
      ],
    };
  }

  get currentAssets() {
    return this.assets?.assetTypeInputs?.[this.currentIndex] || [];
  }
  ngOnDestroy(): void {
    this.isEdit = false;
  }

  /**
   * This function return the custom meaurement frequency string
   * @param startDate start date for measurement frequency
   * @param repeatInterval repeat interval for measurement frequency
   * @param selectedRepeatOption repeat option for measurement frequency (Days, Weeks, Months Or Years)
   * @returns
   */
  getCustomFrequencyString(
    startDate: string | null | undefined,
    repeatInterval: string | null | undefined,
    selectedRepeatOption: string | null | undefined,
  ) {
    if (!startDate && !repeatInterval && !selectedRepeatOption) {
      return 'Custom';
    }

    const date = new Date(startDate!);

    // Format the date as dd-MM-yyyy using DatePipe
    const formattedDate = this.datePipe.transform(date, 'dd-MM-yyyy');
    return `Occurs every ${repeatInterval} ${selectedRepeatOption!.toLowerCase()} starting from ${formattedDate}`;
  }

  addAssetType() {
    const newAssetType = {
      pk: '',
      sk: '',
      id: '',
      assetTypeTemplateName: '',
      customFrequencyString: this.custFrequency,
      isDefaultFrequency: true,
      numberOfOccurrences1: this.numberOfOccurrences1Val,
      duration1: this.duration1Val,
      numberOfOccurrences2: this.numberOfOccurrences2Val,
      duration2: this.duration2Val,
      isDeleted: false,
      activeMeasurementCriteriasCount: 0,
      isAnnualCompliance: true,
      isPeriodicCompliance: true,
      isAssetConfigured: false,
      measurementCriteriaInputs: [
        {
          pk: '',
          sk: '',
          id: '',
          compliance: false,
          viewInMainGrid: false,
          measurementSource: '',
          measurementType: '',
          isDeleted: false,
        },
      ],
    };

    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs = [
      ...this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs,
      newAssetType,
    ];

    const activeCount = this.assets.assetTypeInputs[
      this.currentIndex
    ].assetTypeTemplateInputs.filter(
      (item: { isDeleted: any }) => !item.isDeleted,
    ).length;

    this.assets.assetTypeInputs[this.currentIndex].activeAssetTypeInputs =
      activeCount;
    this.checkFormChanges();
  }
  complianceCheckBoxChange(assetIndex: number, measurementIndex: number) {
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      assetIndex
    ].measurementCriteriaInputs.forEach((x: any) => (x.compliance = false));
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      assetIndex
    ].measurementCriteriaInputs[measurementIndex].compliance = true;
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      assetIndex
    ].measurementCriteriaInputs[measurementIndex].viewInMainGrid = true;
  }

  addMeasurement(assetIndex: number) {
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      assetIndex
    ].measurementCriteriaInputs.push({
      pk: '',
      sk: '',
      id: '',
      compliance: false,
      viewInMainGrid: false,
      measurementSource: '',
      measurementType: '',
      isDeleted: false,
    });
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      assetIndex
    ].activeMeasurementCriteriasCount = this.getActiveMeasurementCriteriasCount(
      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        assetIndex
      ].measurementCriteriaInputs,
    );
    this.checkFormChanges();
  }

  deleteMeasurement(assetIndex: number, measurementIndex: number) {
    if (this.isEdit || !this.isCreateAPIFlag) {
      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        assetIndex
      ].measurementCriteriaInputs[measurementIndex].isDeleted = true;

      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        assetIndex
      ].activeMeasurementCriteriasCount =
        this.getActiveMeasurementCriteriasCount(
          this.assets.assetTypeInputs[this.currentIndex]
            .assetTypeTemplateInputs[assetIndex].measurementCriteriaInputs,
        );
    } else {
      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        assetIndex
      ].measurementCriteriaInputs.splice(measurementIndex, 1);
    }
    this.checkFormChanges();
  }

  // Update activeMeasurementCriteriasCount based on actual counts
  getActiveMeasurementCriteriasCount(measurementCriteriaInputs: any) {
    const activeItemsCount = measurementCriteriaInputs.filter(
      (item: { isDeleted: any }) => !item.isDeleted,
    ).length;
    return activeItemsCount;
  }
  getActiveAssetTypeInputs(assetTypeInputs: any) {
    const activeItemsCount = assetTypeInputs.filter(
      (item: { isDeleted: any }) => !item.isDeleted,
    ).length;
    return activeItemsCount;
  }

  cancel() {
    this.popupDialogService.openDialog(
      CANCEL_TEMPLATE_HEADING,
      CANCEL_TEMPLATE_MSG,
      FAILED,
      CANCEL_TEMPLATE_BTN_CONFIRM_TXT,
      () => this.router.navigate([SETUP_COMPANY_OVERVIEW]),
      true,
      NO,
      true,
    );
  }

  async saveAndExit(form: any) {
    return this.router.navigate([SETUP_COMPANY_OVERVIEW]);
  }

  async saveAndNext(form: any) {
    return this.router.navigate([SETUP_HIERARCHY_LEVELS]);
  }

  // Handle measurement frequency change
  onMeasurementFrequencyChange(frequency: any) {
    const index = this.assets.assetTypeInputs.length - 1;
    // this.assets.measurementFrequency = frequency;
    if (frequency === 'custom') {
      this.isDialogOpen = true;
    } else {
      this.isDialogOpen = false;
      if (this.assets)
        this.assets.assetTypeInputs[index].customFrequencyBtnFlag = false;
    }
  }
  setCustomFrequency(event: {
    isDefaultFrequency: boolean | null;
    numberOfOccurrences1: number | null;
    numberOfOccurrences2: number | null;
    duration1: string | null;
    duration2: string | null;
  }) {
    if (event.isDefaultFrequency) {
      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        this.frequencyIndexFlag
      ].isDefaultFrequency = event.isDefaultFrequency;
      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        this.frequencyIndexFlag
      ].numberOfOccurrences1 = this.numberOfOccurrences1Val;

      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        this.frequencyIndexFlag
      ].numberOfOccurrences2 = this.numberOfOccurrences2Val;

      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        this.frequencyIndexFlag
      ].duration1 = this.duration1Val;

      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        this.frequencyIndexFlag
      ].duration2 = this.duration2Val;

      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        this.frequencyIndexFlag
      ].customFrequencyString =
        this.numberOfOccurrences1Val +
        ' ' +
        this.duration1Val +
        ' not to exceed ' +
        this.numberOfOccurrences2Val +
        ' ' +
        this.duration2Val;
    } else {
      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        this.frequencyIndexFlag
      ].isDefaultFrequency = event.isDefaultFrequency;

      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        this.frequencyIndexFlag
      ].numberOfOccurrences1 = event.numberOfOccurrences1;

      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        this.frequencyIndexFlag
      ].numberOfOccurrences2 = event.numberOfOccurrences2;

      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        this.frequencyIndexFlag
      ].duration1 = event.duration1;

      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        this.frequencyIndexFlag
      ].duration2 = event.duration2;
      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        this.frequencyIndexFlag
      ].customFrequencyString =
        event.numberOfOccurrences1 +
        ' ' +
        event.duration1 +
        ' not to exceed ' +
        event.numberOfOccurrences2 +
        ' ' +
        event.duration2;
    }
    this.checkFormChanges();
    this.isDialogOpen = false;
  }

  //Set isDeleted field to true of assetType and its all the measurement criteria
  deleteAssetType(index: number): void {
    if (this.isEdit || !this.isCreateAPIFlag) {
      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        index
      ].isDeleted = true;
      this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
        index
      ].measurementCriteriaInputs.forEach(
        (criteria: { isDeleted: boolean }) => (criteria.isDeleted = true),
      );
    } else {
      if (
        this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs
          .length > 1
      ) {
        this.assets.assetTypeInputs[
          this.currentIndex
        ].assetTypeTemplateInputs.splice(index, 1);
      }
    }

    const activeCount = this.assets.assetTypeInputs[
      this.currentIndex
    ].assetTypeTemplateInputs.filter(
      (item: { isDeleted: any }) => !item.isDeleted,
    ).length;

    this.assets.assetTypeInputs[this.currentIndex].activeAssetTypeInputs =
      activeCount;
    this.checkFormChanges();
  }

  checkValidation() {
    let isValid = true;
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs
      .filter((asset: any) => !asset.isDeleted)
      .forEach((asset: any) => {
        // Check if the asset type is empty
        if (
          !this.assets.assetTypeInputs[this.currentIndex].assetType ||
          this.assets.assetTypeInputs[this.currentIndex].assetType.trim() === ''
        ) {
          this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
          isValid = false;
        } else if (
          !asset.assetTypeTemplateName ||
          asset.assetTypeTemplateName.trim() === ''
        ) {
          this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
          isValid = false;
        }
        if (isValid == true) {
          // Loop through measurement criteria inputs for each asset
          asset.measurementCriteriaInputs
            .filter((measurement: any) => !measurement.isDeleted)
            .forEach((measurement: any) => {
              // Validate measurement source
              if (
                !measurement.measurementSource ||
                measurement.measurementSource.trim() === ''
              ) {
                this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
                isValid = false;
              }

              // Validate measurement type
              else if (
                !measurement.measurementType ||
                measurement.measurementType.trim() === ''
              ) {
                this.toastr.showError(ERROR_CREATE_ASSET_TYPE, 'Error');
                isValid = false;
              }
            });
        }
      });
    return isValid;
  }

  allowOnlyNumbersAndSymbols(event: KeyboardEvent) {
    const allowedCharacters = /[0-9+\-.]/; // Allow digits, +, -, and .
    const inputChar = String.fromCharCode(event.keyCode || event.which);

    if (!allowedCharacters.test(inputChar)) {
      event.preventDefault(); // Prevent the character from being entered
    }
  }

  async buildUpdateInput() {
    try {
      const transformedUpdatAsset: UpdateAssetTypeTemplateInput = {
        primaryCompanyId: this.primaryCompanyId,
        updatedBy: this.assets.updatedBy || '',
        assetTypeUpdateInputs: [
          {
            id: this.assets.assetTypeInputs[this.currentIndex]?.id || '',
            pk: this.assets.assetTypeInputs[this.currentIndex]?.pk || '',
            sk: this.assets.assetTypeInputs[this.currentIndex]?.sk || '',
            assetType:
              this.assets.assetTypeInputs[this.currentIndex]?.assetType || '',
            assetTypeId:
              this.assets.assetTypeInputs[this.currentIndex]?.assetTypeId || '',
            assetTypeTemplateUpdateInputs: (
              this.assets.assetTypeInputs[this.currentIndex]
                ?.assetTypeTemplateInputs || []
            ).map((templateInput: any) => ({
              id: templateInput?.id || '',
              pk: templateInput?.pk || '',
              sk: templateInput?.sk || '',
              measurementFrequency: templateInput?.measurementFrequency || '',
              assetTypeTemplateName: templateInput?.assetTypeTemplateName || '',
              isDefaultFrequency: templateInput?.isDefaultFrequency || true,
              numberOfOccurrences1: templateInput?.numberOfOccurrences1 || '',
              numberOfOccurrences2: templateInput?.numberOfOccurrences2 || '',
              duration1: templateInput?.duration1 || '',
              duration2: templateInput?.duration2 || '',
              isAnnualCompliance: templateInput?.isAnnualCompliance || false,
              isPeriodicCompliance:
                templateInput?.isPeriodicCompliance || false,
              isAssetConfigured: templateInput?.isAssetConfigured || '',
              isDeleted: templateInput?.isDeleted || false,
              measurementTypeUpdateInputs: (
                templateInput?.measurementCriteriaInputs || []
              ).map((criteriaInput: any) => ({
                id: criteriaInput?.id || '',
                pk: criteriaInput?.pk || '',
                sk: criteriaInput?.sk || '',
                compliance: criteriaInput?.compliance || false,
                viewInMainGrid: criteriaInput?.viewInMainGrid || false,
                measurementSource: criteriaInput?.measurementSource || '',
                measurementType: criteriaInput?.measurementType || '',
                isDeleted: criteriaInput?.isDeleted || false,
              })),
            })),
          },
        ],
      };
      const response = await this.assetTypeService.updateAssetTypeAndMC(
        transformedUpdatAsset,
      );
      if (response?.status?.toLocaleLowerCase() === 'success') {
        this.toastr.showSuccess(SUCCESS_CREATE_ASSET_SETUP, TOASTER_SUCCESS);
      } else {
        this.toastr.showError(FAILED_CREATE_ASSET_SETUP, ERROR);
      }
    } catch (error) {
      console.error('Error saving asset setup:', error);
      this.toastr.showError(FAILED_CREATE_ASSET_SETUP, ERROR);
    } finally {
      this.isLoader = false;
    }
  }
  async buildCreateInput() {
    try {
      this.createAssetTypeMC.assetTypeInputs = [];
      const transformedAssets: CreateAssetTypeTemplateInput = {
        primaryCompanyId: this.primaryCompanyId,
        createdBy: this.assets.createdBy,
        assetTypeInputs: [
          {
            assetType:
              this.assets.assetTypeInputs[this.currentIndex]?.assetType || '',
            assetTypeId:
              this.assets.assetTypeInputs[this.currentIndex]?.assetTypeId || '',
            assetTypeTemplateInputs: (
              this.assets.assetTypeInputs[this.currentIndex]
                ?.assetTypeTemplateInputs || []
            ).map((templateInput: any) => ({
              measurementFrequency: templateInput?.measurementFrequency || '',
              assetTypeTemplateName: templateInput?.assetTypeTemplateName || '',
              isDefaultFrequency: templateInput?.isDefaultFrequency || true,
              numberOfOccurrences1: templateInput?.numberOfOccurrences1 || '',
              numberOfOccurrences2: templateInput?.numberOfOccurrences2 || '',
              duration1: templateInput?.duration1 || '',
              duration2: templateInput?.duration2 || '',
              isAnnualCompliance: templateInput?.isAnnualCompliance || false,
              isPeriodicCompliance:
                templateInput?.isPeriodicCompliance || false,
              isAssetConfigured: templateInput?.isAssetConfigured || '',
              measurementTypeInputs: (
                templateInput?.measurementCriteriaInputs || []
              ).map((criteriaInput: any) => ({
                compliance: criteriaInput?.compliance || false,
                viewInMainGrid: criteriaInput?.viewInMainGrid || false,
                measurementSource: criteriaInput?.measurementSource || '',
                measurementType: criteriaInput?.measurementType || '',
              })),
            })),
          },
        ],
      };
      const response =
        await this.assetTypeService.createAssetTypeAndMC(transformedAssets);
      if (response?.status?.toLocaleLowerCase() === 'success') {
        this.toastr.showSuccess(SUCCESS_CREATE_ASSET_SETUP, TOASTER_SUCCESS);
        this.currentIndex++;
        this.isCreateAPIFlag = false;
        this.addNewAssetTemplate();
      } else {
        this.toastr.showError(FAILED_CREATE_ASSET_SETUP, ERROR);
      }
    } catch (error) {
      console.error('Error saving asset setup:', error);
      this.toastr.showError(FAILED_CREATE_ASSET_SETUP, ERROR);
    } finally {
      this.isLoader = false;
    }
  }

  openMeasurementFrequencyPopup(index: number) {
    this.frequencyIndexFlag = index;
    this.isDialogOpen = true;
  }
  closeMeasurementFrequencyPopup() {
    this.isDialogOpen = false;
  }

  async showPreviousData() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
      this.originalFormData = structuredClone(
        this.assets.assetTypeInputs[this.currentIndex],
      );

      await this.checkDefalutCustomFrequencyString();
      this.checkFormChanges();
    }
  }
  async checkDefalutCustomFrequencyString() {
    const assetCategoryId =
      this.assets.assetTypeInputs[this.currentIndex].assetTypeId;
    if (assetCategoryId) {
      this.selectedAssetTypeCategory = await this.assetTypeCategoryList?.find(
        (item) => item?.id === assetCategoryId,
      );
    }
    if (this.selectedAssetTypeCategory) {
      this.setDefalutCustomFrequency();
    }
  }
  async showNextData() {
    if (this.currentIndex < this.assets.assetTypeInputs.length - 1) {
      this.currentIndex++;
      this.originalFormData = structuredClone(
        this.assets.assetTypeInputs[this.currentIndex],
      );
      await this.checkDefalutCustomFrequencyString();
      this.checkFormChanges();
    }
  }
  checkFormChanges() {
    const currentIndexFormData = this.assets.assetTypeInputs[this.currentIndex];
    // Compare current form data with original data
    this.isFormChanged =
      JSON.stringify(currentIndexFormData) !==
      JSON.stringify(this.originalFormData);
    if (this.isFormChanged) {
      this.nextBtnFlag = true;
    } else {
      this.nextBtnFlag = false;
    }
  }
  async getAssetTypeCategory() {
    this.assetTypeCategoryList =
      await this.assetTypeService.getAssetTypeCategoy();
  }

  async saveAssetDetails() {
    this.nextBtnFlag = false;
    const isValid = this.checkValidation();
    const assetTypeTemplateResp: AssetTypeTemplateResponse | null = null;
    if (isValid) {
      this.isLoader = true;

      if (this.isEdit || !this.isCreateAPIFlag) {
        await this.buildUpdateInput();
      } else {
        await this.buildCreateInput();
      }
      this.checkFormChanges();
    }
  }

  setDefalutCustomFrequency() {
    this.numberOfOccurrences1Val =
      this.selectedAssetTypeCategory?.numberOfOccurrences1 || 0;
    this.numberOfOccurrences2Val =
      this.selectedAssetTypeCategory?.numberOfOccurrences2 || 0;
    this.duration1Val = this.selectedAssetTypeCategory?.duration1 || '';
    this.duration2Val = this.selectedAssetTypeCategory?.duration2 || '';
    this.custFrequency =
      this.numberOfOccurrences1Val && this.numberOfOccurrences2Val
        ? this.numberOfOccurrences1Val +
          ' ' +
          this.duration1Val +
          ' not to exceed ' +
          this.numberOfOccurrences2Val +
          ' ' +
          this.duration2Val
        : '';
  }

  addNewAssetTemplate() {
    const newTemplate = {
      assetType: '',
      assetTypeId: '',
      activeAssetTypeInputs: 0,
      measurementTypesList: [],
      assetTypeTemplateInputs: [
        {
          pk: '',
          sk: '',
          id: '',
          assetTypeTemplateName: '',
          customFrequencyString: this.custFrequency,
          isDefaultFrequency: true,
          numberOfOccurrences1: this.numberOfOccurrences1Val,
          duration1: this.duration1Val,
          numberOfOccurrences2: this.numberOfOccurrences2Val,
          duration2: this.duration2Val,
          isDeleted: false,
          activeMeasurementCriteriasCount: 0,
          isAnnualCompliance: true,
          isPeriodicCompliance: true,
          isAssetConfigured: false,
          measurementCriteriaInputs: [
            {
              pk: '',
              sk: '',
              id: '',
              compliance: false,
              viewInMainGrid: false,
              measurementSource: '',
              measurementType: '',
              isDeleted: false,
            },
          ],
        },
      ],
    };

    this.assets.assetTypeInputs[this.currentIndex] = newTemplate;
  }

  async onAssetTypeChange(selectedValue: string): Promise<void> {
    try {
      this.isLoader = true;
      if (selectedValue) {
        this.selectedAssetTypeCategory = await this.assetTypeCategoryList?.find(
          (item) => item?.id === selectedValue,
        );

        this.setDefalutCustomFrequency();

        const templateValues =
          await this.assetTypeService.getAllCoreAssetTemplatesByAssetCategory(
            selectedValue,
          );
        if (
          templateValues?.assetTypeTemplates?.length &&
          templateValues?.assetTypeTemplates?.length > 0
        ) {
          await this.mapAssetTemplate(templateValues);
        } else {
          await this.addNewAssetTemplate();
          this.assets.assetTypeInputs[this.currentIndex].assetTypeId =
            selectedValue ?? '';
          this.assets.assetTypeInputs[this.currentIndex].assetType =
            this.selectedAssetTypeCategory?.assetType ?? '';
        }
      } else {
        this.addNewAssetTemplate();
      }
    } finally {
      const measurementValues =
        await this.assetTypeService.getAllCoreMeasurementTypesByAssetCategory(
          selectedValue,
        );
      this.assets.assetTypeInputs[this.currentIndex].measurementTypesList =
        measurementValues;
      this.isLoader = false;
    }
  }
  async mapAssetTemplate(input: AssetType) {
    const newAssetTemplate = {
      assetType: input.assetType || '',
      assetTypeId: input.id || '',
      activeAssetTypeInputs: this.getActiveAssetTypeInputs(
        input?.assetTypeTemplates,
      ),
      measurementTypesList: [],
      assetTypeTemplateInputs: input?.assetTypeTemplates?.map((template) => ({
        pk: template?.pk || '',
        sk: template?.sk || '',
        id: template?.id || '',
        assetTypeTemplateName: template?.assetTypeTemplateName || '',
        isDefaultFrequency: template?.isDefaultFrequency || true,
        numberOfOccurrences1:
          template?.numberOfOccurrences1 ||
          this.selectedAssetTypeCategory?.numberOfOccurrences1 ||
          '',
        duration1:
          template?.duration1 ||
          this.selectedAssetTypeCategory?.duration1 ||
          '',
        numberOfOccurrences2:
          template?.numberOfOccurrences2 ||
          this.selectedAssetTypeCategory?.numberOfOccurrences2 ||
          '',
        duration2:
          template?.duration2 ||
          this.selectedAssetTypeCategory?.duration2 ||
          '',
        customFrequencyString: this.custFrequency,
        isDeleted: template?.isDeleted || false,
        activeMeasurementCriteriasCount:
          this.getActiveMeasurementCriteriasCount(template?.measurementTypes), // Default value, not in the input
        isAnnualCompliance: template?.isAnnualCompliance || false,
        isPeriodicCompliance: template?.isPeriodicCompliance || false,
        isAssetConfigured: template?.isAssetConfigured || false,
        measurementCriteriaInputs: template?.measurementTypes?.map(
          (measurement) => ({
            pk: measurement?.pk || '',
            sk: measurement?.sk || '',
            id: measurement?.id || '',
            compliance: measurement?.compliance || false,
            viewInMainGrid:
              measurement?.viewInMainGrid || measurement?.compliance || false,
            measurementSource: measurement?.measurementSource || '',
            measurementType: measurement?.measurementType || '',
            isDeleted: measurement?.isDeleted || false,
          }),
        ),
      })),
    };
    this.assets.assetTypeInputs[this.currentIndex] = newAssetTemplate;
  }

  isUniqueAssetName(value: string, index: number): boolean {
    return (
      this.assets.assetTypeInputs[
        this.currentIndex
      ].assetTypeTemplateInputs.findIndex(
        (asset: any, i: number) =>
          i !== index && asset.assetTypeTemplateName === value,
      ) === -1
    );
  }

  isUniqueMeasurementCombination(
    source: string,
    type: string,
    index: number,
    measurementIndex: number,
  ): boolean {
    // Safeguard to ensure all references are valid before accessing the array
    const measurementCriteriaInputs =
      this.assets?.assetTypeInputs?.[this.currentIndex]
        ?.assetTypeTemplateInputs?.[index]?.measurementCriteriaInputs;

    // If undefined, assume valid to avoid errors
    if (
      !measurementCriteriaInputs ||
      !Array.isArray(measurementCriteriaInputs)
    ) {
      return true;
    }

    // Normalize input for comparison (optional)
    const normalizedSource = source.trim().toLowerCase();
    const normalizedType = type.trim().toLowerCase();

    // Perform the uniqueness check
    return (
      measurementCriteriaInputs.findIndex(
        (measurement: any, i: number) =>
          i !== measurementIndex &&
          !measurement.isDeleted &&
          measurement.measurementSource?.trim().toLowerCase() ===
            normalizedSource &&
          measurement.measurementType?.trim().toLowerCase() === normalizedType,
      ) === -1
    );
  }

  validateAssetTypeName(value: string, index: number): void {
    const isUnique = this.isUniqueAssetName(value, index);
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      index
    ].isNotUnique = !isUnique;
    this.checkFormChanges();
  }
  createAssetTypeBtnClick() {
    if (
      this.currentIndex < this.assets.assetTypeInputs.length &&
      this.assets.assetTypeInputs[this.currentIndex].assetTypeId
    ) {
      this.currentIndex = this.assets.assetTypeInputs.length;
      this.addNewAssetTemplate();
    } else {
      this.toastr.showError(CREATE_NEW_ASSET_TEMPLATE_FAILED, ERROR);
    }
  }

  enableCustomInput(assetIndex: number, measurementIndex: number): void {
    this.assets.assetTypeInputs[this.currentIndex].assetTypeTemplateInputs[
      assetIndex
    ].measurementCriteriaInputs[
      measurementIndex
    ].isCustomTestStationOptionSelected = true;
  }
}
