import { ElementRef, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { filterButtonsEnum } from '../../enums';
import { BaseAWSRequestService } from '../base-services/base-aws-request.service';
import {
  AssetType,
  AssetTypeConnection,
  FilterDropdownInput,
  GetAllAssetTypeByPrimaryCompanyQueryVariables,
  GetAllCoreAssetTemplatesByAssetCategoryQueryVariables,
  GetAllLevel1ValueByPrimaryCompanyQueryVariables,
  GetAllLevel2ValueByPrimaryCompanyQueryVariables,
  GetAllLevel3ValueByPrimaryCompanyQueryVariables,
  GetAllLevel4ValueByPrimaryCompanyQueryVariables,
  GetAllLevelNameByPrimaryCompanyQueryVariables,
  GetAllSegmentTypeByPrimaryCompanyQueryVariables,
  GetAllSurveyRouteBySubFilterInputQueryVariables,
  GetAssetTypeTemplateByPrimaryCompanyIdQueryVariables,
  Level1ValueConnection,
  Level2ValueConnection,
  Level3ValueConnection,
  Level4ValueConnection,
  LevelNameConnection,
  RouteConnection,
  SegmentTypeConnection,
} from '../../../../awsAppSync/API';
import {
  getAllAssetTypeByPrimaryCompany,
  getAllCoreAssetTemplatesByAssetCategory,
  getAllLevel1ValueByPrimaryCompany,
  getAllLevel2ValueByPrimaryCompany,
  getAllLevel3ValueByPrimaryCompany,
  getAllLevel4ValueByPrimaryCompany,
  getAllLevelNameByPrimaryCompany,
  getAllSegmentTypeByPrimaryCompany,
  getAllSurveyRouteBySubFilterInput,
  getAssetTypeTemplateByPrimaryCompanyId,
} from '../../../../awsAppSync/graphql/queries';
import { GENERIC_FAILED_ERROR } from '../../constants';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../../../core/store/authenticate-user.state';
import { UserProfileDataState } from '../../../core/store/user-profile.state';

@Injectable({
  providedIn: 'root',
})
export class HeirarchyFilterServiceService {
  public levelNames: LevelNameConnection | null = null;
  public surveyRouteList: RouteConnection | null = null;
  public level1List: Level1ValueConnection | null = null;
  public level2List: Level2ValueConnection | null = null;
  public level3List: Level3ValueConnection | null = null;
  public segmentTypeList: SegmentTypeConnection | null = null;
  public segmentList: Level4ValueConnection | null = null;
  public assetTypeList: AssetTypeConnection | null = null;

  public filter: { primaryCompanyId: string } = { primaryCompanyId: '' };

  createdAsset:
    | ({
        __typename: 'Asset';
        id: string;
        entityType?: string | null;
        assetName?: string | null;
        primaryCompany?: string | null;
        company?: string | null;
        region?: string | null;
        segmentName?: string | null;
        station?: string | null;
        milestone?: string | null;
        chainage?: string | null;
        assetType?: string | null;
        status?: string | null;
        latitude?: string | null;
        longitude?: string | null;
        assetTypeTemplate?: string | null;
        measurementFrequency?: string | null;
        complainceCriteria?: string | null;
        dateOfInsepction?: string | null;
        nextDelinquencyDate?: string | null;
        imageURL?: string | null;
        createdDate?: string | null;
        updatedDate?: string | null;
        createdBy?: string | null;
        isDeleted?: boolean | null;
        isMeasurementAvailable?: boolean | null;
      } | null)[]
    | null
    | undefined;

  storePrimaryCompanyID$: Observable<string | null>;
  selectedPrimaryCompanyId: string | null = null;

  storeCorViewUserID$: Observable<string | null>;
  corViewUserId: string | null = null;

  storeIsMTXUser$: Observable<boolean | null>;
  isMTXUser: boolean | null = null;

  constructor(
    private awsService: BaseAWSRequestService,
    private store: Store,
  ) {
    this.storePrimaryCompanyID$ = this.store.select(
      AuthenticateUserState.getSyrcPrimaryCompanyId,
    );
    this.storeCorViewUserID$ = this.store.select(
      AuthenticateUserState.getLoggedInCorViewUserId,
    );
    this.storeIsMTXUser$ = this.store.select(UserProfileDataState.getIsMTXUser);
  }

  public selectedFilter = new ReplaySubject<{
    filterId: filterButtonsEnum;
    anchor: ElementRef | null;
  }>(0);
  setSelectedFilter(selectedFilter: {
    filterId: filterButtonsEnum;
    anchor: ElementRef | null;
  }) {
    this.selectedFilter.next(selectedFilter);
  }

  public filterAdded = new BehaviorSubject<{
    filterId: number;
    filterAdded: boolean;
  }>({ filterAdded: false, filterId: 0 });
  public setfilterAdded(filterAdded: {
    filterId: number;
    filterAdded: boolean;
  }) {
    this.filterAdded.next(filterAdded);
  }

  public isClearFilterClick = new BehaviorSubject<boolean>(false);
  public setIsClearFilterClick(isClearFilterClick: boolean) {
    this.isClearFilterClick.next(isClearFilterClick);
  }

  public isSurveyRouteClearFilterClick = new BehaviorSubject<boolean>(false);
  public setIsSurveyRouteClearFilterClick(
    isSurveyRouteClearFilterClick: boolean,
  ) {
    this.isSurveyRouteClearFilterClick.next(isSurveyRouteClearFilterClick);
  }

  public isDropDownOpen = new BehaviorSubject<boolean>(false);
  public setIsDropDownOpen(isDropDownOpen: boolean) {
    this.isDropDownOpen.next(isDropDownOpen);
  }

  public isTrayButtonVisible = new BehaviorSubject<boolean>(true);
  public setIsTrayButtonVisible(isTrayButtonVisible: boolean) {
    this.isTrayButtonVisible.next(isTrayButtonVisible);
  }

  public isSurveyRouteFilterAdded = new BehaviorSubject<boolean>(false);
  public setIsSurveyRouteFilterAdded(isSurveyRouteFilterAdded: boolean) {
    this.isSurveyRouteFilterAdded.next(isSurveyRouteFilterAdded);
  }

  public isPrimaryCompanyChange = new BehaviorSubject<boolean>(false);
  public setIsPrimaryCompanyChange(isPrimaryCompanyChange: boolean) {
    this.isPrimaryCompanyChange.next(isPrimaryCompanyChange);
  }

  async initialize(): Promise<void> {
    this.storePrimaryCompanyID$.subscribe((id) => {
      this.selectedPrimaryCompanyId = id;
    });

    this.storeCorViewUserID$.subscribe((corViewUserId) => {
      this.corViewUserId = corViewUserId;
    });

    this.storeIsMTXUser$.subscribe((isMTXUser) => {
      this.isMTXUser = isMTXUser ?? false;
    });

    this.levelNames = await this.getLevelNames();
    this.level1List = await this.getAllLevel1();
    this.level2List = await this.getAllLevel2();
    this.level3List = await this.getAllLevel3();
    this.segmentTypeList = await this.getAllSegmentTypes();
    this.segmentList = await this.getAllLevel4();
    this.assetTypeList = await this.getAllAssetTypes();
    this.surveyRouteList = await this.getAllSurveyRoutes();
  }

  public async getAllLevelNameByPrimaryCompany(
    primaryCompanyId: string,
  ): Promise<LevelNameConnection | null> {
    const query = getAllLevelNameByPrimaryCompany; // Ensure this is your GraphQL query string or document
    const variables: GetAllLevelNameByPrimaryCompanyQueryVariables = {
      primaryCompanyId,
    };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAllLevelNameByPrimaryCompany: LevelNameConnection;
      }>(query, variables);
      return result.getAllLevelNameByPrimaryCompany || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getAllLevel1ValueByPrimaryCompany(
    primaryCompanyId: string,
  ): Promise<Level1ValueConnection | null> {
    const filterModel: FilterDropdownInput = {};
    filterModel.primaryCompanyId = primaryCompanyId;
    filterModel.isMTXUser = this.isMTXUser;
    filterModel.userId = this.corViewUserId;
    const query = getAllLevel1ValueByPrimaryCompany; // Ensure this is your GraphQL query string or document
    const variables: GetAllLevel1ValueByPrimaryCompanyQueryVariables = {
      input: filterModel,
    };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAllLevel1ValueByPrimaryCompany: Level1ValueConnection;
      }>(query, variables);
      return result.getAllLevel1ValueByPrimaryCompany || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getAllLevel2ValueByPrimaryCompany(
    primaryCompanyId: string,
  ): Promise<Level2ValueConnection | null> {
    const filterModel: FilterDropdownInput = {};
    filterModel.primaryCompanyId = primaryCompanyId;
    filterModel.isMTXUser = this.isMTXUser;
    filterModel.userId = this.corViewUserId;
    const query = getAllLevel2ValueByPrimaryCompany; // Ensure this is your GraphQL query string or document
    const variables: GetAllLevel2ValueByPrimaryCompanyQueryVariables = {
      input: filterModel,
    };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAllLevel2ValueByPrimaryCompany: Level2ValueConnection;
      }>(query, variables);
      return result.getAllLevel2ValueByPrimaryCompany || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getAllLevel3ValueByPrimaryCompany(
    primaryCompanyId: string,
  ): Promise<Level3ValueConnection | null> {
    const filterModel: FilterDropdownInput = {};
    filterModel.primaryCompanyId = primaryCompanyId;
    filterModel.isMTXUser = this.isMTXUser;
    filterModel.userId = this.corViewUserId;
    const query = getAllLevel3ValueByPrimaryCompany; // Ensure this is your GraphQL query string or document
    const variables: GetAllLevel3ValueByPrimaryCompanyQueryVariables = {
      input: filterModel,
    };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAllLevel3ValueByPrimaryCompany: Level3ValueConnection;
      }>(query, variables);
      return result.getAllLevel3ValueByPrimaryCompany || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getAllSegmentTypeByPrimaryCompany(
    primaryCompanyId: string,
  ): Promise<SegmentTypeConnection | null> {
    const query = getAllSegmentTypeByPrimaryCompany; // Ensure this is your GraphQL query string or document
    const variables: GetAllSegmentTypeByPrimaryCompanyQueryVariables = {
      primaryCompanyId,
    };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAllSegmentTypeByPrimaryCompany: SegmentTypeConnection;
      }>(query, variables);
      return result.getAllSegmentTypeByPrimaryCompany || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getAllLevel4ValueByPrimaryCompany(
    primaryCompanyId: string,
  ): Promise<Level4ValueConnection | null> {
    const filterModel: FilterDropdownInput = {};
    filterModel.primaryCompanyId = primaryCompanyId;
    filterModel.isMTXUser = this.isMTXUser;
    filterModel.userId = this.corViewUserId;
    const query = getAllLevel4ValueByPrimaryCompany; // Ensure this is your GraphQL query string or document
    const variables: GetAllLevel4ValueByPrimaryCompanyQueryVariables = {
      input: filterModel,
    };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAllLevel4ValueByPrimaryCompany: Level4ValueConnection;
      }>(query, variables);
      return result.getAllLevel4ValueByPrimaryCompany || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getAllAssetTypeByPrimaryCompany(
    primaryCompanyId: string,
  ): Promise<AssetTypeConnection | null> {
    const query = getAllAssetTypeByPrimaryCompany; // Ensure this is your GraphQL query string or document
    const variables: GetAllAssetTypeByPrimaryCompanyQueryVariables = {
      primaryCompanyId,
    };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAllAssetTypeByPrimaryCompany: AssetTypeConnection;
      }>(query, variables);
      return result.getAllAssetTypeByPrimaryCompany || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getAllSurveyRouteBySubFilterInput(filter: {
    primaryCompanyId: string;
  }): Promise<RouteConnection | null> {
    const query = getAllSurveyRouteBySubFilterInput; // Ensure this is your GraphQL query string or document
    const variables: GetAllSurveyRouteBySubFilterInputQueryVariables = {
      filter,
    };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAllSurveyRouteBySubFilterInput: RouteConnection;
      }>(query, variables);
      return result.getAllSurveyRouteBySubFilterInput || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  public async getLevelNames() {
    return await this.getAllLevelNameByPrimaryCompany(
      this.selectedPrimaryCompanyId ?? '',
    );
  }

  public async getAllLevel1() {
    return await this.getAllLevel1ValueByPrimaryCompany(
      this.selectedPrimaryCompanyId ?? '',
    );
  }

  public async getAllLevel2() {
    return await this.getAllLevel2ValueByPrimaryCompany(
      this.selectedPrimaryCompanyId ?? '',
    );
  }

  public async getAllLevel3() {
    return await this.getAllLevel3ValueByPrimaryCompany(
      this.selectedPrimaryCompanyId ?? '',
    );
  }

  public async getAllSegmentTypes() {
    return await this.getAllSegmentTypeByPrimaryCompany(
      this.selectedPrimaryCompanyId ?? '',
    );
  }

  public async getAllLevel4() {
    return await this.getAllLevel4ValueByPrimaryCompany(
      this.selectedPrimaryCompanyId ?? '',
    );
  }

  public async getAllAssetTypes() {
    return await this.getAllAssetTypeByPrimaryCompany(
      this.selectedPrimaryCompanyId ?? '',
    );
  }

  public async getAllSurveyRoutes() {
    this.filter = { primaryCompanyId: this.selectedPrimaryCompanyId ?? '' };
    return await this.getAllSurveyRouteBySubFilterInput(this.filter);
  }

  public async getAllAssetTypesByPrimaryCompany(primaryId: string) {
    return await this.getAllAssetTypeByPrimaryCompany(primaryId);
  }

  public async getLevelNamesByPrimaryComapany() {
    return await this.getAllLevelNameByPrimaryCompany(
      this.selectedPrimaryCompanyId ?? '',
    );
  }

  public async getAllCoreAssetTemplatesByAssetCategory(
    assetCategoryId: string,
  ): Promise<AssetType | null> {
    const query = getAllCoreAssetTemplatesByAssetCategory; // Ensure this is your GraphQL query string or document
    const variables: GetAllCoreAssetTemplatesByAssetCategoryQueryVariables = {
      assetCategoryId,
    };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAllCoreAssetTemplatesByAssetCategory: AssetType;
      }>(query, variables);
      return result.getAllCoreAssetTemplatesByAssetCategory || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }
  public async getAssetTypeTemplateByPrimaryCompanyId(
    primaryCompanyId: string,
  ): Promise<AssetTypeConnection | null> {
    const query = getAssetTypeTemplateByPrimaryCompanyId; // Ensure this is your GraphQL query string or document
    const variables: GetAssetTypeTemplateByPrimaryCompanyIdQueryVariables = {
      id: primaryCompanyId,
    };
    try {
      const result = await this.awsService.executeGetQuery<{
        getAssetTypeTemplateByPrimaryCompanyId: AssetTypeConnection;
      }>(query, variables);
      return result.getAssetTypeTemplateByPrimaryCompanyId || null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }
}
