import { Injectable } from '@angular/core';
import { BaseAWSRequestService } from '../../shared/service/base-services/base-aws-request.service';
import {
  BulkImportMutation,
  BulkImportMutationVariables,
  BulkImportResponse,
  CoreAssetTypeConnection,
  CoreMeasurementTypeConnection,
  FileUpload,
  FileUploadInput,
  GetAllCoreAssetTypesQuery,
  GetAllCoreMeasurementTypesByAssetCategoryQuery,
  GetAllCoreMeasurementTypesByAssetCategoryQueryVariables,
  importInput,
  InitializeFileUploadMutationVariables,
} from '../../../awsAppSync/API';
import {
  bulkImport,
  initializeFileUpload,
} from '../../../awsAppSync/graphql/mutations';
import { Observable, of, Subscription } from 'rxjs';
import { generateClient } from 'aws-amplify/api';
import { Store } from '@ngxs/store';
import { AuthenticateUserState } from '../store/authenticate-user.state';
import { ImportInspectionDataService } from './import-inspection-data.service';
import {
  getAllCoreAssetTypes,
  getAllCoreMeasurementTypesByAssetCategory,
} from '../../../awsAppSync/graphql/queries';
import { GENERIC_FAILED_ERROR } from '../../shared/constants';

export interface BulkImportStatus {
  userId: string;
  status: string;
  fileURL: string;
  importMessage: string;
  __typename: string;
}
@Injectable({
  providedIn: 'root',
})
export class ImportService {
  public client: any;
  private idToken: string | null = null;
  private idToken$: Observable<string | null> = of(null);
  measurementDetails: string[] = [];
  private subscriptions: Subscription = new Subscription();
  constructor(
    private awsService: BaseAWSRequestService,
    private store: Store,
    private importInspectionData: ImportInspectionDataService,
  ) {
    this.client = generateClient();
    this.idToken$ = this.store.select(AuthenticateUserState.getIdToken);
    this.subscriptions.add(
      this.idToken$.subscribe((token) => {
        this.idToken = token;
      }),
    );
  }

  async bulkImport(input: importInput): Promise<BulkImportResponse | null> {
    try {
      const variables: BulkImportMutationVariables = { input };

      let result = await this.awsService.executeMutation<BulkImportMutation>(
        bulkImport,
        variables,
      );

      result = result?.bulkImport || null;

      if (result) {
        return result;
      }
      return null;
    } catch (error) {
      console.error('Error during bulk import', error);
      return null;
    }
  }

  async uploadExcelFile(input: FileUploadInput): Promise<FileUpload | null> {
    try {
      const variables: InitializeFileUploadMutationVariables = { input };

      let uploadedResult = await this.awsService.executeMutation<FileUpload>(
        initializeFileUpload,
        variables,
      );
      uploadedResult = uploadedResult.initializeFileUpload;
      if (uploadedResult && uploadedResult.presignedURL) {
        return uploadedResult;
      }

      return null;
    } catch (error) {
      console.error('Error during file upload', error);
      return null;
    }
  }

  executeImportSubscription(userId: string): Observable<BulkImportStatus> {
    const subscriptionQuery = /* GraphQL */ `
      subscription OnUpdateBulkImportStatus($userId: String!) {
        onUpdateBulkImportStatus(userId: $userId) {
          userId
          status
          fileURL
          importMessage
          __typename
        }
      }
    `;

    const subscriptionObservable = this.client.graphql({
      query: subscriptionQuery,
      variables: { userId: userId },
      authToken: this.idToken,
    });

    console.log('GraphQL subscription initiated.');

    return new Observable<BulkImportStatus>((observer) => {
      const subscription = subscriptionObservable.subscribe({
        next: (response: any) => {
          const data = response?.data?.onUpdateBulkImportStatus;
          if (data) {
            observer.next(data);
          } else {
            observer.error('Invalid subscription response.');
          }
        },
        error: (error: any) => {
          observer.error(error);
        },
        complete: () => {
          observer.complete();
        },
      });

      return () => {
        subscription.unsubscribe();
      };
    });
  }

  async getAllCoreAssetTypes(): Promise<CoreAssetTypeConnection | null> {
    try {
      const response =
        await this.awsService.executeGetQuery<GetAllCoreAssetTypesQuery>(
          getAllCoreAssetTypes,
        );
      return response.getAllCoreAssetTypes ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }

  async getAllCoreMeasurementTypesByAssetCategory(
    assetCategoryId: string,
  ): Promise<CoreMeasurementTypeConnection | null> {
    try {
      const variables: GetAllCoreMeasurementTypesByAssetCategoryQueryVariables =
        { assetCategoryId };
      const response =
        await this.awsService.executeGetQuery<GetAllCoreMeasurementTypesByAssetCategoryQuery>(
          getAllCoreMeasurementTypesByAssetCategory,
          variables,
        );
      return response.getAllCoreMeasurementTypesByAssetCategory ?? null;
    } catch (error) {
      console.error(GENERIC_FAILED_ERROR, error);
      return null;
    }
  }
}
