import { Injectable } from '@angular/core';
import { ExceptionConnection, ExceptionResponse, FilterInput, GetAllExceptionsQuery, GetAllExceptionsQueryVariables, UpdateExceptionInput, UpdateExceptionMutationVariables } from '../../../../awsAppSync/API';
import { BaseAWSRequestService } from '../../../shared/service/base-services/base-aws-request.service';
import { getAllExceptions } from '../../../../awsAppSync/graphql/queries';
import { EMPTY_STRING } from '../../../shared/constants';
import { updateException } from '../../../../awsAppSync/graphql/mutations';

@Injectable({
  providedIn: 'root',
})
export class ExceptionService {
  selectedRowData: any;
  public id: string = EMPTY_STRING;
  exceptiondynamicColumns: any[] = [];

  constructor(
    private awsService: BaseAWSRequestService,
  ) {}


  async getAllExceptionsData(filter: FilterInput): Promise<ExceptionConnection | null> {
    try {
      if(this.selectedRowData){
        if(this.selectedRowData.assetName){
          filter.searchText = this.selectedRowData.assetName;
          this.selectedRowData = null;
        }
      }
      const variables: GetAllExceptionsQueryVariables = { filter };
      const result = await this.awsService.executeGetQuery<GetAllExceptionsQuery>(
        getAllExceptions,
        variables
      );
      return result.getAllExceptions ?? null; // Using nullish coalescing operator
    } catch (error) {
      console.error("Error while fetching data", error);
      return null;
    }
  }

  async updateException(
    input: UpdateExceptionInput,
  ): Promise<ExceptionResponse | null> {
    const mutation = updateException;
    const variables: UpdateExceptionMutationVariables = { input };
    try {
      const exceptionpResult = await this.awsService.executeMutation<{updateException: ExceptionResponse;}>(mutation, variables);
      const exceptionResponse = exceptionpResult.updateException || null;
      this.id = exceptionResponse?.item?.id || null;
      return exceptionpResult.updateException || null;
    } catch (error) {
      console.error("Error updating exception", error);
      return null;
    }
  }

}
